import { config } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";

export const Login = () => {
  // Onclick enter Login
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      LoginCustomer();
    }
  };

  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClose = () => {
    setShow(false);
    setEmail("");
  };
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
    setShow(false);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setShow2(true);
    setShow1(false);
  };
  const Customer = JSON.parse(sessionStorage.getItem("customer"));

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Cpassword, setCpassword] = useState("");

  const LoginCustomer = async () => {
    try {
      const config = {
        url: "/customer/logincustomer",
        baseURL: "https://hayyyak.com/api",
        method: "POST",
        Headers: { "content-type": "application-json" },
        data: {
          Email: Email,
          Password: Password,
        },
      };

      await axios(config).then((res) => {
        if (res.status == 200) {
          alert(res.data.success);
          sessionStorage.setItem("customer", JSON.stringify(res.data.details));
          window.location.assign("/");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // send mail

  const sendMail = async () => {
    if (!Email) {
      return alert("Please Enter Your Email");
    }
    try {
      const config = {
        url: "/customer/sendmail",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        Headers: { "Content-Type": "application/json" },
        data: {
          Email: Email,
        },
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          alert(res.data.success);
          handleShow1();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // OTP Verification

  const [Otp, setOtp] = useState();

  const Otpverification = async () => {
    try {
      const config = {
        url: "/customer/otpverification",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        Headers: { "Content-Type": "application/json" },
        data: {
          Email: Email,
          otp: Otp,
        },
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          setuserId(res.data.success);
          alert(res.data.success);
          handleShow2();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [userId, setuserId] = useState();
  // New Password
  const NewPassword = async () => {
    if (Password !== Cpassword) {
      return alert("New password & Confirm password does not match");
    }
    // alert(Email)
    try {
      const config = {
        url: "/customer/newpassword",
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          Password: Password,
          Email: Email,
        },
      };

      const response = await axios(config);

      if (response.status === 200) {
        alert(response.data.success);
        window.location.reload();
      } else {
        alert("Password update failed");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: 'url("./img/header-bg-img-3.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          padding: "68px",
        }}

        className="login-page"
      >
        <Container>
          <div className="login-bg">
            <div className="login mb-4">
              <h4>
                Sign <span className="text-danger">In</span>
              </h4>
              <p className="text-secondary">Enter Your Details</p>
            </div>

            <div className="mb-4">
              <InputGroup className="mb-4">
                <Form.Control
                  className="login-input"
                  type="email"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="on"
                  onKeyDown={handleKeyPress}
                />
              </InputGroup>
              <div className="login-eye">
                <Form.Control
                  className="login-input"
                  style={{ borderRadius: "5px 0px 0px 5px" }}
                  type={showPassword1 ? "text" : "password"}
                  placeholder="Password here"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <div
                  onClick={togglePasswordVisibility1}
                  className="login-1-eye"
                >
                  {showPassword1 ? (
                    <>
                      {" "}
                      <AiFillEye />
                    </>
                  ) : (
                    <>
                      <AiFillEyeInvisible />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <Button
                className="header-search"
                style={{ width: "100%" }}
                onClick={LoginCustomer}
              >
                Login
              </Button>
            </div>

            <div className="text-center mb-4">
              <p>
                <span
                  onClick={handleShow}
                  style={{ color: "#d81d4a", cursor: "pointer" }}
                >
                  Forgot Password
                </span>{" "}
                | Are you a new user ? |{" "}
                <Link
                  to="/register"
                  style={{
                    color: "#d81d4a",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Register
                </Link>
              </p>
            </div>

            {/* <div className='login mb-4'>
                            <h4>Sign <span className='text-danger'>In</span> using</h4>
                        </div>

                        <ul className='login-social'>
                            <li>
                                <a href="#" className='fb'><FaFacebookF className='me-1 fs-6' />Facebook</a>
                            </li>
                            <li>
                                <a href="#" className='twit'><BsTwitter className='me-1 fs-6' />Twitter</a>
                            </li>
                            <li>
                                <a href="#" className='gogle'><FaGooglePlusG className='me-1 fs-5' />Google</a>
                            </li>
                        </ul> */}
          </div>
        </Container>
      </div>

      {/* Forgot Password Modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="login mb-4">
            <h4>
              Forgot <span className="text-danger">Password</span>
            </h4>
            <p className="text-secondary">Enter Your Email</p>
          </div>

          <InputGroup className="mb-4">
            <Form.Control
              className="login-input"
              type="email"
              placeholder="Email Id"
              aria-describedby="basic-addon1"
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>

          <div className="mb-4">
            <Button
              onClick={sendMail}
              className="header-search"
              style={{ width: "100%" }}
            >
              Send OTP
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* OTP Modal */}

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="login mb-4">
            <h4>
              Enter Your <span className="text-danger">OTP</span>
            </h4>
          </div>
          <InputGroup className="mb-4">
            <Form.Control
              className="login-input"
              type="text"
              placeholder="Enter OTP"
              aria-describedby="basic-addon1"
              onChange={(e) => setOtp(e.target.value)}
            />
          </InputGroup>

          <div className="mb-4">
            <Button
              onClick={Otpverification}
              className="header-search"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* New Password */}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="login mb-4">
            <h4>
              Enter New <span className="text-danger"> Password </span>
            </h4>
          </div>
          <div className="login-eye mb-3">
            <Form.Control
              className="login-input"
              style={{ borderRadius: "5px 0px 0px 5px" }}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              aria-describedby="basic-addon1"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div onClick={togglePasswordVisibility} className="login-1-eye">
              {showPassword ? (
                <>
                  {" "}
                  <AiFillEye />
                </>
              ) : (
                <>
                  <AiFillEyeInvisible />
                </>
              )}
            </div>
          </div>

          <div className="login-eye mb-3">
            <Form.Control
              className="login-input"
              type={showPassword ? "text" : "password"}
              style={{ borderRadius: "5px 0px 0px 5px" }}
              placeholder="Confirm Password"
              aria-describedby="basic-addon1"
              onChange={(e) => setCpassword(e.target.value)}
            />
            <div onClick={togglePasswordVisibility} className="login-1-eye">
              {showPassword ? (
                <>
                  {" "}
                  <AiFillEye />
                </>
              ) : (
                <>
                  <AiFillEyeInvisible />
                </>
              )}
            </div>
          </div>

          <div className="mb-4">
            <Button
              onClick={NewPassword}
              className="header-search"
              style={{ width: "100%" }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
