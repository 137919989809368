import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, Stack } from '@mui/material'


function AdminVehicleModel() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getVehiclecategory()
        GetVehicleModel()
    }, [])


    // Add Vechicle Category
    const [vehiclecategory, setvehiclecategory] = useState("")
    const [vehicleModel, setvehicleModel] = useState("")
    const AddVechicleModel = async () => {
        if (!vehiclecategory) {
            return notify1("Please Select Vehicle Category")
        }
        if (!vehicleModel) {
            return notify1("Please Enter Vehicle Model")
        }
        try {
            const config = {
                url: "/addvechiclemodel",
                baseURL: "https://hayyyak.com/api/admin/taxi",
                method: "post",
                header: { "content-type": "application/json" },
                data: {
                    vehiclecategory: vehiclecategory,
                    vehicleModel: vehicleModel
                }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    handleClose()
                    GetVehicleModel()
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // EDIT Vehicle Category 
    const [editdata, seteditdata] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (id) => { setShow1(true); seteditdata(id) }
    const EditVechicleModel = async () => {
        try {
            const config = {
                url: "/editvechiclemodel/" + editdata,
                baseURL: "https://hayyyak.com/api/admin/taxi",
                method: "put",
                headers: { "content-type": "application/json" },
                data: {
                    vehiclecategory: vehiclecategory,
                    vehicleModel: vehicleModel
                }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    handleClose1()
                    GetVehicleModel()
                }
            })

        } catch (error) {
            console.log(error)
            notify2(error.response.data.error)
        }
    }

    // get vehicle model
    const [getVehicleModelData, setgetVehicleModelData] = useState([]);
    const GetVehicleModel = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/taxi/getvehiclemodel"
            )
            if (res.status === 200) {
                setgetVehicleModelData(res.data.success)                
                setPagination(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Get Vehicle Category Data
    const [getVehicleCat, setgetVehicleCat] = useState([])
    const getVehiclecategory = () => {
        axios.get("https://hayyyak.com/api/admin/taxi/getvechiclecategory")
            .then(function (response) {
                setgetVehicleCat(response.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // Delete 
    const [Data, setData] = useState({});
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => { setShow2(true); setData(id); }

    const DeleteVechicleModel = async () => {
        try {
            const config = {
                url: "/deletevehiclemodel/" + Data,
                baseURL: "https://hayyyak.com/api/admin/taxi",
                method: 'delete',
                headers: { "Content-Type": "application/json" },
            };
            const response = await axios(config);
            if (response.status === 200) {
                notify(response.data.success);
                GetVehicleModel();
                handleClose2();
            }
        } catch (error) {
            notify2(error.response ? error.response.data.error : "An error occurred");
        }
    };

          //Pagination
  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(pagination?.length / usersPerPage);
  const changePage = (selected) => {
    setPageNumber(selected);
  };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Vehicle Model :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Model</button>
                </div>


                <div >
                    <Table responsive bordered className='sdfsd-table-head'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Vehicle Category</th>
                                <th>Vehicle Model</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getVehicleModelData?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item?.vehiclecategory}</td>
                                        <td>{item?.vehicleModel}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <BiSolidEdit className='text-primary' onClick={() => handleShow1(item?._id)} />
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item?._id)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}



                        </tbody>
                    </Table>
                </div>
                <div className="my-3">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              color="primary"
              onChange={(event, value) => {
                changePage(value - 1);
              }}
            />
          </Stack>
        </div>
            </Container>

            {/* Add Activity Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Vechicle Category :</label>
                        <Form.Select aria-label="Default select example" className='vi_0' onChange={(e) => setvehiclecategory(e.target.value)}>
                            <option value="1">Select</option>
                            {getVehicleCat?.map((item) => {
                                return (
                                    <option value={item?.vehiclecategory}>{item?.vehiclecategory}</option>
                                )
                            })}
                        </Form.Select>
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Enter Vechicle Model :</label>
                        <input
                            type="text"
                            placeholder="Category"
                            className="vi_0"
                            onChange={(e) => setvehicleModel(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddVechicleModel}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Activity */}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Vechicle Category :</label>
                        <Form.Select aria-label="Default select example" className='vi_0' onChange={(e) => setvehiclecategory(e.target.value)}>
                            <option value="1">Select</option>
                            {getVehicleCat?.map((item) => {
                                return (
                                    <option value={item?.vehiclecategory}>{item?.vehiclecategory}</option>
                                )
                            })}
                        </Form.Select>
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Enter Vechicle Model :</label>
                        <input
                            type="text"
                            placeholder="Category"
                            className="vi_0"
                            onChange={(e) => setvehicleModel(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger"
                        onClick={EditVechicleModel}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeleteVechicleModel} ><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminVehicleModel
