import React from 'react'
import { Container, InputGroup, Form, Button } from 'react-bootstrap'

export const BookingPage = () => {
  return (
    <div>

      <div
        style={{
          backgroundImage: 'url("./img/header-bg-img-1.jpg")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: 'auto',
          width: '100%',
          padding: '20px'
        }}
      >
        <Container>
          <div className="row align-items-center">
            <div className="col-lg-6 siri">
              <h5 >WORLD'S LEADING TOUR AND TRAVELS TEMPLATE</h5>
              <h1>Hotel booking now!</h1>
              <p>Experience the various exciting tour and travel packages and Make hotel reservations, find vacation packages, search cheap hotels and events</p>
            </div>

            <div className='login-bg col-lg-6 p-5'>
              {/* <div className='login mb-4'>
                <h4>Create an <span className='text-danger'>Account</span></h4>
                <p className='text-secondary'>Enter Your Details</p>
              </div> */}

              <div className='mb-4'>
                <InputGroup className='mb-4'>
                  <Form.Control
                    className='login-input'
                    placeholder=" Name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>

                <div className="row ">
                  <div className="col-lg-6"> <InputGroup className='mb-4' >
                    <Form.Control
                      type='number'
                      className='login-input'
                      placeholder="Mobile"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup></div>
                  <div className="col-lg-6">
                    <InputGroup className='mb-4' >
                      <Form.Control
                        type='email'
                        className='login-input'
                        placeholder="Email"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup></div>
                </div>


                <div className="mb-4">
                  <Form.Select aria-label="Default select example" style={{ border: '1px solid  rgb(0 0 0 / 55%)' }}>
                    <option>City, Destination and Hotel Name</option>
                    <option value="1">Chennai</option>
                    <option value="2">New York</option>
                    <option value="3">Perth</option>
                    <option value="3">London</option>
                  </Form.Select>
                </div>

                <div className="row ">
                  <div className="col-lg-6">
                    <InputGroup className='mb-4' >
                      <Form.Control
                        // type='date'
                        className='login-input'
                        placeholder="Check In"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup></div>
                  <div className="col-lg-6">
                    <InputGroup className='mb-4' >
                      <Form.Control
                        // type='date'
                        className='login-input'
                        placeholder="Check Out"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup></div>
                </div>

                <div className="row mb-4">
                  <div className="col-lg-6">
                    <Form.Select aria-label="Default select example" style={{ border: '1px solid  rgb(0 0 0 / 55%)' }}>
                      <option>Number Of Rooms</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3">4</option>
                      <option value="3">5</option>
                      <option value="3">6</option>
                    </Form.Select>
                  </div>
                  <div className="col-lg-6">
                    <Form.Select aria-label="Default select example" style={{ border: '1px solid  rgb(0 0 0 / 55%)' }}>
                      <option>Room Type</option>
                      <option value="1">Ultra Premium</option>
                      <option value="2">New York</option>
                      <option value="3">Ultra Delux</option>
                      <option value="3">Premium</option>
                    </Form.Select>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-lg-6">
                    <Form.Select aria-label="Default select example" style={{ border: '1px solid  rgb(0 0 0 / 55%)' }}>
                      <option>Number Of Adults</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3">4</option>
                      <option value="3">5</option>
                      <option value="3">6</option>
                    </Form.Select>
                  </div>
                  <div className="col-lg-6">
                    <Form.Select aria-label="Default select example" style={{ border: '1px solid  rgb(0 0 0 / 55%)' }}>
                      <option>Number Of Childrens</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3">4</option>
                      <option value="3">5</option>
                      <option value="3">6</option>
                    </Form.Select>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-6"> <InputGroup className='mb-4' >
                    <Form.Control
                      type='text'
                      className='login-input'
                      placeholder="Min Price"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup></div>
                  <div className="col-lg-6">
                    <InputGroup className='mb-4' >
                      <Form.Control
                        type='text'
                        className='login-input'
                        placeholder="Max Price"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup></div>
                </div>


              </div>

              <div className='mb-4'>
                <Button className='header-search' style={{ width: '100%' }}>
                  Book Now
                </Button>
              </div>
            </div>

          </div>
        </Container>

      </div>
    </div>
  )
}
