import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Carousel, Container, Form, Modal } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import parse from "html-react-parser"


export const HotelRooms = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [show1, setShow1] = useState(false);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);

    // data passing through item
    const location = useLocation()
    const { item, stayslocation } = location.state;
    console.log("itemmmm", item);
    console.log("stayslocation", stayslocation);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Container style={{ maxHeight: '250px', overflow: 'auto', marginBottom: '20px', WebkitScrollbarWidth: '6px' }}>
                {item?.Rooms?.map((ele) => {
                    return (
                        <div className=" mb-4 "
                            style={{
                                borderBottom: '1px solid #0000005e',
                                padding: '12px',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                position: 'relative'
                            }}>
                            <div className='row justify-content-between'>
                                <div className="col-lg-4 hotel-details-img sgdffhjd_0 ">
                                    <Carousel>
                                        {ele?.roomimages?.map((ele1) => {
                                            return (
                                                <Carousel.Item interval={1000}>
                                                    <img src={`https://hayyyak.com/VendorStay/${ele1?.image}`} alt="" />
                                                </Carousel.Item>
                                            )
                                        })}
                                    </Carousel>
                                </div>

                                <div className='dnasj_01 col-lg-4'>
                                    <p className=" activity-h2 fw-bold" style={{fontSize:"30px"}}>{ele?.roomname}</p>
                                    {parse(`<div>${ele?.amenities}</div>`)}
                                </div>

                                <div className="col-lg-4">
                                    <p className='text-secondary'>Per Night</p>
                                    <s className='fs-5 text-secondary'>₹{ele?.adultprice}</s>
                                    <p className='text-danger fw-bold fs-4'>₹{ele?.adultdiscountprice} <span className='text-secondary fs-6'>/night</span></p>
                                    <p style={{ fontSize: '14px', letterSpacing: '1px' }}>+ include taxes & fees</p>
                                    <div className="d-flex gap-4 align-items-center mt-3">
                                        <button className="yellow-btn" onClick={handleShow1}>
                                            Check Availablity
                                        </button>
                                        <Link state={{
                                            item2: item,
                                            item: ele,
                                            stayslocation: stayslocation
                                        }} to="/hotelreview" className="text-decoration-none">
                                            <button className="pink-btn" >Book</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='activity-offer'>
                                {ele?.adultdiscount}%off
                            </div>
                        </div>
                    )
                })}

            </Container>


            {/* Hotel Book Modal */}
            < Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Fill The Form for Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body> <form>
                    <div className="form-group pt-2">
                        <input
                            type="text"
                            className=" vi_1"
                            name="Last Name"

                            placeholder="Full Name*"
                            required=""

                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            className=" vi_1"

                            placeholder="Email Id*"
                            required=""
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className=" vi_1"
                            name="mobileno"

                            placeholder="Mobile No*"
                        />
                    </div>
                    <div className="row">
                        <label
                            style={{
                                fontWeight: "600",
                                color: "white",
                                fontSize: "14px",
                            }}
                        >
                            Select Package
                        </label>
                        <div className="col-md-12">
                            <div class="form-group">
                                <Form.Select
                                    className="vi_1"

                                >
                                    <option>Select package</option>

                                </Form.Select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div class="form-group">
                                <Form.Select
                                    className="vi_1"
                                >
                                    <option>Select Occupancy</option>
                                    <option value="Single Occupancy Weekday">
                                        Single Occupancy Weekday{" "}
                                    </option>
                                    <option value="Double Occupancy Weekday">
                                        Double Occupancy Weekday
                                    </option>
                                    <option value="Single Occupancy Weekend">
                                        Single Occupancy Weekend{" "}
                                    </option>
                                    <option value="Double Occupancy Weekend">
                                        Double Occupancy Weekend
                                    </option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label
                            style={{
                                fontWeight: "600",
                                color: "white",
                                fontSize: "14px",
                            }}
                        >
                            Check In Date/Time
                        </label>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input
                                    type="date"
                                    className=" vi_1"
                                    name="Description"



                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <input
                                    type="time"
                                    className=" vi_1"


                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label
                            style={{
                                fontWeight: "600",
                                color: "white",
                                fontSize: "14px",
                            }}
                        >
                            Check Out Date/Time
                        </label>
                        <div className="col-md-6">
                            <div class="form-group ">
                                <input
                                    type="date"
                                    className="vi_1"


                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group ">
                                <input
                                    type="time"
                                    className="vi_1"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label
                            style={{
                                fontWeight: "600",
                                color: "white",
                                fontSize: "14px",
                            }}
                        >
                            Number Of Guest
                        </label>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="number"
                                    className=" vi_1"
                                    placeholder="Adults"
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div class="form-group">
                                <input
                                    type="number"
                                    className="vi_1"
                                    min="0"
                                    // value={NumberOfChild}
                                    placeholder="Childs"
                                />
                            </div>
                        </div>

                        <div>
                            <div >
                                <input
                                    type="number"
                                    className="vi_1"
                                    min="0"
                                    // value={NumberOfChild}
                                    placeholder="Age"
                                />
                            </div>
                        </div>
                    </div>

                    <div >
                        <input
                            type="text"
                            className=" vi_1"
                            placeholder="Extra matter"
                        />
                    </div>


                </form></Modal.Body>
                <Modal.Footer>
                    <button className="pink-btn" onClick={handleClose}>
                        Book
                    </button>
                </Modal.Footer>
            </Modal >

            {/* Check Avaiablity Modal */}
            < Modal show={show1} onHide={handleClose1} className="amenties-modal">
                <Modal.Header closeButton>
                    <div>Check <span className='text-danger'> Availablity</span></div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-end">
                        <div className='col-lg-4'>
                            <label className='fw-bold'>From :</label>
                            <input type="date" className='form-control' />
                        </div>
                        <div className='col-lg-4 mb-2'>
                            <label className='fw-bold'>To :</label>
                            <input type="date" className='form-control' />
                        </div>
                        <div className='col-lg-4'>
                            <button className='pink-btn'>Check</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </div>
    )
}
