import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineHome, AiOutlineUserAdd } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { TiTickOutline } from "react-icons/ti";
import { ImCancelCircle } from "react-icons/im";
import axios from "axios";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import MRCommunityHeader from "./MobileRes/MRCommunityHeader";

export const CommunityHeader = () => {
  const [IsOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!IsOpen);
  };

  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  if (!Customer) {
    alert("Please Login..!");
    window.location.assign("/login");
  }

  return (
    <div>
      <div className="facebook">
        <Container fluid>
          <div className="row p-2 d-flex align-items-center justify-content-between">
            <div className="col-lg-5 d-flex gap-2 align-items-center">
              <a href="/community">
                <img src="./img/logo.jpeg" alt="" style={{ height: "62px" }} />
              </a>
              <div className="input-group face-search">
                {/* <span className="input-group-text" id="basic-addon1">
                  <BsSearch />
                </span> */}
                {/* <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                /> */}
              </div>
              {/* <span><AiOutlineHome className='fs-4 text-light fw-bold' /></span>
                            <span><AiOutlineUserAdd className='fs-4 text-light fw-bold' /></span> */}
            </div >

            <div className=" col-lg-3 d-flex align-items-center justify-content-center  ">
              <div className="d-flex align-items-center gap-3 mobile-res-community-header">
                {/* <div className="total-post">
                                <span>326</span>
                                <p>Total posts</p>
                            </div>
                            <div className="total-post">
                                <span>2456</span>
                                <p>Total Friends</p>
                            </div> */}
                <Link to="/chat" className="text-decoration-none text-light">
                  <Stack spacing={2} direction="row">
                    <Badge badgeContent={0} color="secondary">
                      <IoChatbubbleEllipsesOutline className='text-light fs-3' />
                    </Badge>
                  </Stack>
                </Link>
                <div style={{ position: "relative" }}>
                  {/* <Stack spacing={2} direction="row">
                                   
                                    <Badge badgeContent={4} color="success">
                                        <NotificationsIcon className='text-light' onClick={handleClick} style={{ cursor: 'pointer' }} />
                                    </Badge>
                                </Stack> */}
                  {IsOpen && (
                    <>
                      <div className="fb-notification">
                        <h3 className="fw-bold text-secondary">Notification</h3>
                        <ul>
                          <li className="d-flex gap-2 align-items-center">
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                margin: "8px",
                              }}
                              src="./img/review-2.png"
                              alt=""
                            />
                            <div>
                              <p className="fw-bold">
                                Ganesh Kalal{" "}
                                <span className="text-secondary">
                                  Send Your Friend Request
                                </span>
                              </p>
                              <div className="d-flex justify-content-between">
                                <p>1 Mutual Fried</p>
                                <div className="jhdjs_0">
                                  <span>
                                    <TiTickOutline />
                                  </span>
                                  <span>
                                    <ImCancelCircle />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="d-flex gap-2 align-items-center">
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                margin: "8px",
                              }}
                              src="./img/review-2.png"
                              alt=""
                            />
                            <div>
                              <p className="fw-bold">
                                Paige Turner{" "}
                                <span className="text-secondary">
                                  Added Their Stories
                                </span>
                              </p>
                              <div className="d-flex justify-content-between">
                                <p>8 Hours ago</p>
                              </div>
                            </div>
                          </li>
                          <li className="d-flex gap-2 align-items-center">
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                margin: "8px",
                              }}
                              src="./img/review-2.png"
                              alt=""
                            />
                            <div>
                              <p className="fw-bold">
                                Paige Turner{" "}
                                <span className="text-secondary">
                                  Send Your Friend Request
                                </span>
                              </p>
                              <div className="d-flex justify-content-between">
                                <p>1 Mutual Fried</p>
                                <div className="jhdjs_0">
                                  <span>
                                    <TiTickOutline />
                                  </span>
                                  <span>
                                    <ImCancelCircle />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="d-flex gap-2 align-items-center">
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                margin: "8px",
                              }}
                              src="./img/review-2.png"
                              alt=""
                            />
                            <div>
                              <p className="fw-bold">
                                Paige Turner{" "}
                                <span className="text-secondary">
                                  Send Your Friend Request
                                </span>
                              </p>
                              <div className="d-flex justify-content-between">
                                <p>1 Mutual Fried</p>
                                <div className="jhdjs_0">
                                  <span>
                                    <TiTickOutline />
                                  </span>
                                  <span>
                                    <ImCancelCircle />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>

                <a href="/coummunityprofile" className="text-decoration-none">
                  <div className="face-profile">
                    {Customer?.profileImage ? (
                      <>
                        <img
                          src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src="./img/profile.jpg"
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    )}

                    <div className="active-dot"></div>
                    <div>
                      <p clas>
                        {Customer?.Fname} {Customer?.Lname}
                      </p>
                      <span>Active Now</span>
                    </div>
                  </div>
                </a>
              </div>
              <MRCommunityHeader />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
