import React from 'react'
import { Container } from 'react-bootstrap'
import V_Activity_Side from './V_Activity_Side'
import V_Activity_Header from './V_Activity_Header'

function V_Activity_Comman(props) {
    return (
        <>
            <Container fluid >
                <div className="dash">
                    <div className="d-flex me-0">
                        <div className=" p-0" >
                            <div className="left-side">
                                <V_Activity_Side />
                            </div>
                        </div>

                        <div className=" p-0 right-h" style={{ width: '83%' }}>
                            <V_Activity_Header />
                            {props.children}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default V_Activity_Comman