import React, { useEffect, useRef, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function VendorAddThingsToDo() {
  const ThingToDoVendor = JSON.parse(sessionStorage.getItem("ThingsVendor"));
  const navigate = useNavigate();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);

  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountryList(countries);
  }, []);

  useEffect(() => {
    if (Country1) {
      const countryCode = Country1.split(",")[0]; // Extracting the country code
      const states = State.getStatesOfCountry(countryCode);
      setStateList(states);
    }
  }, [Country1]);

  useEffect(() => {
    if (Country1 && State1) {
      const countryCode = Country1.split(",")[0]; // Extracting the country code
      const stateCode = State1.split(",")[0]; // Extracting the country code
      const cities = City.getCitiesOfState(countryCode, stateCode);
      setCityList(cities);
    }
  }, [Country1, State1]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [GalleryView, setGalleryView] = useState({});
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // // EDIT Vehicle Category
  const [editdata, seteditdata] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [PlaceName, setPlaceName] = useState("");
  const [MoblileNo, setMoblileNo] = useState("");
  const [PropartyAdd, setPropartyAdd] = useState("");
  const [ThingstodoPincode, setThingstodoPincode] = useState("");
  const [LattitudeTTD, setLattitudeTTD] = useState("");
  const [LongitudeTTD, setLongitudeTTD] = useState("");
  const [PropertyLink, setPropertyLink] = useState("");
  const [ThingatodoEmail, setThingatodoEmail] = useState("");
  const EditThingsToDo = async () => {
    try {
      const config = {
        url: "updatethingstodo/" + editdata?._id,
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo/",
        headers: { "content-type": "application/json" },
        data: {
          thingstodoname: PlaceName,
          thingstodocity: City1,
          thingstodostate: State1,
          thingstodocountry: Country1,
          thingstodoaddress: PropartyAdd,
          latitude: LattitudeTTD,
          longitude: LongitudeTTD,
          pincode: ThingstodoPincode,
          website: PropertyLink,
          phonenumber: MoblileNo,
          email: ThingatodoEmail,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.msg);
        handleClose1();
        GetAdminThingsTodo();
      }
    } catch (error) {
      notify(error.response.data.error);
    }
  };

  const [SubPackageName, setSubPackageName] = useState("");
  const [SubPackageType, setSubPackageType] = useState("");
  const [Price, setPrice] = useState(0);
  const [AgeLimit, setAgeLimit] = useState("");
  const [Duration, setDuration] = useState("");
  const [TicketType, setTicketType] = useState("");
  const [LanguageType, setLanguageType] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Logitude, setLogitude] = useState("");
  const [Address, setAddress] = useState("");
  const [Number, setNumber] = useState();
  const [Email, setEmail] = useState("");
  const [About, setAbout] = useState("");
  const [Include, setInclude] = useState("");
  const [Expect, setExpect] = useState("");
  const [Departure, setDeparture] = useState("");
  const [Accessibility, setAccessibility] = useState("");
  const [AdditionalInfo, setAdditionalInfo] = useState("");
  const [cancellationpolicy, setCancellationpolicy] = useState("");
  const [FAQ, setFAQ] = useState("");
  const [Help, setHelp] = useState("");
  const [SubPackagesId, setSubPackagesId] = useState({});
  
 const [ViewSubThingstodo, setViewSubThingstodo] = useState({});
  console.log("ViewSubThingstodo",ViewSubThingstodo);
  
  const EditSubpackages = async () => {
    try {
      const config = {
        url: "updateaddthingstodovisit/" + SubPackagesId?._id,
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo/",
        headers: { "content-type": "application/json" },
        data: {
          thingsId: ViewSubThingstodo?._id,
          visitname: SubPackageName,
          visittype: SubPackageType,
          price: Price,
          Duration: Duration,
          tickettype: TicketType,
          languagetype: LanguageType,
          latitude: Latitude,
          longitude: Logitude,
          address: Address,
          phonenumber1: Number,
          email1: Email,
          visitdescription: About,
          whatsinclude: Include,
          whattoexcept: Expect,
          Departureandreturn: Departure,
          accessibility: Accessibility,
          addinfo: AdditionalInfo,
          cancelpolicy: cancellationpolicy,
          Faq: FAQ,
          Help: Help,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.msg);
        handleClose5();
        GetAdminThingsTodo();
      }
    } catch (error) {
      notify(error.response.data.error);
    }
  };

  // get Things ToDo
  const [ThingatoDo, setThingatoDo] = useState([]);
  const GetAdminThingsTodo = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/thingstodo/getthingstodonamebyId/" +
          ThingToDoVendor?._id
      );
      if (res.status === 200) {
        setThingatoDo(res.data.Thingsdetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetAdminThingsTodo();
  }, []);

  // // Delete
  const [Data, setData] = useState({});
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    setData(id);
  };

  const DeleteThimgatoDo = async () => {
    try {
      const config = {
        url: "/deletethingstodo/" + Data,
        baseURL: "https://hayyyak.com/api/admin/thingstodo",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const response = await axios(config);
      if (response.status === 200) {
        notify(response.data.sucess);
        GetAdminThingsTodo();
        handleClose2();
      }
    } catch (error) {
      notify2(error.response ? error.response.data.error : "An error occurred");
    }
  };

  const DeleteGalleryImg = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        const config = {
          url: "/deletegallery/" + id,
          baseURL: "https://hayyyak.com/api/admin/thingstodo",
          method: "delete",
          headers: { "Content-Type": "application/json" },
          data: {
            packid: GalleryView?._id,
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          notify(response.data.msg);
          GetAdminThingsTodo();
          setGalleryView(response.data.sucess);
        }
      } catch (error) {
        notify2(
          error.response ? error.response.data.error : "An error occurred"
        );
      }
    } else {
      // notify1("Sorry,Try again..");
    }
  };
  const DeleteSubPackageGalleryImg = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        const config = {
          url: "/deletethingsvisitgallery",
          baseURL: "https://hayyyak.com/api/admin/thingstodo",
          method: "delete",
          headers: { "Content-Type": "application/json" },
          data: {
            mainPackageId: ViewSubThingstodo?._id,
            visitPackageId: SubThingstodoGalleeyView?._id,
            galleryId: id,
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          notify(response.data.success);
          GetAdminThingsTodo();
          setSubThingstodoGalleeyView({
            ...SubThingstodoGalleeyView,
            gallery: response.data.gallery,
          });
        }
      } catch (error) {
        notify2(
          error.response ? error.response.data.error : "An error occurred"
        );
      }
    } else {
      // notify1("Sorry,Try again..");
    }
  };

  const blockResto = async (id) => {
    try {
      const config = {
        url: "/thingstodo/approvedthingatodo",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          restoid: id,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        notify(response.data.success);
        GetAdminThingsTodo();
      }
    } catch (error) {
      console.error("Error:", error); // Log any errors
      notify2(error.response.data.error);
    }
  };

  // Add Gallery Image
  const fileInputRef1 = useRef(null);
  const openFile1 = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    } else {
      console.error("File input ref is not initialized properly.");
    }
  };
  const AddImageGallery = async (image1) => {
    try {
      const configGallery = {
        url: "/thingstodo/Addgallery",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          thingsid: GalleryView?._id,
          packageimages: image1,
        },
      };
      let res = await axios(configGallery);
      if (res.status === 200) {
        notify(res.data.sucess);
        GetAdminThingsTodo();
        setGalleryView(res.data.updateddata);
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

 
  const DeleteSubThingstodo = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        const config = {
          url: "/deletethingsvisit/" + id,
          baseURL: "https://hayyyak.com/api/admin/thingstodo",
          method: "delete",
          headers: { "Content-Type": "application/json" },
          data: {
            packid: ViewSubThingstodo?._id,
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          notify(response.data.msg);
          GetAdminThingsTodo();
          setViewSubThingstodo(response.data.sucess);
        }
      } catch (error) {
        notify2(
          error.response ? error.response.data.error : "An error occurred"
        );
      }
    } else {
      // notify1("Sorry,Try again..");
    }
  };

  // Add SubGallery Image
  const fileInputRef2 = useRef(null);
  const openFile2 = () => {
    if (fileInputRef2.current) {
      fileInputRef2.current.click();
    } else {
      console.error("File input ref is not initialized properly.");
    }
  };

  const [SubThingstodoGalleeyView, setSubThingstodoGalleeyView] = useState({});
  const AddSubImageGallery = async (image1) => {
    try {
      const configsubGallery = {
        url: "/thingstodo/Addvisitgallery",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          visitid: SubThingstodoGalleeyView?._id,
          images: image1,
        },
      };
      let res = await axios(configsubGallery);
      if (res.status === 200) {
        notify(res.data.success);
        GetAdminThingsTodo();
        setSubThingstodoGalleeyView({
          ...SubThingstodoGalleeyView,
          gallery: res.data.gallery,
        });
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2 mb-3">
          <p className="text-danger fs-4 fw-semibold">Things To Do :</p>
          <button
            className="pink-btn"
            onClick={() => navigate("/addtodothingsvendor")}
          >
            Add
          </button>
        </div>

        <div>
          <Table responsive bordered className="admin-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Address</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Map Location</th>
                <th>Location</th>
                <th>Gallery</th>
                <th>Sub Packages</th>
                <th>Block Status</th>
                {/* <th>Block/UnBlock</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ThingatoDo?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>
                        <div>{i + 1}</div>
                      </td>
                      <td>
                        <div>{item?.thingstodoname}</div>
                      </td>
                      <td>
                        <div className="eyew">
                          <p className="eyew">
                            {" "}
                            {item?.thingstodoaddress},{item?.thingstodocity},
                            {"\n"}
                            {item?.thingstodostate},{item?.thingstodocountry},
                            {item?.pincode}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>{item?.phonenumber}</div>
                      </td>
                      <td>
                        <div>{item?.email}</div>
                      </td>
                      <td>
                        <div className="eyew">{item?.website}</div>
                      </td>
                      <td>
                        <div>
                          {item?.latitude}
                          <br />
                          {item?.longitude}
                        </div>{" "}
                      </td>
                      <td>
                        <div
                          className="fs-3 text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          <AiFillEye
                            onClick={() => {
                              handleShow3();
                              setGalleryView(item);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          className="fs-3 text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          <AiFillEye
                            onClick={() => {
                              handleShow();
                              setViewSubThingstodo(item);
                            }}
                          />
                        </div>
                      </td>

                      <td>
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit
                            className="text-primary"
                            onClick={() => {
                              handleShow1();
                              seteditdata(item);
                            }}
                          />
                          <MdDelete
                            className="text-danger"
                            onClick={() => handleShow2(item?._id)}
                          />
                        </div>
                      </td>
                      <td>
                        {item?.isApproved === false ? (
                          <b style={{ color: "red" }}>Block</b>
                        ) : (
                          <b style={{ color: "green" }}> Approved</b>
                        )}
                      </td>
                      {/* <td>
                      {item?.isApproved === false ? (
                        <Button 
                        onClick={()=>blockResto(item?._id)} 
                        variant="success">Approve</Button>
                        ):(
                        <Button  
                        onClick={()=>blockResto(item?._id)} 
                        variant="danger">Block</Button>
                        ) }
                      </td> */}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* Edit ThingsToDo */}
      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            ThingsToDo Edit Model
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Place Name :</label>
              <input
                type="text"
                placeholder={editdata?.thingstodoname}
                className="vi_0"
                onChange={(e) => setPlaceName(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Mobile Number :</label>
              <input
                type="number"
                placeholder={editdata?.phonenumber}
                className="vi_0"
                onChange={(e) => setMoblileNo(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Country :</label>
              <Form.Select
                onChange={(e) => setCountry1(e.target.value)}
                className="vi_0"
              >
                <option>{editdata?.thingstodocountry}</option>
                {CountryList?.map((country) => {
                  return (
                    <option value={`${country?.isoCode},${country?.name}`}>
                      {country?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">State :</label>
              <Form.Select
                onChange={(e) => setState1(e.target.value)}
                className="vi_0"
              >
                <option>{editdata?.thingstodostate}</option>
                {StateList?.map((state) => {
                  return (
                    <option value={`${state?.isoCode},${state?.name}`}>
                      {state?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">City :</label>
              <Form.Select
                onChange={(e) => setCity1(e.target.value)}
                className="vi_0"
              >
                <option>{editdata?.thingstodocity}</option>
                {CityList?.map((city) => {
                  return <option value={city?.name}>{city?.name}</option>;
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Property Address :</label>
              <input
                type="text"
                placeholder={editdata?.thingstodoaddress}
                className="vi_0"
                onChange={(e) => setPropartyAdd(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Pincode :</label>
              <input
                type={editdata?.pincode}
                placeholder="Pincode"
                className="vi_0"
                onChange={(e) => setThingstodoPincode(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Latitude :</label>
              <input
                type="text"
                placeholder={editdata?.latitude}
                className="vi_0"
                onChange={(e) => setLattitudeTTD(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Longitude :</label>
              <input
                type="text"
                placeholder={editdata?.longitude}
                className="vi_0"
                onChange={(e) => setLongitudeTTD(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Property Location(MAP Link) :</label>
              <input
                type="text"
                placeholder={editdata?.website}
                className="vi_0"
                onChange={(e) => setPropertyLink(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Email :</label>
              <input
                type="email"
                placeholder={editdata?.email}
                className="vi_0"
                onChange={(e) => setThingatodoEmail(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button onClick={EditThingsToDo} variant="danger">
            {" "}
            Submit{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Show Sub_ThingsToDo */}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Sub Packages ThingsToDo{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="mb-3"
            style={{ overflow: "hidden", overflowX: "scroll" }}
          >
            <Table className="sdfsd-table-head text-center" bordered>
              <thead>
                <th>SL.No</th>
                <th>name</th>
                <th>type</th>
                <th>Price</th>
                <th>Age Limit </th>
                <th>Duration </th>
                <th>Ticket Type </th>
                <th>Language Type </th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Phone No</th>
                <th>Address</th>
                <th>Email</th>
                <th>About</th>
                <th>Include</th>
                <th>Departure</th>
                <th>Accessibility</th>
                <th>Additional Information</th>
                <th>Gallery</th>
                <th>FAQ</th>
                <th>Help</th>
                <th>Action</th>
              </thead>
              <tbody>
                {ViewSubThingstodo?.visitpackages?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.visitname}</td>
                      <td>{item?.visittype}</td>
                      <td>{item?.price}</td>
                      <td>{item?.agelimit}</td>
                      <td>{item?.Duration}</td>
                      <td>{item?.tickettype}</td>
                      <td>{item?.languagetype}</td>
                      <td>{item?.latitude}</td>
                      <td>{item?.longitude}</td>
                      <td>{item?.phonenumber1}</td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {item?.address}
                        </div>
                      </td>
                      <td>{item?.email1}</td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {parse(`<div>${item?.visitdescription}</div>`)}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {parse(`<div>${item?.whatsinclude}</div>`)}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {" "}
                          {parse(`<div>${item?.Departureandreturn}</div>`)}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {" "}
                          {parse(`<div>${item?.accessibility}</div>`)}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {" "}
                          {parse(`<div>${item?.addinfo}</div>`)}
                        </div>
                      </td>
                      <td className="fs-3 text-danger">
                        <AiFillEye
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleShow4();
                            setSubThingstodoGalleeyView(item);
                          }}
                        />
                      </td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {parse(`<div>${item?.Faq}</div>`)}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "250px",
                            padding: "10px",
                            textAlign: "justify",
                            overflow: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {" "}
                          {parse(`<div>${item?.Help}</div>`)}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <BiSolidEdit
                            style={{
                              cursor: "pointer",
                              color: "green",
                              fontSize: "25px",
                            }}
                            onClick={() => {
                              handleShow5();
                              setSubPackagesId(item);
                            }}
                          />
                          <MdDelete
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontSize: "25px",
                            }}
                            onClick={() => DeleteSubThingstodo(item?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Sub Category */}

      <Modal show={show5} onHide={handleClose5} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Edit Sub-Packages ThingsToDo{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Package name :</label>
                <input
                  type="text"
                  placeholder="Package name"
                  className="vi_0"
                  onChange={(e) => setSubPackageName(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Package type :</label>
                <input
                  type="text"
                  placeholder="Package type"
                  className="vi_0"
                  onChange={(e) => setSubPackageType(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Package Price :</label>
                <input
                  type="number"
                  placeholder="Package price"
                  className="vi_0"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Duration:</label>
                <input
                  type="text"
                  placeholder="Duration"
                  className="vi_0"
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Ticket Type:</label>
                <input
                  type="text"
                  placeholder="ticket type"
                  className="vi_0"
                  onChange={(e) => setTicketType(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Language Type:</label>
                <input
                  type="text"
                  placeholder="language type"
                  className="vi_0"
                  onChange={(e) => setLanguageType(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Latitude:</label>
                <input
                  type="text"
                  placeholder="Latitude"
                  className="vi_0"
                  onChange={(e) => setLatitude(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Longitude:</label>
                <input
                  type="text"
                  placeholder="Longitude"
                  className="vi_0"
                  onChange={(e) => setLogitude(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Address:</label>
                <input
                  type="text"
                  placeholder="Address"
                  className="vi_0"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Phone Number:</label>
                <input
                  type="number"
                  placeholder="phone number"
                  className="vi_0"
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
              <div className=" mt-2 col-lg-4">
                <label className="fw-bold"> Email:</label>
                <input
                  type="email"
                  placeholder="email"
                  className="vi_0"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">About :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={About}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAbout(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">What's Included :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Include}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setInclude(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">What to Expect :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Expect}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setExpect(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">Departure Return :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Departure}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDeparture(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">Accessibility :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Accessibility}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAccessibility(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">Additional Information :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={AdditionalInfo}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAdditionalInfo(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">Cancellation policy :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={cancellationpolicy}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setCancellationpolicy(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">FAQ :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={FAQ}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFAQ(data);
                  }}
                />
              </div>
              <div className=" mt-2 col-lg-6">
                <label className="fw-bold">Help :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Help}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setHelp(data);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose5}>
            Close
          </Button>
          <Button onClick={EditSubpackages} variant="danger">
            {" "}
            Edit{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Gallery Model */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            ref={fileInputRef1}
            style={{ display: "none" }}
            onChange={(e) => {
              AddImageGallery(e.target.files[0]);
            }}
          />
          <Button variant="danger" onClick={openFile1}>
            Add
          </Button>
          <Table className="sdfsd-table-head text-center" bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {GalleryView?.packagegallery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}.</td>
                    <td>
                      <img
                        src={`https://hayyyak.com/Thingstodo/${item?.packageimages}`}
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </td>
                    <td className="text-danger fs-5">
                      <MdDelete
                        style={{ cursor: "pointer" }}
                        onClick={() => DeleteGalleryImg(item?._id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Sub Things To Do Gallery  */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            ref={fileInputRef2}
            style={{ display: "none" }}
            onChange={(e) => {
              AddSubImageGallery(e.target.files[0]);
            }}
          />
          <Button variant="danger" onClick={openFile2}>
            Add
          </Button>
          <Table className="sdfsd-table-head text-center" bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {SubThingstodoGalleeyView?.gallery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}.</td>
                    <td>
                      <img
                        src={`https://hayyyak.com/Thingstodo/${item?.images}`}
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </td>
                    <td className="text-danger fs-5">
                      <MdDelete
                        style={{ cursor: "pointer" }}
                        onClick={() => DeleteSubPackageGalleryImg(item?._id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteThimgatoDo}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default VendorAddThingsToDo;
