import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react'


function DiscountBanner() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);



    // Add Discount Banner
    const [offerBanner, setofferBanner] = useState()
    const AddDiscountBanner = async () => {
        if (!offerBanner) {
            return notify1("Please Add Banner")
        }
        try {
            const config = {
                url: 'admin/home/adddiscountbanner',
                baseURL: 'https://hayyyak.com/api/',
                method: "POST",
                headers: { 'Content-Type': 'multipart/form-data' },
                data: {
                    OfferBanner: offerBanner
                }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify("Banner Added Succesfully")
                    getDiscountBanner()
                    handleClose()
                    setofferBanner("")
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // get Discount Banner
    const [DiscountBanner, steDiscountBanner] = useState([])
    const getDiscountBanner = async () => {
        axios
            .get("https://hayyyak.com/api/admin/home/getdiscountbanner")
            .then(function (response) {
                console.log(response.data);
                steDiscountBanner(response.data.Discount)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    console.log("DiscountBanner", DiscountBanner);

    // Delete Discount Banner
    const [deleteData, setdeleteData] = useState()
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setdeleteData(item) }
    const deleteDiscountBanner = async () => {
        try {
            const config = {
                url: "admin/home/deletediscountbanner/" + deleteData?._id,
                baseURL: "https://hayyyak.com/api",
                method: "DELETE",
                headers: { "Content-Type": "application/json" }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    getDiscountBanner()
                    handleClose2()
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    useEffect(() => {
        getDiscountBanner()
    }, [])
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Discount Banner :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Banner</button>
                </div>

                <div >
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DiscountBanner?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td><img src={`https://hayyyak.com/DiscountBanner/${item?.OfferBanner}`} alt="" style={{ width: '600px', height: '150px', imageRendering: "pixelated" }} /></td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                {/* <BiSolidEdit className='text-primary' onClick={handleShow1} /> */}
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Discount Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setofferBanner(e.target.files[0])}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddDiscountBanner}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            {/* <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Taxi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold'>Choose Image :</label>
                        <input
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                        />
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Name :</label>
                            <input type="text" placeholder='Name' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Category :</label>
                            <Form.Select aria-label="Default select example" className='vi_0'>
                                <option>Select Category</option>
                                <option value="1">Hatchback</option>
                                <option value="2">Sedan</option>
                                <option value="3">Suv</option>
                                <option value="3">Innova</option>
                                <option value="3">Inova Crysta</option>
                                <option value="3">Tempo Traveler</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Model :</label>
                            <input type="text" placeholder='Model' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Ac / Non-Ac :</label>
                            <input type="text" placeholder='AC' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Color :</label>
                            <input type="text" placeholder='Color' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Fuel Type:</label>
                            <input type="text" placeholder='Fuel' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle BasePrice :</label>
                            <input type="text" placeholder='Price' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Trip Type:</label>
                            <input type="text" placeholder='Trip' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Luggage :</label>
                            <input type="text" placeholder='Luggage' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Id:</label>
                            <input type="text" placeholder='ID' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Night Time :</label>
                            <input type="time" placeholder='Time' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Night Charge:</label>
                            <input type="text" placeholder='Charges' className="vi_0" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose1}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteDiscountBanner}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default DiscountBanner
