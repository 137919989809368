import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useRef, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { Country, State, City } from "country-state-city";
import { useEffect } from 'react'
import parse from "html-react-parser"

const AddHotel = () => {
    let admin = JSON.parse(sessionStorage.getItem("admin"));
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [selectedAmenities, setSelectedAmenities] = useState([]);

    const handleSelectChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedAmenities(value);
    };

    const [seleceCategory, setseleceCategory] = useState([]);

    const handleSelectCategory = (event) => {
        const {
            target: { value },
        } = event;
        setseleceCategory(value);
    };

    // Add Gallery Image
    const [imageURL2, setimageURL2] = useState(null);
    const [Image, setImage] = useState("");
    const [galleryImages, setGalleryImages] = useState([]);
    const onImageChange2 = (event) => {
        if (event.target.files && event.target.files[0]) {
            setimageURL2(URL.createObjectURL(event.target.files[0]));
        }
    };
    const fileinputref2 = useRef(null);
    const AddGalleryImage = () => {
        if (!Image) {
            return notify1("Please, Select Image");
        }
        const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

        if (imageExists) {
            return notify2("Image already exists in the gallery.");
        }

        const obj = {
            image: Image,
            imgUrl: imageURL2,
        };

        setGalleryImages([...galleryImages, obj]);
        setImage("")
        if (fileinputref2.current) {
            fileinputref2.current.value = "";
          }
    };
    const removeItem2 = (val) => {
        const ConfirmDelete = window.confirm("Are you sure, want to delete the item..?");
        if(ConfirmDelete){
            const updatedGallery = galleryImages.filter((item, index) => index !== val);
            setGalleryImages(updatedGallery);
        }
    
    };

    // Add FAQ 
    const [allFaq, setallFaq] = useState([]);
    const [question, setquestion] = useState()
    const [answer, setanswer] = useState()
    const AddFaq = () => {
        const FaqExists = allFaq?.filter((ele) =>
            ele.question === question &&
            ele.answer === answer
        )
        if (!FaqExists) {
            return alert("FAQ Details is already exists")
        }
        const obj = {
            question: question,
            answer: answer
        }
        setallFaq([...allFaq, obj])
        setquestion("")
        setanswer("")
    }
    const removeFAQ = (val) => {
        const DeleteConfirm = window.confirm("Are you sure.? Wnat to delete this item..?");
        if(DeleteConfirm){
            const updateFaq = allFaq.filter((item, index) => index !== val);
            setallFaq(updateFaq)
        }     
    }


    //Location
    const [Country1, setCountry1] = useState("")
    const [State1, setState1] = useState("")
    const [City1, setCity1] = useState("")
    const CountryList = Country.getAllCountries();
    const StateList = State.getStatesOfCountry(Country1);
    const CityList = City.getCitiesOfState(Country1, State1);

    const [Aadhar, setAadhar] = useState("");
    const [PanCard, setPanCard] = useState("");
    const [Gst, setGst] = useState("");
    const [GstDocument, setGstDocument] = useState("");
    const [PropartyDoc, setPropartyDoc] = useState("");
    const [PropartyName, setPropartyName] = useState("");
    const [PropartyAddress, setPropartyAddress] = useState("");
    const [location, setlocation] = useState("");
    const [StartingPrice, setStartingPrice] = useState();
    const [Checkin, setCheckin] = useState("");
    const [CheckOut, setCheckOut] = useState("");
    const [Description, setDescription] = useState("");
    const [termsandcondition, settermsandcondition] = useState("");
    const [Suggesition, setSuggesition] = useState("");
    const [Propartyfullimage, setPropartyfullimage] = useState("");
    const [NearByLoc, setNearByLoc] = useState("");
    const [HouseRules, setHouseRules] = useState("");
    const [ThingToDo, setThingToDo] = useState("");

    const formdata = new FormData();
    const AddStaysDetails = async () => {
        if (!Aadhar) {
            return notify1("Please Select Adhaar card")
        }
        if (!PanCard) {
            return notify1("Please Select Pan card")
        }
        if (!Gst) {
            return notify1("Please Enter GST Number")
        }
        if (!GstDocument) {
            return notify1("Please Select GST Document")
        }
        if (!PropartyDoc) {
            return notify1("Please Select Proparty Document")
        }
        if (!PropartyName) {
            return notify1("Please Enter Property Name")
        }
        if (!PropartyAddress) {
            return notify1("Please Enter Property Address")
        }
        if (!location) {
            return notify1("Please Enter Property Location Link")
        }
        if (!Country1) {
            return notify1("Please Select Country")
        }
        if (!State1) {
            return notify1("Please Select State")
        }
        if (!City1) {
            return notify1("Please Select City")
        }
        if (!StartingPrice) {
            return notify1("Please Enter Starting Price")
        }
        if (!selectedAmenities) {
            return notify1("Please Select Amenities")
        }
        if (!seleceCategory) {
            return notify1("Please Select Category")
        }
        if (!Checkin) {
            return notify1("Please Enter Check In Time")
        }
        if (!CheckOut) {
            return notify1("Please Enter Check Out Time")
        }
        if (!Description) {
            return notify1("Please Enter Description")
        }
        if (!termsandcondition) {
            return notify1("Please Enter Terms And Conditions")
        }
        if (!Suggesition) {
            return notify1("Please Enter Suggesitions")
        }
        if (!Propartyfullimage) {
            return notify1("Please Select Property Full Image")
        }
        try {
            formdata.append("vendorId", admin?._id);
            formdata.append("aadhar", Aadhar);
            formdata.append("pancard", PanCard);
            formdata.append("gst", Gst);
            formdata.append("gstdocument", GstDocument);
            formdata.append("propartydoc", PropartyDoc);
            formdata.append("propartyname", PropartyName);
            formdata.append("propartyaddress", PropartyAddress);
            formdata.append("location", location);
            formdata.append("startingprice", StartingPrice);
            formdata.append("checkin", Checkin);
            formdata.append("checkout", CheckOut);
            formdata.append("description", Description);
            formdata.append("termsandcondition", termsandcondition);
            formdata.append("suggesition", Suggesition);
            formdata.append("propartfullimage", Propartyfullimage);
            formdata.append("adminId", "admin");
            formdata.append("country", Country1);
            formdata.append("state", State1);
            formdata.append("city", City1);
            formdata.append("Nearbyplaces", NearByLoc);
            formdata.append("Houserules", HouseRules);
            formdata.append("ThingToDo", ThingToDo);
            formdata.append("faq", JSON.stringify(allFaq));
            formdata.append("Amenities", JSON.stringify(selectedAmenities));
            formdata.append("StaysCategory", JSON.stringify(seleceCategory));
            const config = {
                url: "/admin/stays/adminstaysdetails",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            const response = await axios(config);
            if (response.status === 200) {
                console.log("response", response);
                for (let i = 0; i < galleryImages.length; i++) {
                    const config = {
                        url: "/admin/stays/admingalleryimage",
                        method: "put",
                        baseURL: "https://hayyyak.com/api",
                        headers: { "content-type": "multipart/form-data" },
                        data: {
                            productId: response.data.data?._id,
                            image: galleryImages[i]?.image,
                        },
                    };
                    await axios(config);
                }
                notify("Stays Added Successfully");
                window.location.assign('/stayvendorlist');
            }
        } catch (error) {
            console.log(error);
        }
    };


    // Get Stays Category
    const [StayCategory, setStayCategory] = useState([]);
    const getStayCategory = async () => {
        axios
            .get("https://hayyyak.com/api/admin/stays/getstaycategory")
            .then(function (res) {
                console.log(res.data);
                setStayCategory(res.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // get Stay Amenities
    const [StayAmenities, setStayAmenities] = useState([]);
    const getStayAmenities = async () => {
        axios
            .get("https://hayyyak.com/api/admin/stays/getstayamenities")
            .then(function (response) {
                setStayAmenities(response.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        getStayCategory();
        getStayAmenities();
    }, []);


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <div >
                    <h1 class="display-6 text-center mb-3">Add New <span className='text-danger'>Property</span></h1>

                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className="fw-bold">Aadhar card :</label>
                            <input
                                type="file"
                                multiple
                                className="form-control login-input"
                                onChange={(e) => setAadhar(e.target.files[0])}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold">Pan card :</label>
                            <input
                                type="file"
                                multiple
                                className="form-control login-input"
                                onChange={(e) => setPanCard(e.target.files[0])}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold">GST Number:</label>
                            <input
                                type="number"
                                placeholder='Gst number...'
                                className="form-control login-input"
                                onChange={(e) => setGst(e.target.value)}
                                maxLength={2}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold">GST Document :</label>
                            <input
                                type="file"
                                multiple
                                className="form-control login-input"
                                onChange={(e) =>
                                    setGstDocument(e.target.files[0])
                                }
                            />
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className="fw-bold">
                                Property Document :
                            </label>
                            <input
                                type="file"
                                className="form-control login-input"
                                onChange={(e) =>
                                    setPropartyDoc(e.target.files[0])
                                }
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Name:</label>
                            <input type="text" placeholder='Property Name' className='form-control login-input' onChange={(e) => setPropartyName(e.target.value)} />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Address :</label>
                            <input type="text" placeholder='Property Address' className='form-control login-input' onChange={(e) => setPropartyAddress(e.target.value)} />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Location (Link):</label>
                            <input type="text" placeholder='link' className='form-control login-input' onChange={(e) => setlocation(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className="fw-bold">
                                Country Name:
                            </label>
                            <select

                                className="form-control login-input"
                                onChange={(e) => setCountry1(e.target.value)}
                            >
                                <option>Select Country</option>
                                {CountryList.map((Country) => (
                                    <option value={Country.isoCode}>
                                        {Country.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>State Name :</label>
                            <select

                                className="form-control login-input"
                                onChange={(e) => setState1(e.target.value)}
                            >
                                <option>Select State</option>
                                {StateList.map((state) => (
                                    <option value={state.isoCode}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>City Name:</label>
                            <select
                                className="form-control login-input"
                                onChange={(e) => setCity1(e.target.value)}
                            >
                                <option>Select City</option>
                                {CityList.map((city) => (
                                    <option value={city.isoCode}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>

                    <div className="row mb-4">

                        <div className="col-lg-3">
                            <label className='fw-bold'>Starting Price  :</label>
                            <input type="text" placeholder='Price' className='form-control login-input' onChange={(e) => setStartingPrice(e.target.value)} />
                        </div>
                        <div className="col-lg-3">
                            <div>
                                <label className='fw-bold'>Amenities :</label>
                                <FormControl sx={{ m: 1, width: 245, height: 43 }} >
                                    <InputLabel id="demo-multiple-checkbox-label">Amenities</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedAmenities}
                                        onChange={handleSelectChange}
                                        input={<OutlinedInput label="Amenities" />}
                                        renderValue={(selected) => selected.map((amenity) => amenity.AmenitiName).join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {StayAmenities?.map((amenity) => (
                                            <MenuItem key={amenity._id} value={amenity}>
                                                <Checkbox checked={selectedAmenities.some((selected) => selected._id === amenity._id)} />
                                                <ListItemText primary={amenity.AmenitiName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='col-lg-3'>
                            <label className='fw-bold'>Category :</label>
                            <FormControl sx={{ m: 1, width: 245, height: 43 }} >
                                <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={seleceCategory}
                                    onChange={handleSelectCategory}
                                    input={<OutlinedInput label="Category" />}
                                    renderValue={(selected) => selected.map((category) => category.Category).join(", ")}
                                    MenuProps={MenuProps}
                                >
                                    {StayCategory?.map((category) => (
                                        <MenuItem key={category?._id} value={category}>
                                            <Checkbox checked={seleceCategory?.some((selected) => selected._id === category._id)} />
                                            <ListItemText primary={category?.Category} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Check In Time:</label>
                            <input type="time" className='form-control login-input' onChange={(e) => setCheckin(e.target.value)} />
                        </div>
                        <div className="col-lg-3 mt-3">
                            <label className='fw-bold'>Check Out Time :</label>
                            <input type="time" className='form-control login-input' onChange={(e) => setCheckOut(e.target.value)} />
                        </div>
                    </div>
                    <div className='mb-4 row'>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">
                                Property Description:
                            </label>
                            <CKEditor
                                data={Description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>

                        <div className="col-lg-6 ">
                            <label className="fw-bold">
                                Terms & Conditions:
                            </label>
                            <CKEditor
                                data={termsandcondition}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    settermsandcondition(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6">
                            <label className="fw-bold">Suggestion:</label>
                            <CKEditor
                                data={Suggesition}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSuggesition(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2 ">
                            <label className="fw-bold">
                                Near By Location:
                            </label>
                            <CKEditor
                                data={NearByLoc}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setNearByLoc(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">House Rules:</label>
                            <CKEditor
                                data={HouseRules}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHouseRules(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">Things To Do:</label>
                            <CKEditor
                                data={ThingToDo}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setThingToDo(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div>
                                    <label className="fw-bold">
                                        Property Full Image :
                                    </label>
                                    <input
                                        type="file"
                                        multiple
                                        className="form-control login-input"
                                        onChange={(e) =>
                                            setPropartyfullimage(e.target.files[0])
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">

                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="d-flex gap-5 align-items-end">
                                    <div>
                                        <label className='fw-bold'>Gallery Images:</label>
                                        <input type="file"
                                            multiple
                                            className='form-control login-input'
                                            ref={fileinputref2}
                                            onChange={(e) => {                                              
                                                onImageChange2(e);
                                                setImage(e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                    <button onClick={AddGalleryImage} className='pink-btn' style={{ height: '38px' }}>Add</button>
                                </div>
                            </div>
                            <div>
                                <Table className="sdfsd-table-head">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Image</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {galleryImages?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <th>{i + 1}</th>
                                                    <th>
                                                        <img
                                                            src={item?.imgUrl}
                                                            alt=""
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                            }}
                                                        />
                                                    </th>
                                                    <th><MdDelete style={{ cursor: 'pointer' }} className='text-danger fs-3' onClick={() => removeItem2(i)} /></th>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <label className="fw-bold" >FAQ :</label>
                            <div className="mb-2">
                                <input
                                    type="text"
                                    placeholder="Questions...??"
                                    multiple
                                    className="form-control login-input"
                                    onChange={(e) => {
                                        setquestion(e.target.value);
                                    }}
                                    value={question}
                                />
                            </div>
                            <div className="mb-2">
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        placeholder: 'Answer...!!!',
                                    }}
                                    data={answer}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setanswer(data);
                                    }}
                                />
                            </div>
                            <button
                                className="pink-btn m-auto mb-2"
                                style={{ height: "38px" }}
                                onClick={AddFaq}
                            >
                                Add Faq
                            </button>

                            <div>
                                <Table bordered className="sdfsd-table-head">
                                    <thead>
                                        <tr>
                                            <th>Question / Answer</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allFaq?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <p className="fw-bold">Q : {item?.question}?</p>
                                                            <p>{parse(`<div>A : ${item?.answer}</div>`)}</p>
                                                        </div>
                                                    </td>
                                                    <th>
                                                        <MdDelete
                                                        style={{cursor:"pointer"}}
                                                            onClick={() => removeFAQ(i)}
                                                            className="text-danger fs-5"
                                                        />
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                </div>
                <button className='pink-btn mb-3 ' onClick={AddStaysDetails}>Add Property</button>
            </Container>
        </div>
    )
}

export default AddHotel;