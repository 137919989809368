import React, { useEffect, useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { AiOutlineFieldTime, AiOutlineRight } from 'react-icons/ai'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import { CiLocationOn } from 'react-icons/ci'
import { Link, useLocation } from 'react-router-dom'
import parse from "html-react-parser"
import axios from 'axios'

export const MultipleActivity = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation()
    const { item } = location?.state;
    console.log("ghthtthththt", item);

    var text = "652e7b780847d4be85502ca9"
    // Get Tour Packages
    const [GetActivityByid, setGetActivityByid] = useState([]);
    const geactivitybyid = async () => {
        try {
            let res = await axios.get(
                "https://hayyyak.com/api/customer/activity/getactivitybyid/" + text
            );
            if (res.status === 200) {
                setGetActivityByid(res.data.getActivity);
            }
        } catch (error) {
            console.log(error);
            setGetActivityByid([]);
        }
    };
    useEffect(() => {
        geactivitybyid()
    }, [])
    console.log("GetActivityByid", GetActivityByid);


    // Arrays
    const [State, setState] = useState([])
    const [City, setCity] = useState([])
    const [multipleActivity, setMultipleActivity] = useState([]);
    const [dddd, setdddd] = useState([])
    useEffect(() => {
        if (item?.addlocation) {
            setMultipleActivity(item?.addlocation);
            setdddd(item?.addlocation)
        }
    }, [item]);
    //Filter location
    const [Country1, setCountry1] = useState("")
    const [State1, setState1] = useState("")
    const [City1, setCity1] = useState("")

    useEffect(() => {
        if (Country1 && !State1 && !City1) {
            const filtercountry = dddd?.filter((country) => country.Country === Country1)
            setState(filtercountry)
            setMultipleActivity(filtercountry)
        } else if (Country1 && State1 && !City1) {
            const filterstate = dddd?.filter((state) => state.State === State1 && state.Country === Country1)
            setCity(filterstate)
            setMultipleActivity(filterstate)
        } else if (Country1 && State1 && City1) {
            const filtercity = dddd?.filter((city) => city.City === City1)
            setMultipleActivity(filtercity)
        }
    }, [Country1, State1, City1, dddd])
    console.log("multipleactivity", multipleActivity);
    console.log("Country1", Country1);
    console.log("State1", State1);
    console.log("State", State);
    console.log("City", City);

    return (
        <div>
            <div className='mb-4 Multiple-Activity-mb'>
                <div className='activity-bg'>
                    <div
                        style={{
                            backgroundImage: 'url(././img/activity-bg.jpg)',
                            backgroundPosition: 'initial',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            height: "350px",
                            width: "100%",
                        }}

                        className='multiple-activity-top'
                    >
                        <div className="activity-text">
                            <div className='bread'>
                                <h2 className='text-uppercase fw-bold'>Activities</h2>
                                <div className='d-flex align-items-center gap-2'>
                                    <Link to='/' className='breadcrumb-active'><span>Home </span></Link>
                                    <span><AiOutlineRight /></span>
                                    <span className='' style={{color:"#D81D4A", fontWeight:"bold"}}>Activities</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Container>

                <div className="row align-items-center mb-4 Multiple-Activity-mb">
                    <div className="col-lg-3 fw-bold fs-5">
                        <p> <span className='text-danger'>Popular</span> Activities Near You :</p>
                    </div>
                    <div className="col-lg-3" >
                        <label className='fw-bold'>Country :</label>
                        <div class="input-group mb-3">
                            <label class="input-group-text" for="inputGroupSelect01"><CiLocationOn /></label>
                            <Form.Select aria-label="Default select example"
                                onChange={(e) => setCountry1(e.target.value)}
                            >
                                <option>Select Country</option>


                                {[...new Set(item?.addlocation?.map((Country) => Country.Country))]?.map((countryName) => (
                                    <option key={countryName} value={countryName}>
                                        {countryName}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <label className='fw-bold'>State :</label>
                        <div class="input-group mb-3">
                            <label class="input-group-text" for="inputGroupSelect01"><CiLocationOn /></label>
                            <Form.Select aria-label="Default select example"
                                onChange={(e) => setState1(e.target.value)}>
                                <option>Select State</option>
                                {[...new Set(State?.map((state) => state.State))]?.map((stateName) => (
                                    <option key={stateName} value={stateName}>
                                        {stateName}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <label className='fw-bold'>City :</label>
                        <div class="input-group mb-3">
                            <label class="input-group-text" for="inputGroupSelect01"><CiLocationOn /></label>
                            <Form.Select aria-label="Default select example"
                                onChange={(e) => setCity1(e.target.value)}>
                                <option>Select City</option>
                                {City.map((city) => (
                                    <option value={city.City}>
                                        {city.City}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>
                <hr />
                <div className=" row align-items-start  mb-5 Multiple-Activity-mb">
                    <div className='col-lg-3'>
                        <div className=" bg-yellow">
                            <img src={`https://hayyyak.com/ActivityCategory/${item?.activitycategories?.Image}`} alt=""/>
                        </div>

                        <div className=''>
                            <div className=' mb-4 '>
                                <h2 style={{ fontSize: "18px" }} className='activity-h2'>{parse(`<div>${item?.activitycategories?.Title}</div>`)}</h2>
                                <p className='text-dark' style={{ fontSize: '12px' }}>
                                    {parse(`<div>${item?.activitycategories?.Description}</div>`)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-9'>
                        <h2 className='mb-3'>Multiple  <span style={{ color: 'rgb(244, 54, 79)' }}>Location </span></h2>
                        <div className="row justify-content-between">
                            {multipleActivity?.map((act) =>
                                <div className='d-flex gap-4 align-items-center activity-cart-1 col-lg-6' >
                                    <div style={{ borderRight: '1px solid #00000047', padding: '8px' }}>
                                        <span className='text-light' style={{ fontSize: '12px' }}><CiLocationOn />{act.City} {act.State} {act.Country}</span>
                                        <p className='mb-1 fw-semibold'>{act?.Location}</p>
                                        <span className='text-light' style={{ fontSize: '15px' }}>
                                            {/* <BsFillCalendarCheckFill /> Trip Days: {act.Day}  */}
                                            <AiOutlineFieldTime /> Duration: {act.Timing}</span>
                                    </div>
                                    <div>
                                        <span className='text-light' style={{ fontSize: '12px' }} > Starts From <s className='fw-bold'>₹{act?.Price}</s></span>
                                        <p > <span style={{ color: '#e92c44', fontWeight: 'bold' }}>₹{act?.Adulttotal}</span> / person</p>
                                        <Link to="/activitydetails" state={{
                                            item: {
                                                img: item?.activitycategories?.Image,
                                                title: item?.activitycategories?.Title,
                                                description: item?.activitycategories?.Description,
                                                tours: item?.Tours,
                                                overview: item?.Overview,
                                                act: act,
                                                id: item?._id,
                                                Gallery: item?.GalleryImages,
                                                Terms: item?.Terms,
                                                Suggestion: item?.Suggestion,
                                                Policy: item?.Policy,
                                            }
                                        }}>
                                            <button className='pink-btn mb-4'>View Activity</button>
                                        </Link> <br />
                                    </div>
                                    <div className='activity-offer'>{act?.AdultDiscount}%Off</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}
