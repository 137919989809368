import React, { useEffect, useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function AddStayCategory() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Add Category
    const [Icon, setIcon] = useState("")
    const [Category, setCategory] = useState("")
    const AddStayCategory = async () => {
        if (!Icon) {
            return notify1("Please Select Icon")
        }
        if (!Category) {
            return notify1("Please Enter Category")
        }
        try {
            const config = {
                url: 'admin/stays/addstaycategory',
                baseURL: 'https://hayyyak.com/api/',
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: {
                    Category: Category,
                    Icon: Icon
                }
            };
            const response = await axios(config);
            if (response.status === 200) {
                notify(response.data.success);
                handleClose();
                getStayCategory()
            }
        } catch (error) {
            notify1(error.response.data.error)
        }
    }

    // Get Category
    const [StayCategory, setStayCategory] = useState([]);
    const getStayCategory = async () => {
        axios
            .get("https://hayyyak.com/api/admin/stays/getstaycategory")
            .then(function (res) {
                setStayCategory(res.data.success)
                setPagination(res.data.success)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    // Edit Category
    const [EditCat, setEditCat] = useState({})
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); setEditCat(item) }
    const EditStayCategory = async () => {
        try {
            const formData = new FormData();
            formData.append("Category", Category);
            formData.append("Icon", Icon);
            const config = {
                url: "admin/stays/editstaycategory/" + EditCat?._id,
                baseURL: "https://hayyyak.com/api/",
                method: "put",
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            };

            const res = await axios(config);
            if (res.status === 200) {
                notify(res.data.success);
                handleClose1();
                getStayCategory()
            }
        } catch (error) {
            notify1(error.response.data.error);
        }
    };

    // Delete StayCategory
    const [delteCat, setdelteCat] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setdelteCat(item) }
    const DeleteStayCategory = async () => {
        try {
            const config = {
                url: "admin/stays/deletestaycategory/" + delteCat?._id,
                baseURL: "https://hayyyak.com/api/",
                method: "delete",
                headers: { "Content-Type": "multipart/form-data" },
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success);
                handleClose2();
                getStayCategory()
            }
        } catch (error) {
            notify2(error.res.data.error)
        }
    }

    // Activate & Deactivate 
    const toggleStatus = async (tourID) => {
        try {
            const response = await axios.put(`https://hayyyak.com/api/admin/stays/admin-approved/${tourID}`);
            if (response.status === 200) {
                notify(response.data.success);
                notify1(response.data.message);
                getStayCategory()
            }
        } catch (error) {
            // Handle errors
            console.error("Error toggling status:", error);
            notify2("An error occurred while toggling status.");
        }
    }

    // Search
    const [SearchItem, setSearchItem] = useState("");

    const filteredItems = StayCategory?.filter((item) => {
        const searchLower = SearchItem.toLowerCase();
        return (
          item?.Category?.toLowerCase().includes(searchLower) ||
          moment(item?.updatedAt).format("DD/MM/YYYY").toLowerCase().includes(searchLower)
        );
      });

    //Pagination
    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(pagination?.length / usersPerPage);
    const changePage = (selected) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        getStayCategory()
    }, [])


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Stays Category :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Category</button>
                </div>

                <div className="row p-2 align-items-end justify-content-start mb-3 nbjhasd_0">
                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>

                </div>
                <div >
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Icon</th>
                                <th>Category Name</th>
                                <th>Last Update</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredItems?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                              
                              
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <img
                                                    src={`https://hayyyak.com/StayCategory/${item?.Icon}`}
                                                    alt=""
                                                    style={{ width: '50px', height: '50px', imageRendering: 'pixelated' }}
                                                />
                                            </td>
                                            <td>{item?.Category}</td>
                                            <td>{moment(item?.updatedAt).format("DD/MM/YYYY")}</td>
                                            <td>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <span
                                                        onClick={() => toggleStatus(item?._id)}
                                                        className={item?.CategoryStatus ? 'unblock-user' : 'block-user'}
                                                    >
                                                        {item?.CategoryStatus ? 'Activated' : 'Deactivated'}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex gap-2 fs-4 align-items-center" style={{ cursor: 'pointer' }}>
                                                    <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                    {item?.CategoryStatus === false && (
                                                        <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                               
                               
                            })}
                        </tbody>
                    </Table>
                    <div className="my-3">
                        <Stack spacing={2}>
                            <Pagination
                                count={pageCount}
                                onChange={(event, value) => {
                                    changePage(value - 1)
                                }}
                                color="primary"
                            />
                        </Stack>
                    </div>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Icon (choose black icon with background white):</label>
                        <input
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                            accept='image/*'
                            id='upload'
                            onChange={(e) => setIcon(e.target.files[0])}
                        />
                    </div>
                    <div >
                        <label className='fw-bold'>Category Name :</label>
                        <input type="text" placeholder='Name' className="vi_0" onChange={(e) => setCategory(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddStayCategory}>
                        Add Category
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Icon (choose black icon with background white):</label>
                        <input
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                            accept='image/*'
                            id='upload'
                            onChange={(e) => setIcon(e.target.files[0])}
                        />
                    </div>
                    <div >
                        <label className='fw-bold'>Category Name :</label>
                        <input type="text" placeholder='Name' className="vi_0" onChange={(e) => setCategory(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditStayCategory}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="warning" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeleteStayCategory}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AddStayCategory
