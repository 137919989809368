import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MdDashboardCustomize,
  MdOutlineFlight,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlinePriceChange,
  MdOutlineReviews,
  MdQuestionAnswer,
  MdTour,
} from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { SiPowerpages, SiSmartthings } from "react-icons/si";
import { HiHome } from "react-icons/hi";
import { GrServices, GrUserAdmin } from "react-icons/gr";
import { FaBlog, FaFileWaveform, FaHotel, FaUsersLine } from "react-icons/fa6";
import HikingIcon from "@mui/icons-material/Hiking";
import { colors } from "@mui/material";
import {
  BsFillBusFrontFill,
  BsFillChatLeftQuoteFill,
  BsFillChatTextFill,
  BsFillEnvelopeAtFill,
  BsFillPersonBadgeFill,
  BsFillTaxiFrontFill,
  BsFillTrainFrontFill,
  BsImages,
} from "react-icons/bs";
import { Collapse } from "react-bootstrap";
import {
  BiSolidContact,
  BiSolidDownArrow,
  BiSolidUpArrow,
} from "react-icons/bi";
import { AiFillHdd, AiFillHome, } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { IoMdNotifications } from "react-icons/io";
import { IoRestaurantSharp } from "react-icons/io5";
import { ImListNumbered } from "react-icons/im";


function AdminSideBar() {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);

  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [ContactList, setContactList] = useState([]);
  const getCustomerContastlist = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/getcontactcustomer"
      );
      if (res.status == 200) {
        setContactList(res.data.getdata);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getCustomerContastlist();
  }, []);
  const Admin = JSON.parse(sessionStorage.getItem("admin"))
  if (!Admin) {
    alert("Please login.....")
    window.location.assign("/admin")
  } else
    return (
      <div className="si09">
        <div style={{ borderBottom: "1px solid #fff" }}>
          <img
            src="./img/logo.jpeg"
            alt="adminlogo"
            style={{ width: "100%", height: "75px" }}
          />
        </div>

        <ul className="side-bar">
          <Link to="/admindashboard">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item-0">
                <span>
                  <LuLayoutDashboard style={{ fontSize: "16px" }} />
                </span>
                <span>Dashboard</span>
              </div>
            </li>
          </Link>

          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen1(!open1)}>
              <BsFillTaxiFrontFill style={{ fontSize: "16px" }} />
              <p> Taxi </p>
              {!open1 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open1}>
              <ul className="side-bar-item-ul">

                <li className="nav-item">
                  <Link to="/admin_vehicle_driver" >
                    <p className="side-bar-item-2">Drivers</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/admin_vehicle_category" >
                    <p className="side-bar-item-2">Vehicle Category</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/admin_vehicle_model" >
                    <p className="side-bar-item-2">Vehicle Model</p>
                  </Link>
                </li>

                <li >
                  <Link to="/admin_vehicle" >
                    <p className="side-bar-item-2">Vehicle</p>
                  </Link>
                </li>

                <li >
                  <Link to="/admin_vehicle_dailyride" >
                    <p className="side-bar-item-2">Daily Rides</p>
                  </Link>
                </li>

                <li >
                  <Link to="/admin_vehicle_outStation" >
                    <p className="side-bar-item-2">Out Station</p>
                  </Link>
                </li>

                <li >
                  <Link to="/admin_vehicle_airport" >
                    <p className="side-bar-item-2">Airport</p>
                  </Link>
                </li>

                <li >
                  <Link to="/admin_vehicle_rental" >
                    <p className="side-bar-item-2">Rental</p>
                  </Link>
                </li>

                <li >
                  <Link to="/admin_vehicle_allbooking" >
                    <p className="side-bar-item-2">All Bookings</p>
                  </Link>
                </li>

                {/* <li >
                <Link to="" >
                  <p className="side-bar-item-2">Taxi Account Statement</p>
                </Link>
              </li> */}
              </ul>
            </Collapse>
          </li>

          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen2(!open2)}>
              <HikingIcon style={{ fontSize: "16px" }} />
              <p> Activity </p>
              {!open2 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open2}>
              <ul className="side-bar-item-ul">
                <li>
                  <Link to="/activitycategory">
                    <p className="side-bar-item-2">Activity Category</p>
                  </Link>
                </li>
                <li>
                  <Link to="/addactivity">
                    <p className="side-bar-item-2">Add Activity</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/bookedactivity">
                    <p className="side-bar-item-2">Booked Activity</p>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen4(!open4)}>
              <FaHotel style={{ fontSize: "16px" }} />
              <p> Stays </p>
              {!open4 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open4}>
              <ul className="side-bar-item-ul">
                <li >
                  <Link to="/addstaycategory" >
                    <p
                      className="side-bar-item-2"
                    >
                      Stays Category
                    </p>
                  </Link>
                </li>
                <li >
                  <Link to="/addstayamenities" >
                    <p
                      className="side-bar-item-2"
                    >
                      Stays Amenities
                    </p>
                  </Link>
                </li>
                <li >
                  <Link to="/stayvendorlist" >
                    <p
                      className="side-bar-item-2"
                    >
                      Stays List
                    </p>
                  </Link>
                </li>
                <li >
                  <Link to="/admin_add_rooms" >
                    <p
                      className="side-bar-item-2"
                    >
                      Add Rooms
                    </p>
                  </Link>
                </li>

                <li >
                  <Link to="/bookedstays" >
                    <p
                      className="side-bar-item-2"
                    >
                      Booked Stays
                      <Badge badgeContent={2} color="success">
                        <NotificationsIcon className="text-light" />
                      </Badge>
                    </p>
                  </Link>
                </li>

                {/* <li >
                <Link to="" >
                  <p
                    className="side-bar-item-2"
                  >
                    Stays Account Statement
                  </p>
                </Link>
              </li> */}
              </ul>
            </Collapse>
          </li>

          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen5(!open5)}>
              <MdTour style={{ fontSize: "16px" }} />
              <p> Tour Packages </p>
              {!open5 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open5}>
              <ul className="side-bar-item-ul">
                <li >
                  <Link to="/tourcategory" >
                    <p className="side-bar-item-2">Add Category</p>
                  </Link>
                  <Link to="/addtourpackage" >
                    <p className="side-bar-item-2">Add Package</p>
                  </Link>
                  <Link to="/packagesist" >
                    <p className="side-bar-item-2">Package List</p>
                  </Link>
                  <Link to="/bookedtourpackages" >
                    <p className="side-bar-item-2">Booked Package</p>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <Link to="/admin_restaurant_cafe">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item">
                <span>
                  <IoRestaurantSharp style={{ fontSize: "16px" }} />
                </span>
                <span>Restaurant / Cafe</span>
              </div>
            </li>
          </Link>

          <Link to="/admin_things_todo">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item-0">
                <span>
                  <SiSmartthings style={{ fontSize: "16px" }} />
                </span>
                <span>Things To Do</span>
              </div>
            </li>
          </Link>


          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen7(!open7)}>
              <MdOutlinePriceChange style={{ fontSize: "16px" }} />
              <p> Subscription </p>
              {!open7 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open7}>
              <ul className="side-bar-item-ul">
                <li >
                  <Link to="/Subscription" >
                    <p className="side-bar-item-2">Add Subscription</p>
                  </Link>
                  <Link to="/Vendor_Subscription" >
                    <p className="side-bar-item-2">Vendor Subscription</p>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen8(!open8)}>
              <ImListNumbered style={{ fontSize: "16px" }} />
              <p> All Vendor List </p>
              {!open8 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open8}>
              <ul className="side-bar-item-ul">
                <li >
                  <Link to="/All_Stay_Vendor" >
                    <p className="side-bar-item-2">Stay Vendor List</p>
                  </Link>
                  <Link to="/All_Activity_Vendor" >
                    <p className="side-bar-item-2">Activity Vendor List</p>
                  </Link>
                  <Link to="/All_Tour_Vendor" >
                    <p className="side-bar-item-2">Tour Vendor List</p>
                  </Link>
                  <Link to="/All_Restaurant_Vendor" >
                    <p className="side-bar-item-2">Restaurant Vendor List</p>
                  </Link>
                  <Link to="/All_Things_Vendor" >
                    <p className="side-bar-item-2">ThingsToDo Vendor List</p>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>


          <li className="nav-item a-ele">
            <a className="side-bar-item" onClick={() => setOpen6(!open6)}>
              <AiFillHome style={{ fontSize: "16px" }} />
              <p> Home </p>
              {!open6 ? (
                <i>
                  <BiSolidDownArrow />
                </i>
              ) : (
                <i>
                  <BiSolidUpArrow />
                </i>
              )}
            </a>
            <Collapse in={open6}>
              <ul className="side-bar-item-ul">
                <li >
                  <Link to="/adminhomepage" >
                    <p className="side-bar-item-2">Banner</p>
                  </Link>
                  <Link to="/addallpackages" >
                    <p className="side-bar-item-2">All Packages</p>
                  </Link>
                  <Link to="/discountbanner" >
                    <p className="side-bar-item-2">Discount Banner</p>
                  </Link>
                  <Link to="/admintestimonial" >
                    <p className="side-bar-item-2">Testimonials</p>
                  </Link>
                  <Link to="/admin_footer" >
                    <p className="side-bar-item-2">Footer</p>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <Link to="/admincontact">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item">
                <span>
                  <BiSolidContact style={{ fontSize: "16px" }} />
                </span>
                <span>Enquiry</span>
                <Badge badgeContent={ContactList?.length} color="success">
                  <NotificationsIcon className="text-light" />
                </Badge>
              </div>
            </li>
          </Link>
          <Link to="/adminabout">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item-0">
                <span>
                  <AiFillHdd style={{ fontSize: "16px" }} />
                </span>
                <span>About Us</span>
              </div>
            </li>
          </Link>
          <Link to="/registereduser">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item-0">
                <span>
                  <AiOutlineUserSwitch style={{ fontSize: "16px" }} />
                </span>
                <span>Registered Users</span>
              </div>
            </li>
          </Link>
          <Link to="/adminblog">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item-0">
                <span>
                  <FaBlog style={{ fontSize: "16px" }} />
                </span>
                <span>Blog</span>
              </div>
            </li>
          </Link>

          <Link to="/admin_notification">
            <li
              className={`a-ele ${acc ? "active-0" : ""}`}
              onClick={() => {
                setacc(true);
              }}
            >
              <div className="side-bar-item-0">
                <span>
                  <IoMdNotifications style={{ fontSize: "16px" }} />
                </span>
                <span>Notification</span>
              </div>
            </li>
          </Link>

        </ul>
      </div>
    );
}

export default AdminSideBar;
