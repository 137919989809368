import React, { useState } from 'react'
import { Button, Container, Form, Modal, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FaLock, FaLockOpen } from 'react-icons/fa6'
import { useEffect } from 'react'
import axios from 'axios'
import moment from 'moment/moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import { Pagination, Stack } from '@mui/material'


function RegisteredUser() {

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);


    const [resgisterUser, setRegisterUser] = useState([])

    const GetRegisterUser = async () => {
        try {
            const res = await axios.get("https://hayyyak.com/api/customer/registeruser")
            if (res.status === 200) {
                setRegisterUser(res.data.success)
                setnochangedata(res.data.success)
                setPagination(res.data.success)
            }
        } catch (error) {
            alert(error.response.data.error)
        }
    }

    // Block and Unblock
    const BlockUser = async (blockId) => {
        try {
            const res = await axios.put(`https://hayyyak.com/api/customer/blockuser/${blockId}`)
            if (res.status === 200) {
                notify(res.data.success)
                notify1(res.data.msg)
                GetRegisterUser()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    useEffect(() => {
        GetRegisterUser()
    }, [])

    // Search
    const [SearchItem, setSearchItem] = useState("");

    //Pagination
    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(pagination?.length / usersPerPage);
    const changePage = (selected) => {
        setPageNumber(selected);
    };

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setRegisterUser(abc);
    };

    return (
        <div>
            <ToastContainer
                position='top-center'
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Registered User :</p>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>

                </div>
                <div>
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Mobile Number</th>
                                <th>Email</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resgisterUser?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                if (SearchItem === "" ||
                                    Object.values(item).some(
                                        value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
                                    )
                                )
                                    return (<>
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item?.Fname}</td>
                                            <td>{item?.Lname}</td>
                                            <td>{item?.Mobile}</td>
                                            <td>{item?.Email}</td>
                                            <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                            <td>
                                                <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>

                                                    <span
                                                        onClick={() => BlockUser(item?._id)}
                                                        className={item?.BlockCustomer ? 'block-user' : 'unblock-user'}>
                                                        {item?.BlockCustomer ? 'Block' : 'Unblock'}
                                                    </span>

                                                </div>
                                            </td>
                                        </tr>
                                    </>)
                            })
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="my-3">
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            onChange={(event, value) => {
                                changePage(value - 1)
                            }}
                            color="primary"
                        />
                    </Stack>
                </div>
            </Container>

        </div >
    )
}
export default RegisteredUser
