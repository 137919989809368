import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

function V_Things_Subscription() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const VendorThings = JSON.parse(sessionStorage.getItem("ThingsVendor"));

  const [AllSubcribeVend, setAllSubcribeVend] = useState([]);
  const GetVendorSubscriptions = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/getSubscriptionByVendorId/" +
          VendorThings?._id
      );
      if (res.status === 200) {
        setAllSubcribeVend(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const todate = moment();
  // Add Subscription
  // const [paymentId, setpaymentId] = useState("98546721345")
  const VendorSubscription = async (PackageName, PackagePrice, id, day) => {
    try {
      const config = {
        url: "/VendorSubscription",
        baseURL: "https://hayyyak.com/api/vendor",
        method: "post",
        header: { "content-type": "application/json" },
        data: {
          PackageName: PackageName,
          PackagePrice: PackagePrice,
          StartDate: moment().format("DD/MM/YYYY"),
          EndDate: moment(todate.add(day, "days")).format("DD/MM/YYYY"),
          paymentId: "98546721345",
          VendorName: VendorThings?.Fname,
          VendorNumber: VendorThings?.Mobile,
          VendorType: "Things To Do Vendor",
          VendorID: VendorThings?._id,
          SubscriptionID: id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          GetVendorSubscriptions();
        }
      });
    } catch (error) {
      console.log(error);
      notify2(error.response.data.error);
    }
  };
  // get
  const [getSubscription, setgetSubscription] = useState([]);
  const GetAdminSubscription = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/getsubscription"
      );
      if (res.status === 200) {
        setgetSubscription(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAdminSubscription();
  }, []);

  function getDayDifference(date1, date2) {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    // Calculate the difference in days
    const difference = momentDate2.diff(momentDate1, "days");

    return difference;
  }

  useEffect(() => {
    if (VendorThings) {
      GetVendorSubscriptions();
    }
  }, [VendorThings?._id]);

  const today1 = moment().format("DD/MM/YYYY")?.split("/")?.reverse()?.join("");

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2 mb-3">
          <p className="text-danger fs-4 fw-semibold">Subscription :</p>
        </div>

        <div>
          <div className="row">
            {getSubscription?.map((ele) => (
              <div className="col-lg-4">
                <div
                  className={`card mb-4 rounded-3 ${ele?.PacakageName === "Silver"
                      ? "Ouhads_01"
                      : ele?.PacakageName === "Gold"
                        ? "Ouhads_02"
                        : ele?.PacakageName === "Premium"
                          ? "Ouhads_03"
                          : ""
                    }`}
                >
                  <div className="card-header py-3 text-white">
                    <h4 className="my-0 fw-normal">{ele?.PacakageName}</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      ₹{ele?.Price}
                      <small className="text-muted fw-light">
                        /{getDayDifference(ele?.startDate, ele?.endDate)} Days
                      </small>
                    </h1>
                    {!AllSubcribeVend?.find(
                      (item) => item?.SubscriptionID === ele?._id
                    ) ? (
                      <button
                        className="pink-btn w-100"
                        onClick={() =>
                          VendorSubscription(
                            ele?.PacakageName,
                            ele?.Price,
                            ele?._id,
                            getDayDifference(ele?.startDate, ele?.endDate)
                          )
                        }
                      >
                        Continue
                      </button>
                    ) : (
                      <>
                        {AllSubcribeVend?.filter(
                          (item) => item?.SubscriptionID === ele?._id
                        )?.map((date) => {
                          return (
                            <ul className="mt-3 mb-2">
                              <li>
                                Start Date:{" "}
                                {new Date(date.StartDate).toLocaleDateString()}
                              </li>
                              <li>
                                End Date:{" "}
                                {new Date(date.EndDate).toLocaleDateString()}
                              </li>
                            </ul>
                          );
                        })}

                        <div className="text-center">
                          <button className="btn btn-success">Activated</button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
export default V_Things_Subscription;
