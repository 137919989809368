


import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineHome, AiOutlineUserAdd } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { TiTickOutline } from "react-icons/ti";
import { ImCancelCircle } from "react-icons/im";
import axios from "axios";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";

const MRCommunityHeader = () => {
    const [IsOpen, setIsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [Menuicon, setMenuicon] = useState(true)

    // useEffect(() => {
    //     setMenuicon(open)
    // }, [open])


    const handleClick = () => {
        setIsOpen(!IsOpen);

    };
    const Customer = JSON.parse(sessionStorage.getItem("customer"));
    if (!Customer) {
        alert("Please Login..!");
        window.location.assign("/login");
    }

    return (

        <div className="Community-tab-header-mobileview  ">


            <Button
                onClick={() => { setOpen(!open); setMenuicon(open) }}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="button-community-tab-header btn btn-light"


            >
                {Menuicon ? <GiHamburgerMenu /> : <IoMdClose />}
            </Button>
            <div style={{ minHeight: '150px', position: "absolute", top: "10%", right: "0%" }}>
                <Collapse in={open} dimension="width">
                    <div id="example-collapse-text">
                        <Card body className="card-body-header-main" style={{ width: '200px', backgroundColor: "#D81D4A" }}>



                            <div style={{ backgroundColor: "#D81D4A" }} className=" d-flex flex-column-reverse justify-content-center gap-1  align-items-start ">
                                {/* <div className="total-post">
                                <span>326</span>
                                <p>Total posts</p>
                            </div>
                            <div className="total-post">
                                <span>2456</span>
                                <p>Total Friends</p>
                            </div> */}

                                <Link to="/chat" className="text-decoration-none  ps-1 text-light d-flex justify-content-evenly align-items-center">

                                    <Stack spacing={4} direction="row " className="d-flex mb-1  align-items-center justify-content-between">
                                        <Badge badgeContent={0} color="secondary">
                                            <IoChatbubbleEllipsesOutline className='text-light fs-3' style={{
                                                width: "35px",
                                                height: "35px",

                                                margin: "0px 8px",
                                            }} />

                                        </Badge>
                                        <p>Chats</p>
                                    </Stack>
                                </Link>


                                <hr className="P-0 m-0 borderhrlinec" />
                                <div style={{ position: "relative" }}>
                                    {/* <Stack spacing={2} direction="row">
                                   
                                    <Badge badgeContent={4} color="success">
                                        <NotificationsIcon className='text-light' onClick={handleClick} style={{ cursor: 'pointer' }} />
                                    </Badge>
                                </Stack> */}
                                    {IsOpen && (
                                        <>
                                            <div className="fb-notification">
                                                <h3 className="fw-bold text-secondary">Notification</h3>
                                                <ul>
                                                    <li className="d-flex gap-2 align-items-center">
                                                        <img
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius: "50%",
                                                                margin: "8px",
                                                            }}
                                                            src="./img/review-2.png"
                                                            alt=""
                                                        />
                                                        <div>
                                                            <p className="fw-bold">
                                                                Ganesh Kalal{" "}
                                                                <span className="text-secondary">
                                                                    Send Your Friend Request
                                                                </span>
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <p>1 Mutual Fried</p>
                                                                <div className="jhdjs_0">
                                                                    <span>
                                                                        <TiTickOutline />
                                                                    </span>
                                                                    <span>
                                                                        <ImCancelCircle />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex gap-2 align-items-center">
                                                        <img
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius: "50%",
                                                                margin: "8px",
                                                            }}
                                                            src="./img/review-2.png"
                                                            alt=""
                                                        />
                                                        <div>
                                                            <p className="fw-bold">
                                                                Paige Turner{" "}
                                                                <span className="text-secondary">
                                                                    Added Their Stories
                                                                </span>
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <p>8 Hours ago</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex gap-2 align-items-center">
                                                        <img
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius: "50%",
                                                                margin: "8px",
                                                            }}
                                                            src="./img/review-2.png"
                                                            alt=""
                                                        />
                                                        <div>
                                                            <p className="fw-bold">
                                                                Paige Turner{" "}
                                                                <span className="text-secondary">
                                                                    Send Your Friend Request
                                                                </span>
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <p>1 Mutual Fried</p>
                                                                <div className="jhdjs_0">
                                                                    <span>
                                                                        <TiTickOutline />
                                                                    </span>
                                                                    <span>
                                                                        <ImCancelCircle />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex gap-2 align-items-center">
                                                        <img
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius: "50%",
                                                                margin: "8px",
                                                            }}
                                                            src="./img/review-2.png"
                                                            alt=""
                                                        />
                                                        <div>
                                                            <p className="fw-bold">
                                                                Paige Turner{" "}
                                                                <span className="text-secondary">
                                                                    Send Your Friend Request
                                                                </span>
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <p>1 Mutual Fried</p>
                                                                <div className="jhdjs_0">
                                                                    <span>
                                                                        <TiTickOutline />
                                                                    </span>
                                                                    <span>
                                                                        <ImCancelCircle />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <a href="/coummunityprofile" className="text-decoration-none ps-2">
                                    <div className="face-profile mt-1">
                                        {Customer?.profileImage ? (
                                            <>
                                                <img
                                                    src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                    alt=""
                                                    style={{
                                                        width: "35px",
                                                        height: "35px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src="./img/profile.jpg"
                                                    alt=""
                                                    style={{
                                                        width: "35px",
                                                        height: "35px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </>
                                        )}

                                        <div className="active-dot-mob-res"></div>
                                        <div>
                                            <p >
                                                {Customer?.Fname} {Customer?.Lname}
                                            </p>
                                            {/* <span>Active Now</span> */}
                                        </div>
                                    </div>
                                </a>
                            </div>


                        </Card>
                    </div>
                </Collapse>
            </div>

        </div>
    )
}

export default MRCommunityHeader