import React, { useEffect, useState } from "react";
import { Accordion, Container, Form } from "react-bootstrap";
import { MdDirections } from "react-icons/md";
import Carousel from "react-multi-carousel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Rating from "@mui/material/Rating";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { FaPen } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
export const ThingsToDoReview = () => {
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [value1, setValue1] = React.useState("2");
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { thingsToDoId, item } = location.state;
  console.log("SubThingstoDo", thingsToDoId);
  console.log("Item", item);

  const [ViewImage, setViewImage] = useState({});
  const [SUbThingsToDoById, setSubThingsToDoById] = useState({});
  const getsubthingsbyId = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/admin/thingstodo/getthingstodobyId/${thingsToDoId}`
      );
      if (res.status === 200) {
        setSubThingsToDoById(res.data.Thingsdetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (thingsToDoId) {
      getsubthingsbyId();
    }
  }, []);

  console.log("SubThingsToDoByIddddd", SUbThingsToDoById);

  const [VisitThingstoDo, setVisitThingstoDo] = useState({});

  useEffect(() => {
    if (SUbThingsToDoById && item) {
      const filteredPackages = SUbThingsToDoById.visitpackages?.find(
        (ele) => ele._id === item._id
      );
      setVisitThingstoDo(filteredPackages);
    }
  }, [SUbThingsToDoById, item]);

  console.log("first", VisitThingstoDo);
  console.log("languagetype", VisitThingstoDo?.languagetype);

  const [GalleryImages, setGalleryImages] = useState("");
  const AddsubImageGallery = async () => {
    try {
      const configGallery = {
        url: "/thingstodo/Addvisitgallery",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          visitid: item?._id,
          images: GalleryImages,
        },
      };
      let res = await axios(configGallery);
      if (res.status === 200) {
        notify(res.data.success);
        setVisitThingstoDo(res.data.visitthings);
        setGalleryImages("");
        getsubthingsbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [KindofQues, setKindofQues] = useState("");
  const [Visitdate, setVisitdate] = useState("");
  const [review, setreview] = useState("");
  const [reviewtitle, setreviewtitle] = useState("");
  const addsubreview = async () => {
    if (!Customer) {
      return alert("please login..");
    }
    try {
      const config = {
        url: "/addvisitreview",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo",
        headers: { "Content-Type": "application/json" },
        data: {
          id: thingsToDoId,
          packid: item?._id,
          userId: Customer?._id,
          stars1: value1,
          visitkind1: KindofQues,
          visitdate1: Visitdate,
          review1: review,
          titlereview1: reviewtitle,
          reviewname1: Customer?.Fname,
          reviewaddress1: Customer?.address,
          reviewimage1: Customer?.profileImage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.message);
        handleClose();
        getsubthingsbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [Question, setQuestion] = useState("");
  const currentDate = new Date();
  const addSubPkgQuestion = async () => {
    try {
      const config = {
        url: "addvisitquestion",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo/",
        headers: { "Content-Type": "application/json" },
        data: {
          id: thingsToDoId,
          packid: item?._id,
          userId: Customer?._id,
          questions1: Question,
          nameq: Customer?.Fname,
          addressq: Customer?.address,
          quesimage: Customer?.profileImage,
          updatedAt: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.message);
        handleClose1();
        getsubthingsbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [Questionid, setQuestionid] = useState("");
  const [Answer, setAnswer] = useState("");
  const currentDate1 = new Date();
  const addSubPkgAnswer = async () => {
    if (!Customer) {
      return alert("Please login...")
    }
    try {
      const config = {
        url: "addvisitpackanswer",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo/",
        headers: { "Content-Type": "application/json" },
        data: {
          id: thingsToDoId,
          packid: item?._id,
          userId: Customer?._id,
          questId: Questionid?._id,
          answers1: Answer,
          namea: Customer?.Fname,
          addressa: Customer?.address,
          ansimage: Customer?.profileImage,
          updatedAt: currentDate1,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.message);
        handleClose2();
        getsubthingsbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        swipeable={true}
      >
        {/* {VisitThingstoDo?.gallery?.map((item) => {
          return (
            <div>
              <img
                src={`https://hayyyak.com/Thingstodo/${item?.images}`}
                alt=""
                className="w-100"
                style={{ height: "300px" }}
              />
            </div>
          );
        })} */}
        <div>
          <img
            src="./img/things-img-1.jpg"
            alt=""
            className="w-100"
            style={{ height: "300px" }}
          />
        </div>
        <div>
          <img
            src="./img/things-img-2.jpg"
            alt=""
            className="w-100"
            style={{ height: "300px" }}
          />
        </div>
        <div>
          <img
            src="./img/things-img-3.jpg"
            alt=""
            className="w-100"
            style={{ height: "300px" }}
          />
        </div>
      </Carousel>

      <Container>
        <div className="row mb-3">
          <div className="col-lg-8">
            <p className="fw-semibold fs-2 text-danger">
              {VisitThingstoDo?.visitname}
            </p>
            <p className="text-muted">
              From{" "}
              <span className="text-danger fs-5">
                ₹{VisitThingstoDo?.price}
              </span>{" "}
              Per adult price
            </p>
            <p>
              Open Now <span className="text-danger">4:15 AM - 5:00 AM</span>{" "}
            </p>
            <div className="mb-3">
              <p className="fw-semibold fs-4 mb-2">About</p>
              <p className="text-secondary">
                {parse(`<div>${VisitThingstoDo?.visitdescription}</div>`)}
              </p>
            </div>
            {/* <div className='mb-3'>
                            <p className='fw-semibold fs-4 mb-2'>Highlights</p>
                            <p className='text-secondary'>Benefit from a full-day tour of Bangalore that covers the highlights and skips the hassle of navigating the city on your own. Take ie fees are included.</p>
                        </div> */}
            <div>
              <Accordion>
                <Accordion.Item eventKey="0" className="mb-3">
                  <Accordion.Header>What's Included</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.whatsinclude}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="mb-3">
                  <Accordion.Header>What to Expect</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.whattoexcept}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="mb-3">
                  <Accordion.Header>Departure Return</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.Departureandreturn}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="mb-3">
                  <Accordion.Header>Accessibility</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.accessibility}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="mb-3">
                  <Accordion.Header>Additional Information</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.addinfo}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="mb-3">
                  <Accordion.Header>Faq</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.Faq}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="mb-3">
                  <Accordion.Header>Help</Accordion.Header>
                  <Accordion.Body>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad */}
                    {parse(`<div>${VisitThingstoDo?.Help}</div>`)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-4 ">
            <div className="cafe_img">
              <p className="fw-normal fs-4">Call</p>
              <p className="text-danger fs-5 mb-2">
                +91{VisitThingstoDo?.phonenumber1}
              </p>
              <p className="fw-normal fs-4">Direction</p>
              <p className="mb-2 text-muted">{VisitThingstoDo?.address}</p>
              <button className="pink-btn__2">
                <MdDirections className="fs-4" /> Direction
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <p className="fw-semibold fs-4 mb-2">Contribute</p>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Write Review" value="1" />
                  <Tab label="Upload Photo" value="2" />
                  <Tab label="Q&A" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <button className="pink-btn mb-3" onClick={handleShow}>
                  Add Review
                </button>
                {VisitThingstoDo?.review?.map((item) => {
                  return (
                    <div>
                      <div className="flex gap-2 align-items-center">
                        <img
                          src={`https://hayyyak.com/Customer/${item?.reviewimage1}`}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p className="fw-semibold">{item?.reviewname1}</p>
                          <Box>
                            <Rating
                              name="read-only"
                              value={item?.stars1}
                              readOnly
                              className="fs-6"
                            />
                          </Box>
                        </div>
                      </div>
                      <h6>{item?.titlereview1}</h6>
                      <p className="text-secondary fs-6">
                        {item?.review1}
                      </p>
                    </div>
                  );
                })}
              </TabPanel>
              <TabPanel value="2">
                <div className="d-flex gap-4 mb-2">
                  <input
                    onChange={(e) => setGalleryImages(e.target.files[0])}
                    type="file"
                    className="form-control w-50"
                  />
                  <button className="pink-btn" onClick={AddsubImageGallery}>
                    Add Photos
                  </button>
                </div>
                {/* <img
                  src="./img/things-img-3.jpg"
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                /> */}
              </TabPanel>
              <TabPanel value="3">
                <button
                  className="pink-btn mb-3"
                  onClick={handleShow1}
                >
                  Add Question
                </button>
                {VisitThingstoDo?.Quesandans?.map((item) => {
                  return (
                    <div>
                      <div className="flex gap-2 align-items-center">
                        <img
                          src={`https://hayyyak.com/Customer/${item?.quesimage}`}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p className="fw-semibold">{item?.nameq} </p>
                        </div>
                      </div>
                      <h6>
                        Q. {item?.questions1} &nbsp;
                        <b
                          style={{
                            color: "green",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            handleShow2();
                            setQuestionid(item)
                          }}
                        >
                          answer
                          <FaPen />{" "}
                        </b>
                      </h6>
                      {item?.answer1?.map((item1) => {
                        return (
                          <p className="text-secondary fs-6">
                            <span style={{ color: "green" }}>Ans.</span>
                            {item1?.answers1}
                          </p>
                        )
                      })}
                    </div>
                  );
                })}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Add <span className="text-danger">Review</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <p className="fw-semibold">How would you rate your experience?</p>
              <Box>
                <Rating
                  name="simple-controlled"
                  value={value1}
                  onChange={(event, newValue) => {
                    setValue1(newValue);
                  }}
                />
              </Box>
            </div>
            <div className=" mt-2">
              <label className="fw-bold">What kind of visit was this ?</label>
              <Form.Select
                onChange={(e) => setKindofQues(e.target.value)}
                className="vi_0"
              >
                <option>select</option>
                <option value="Business">Business</option>
                <option value="Couples">Couples</option>
                <option value="Family(teens)">Family(teens)</option>
                <option value="Friends">Friends</option>
                <option value="Solo">Solo</option>
                <option value="Family">Family</option>
              </Form.Select>
            </div>
            <div className=" mt-2">
              <label className="fw-bold">When did you visit ?</label>
              <Form.Control
                type="date"
                onChange={(e) => setVisitdate(e.target.value)}
                className="vi_0"
              />
            </div>
            <div className=" mt-2">
              <label className="fw-bold">Write Your review..</label>
              <Form.Control
                onChange={(e) => setreview(e.target.value)}
                className="vi_0"
                type="text"
                placeholder="Write Your review.."
              />
            </div>
            <div className=" mt-2">
              <label className="fw-bold">Title this review..</label>
              <Form.Control
                onChange={(e) => setreviewtitle(e.target.value)}
                className="vi_0"
                type="text"
                placeholder="Title this review.."
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={addsubreview}>
            Add Review
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Add <span className="text-danger">Question</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="write your question..?"
                onChange={(e) => setQuestion(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger"
            onClick={addSubPkgQuestion}
          >
            Add Question
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Add <span className="text-danger">Answer</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="write your answer.."
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="danger"
            onClick={addSubPkgAnswer}
          >
            Add Question
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
