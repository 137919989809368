import React, { useEffect, useState } from "react";
import { Container, Modal, Button, Dropdown, Col, Row } from "react-bootstrap";
import {
  AiFillHeart,
  AiOutlineCamera,
  AiOutlineComment,
  AiOutlineFileAdd,
  AiOutlineStar,
  AiOutlineUser,
  AiOutlineUsergroupAdd,
  AiTwotoneCloud,
  AiTwotoneLike,
} from "react-icons/ai";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FormControl, NativeSelect, } from "@mui/material";
import { BiEdit, BiLike, BiSolidDashboard } from "react-icons/bi";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import {
  BsEmojiSmile,
  BsFillTagFill,
  BsTag,
  BsThreeDots,
} from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { MdCancelPresentation, MdDelete } from "react-icons/md";
import { CiSaveDown2 } from "react-icons/ci";
import { FaHeadphonesSimple, FaUserCheck } from "react-icons/fa6";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { Link } from "react-router-dom";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";

export const CoummunityProfile = () => {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [save, setSave] = useState(true);
  const [show, setShow] = useState(false);
  // const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Customer = JSON.parse(sessionStorage.getItem("customer"));

  const [postdataid, setpostdataid] = useState("");
  const [IsOpen, setIsOpen] = useState(false);
  const handleClick = (data) => {
    setpostdataid(data);
    setIsOpen(!IsOpen);
  };

  // For Post Content
  const [isemojipicker, setisemojipicker] = useState(false);
  const [Likes, setLikes] = useState(true);
  const formData = new FormData();
  const [Text, setText] = useState("");
  const [Image, setImage] = useState("");
  const [Privecy, setPrivecy] = useState("");
  const postcontent = async () => {
    if (!Text && !Image) {
      return notify1("Please fill all the fields!");
    }
    try {
      formData.append("userId", Customer?._id);
      formData.append("text", Text);
      formData.append("image", Image);
      formData.append("privecy", Privecy);

      const config = {
        url: "/customer/customerpost",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      };

      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallposts();
          setText("");
          setImage("");
        }
      });
    } catch (error) {
      notify2(error.response.data.error);
    }
  };


  // Get Post
  const [Posts, setPosts] = useState([]);
  const getallposts = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/getallcustomerpost"
      );
      if (res.status === 200) {
        setPosts(res.data.getallpost);
      }
    } catch (error) {
      console.log(error);
    }
  };


  //Total Post
  const [TotalPost, setTotalPost] = useState([]);
  useEffect(() => {
    const totalpostuser = Posts?.filter(
      (ele) => ele.userId === Customer._id
    ).length;
    setTotalPost(totalpostuser);
  }, [Posts, Customer]);

  // Total Likes
  const [TotalLikes, setTotalLikes] = useState([]);
  useEffect(() => {
    let userLikes = 0;
    Posts.filter((item) => item.userId === Customer._id).forEach((val) => {
      userLikes += val.likes.length
    })
    setTotalLikes(userLikes);
  }, [Posts, Customer]);

  // UserLikes
  const userlikes = async (Postid) => {
    try {
      const config = {
        url: "/customer/userlikes",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          postId: Postid,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          getallposts();

        }
      });
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setcommentashow(item);
    setpostid(item?._id);
  };
  const [commentashow, setcommentashow] = useState({});
  // user Comments
  const [Commenttext, setCommenttext] = useState("");
  const userComments = async (Postid) => {
    try {
      const config = {
        url: "/customer/usercomments",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          postId: Postid,
          comment: Commenttext,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          getallposts();
          setIsOpen(false);
          getallcomments();
          // notify(res.data.success);
        }
      });
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Getcomments
  const [postid, setpostid] = useState("");
  const [Comments, setComments] = useState([]);
  const getallcomments = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/customer/getallcustomercomments/${postid}`
      );
      if (res.status === 200) {
        setComments(res.data.comments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Post
  const DeletePost = async (DeletePostId) => {
    try {
      const config = {
        url: "/customer/deletecustomerpost/" + DeletePostId,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallposts();
        }
      });
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  // Delete Comment
  const DeleteCommment = async (userId) => {
    try {
      const config = {
        url: "/customer/deletecustomercomment/" + userId,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "content-type": "applicaion/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallcomments();
          getallposts();
        }
      });
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  // Edit Profile Details
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Email, setEmail] = useState("");
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var f_Name = /^[a-zA-Z][a-zA-Z]/;

  const UpdateProfileDetails = async () => {
    if (Fname) {
      if (!Fname.match(f_Name)) {
        return notify1("Please Enter Your Valid First Name");
      }
    }
    if (Lname) {
      if (!Lname.match(f_Name)) {
        return notify1("Please Enter Your Valid Last Name");
      }
    }
    if (Email) {
      if (!Email.match(validRegex)) {
        return notify1("Please Enter Valid Email Address");
      }
    }
    try {
      const config = {
        url: "/customer/updateuser",
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          Fname: Fname,
          Lname: Lname,
          Email: Email,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          sessionStorage.setItem("customer", JSON.stringify(res.data.userdata));
          handleClose();
        }
      });
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  // Profile Update
  const [ProfileImage, setProfileImage] = useState("");
  const formdata = new FormData();
  const UpdateProfile = async (ProfileImage) => {
    formdata.append("profileImage", ProfileImage);
    formdata.append("userid", Customer?._id);
    try {
      const config = {
        url: "/customer/profileimg",
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          sessionStorage.setItem("customer", JSON.stringify(res.data.success));
          window.location.reload("/coummunityprofile");
          handleClose();
        }
      });
    } catch (error) {
      notify2(error.res.data.error);
    }
  };



  useEffect(() => {
    if (postid) {
      getallcomments();
    }
  }, [postid]);

  // Post Share
  // const [userId, setuserId] = useState("");
  const PostShare = async (id) => {
    try {
      const config = {
        url: "/customer/postshare",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          postid: id?._id,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        // notify(res.data.success);
        getallposts();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };



  // Request Sent to other Friends
  const [Sendreq, setSendreq] = React.useState([]);
  const getSendrequest = () => {
    axios
      .get('https://hayyyak.com/api/customer/getfriendreq/' + Customer?._id)
      .then(function (response) {
        setSendreq(response.data.sentRequests);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("Sendreq=====>", Sendreq);

  // Request Got from Other Friends
  const [Getreq, setGetreq] = React.useState([]);
  const getGetrequest = () => {
    axios
      .get('https://hayyyak.com/api/customer/getfriendreq/' + Customer?._id)
      .then(function (response) {
        setGetreq(response.data.receivedRequests);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const friendreqaccepted = async id => {
    try {
      const config = {
        url: '/friendreqaccepted/' + id,
        method: 'post',
        baseURL: 'https://hayyyak.com/api/customer',
        headers: { "Content-Type": "application/json" },
        data: { status: 'Accepted' },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getGetrequest();
          notify('Friend Request Accepted');
        }
      });
    } catch (error) {
      console.log(error);
      notify2('Intenal Server Error');
    }
  };

  const friendreqrejected = async id => {
    axios({
      method: 'post',
      url: 'https://hayyyak.com/api/customer/friendreqrejected/' + id,
    })
      .then(function (response) {
        if ((response.status = 200)) {
          getGetrequest();
          notify('Friend Request Rejected');
        }
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  // Get friends recivied req and send req
  const [friends, setfriends] = useState([])
  const getfriendrequest = () => {
    axios
      .get('https://hayyyak.com/api/customer/getFririndlistByuserId/' + Customer._id)
      .then(function (response) {
        setfriends(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("friends", friends);

  useEffect(() => {
    getallposts();
    getGetrequest();
    getSendrequest()
    getfriendrequest()
  }, []);

  console.log("Reciviedreq=====>", Getreq);


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="row gap-2">
          <div className="col-lg-1 ">
            <div className="facebook-left">
              <ul>
                <li>
                  <Link to="/community" className="text-decoration-none text-light"> <BiSolidDashboard /> </Link>
                </li>
                <li>
                  <Link to="/coummunityprofile" className="text-decoration-none text-light"><AiOutlineUsergroupAdd /></Link>
                </li>
                <li>
                  <Link to="/chat" className="text-decoration-none text-light">
                    <Stack spacing={2} direction="row">
                      <Badge badgeContent={0} color="secondary">
                        <IoChatbubbleEllipsesOutline className='text-light fs-3' />
                      </Badge>
                    </Stack>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div
              style={{
                backgroundImage: 'url("./img/trip-package-bg-2.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // height: "350px",
                borderRadius: "10px",
                // padding: "10px",
              }}
            >
              <div className="col-lg-3">
                <div className="jkghsidf_0">
                  {Customer?.profileImage ? (
                    <>
                      <img
                        src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src="./img/profile.jpg"
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  )}
                  <p className="fs-5 fw-bold">
                    {Customer?.Fname} {Customer?.Lname}
                  </p>
                  <p className="mb-3">{Customer?.Email}</p>

                  <div className="d-flex gap-3 justify-content-center mt-3 mb-3">
                    <div>
                      <span className="fs-5 fw-bold">{TotalLikes}</span>
                      <p className="text-secondary">Likes</p>
                    </div>
                    <div>
                      <span className="fs-5 fw-bold">{TotalPost}</span>
                      <p className="text-secondary">Posts</p>
                    </div>
                  </div>

                  <div>
                    <button className="pink-btn" onClick={handleShow}>
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      scrollButtons="auto"


                    >

                      <Tab label="Posts" value="1" />
                      <Tab label="Gallery" value="2" />
                      <Tab label="Friends" value="3" />
                      <Tab label="Request Sent" value="4" />
                      <Tab label="Request Received" value="5" />
                      {/* <span className="overflow-mobile-scroll">
                        <Tab label="Posts" value="1" />
                        <Tab label="Gallery" value="2" />
                        <Tab label="Friends" value="3" />
                        <Tab label="Request Sent" value="4" />
                        <Tab label="Request Received" value="5" />
                      </span> */}
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    <div className="row class-community-common-height justify-content-center mobileserponsiver-100w">
                      <div
                        className="col-lg-8 scroll-cat1"
                        style={{ overflow: "auto" }}
                      >
                        <div className="jgashgd_1 mb-4">
                          <div className="d-flex gap-3 align-items-center mb-2">
                            <p className="fs-5 fw-bold">Create Post</p>
                            <Box style={{ width: "45%" }}>
                              <FormControl fullWidth>
                                <NativeSelect
                                  style={{ border: "none", fontSize: "12px" }}
                                  defaultValue={30}
                                  inputProps={{
                                    name: "age",
                                    id: "uncontrolled-native",
                                  }}
                                  onChange={(e) => setPrivecy(e.target.value)}
                                >
                                  <option value="Public">Public</option>
                                  <option value="Friends">Friends</option>
                                  <option value="Only Me">Only Me</option>
                                </NativeSelect>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="mb-3">
                            <input
                              type="text"
                              style={{
                                border: "none",
                                width: "100%",
                                height: "46px",
                                padding: "5px",
                                backgroundColor: "#ecf5fd",
                              }}
                              placeholder="Write something here..."
                              onChange={(e) => setText(e.target.value)}
                            />
                          </div>

                          <div className="feel">
                            <div className="album">
                              <AiOutlineCamera />
                              <span>Album</span>
                              {Image?.name}
                              <input
                                type="file"
                                onChange={(e) => setImage(e.target.files[0])}
                              />
                            </div>
                          </div>

                          <div>
                            <button
                              className="pink-btn w-100"
                              onClick={() => postcontent()}
                            >
                              POST
                            </button>
                          </div>
                        </div>

                        {Posts?.filter(
                          (ele) => ele.User?._id === Customer?._id
                        )?.map((item) => {
                          const postDate = new Date(item.createdAt);
                          const currentDate = new Date();

                          const timeDifferenceInMinutes = Math.floor(
                            (currentDate - postDate) / (1000 * 60)
                          );
                          const timeDifferenceInHours = Math.floor(
                            (currentDate - postDate) / (1000 * 60 * 60)
                          );
                          const timeDifferenceInDays = Math.floor(
                            (currentDate - postDate) / (1000 * 60 * 60 * 24)
                          );
                          return (
                            <>
                              {item?.text && item?.image ? (
                                <>
                                  <div className="kalin">
                                    <div className="d-flex justify-content-between p-2 mb-2">
                                      <div className="d-flex gap-2 align-items-center">
                                        {item?.customers?.profileImage ? (
                                          <>
                                            <img
                                              src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                              alt=""
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src="./img/profile.jpg"
                                              alt=""
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          </>
                                        )}
                                        <div>
                                          <p className="fw-bold text-secondary fs-6">
                                            {item?.User?.Fname}{" "}
                                            {item?.User?.Lname}
                                          </p>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "grey",
                                            }}
                                          >
                                            {timeDifferenceInMinutes <= 60 ? (
                                              <>
                                                {timeDifferenceInMinutes} Mins
                                                Ago
                                              </>
                                            ) : (
                                              <>
                                                {timeDifferenceInHours <= 24 ? (
                                                  <>
                                                    {timeDifferenceInHours}{" "}
                                                    Hours Ago
                                                  </>
                                                ) : (
                                                  <>
                                                    {timeDifferenceInDays <=
                                                      7 ? (
                                                      <>
                                                        {timeDifferenceInDays}{" "}
                                                        Days Ago
                                                      </>
                                                    ) : (
                                                      <>
                                                        {moment(
                                                          item.createdAt
                                                        ).format("DD-MM-YYYY")}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                        >
                                          <BsThreeDots className="text-dark" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              DeletePost(item?._id)
                                            }
                                          >
                                            <MdDelete className="text-danger" />{" "}
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>

                                    <img
                                      src={`https://hayyyak.com/PostImages/${item?.image}`}
                                      alt=""
                                      className="img-fluid mb-2"
                                      style={{
                                        width: "-webkit-fill-available",
                                        height: "350px",
                                      }}
                                    />
                                    <div className="p-2">
                                      <div className="row justify-content-between mb-2">
                                        <p className=" col-lg-8">
                                          {item?.text}
                                        </p>
                                        <span
                                          onClick={() => setSave(!save)}
                                          className="col-lg-1 save-tag"
                                        >
                                          {save ? (
                                            <>
                                              <BsTag />
                                            </>
                                          ) : (
                                            <>
                                              <BsFillTagFill />
                                            </>
                                          )}{" "}
                                        </span>
                                      </div>

                                      <div className="emoji">
                                        <div className="d-flex gap-2">
                                          <img src="./img/like.png" alt="" />
                                          <span className="fw-bold text-secondary">
                                            {item?.likes?.length}
                                          </span>
                                        </div>
                                        <div className="d-flex gap-2 align-items-center fs-4 text-secondary">
                                          <span
                                            onClick={() => {
                                              handleShow1(item);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <AiOutlineComment />{" "}
                                            {item?.comments?.length}
                                          </span>
                                          <span>
                                            <CiSaveDown2 /> {item?.Share?.length}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="react-0">
                                        {item.likes.some(
                                          (like) =>
                                            like.userId === Customer?._id
                                        ) ? (
                                          <>
                                            <span
                                              onClick={() => {
                                                userlikes(item?._id);
                                              }}
                                            >
                                              <AiTwotoneLike
                                                style={{ color: "#0466ff" }}
                                              />
                                              Likes
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span
                                              onClick={() => {
                                                userlikes(item?._id);
                                              }}
                                            >
                                              <BiLike />
                                              Likes
                                            </span>
                                          </>
                                        )}
                                        <span
                                          onClick={() => {
                                            handleClick(item?._id);
                                          }}
                                        >
                                          <AiOutlineComment />
                                          Comment
                                        </span>
                                        <span>
                                          <WhatsappShareButton
                                            onClick={() => PostShare(item)}
                                            url={`http://localhost:3000/communityshare/${item?._id}`}
                                          >
                                            <WhatsappIcon size={32} round />
                                            Share
                                          </WhatsappShareButton>
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {IsOpen && postdataid === item?._id ? (
                                    <>
                                      <div className="jgashgd_1 mb-4">
                                        <div className="d-flex gap-2 align-items-center">
                                          {Customer?.profileImage ? (
                                            <>
                                              <img
                                                src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                alt=""
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src="./img/profile.jpg"
                                                alt=""
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            </>
                                          )}
                                          <div>
                                            <p className="fw-bold text-secondary fs-6">
                                              {Customer?.Fname}{" "}
                                              {Customer?.Lname}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="mb-3">
                                          <input
                                            style={{
                                              border: "none",
                                              width: "100%",
                                              height: "46px",
                                              padding: "5px",
                                              backgroundColor: "#ecf5fd",
                                            }}
                                            onChange={(e) =>
                                              setCommenttext(e.target.value)
                                            }
                                            type="text"
                                            placeholder="Comment here..."
                                          />
                                        </div>

                                        <div>
                                          <button
                                            className="pink-btn w-100"
                                            onClick={() =>
                                              userComments(item?._id)
                                            }
                                          >
                                            POST
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.text ? (
                                    <>
                                      <div className="kalin">
                                        <div className="d-flex justify-content-between p-2 mb-2">
                                          <div className="d-flex gap-2 align-items-center">
                                            {item?.customers?.profileImage ? (
                                              <>
                                                <img
                                                  src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src="./img/profile.jpg"
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </>
                                            )}
                                            <div>
                                              <p className="fw-bold text-secondary fs-6">
                                                {item?.User?.Fname}{" "}
                                                {item?.User?.Lname}
                                              </p>
                                              <span
                                                style={{
                                                  fontSize: "12px",
                                                  color: "grey",
                                                }}
                                              >
                                                {timeDifferenceInMinutes <=
                                                  60 ? (
                                                  <>
                                                    {timeDifferenceInMinutes}{" "}
                                                    Mins Ago
                                                  </>
                                                ) : (
                                                  <>
                                                    {timeDifferenceInHours <=
                                                      24 ? (
                                                      <>
                                                        {timeDifferenceInHours}{" "}
                                                        Hours Ago
                                                      </>
                                                    ) : (
                                                      <>
                                                        {timeDifferenceInDays <=
                                                          7 ? (
                                                          <>
                                                            {
                                                              timeDifferenceInDays
                                                            }{" "}
                                                            Days Ago
                                                          </>
                                                        ) : (
                                                          <>
                                                            {moment(
                                                              item.createdAt
                                                            ).format(
                                                              "DD-MM-YYYY"
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              id="dropdown-basic"
                                              style={{
                                                background: "none",
                                                border: "none",
                                              }}
                                            >
                                              <BsThreeDots className="text-dark" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  DeletePost(item?._id)
                                                }
                                              >
                                                <MdDelete className="text-danger" />{" "}
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>

                                        <div className="p-2">
                                          <div className="row justify-content-between mb-2">
                                            <p className="col-lg-8">
                                              {item?.text}
                                            </p>
                                            <span
                                              onClick={() => setSave(!save)}
                                              className="col-lg-1 save-tag"
                                            >
                                              {save ? (
                                                <>
                                                  <BsTag />
                                                </>
                                              ) : (
                                                <>
                                                  <BsFillTagFill />
                                                </>
                                              )}{" "}
                                            </span>
                                          </div>

                                          <div className="emoji">
                                            <div className="d-flex gap-2">
                                              <img
                                                src="./img/like.png"
                                                alt=""
                                              />
                                              <span className="fw-bold text-secondary">
                                                {item?.likes?.length}
                                              </span>
                                            </div>
                                            <div className="d-flex gap-2 align-items-center fs-5 text-secondary">
                                              <span
                                                onClick={() => {
                                                  handleShow1(item);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <AiOutlineComment />{" "}
                                                {item?.comments?.length}
                                              </span>
                                              <span>
                                                <CiSaveDown2 /> {item?.Share?.length}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="react-0">
                                            {item.likes.some(
                                              (like) =>
                                                like.userId === Customer?._id
                                            ) ? (
                                              <>
                                                <span
                                                  onClick={() => {
                                                    userlikes(item?._id);
                                                  }}
                                                >
                                                  <AiTwotoneLike
                                                    style={{ color: "#0466ff" }}
                                                  />
                                                  Likes
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  onClick={() => {
                                                    userlikes(item?._id);
                                                  }}
                                                >
                                                  <BiLike />
                                                  Likes
                                                </span>
                                              </>
                                            )}
                                            <span
                                              onClick={() => {
                                                handleClick(item?._id);
                                              }}
                                            >
                                              <AiOutlineComment />
                                              Comment
                                            </span>
                                            <span>
                                              <WhatsappShareButton
                                                onClick={() => PostShare(item)}
                                                url={`http://localhost:3000/communityshare/${item?._id}`}
                                              >
                                                <WhatsappIcon size={32} round />
                                                Share
                                              </WhatsappShareButton>
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      {IsOpen && postdataid === item?._id ? (
                                        <>
                                          <div className="jgashgd_1 mb-4">
                                            <div className="d-flex gap-2 align-items-center">
                                              {Customer?.profileImage ? (
                                                <>
                                                  <img
                                                    src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    src="./img/profile.jpg"
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </>
                                              )}
                                              <div>
                                                <p className="fw-bold text-secondary fs-6">
                                                  {Customer?.Fname}{" "}
                                                  {Customer?.Lname}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mb-3">
                                              <input
                                                style={{
                                                  border: "none",
                                                  width: "100%",
                                                  height: "46px",
                                                  padding: "5px",
                                                  backgroundColor: "#ecf5fd",
                                                }}
                                                onChange={(e) =>
                                                  setCommenttext(e.target.value)
                                                }
                                                type="text"
                                                placeholder="Comment here..."
                                              />
                                            </div>

                                            <div>
                                              <button
                                                className="pink-btn w-100"
                                                onClick={() =>
                                                  userComments(item?._id)
                                                }
                                              >
                                                POST
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className="kalin">
                                        <div className="d-flex justify-content-between p-2 mb-2">
                                          <div className="d-flex gap-2 align-items-center">
                                            {item?.customers?.profileImage ? (
                                              <>
                                                <img
                                                  src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src="./img/profile.jpg"
                                                  alt=""
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </>
                                            )}
                                            <div>
                                              <p className="fw-bold text-secondary fs-6">
                                                {item?.User?.Fname}{" "}
                                                {item?.User?.Lname}
                                              </p>
                                              <span
                                                style={{
                                                  fontSize: "12px",
                                                  color: "grey",
                                                }}
                                              >
                                                {timeDifferenceInMinutes <=
                                                  60 ? (
                                                  <>
                                                    {timeDifferenceInMinutes}{" "}
                                                    Mins Ago
                                                  </>
                                                ) : (
                                                  <>
                                                    {timeDifferenceInHours <=
                                                      24 ? (
                                                      <>
                                                        {timeDifferenceInHours}{" "}
                                                        Hours Ago
                                                      </>
                                                    ) : (
                                                      <>
                                                        {timeDifferenceInDays <=
                                                          7 ? (
                                                          <>
                                                            {
                                                              timeDifferenceInDays
                                                            }{" "}
                                                            Days Ago
                                                          </>
                                                        ) : (
                                                          <>
                                                            {moment(
                                                              item.createdAt
                                                            ).format(
                                                              "DD-MM-YYYY"
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              id="dropdown-basic"
                                              style={{
                                                background: "none",
                                                border: "none",
                                              }}
                                            >
                                              <BsThreeDots className="text-dark" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  DeletePost(item?._id)
                                                }
                                              >
                                                <MdDelete className="text-danger" />{" "}
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>

                                        <img
                                          src={`https://hayyyak.com/PostImages/${item?.image}`}
                                          alt=""
                                          className="img-fluid mb-2"
                                          style={{
                                            width: "-webkit-fill-available",
                                            height: "350px",
                                          }}
                                        />
                                        <div className="p-2">
                                          <div className="emoji">
                                            <div className="d-flex gap-2">
                                              <img
                                                src="./img/like.png"
                                                alt=""
                                              />
                                              <span className="fw-bold text-secondary">
                                                {item?.likes?.length}
                                              </span>
                                            </div>
                                            <div className="d-flex gap-2 align-items-center fs-5 text-secondary">
                                              <span
                                                onClick={() => {
                                                  handleShow1(item);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <AiOutlineComment />{" "}
                                                {item?.comments?.length}
                                              </span>
                                              <span>
                                                <CiSaveDown2 /> {item?.Share?.length}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="react-0">
                                            {item.likes.some(
                                              (like) =>
                                                like.userId === Customer?._id
                                            ) ? (
                                              <>
                                                <span
                                                  onClick={() => {
                                                    userlikes(item?._id);
                                                  }}
                                                >
                                                  <AiTwotoneLike
                                                    style={{ color: "#0466ff" }}
                                                  />
                                                  Likes
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  onClick={() => {
                                                    userlikes(item?._id);
                                                  }}
                                                >
                                                  <BiLike />
                                                  Likes
                                                </span>
                                              </>
                                            )}
                                            <span
                                              onClick={() => {
                                                handleClick(item?._id);
                                              }}
                                            >
                                              <AiOutlineComment />
                                              Comment
                                            </span>
                                            <span>
                                              <WhatsappShareButton
                                                onClick={() => PostShare(item)}
                                                url={`http://localhost:3000/communityshare/${item?._id}`}
                                              >
                                                <WhatsappIcon size={32} round />
                                                Share
                                              </WhatsappShareButton>
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      {IsOpen && postdataid === item?._id ? (
                                        <>
                                          <div className="jgashgd_1 mb-4">
                                            <div className="d-flex gap-2 align-items-center">
                                              {Customer?.profileImage ? (
                                                <>
                                                  <img
                                                    src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    // src={`https://hayyyak.com/PostImages/${item?.image}`}
                                                    src="./img/profile.jpg"
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </>
                                              )}
                                              <div>
                                                <p className="fw-bold text-secondary fs-6">
                                                  {Customer?.Fname}{" "}
                                                  {Customer?.Lname}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mb-3">
                                              <input
                                                style={{
                                                  border: "none",
                                                  width: "100%",
                                                  height: "46px",
                                                  padding: "5px",
                                                  backgroundColor: "#ecf5fd",
                                                }}
                                                onChange={(e) =>
                                                  setCommenttext(e.target.value)
                                                }
                                                type="text"
                                                placeholder="Comment here..."
                                              />
                                            </div>

                                            <div>
                                              <button
                                                className="pink-btn w-100"
                                                onClick={() =>
                                                  userComments(item?._id)
                                                }
                                              >
                                                POST
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </TabPanel>
                  {/* <TabPanel value="2">
                    <div className="row class-community-common-height ">



                      {Posts?.filter(
                        (ele) => ele.customers?._id === Customer?._id && ele.image !== null && ele.image !== undefined
                      )?.length ? ("") : (<p className="p-4  mt-3"> No Photo Posted yet...!</p>)}
                      {Posts?.filter(
                        (ele) => ele.customers?._id === Customer?._id && ele.image !== null && ele.image !== undefined
                      )?.map((item) => {
                        return (
                          <div className="col-lg-3">
                            <div >
                              <div className="image-container-responsive" key={item.id}>
                                <img src={`https://hayyyak.com/PostImages/${item?.image}`} alt="" className="gallery-image-responsive" />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel> */}

                  <TabPanel value="2">
                    <div className="row class-community-common-height">
                      {Posts?.filter(
                        (ele) => ele.customers?._id === Customer?._id && ele.image !== null && ele.image !== undefined
                      )?.length ? (
                        Posts?.filter(
                          (ele) => ele.customers?._id === Customer?._id && ele.image !== null && ele.image !== undefined
                        )?.map((item) => (
                          <div className="col-lg-3 col-md-3 col-sm-3 " key={item.id}>
                            <div className="gallery-main-container">
                              <div className="image-container-responsive">
                                <img src={`https://hayyyak.com/PostImages/${item?.image}`} alt={item?.description || 'Image description'} className="m-1 gallery-image-responsive" />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="p-4 mt-3">No Photo Posted yet...!</p>
                      )}
                    </div>
                  </TabPanel>
                  {/* <TabPanel value="3">
                    <div>
                      <div className="row class-community-common-height">
                        {friends?.length ? ("") : (<p className="p-4  mt-3"> No Friends ...!</p>)}
                       
                        {friends?.map((item) => (<>
                          {item?.senderId?._id !== Customer._id ? (<>
                            <div className="col-lg-5 d-flex gap-4 align-items-center">
                              {item?.senderId?.profileImage ? (<>
                                <img src={`https://hayyyak.com/Customer/${item?.senderId?.profileImage}`} alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              </>) : (<>
                                <img src="./img/profile.jpg" alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              </>)}
                              <div>
                                <p className="fw-bold activity-h2 mb-1 ">{item?.senderId?.Fname} {item?.senderId?.Lname}</p>
                                <p className="frnd"><FaUserCheck />Friends</p>
                              </div>
                            </div>
                          </>) : (<>
                            <div className="col-lg-5 d-flex gap-4 align-items-center">
                              {item?.receiverId?.profileImage ? (<>
                                <img src={`https://hayyyak.com/Customer/${item?.receiverId?.profileImage}`} alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              </>) : (<>
                                <img src="./img/profile.jpg" alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              </>)}
                              <div>
                                <p className="fw-bold activity-h2 mb-1 ">{item?.receiverId?.Fname} {item?.receiverId?.Lname}</p>
                                <p className="frnd"><FaUserCheck />Friends</p>
                              </div>
                            </div>
                          </>)}
                        </>))}
                      </div>
                    </div>
                  </TabPanel> */}
                  <TabPanel value="3" className=" d-inline-block mt-0">
                    <div className=" class-community-common-height">
                      <div className="d-inline-block mt-0 ">
                        {friends?.length ? ("") : (<p className="p-4  mt-3"> No Friends ...!</p>)}
                        <div className="row  p-3  ">

                          {friends?.map((item) => (
                            <>
                              {item?.senderId?._id !== Customer._id ? (
                                <span className="border p-1">
                                  <span className="col-lg-5 d-flex gap-4 align-items-center ">
                                    {item?.senderId?.profileImage ? (<>
                                      <img src={`https://hayyyak.com/Customer/${item?.senderId?.profileImage}`} alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                                    </>) : (<>
                                      <img src="./img/profile.jpg" alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                                    </>)}
                                    <div className="w-100">
                                      <p className="fw-bold activity-h2 mb-1 ">{item?.senderId?.Fname} {item?.senderId?.Lname}</p>
                                      <p className="frnd"><FaUserCheck />Friends</p>
                                    </div>
                                  </span>
                                </span>) : (
                                <span>
                                  <div className="col-lg-5 d-flex gap-4 align-items-center ">
                                    {item?.receiverId?.profileImage ? (<>
                                      <img src={`https://hayyyak.com/Customer/${item?.receiverId?.profileImage}`} alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                                    </>) : (<>
                                      <img src="./img/profile.jpg" alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                                    </>)}
                                    <div>
                                      <p className="fw-bold activity-h2 mb-1 ">{item?.receiverId?.Fname} {item?.receiverId?.Lname}</p>
                                      <p className="frnd"><FaUserCheck />Friends</p>
                                    </div>
                                  </div>
                                </span>)}
                            </>))}
                        </div>
                      </div>
                    </div>
                  </TabPanel>


                  <TabPanel value="4" className="mt-0" >
                    <div className=" class-community-common-height">
                      <div className=" d-inline-block mt-0 " >
                        {Sendreq?.filter((ele) => ele?.status === "Pending")?.length ? ("") : (<p className=" p-4  mt-3 "> No Request Sent ...!</p>)}
                        <div className="row  p-3">
                          {Sendreq?.filter((ele) => ele?.status === "Pending")?.map((item) => (<>
                            <span className="col-lg-5 d-flex gap-4 align-items-center border p-1">
                              <img src={`https://hayyyak.com/Customer/${item?.receiverId?.profileImage}`} alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              <div>
                                <p className="fw-bold activity-h2 mb-1 ">{item?.receiverId?.Fname} {item?.receiverId?.Lname}</p>
                                <p className="frnd"><FaUserCheck />Request Sent</p>
                              </div>
                            </span>
                          </>))}
                        </div>
                      </div>
                    </div>
                  </TabPanel>


                  <TabPanel value="5" className=" d-inline-block ">
                    <div className=" class-community-common-height">
                      <div className="d-inline-block mt-0 ">

                        <div className="row  p-3  ">
                          {Getreq?.filter((ele) => ele?.status === "Pending")?.length ? ("") : (<p className="p-4  mt-3"> No Request Found ...!</p>)}
                          {Getreq?.filter((ele) => ele?.status === "Pending")?.map((item) => (<>
                            <div className="col-lg-5 d-flex gap-4 align-items-center p-1">
                              {item?.senderId?.profileImage ? (<>
                                <img src={`https://hayyyak.com/Customer/${item?.senderId?.profileImage}`} alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              </>) : (<>
                                <img src="./img/profile.jpg" alt="" style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
                              </>)}
                              <div>
                                <p className="fw-bold activity-h2 mb-1 ">{item?.senderId?.Fname} {item?.senderId?.Lname}</p>
                                <div className="d-flex gap-1">
                                  <p onClick={() => { friendreqaccepted(item?._id) }} className="frnd"><FaUserCheck />Accecpt</p>
                                  <p onClick={() => { friendreqrejected(item?._id) }} className="frnd" style={{ backgroundColor: '#e92c45', color: '#fff' }}><FaUserCheck />Reject</p>
                                </div>
                              </div>
                            </div>
                          </>))}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </Container>

      {/* Edit Profile */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fb-profile">
            {Customer?.profileImage ? (
              <>
                <img
                  src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                  alt=""
                  style={{ width: "-webkit-fill-available", height: "300px" }}
                />
              </>
            ) : (
              <>
                <img
                  src="./img/profile.jpg"
                  alt=""
                  style={{ width: "-webkit-fill-available", height: "300px" }}
                />
              </>
            )}

            <p>
              <span style={{ cursor: "pointer" }}>
                {" "}
                Edit Image <BiEdit />
              </span>
              <input
                id="profileupload"
                type="file"
                accept="image/*"
                onChange={(e) => UpdateProfile(e.target.files[0])}
              />
            </p>
          </div>
          <div className="row uihsdf_0">
            <div className="col-lg-6">
              <label className="fw-bold">First Name</label>
              <br />
              <input
                type="text"
                className="form-control"
                onChange={(e) => setFname(e.target.value)}
                placeholder={Customer?.Fname}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Last Name</label>
              <br />
              <input
                placeholder={Customer?.Lname}
                type="text"
                className="form-control"
                onChange={(e) => setLname(e.target.value)}
              />
            </div>
            <div className="col-lg-12">
              <label className="fw-bold">Email</label>
              <br />
              <input
                placeholder={Customer?.Email}
                type="text"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              UpdateProfileDetails();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Comment Model */}

      <Modal show={show1} onHide={handleClose1} className="post-model">
        <Modal.Header className="justify-content-center">
          <Modal.Title className="fw-bold">
            {commentashow?.User?.Fname}'s Post
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-2">
            <div className="d-flex gap-2 align-items-center">
              {commentashow?.customers?.profileImage ? (
                <>
                  <img
                    src={`https://hayyyak.com/Customer/${commentashow?.customers?.profileImage}`}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      imageRendering: "pixelated",
                      borderRadius: "50%",
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src="./img/profile.jpg"
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </>
              )}
              <div>
                <p className="fw-bold fs-6">
                  {commentashow?.User?.Fname} {commentashow?.User?.Lname}
                </p>
              </div>
            </div>
          </div>

          <div>
            {commentashow?.image ? (
              <>
                <img
                  src={`https://hayyyak.com/PostImages/${commentashow?.image}`}
                  alt=""
                  className="mb-2"
                  style={{ width: "-webkit-fill-available", height: "250px" }}
                />
                <h4 className=" p-2">{commentashow?.text}</h4>
              </>
            ) : (
              <>
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <h4>{commentashow?.text}</h4>
                </div>
              </>
            )}

            <div className="emoji">
              <div className="d-flex gap-2">
                <img src="./img/like.png" alt="" />
                <span className="fw-bold text-secondary">
                  {commentashow?.likes?.length}
                </span>
              </div>
              <div
                className="d-flex gap-2 align-items-center fs-5"
                style={{ fontSize: "16px", color: "grey" }}
              >
                <span>
                  <AiOutlineComment /> {commentashow?.comments?.length}
                </span>
              </div>
            </div>
          </div>

          {Comments?.map((item) => {
            return (
              <div className="d-flex align-items-start gap-2 p-2">
                <div>
                  {item?.userId?.profileImage ? (
                    <img
                      src={`https://hayyyak.com/Customer/${item?.userId?.profileImage}`}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        imageRendering: "pixelated",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <img
                      src="./img/profile.jpg"
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        imageRendering: "pixelated",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>
                <div>
                  <p className="fw-bold fs-5">
                    {item?.userId?.Fname} {item?.userId?.Lname}
                  </p>
                  <p>{item?.comment}</p>
                </div>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      style={{ background: "none", border: "none" }}
                    >
                      <BsThreeDots className="text-dark" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          DeleteCommment(item?._id);
                        }}
                        className="d-flex align-items-center gap-2"
                      >
                        <MdDelete className="text-danger" />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
