import React, { useEffect, useState } from 'react'
import { Container, Modal, Button, } from 'react-bootstrap'
import { AiFillHeart, AiOutlineComment, AiOutlineUsergroupAdd, AiTwotoneLike } from 'react-icons/ai'
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link, useLocation } from 'react-router-dom';
import { BiLike, BiSolidDashboard } from 'react-icons/bi';
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { FaUserCheck, FaUserPlus } from 'react-icons/fa6';
import { CiSaveDown2 } from 'react-icons/ci';
import { BsEmojiSmile } from 'react-icons/bs';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import moment from 'moment';

export const CommunityFriend = () => {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);
    const Customer = JSON.parse(sessionStorage.getItem("customer"));

    const location = useLocation()
    const { item } = location.state
    console.log("item", item)
    console.log("CustomerID", Customer?._id);
    console.log("ItemmmmID", item?._id);

    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [postdataid, setpostdataid] = useState("");
    const [IsOpen, setIsOpen] = useState(false);
    const handleClick = (data) => {
        setpostdataid(data);
        setIsOpen(!IsOpen);
    };


    // Get Post
    const [Posts, setPosts] = useState([]);
    const getallposts = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/getallcustomerpost"
            );
            if (res.status === 200) {
                setPosts(res.data.getallpost);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // UserLikes
    const userlikes = async (Postid) => {
        try {
            const config = {
                url: "/customer/userlikes",
                method: "post",
                baseURL: "https://hayyyak.com/api",
                headers: { "Content-Type": "application/json" },
                data: {
                    userId: Customer?._id,
                    postId: Postid,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    getallposts();
                    notify(res.data.success);
                }
            });
        } catch (error) {
            notify(error.response.data.error);
        }
    };
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => {
        setShow1(true);
        setcommentashow(item);
        setpostid(item?._id);
    };

    const [commentashow, setcommentashow] = useState({});
    // user Comments
    const [Commenttext, setCommenttext] = useState("");
    const userComments = async (Postid) => {
        try {
            const config = {
                url: "/customer/usercomments",
                method: "post",
                baseURL: "https://hayyyak.com/api",
                headers: { "Content-Type": "application/json" },
                data: {
                    userId: Customer?._id,
                    postId: Postid,
                    comment: Commenttext,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    getallposts();
                    setIsOpen(false);
                    getallcomments();
                }
            });
        } catch (error) {
            notify(error.response.data.error);
        }
    };

    // Getcomments
    const [postid, setpostid] = useState("");
    const [Comments, setComments] = useState([]);
    const getallcomments = async () => {
        try {
            const res = await axios.get(
                `https://hayyyak.com/api/customer/getallcustomercomments/${postid}`
            );
            if (res.status === 200) {
                setComments(res.data.comments);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (postid) {
            getallcomments()
        }
    }, [postid])


    // Post Share
    // const [userId, setuserId] = useState("");
    const PostShare = async (id) => {
        try {
            const config = {
                url: "/customer/postshare",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "Content-Type": "application/json" },
                data: {
                    userId: Customer?._id,
                    postid: id?._id,
                },
            };
            const res = await axios(config);
            if (res.status === 200) {
                // notify(res.data.success);
                getallposts();
            }
        } catch (error) {
            notify2(error.response.data.error);
        }
    };


    const addfriendreq = async (id) => {
        try {
            const config = {
                url: '/addfriendreq',
                method: 'post',
                baseURL: 'https://hayyyak.com/api/customer',
                headers: { "Content-Type": "application/json" },
                data: {
                    senderId: Customer?._id,
                    receiverId: id,
                    status: 'Pending',
                },
            };
            const res = await axios(config)
            if (res.status === 200) {
                notify('Friend request sent');
                postfrndreqdetails(id)
            }
        } catch (error) {
            console.log(error);
            notify1("Friend request already sent");
        }
    };


    // const [gatStatusData, setgatStatusData] = useState([])
    // const getfriendrequest = () => {
    //   axios
    //     .get('https://hayyyak.com/api/customer/getreqdetails/' + Customer._id)
    //     .then(function (response) {
    //         setgatStatusData(response.data.request);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // };
    // useEffect(()=>{
    //     getfriendrequest()
    // })
    // console.log("gatStatusData========>",gatStatusData);


    const [data, setdata] = useState([])
    console.log("data", data)
    const postfrndreqdetails = async (id) => {
        try {
            const config = {
                url: '/postreqdetails',
                method: 'post',
                baseURL: 'https://hayyyak.com/api/customer',
                data: {
                    senderId: Customer?._id,
                    receiverId: id
                },
            };
            await axios(config).then(function (res) {
                if ((res.status = 200)) {
                    setdata(res.data.request);
                }
            });
        } catch (error) {
            console.log(error);
            notify('not able to complete');
        }
    };

    // Total Likes
    const [TotalLikes, setTotalLikes] = useState([]);
    useEffect(() => {
        let userLikes = 0;
        Posts.filter((ele) => ele.userId === item?.userId).forEach((val) => {
            userLikes += val.likes.length;
        });
        setTotalLikes(userLikes);
    }, [Posts, item?.userId]);

    useEffect(() => {
        getallposts()
        getallcomments()
    }, [])

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className="row gap-5">
                    <div className="col-lg-1 ">
                        <div className="facebook-left">
                            <ul>
                                <li>
                                    <Link to="/community" className="text-decoration-none text-light"> <BiSolidDashboard /> </Link>
                                </li>
                                <li>
                                    <Link to="/coummunityprofile" className="text-decoration-none text-light"><AiOutlineUsergroupAdd /></Link>
                                </li>
                                <li>
                                    <Link to="/chat" className="text-decoration-none text-light">
                                        <Stack spacing={2} direction="row">
                                            <Badge badgeContent={0} color="secondary">
                                                <IoChatbubbleEllipsesOutline className='text-light fs-3' />
                                            </Badge>
                                        </Stack>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-9 mobilerespondive-friend-profile">
                        <div style={{
                            backgroundImage: 'url("./img/trip-package-bg-2.jpg")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            // height: '379px',
                            borderRadius: '10px',
                            padding: '10px'
                        }}>
                            <div className="col-lg-3">
                                <div className="jkghsidf_0">
                                    {item?.User?.profileImage ? (
                                        <>
                                            <img
                                                src={`https://hayyyak.com/Customer/${item?.User?.profileImage}`}
                                                alt=""
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "50%",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src="./img/profile.jpg"
                                                alt=""
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "50%",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                        </>
                                    )}

                                    <p className="fs-5 fw-bold">
                                        {item?.User?.Fname} {item?.User?.Lname}{" "}
                                        <span className="text-danger">
                                            <AiFillHeart />
                                        </span>
                                    </p>
                                    <p className="mb-3">{item?.User?.Email}</p>
                                    <div className="d-flex gap-3 justify-content-center mt-3 mb-3">
                                        <div>
                                            <span className="fs-5 fw-bold">{TotalLikes}</span>
                                            <p className="text-secondary">Likes</p>
                                        </div>
                                        <div>
                                            <span className="fs-5 fw-bold">{Posts?.filter((ele) => ele?.userId === item?.userId)?.length}</span>
                                            <p className="text-secondary">Posts</p>
                                        </div>
                                    </div>

                                    <div>

                                        {data?.status === 'Accepted' ? (<>
                                            <button className='pink-btn' style={{ fontSize: '12px', backgroundColor: '#fff', color: '#e92c45', padding: '2px 16px' }}><FaUserCheck className='mb-1' />Friends</button>
                                        </>) : (data?.status === 'Pending' ? (<>
                                            <button className='pink-btn' style={{ fontSize: '12px', backgroundColor: '#fff', color: '#e92c45', padding: '2px 16px' }}><FaUserPlus className='mb-1' />Request Sent</button>
                                        </>) : (<>
                                            <button onClick={() => { addfriendreq(item?.User?._id) }} className='pink-btn' style={{ fontSize: '12px', padding: '2px 16px' }}><FaUserPlus className='mb-1' />Add Friend</button>
                                        </>))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example"
                                    >
                                        <Tab label="Posts" value="1" />
                                        <Tab label="Gallery" value="2" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <div className="row justify-content-center mobileserponsiver-100w  ">
                                        <div
                                            className="col-lg-8 scroll-cat scroll-cat-res"
                                            style={{ height: "600px", overflow: "auto" }}
                                        >
                                            {Posts?.filter((ele) => ele?.userId === item?.userId)?.map((item) => {
                                                const postDate = new Date(item.createdAt);
                                                const currentDate = new Date();

                                                const timeDifferenceInMinutes = Math.floor(
                                                    (currentDate - postDate) / (1000 * 60)
                                                );
                                                const timeDifferenceInHours = Math.floor(
                                                    (currentDate - postDate) / (1000 * 60 * 60)
                                                );
                                                const timeDifferenceInDays = Math.floor(
                                                    (currentDate - postDate) / (1000 * 60 * 60 * 24)
                                                );
                                                return (
                                                    <>
                                                        {item?.text && item?.image ? (
                                                            <>
                                                                <div className="kalin">
                                                                    <div className="d-flex justify-content-between  mb-2">
                                                                        <div className="d-flex gap-2 align-items-center">
                                                                            {item?.customers?.profileImage ? (
                                                                                <>
                                                                                    <img
                                                                                        src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: "50px",
                                                                                            height: "50px",
                                                                                            borderRadius: "50%",
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img
                                                                                        src="./img/profile.jpg"
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: "50px",
                                                                                            height: "50px",
                                                                                            borderRadius: "50%",
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            <div>
                                                                                <p className="fw-bold text-secondary fs-6">
                                                                                    {item?.User?.Fname} {item?.User?.Lname}
                                                                                </p>
                                                                                <span
                                                                                    style={{ fontSize: "12px", color: "grey" }}
                                                                                >
                                                                                    {timeDifferenceInMinutes <= 60 ? (
                                                                                        <>{timeDifferenceInMinutes} Mins Ago</>
                                                                                    ) : (
                                                                                        <>
                                                                                            {timeDifferenceInHours <= 24 ? (
                                                                                                <>{timeDifferenceInHours} Hours Ago</>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {timeDifferenceInDays <= 7 ? (
                                                                                                        <>
                                                                                                            {timeDifferenceInDays} Days Ago
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {moment(item.createdAt).format(
                                                                                                                "DD-MM-YYYY"
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <img
                                                                        src={`https://hayyyak.com/PostImages/${item?.image}`}
                                                                        alt=""
                                                                        className="img-fluid mb-2"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "300px",
                                                                        }}
                                                                    />
                                                                    <div className="p-2">
                                                                        <div className="row justify-content-between mb-2">
                                                                            <p className=" col-lg-8">
                                                                                {item?.text}
                                                                            </p>
                                                                        </div>
                                                                        <div className="emoji">
                                                                            <div className="d-flex gap-2">
                                                                                <img src="./img/like.png" alt="" />
                                                                                <span className="fw-bold text-secondary">
                                                                                    {item?.likes?.length}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="d-flex gap-1 align-items-center"
                                                                                style={{ fontSize: "16px", color: "grey" }}
                                                                            >
                                                                                <span
                                                                                    onClick={() => {
                                                                                        handleShow1(item);
                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                >
                                                                                    <AiOutlineComment />{" "}
                                                                                    {item?.comments?.length}
                                                                                </span>
                                                                                <span>
                                                                                    <CiSaveDown2 /> {item?.Share?.length}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="react-0">
                                                                            {item.likes.some(
                                                                                (like) => like.userId === Customer?._id
                                                                            ) ? (
                                                                                <>
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            userlikes(item?._id);
                                                                                        }}
                                                                                    >
                                                                                        <AiTwotoneLike
                                                                                            style={{ color: "#0466ff" }}
                                                                                        />
                                                                                        Likes
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            userlikes(item?._id);
                                                                                        }}
                                                                                    >
                                                                                        <BiLike />
                                                                                        Likes
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                            <span
                                                                                onClick={() => {
                                                                                    handleClick(item?._id);
                                                                                }}
                                                                            >
                                                                                <AiOutlineComment />
                                                                                Comment
                                                                            </span>
                                                                            <span>
                                                                                <WhatsappShareButton
                                                                                    onClick={() => PostShare(item)}
                                                                                    url={`http://localhost:3000/communityshare/${item?._id}`}
                                                                                >
                                                                                    <WhatsappIcon size={32} round />
                                                                                    Share
                                                                                </WhatsappShareButton>
                                                                            </span>
                                                                            {/* </Link> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {IsOpen && postdataid === item?._id ? (
                                                                    <>
                                                                        <div className="jgashgd_1 mb-4">
                                                                            <div className="d-flex gap-2 align-items-center">
                                                                                {Customer?.profileImage ? (
                                                                                    <>
                                                                                        <img
                                                                                            src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: "50px",
                                                                                                height: "50px",
                                                                                                borderRadius: "50%",
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <img
                                                                                            src="./img/profile.jpg"
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: "50px",
                                                                                                height: "50px",
                                                                                                borderRadius: "50%",
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                                <div>
                                                                                    <p className="fw-bold text-secondary fs-6">
                                                                                        {Customer?.Fname} {Customer?.Lname}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <input
                                                                                    style={{
                                                                                        border: "none",
                                                                                        width: "100%",
                                                                                        height: "46px",
                                                                                        padding: "5px",
                                                                                        backgroundColor: "#ecf5fd",
                                                                                    }}
                                                                                    onChange={(e) =>
                                                                                        setCommenttext(e.target.value)
                                                                                    }
                                                                                    type="text"
                                                                                    placeholder="Comment here..."
                                                                                />
                                                                            </div>

                                                                            <div>
                                                                                <button
                                                                                    className="pink-btn w-100"
                                                                                    onClick={() => userComments(item?._id)}
                                                                                >
                                                                                    POST
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {item?.text ? (
                                                                    <>
                                                                        <div className="kalin">
                                                                            <div className="d-flex justify-content-between p-2 mb-2">
                                                                                <div className="d-flex gap-2 align-items-center">
                                                                                    {item?.customers?.profileImage ? (
                                                                                        <>
                                                                                            <img
                                                                                                src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: "50px",
                                                                                                    height: "50px",
                                                                                                    borderRadius: "50%",
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <img
                                                                                                src="./img/profile.jpg"
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: "50px",
                                                                                                    height: "50px",
                                                                                                    borderRadius: "50%",
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                    <div>
                                                                                        <p className="fw-bold text-secondary fs-6">
                                                                                            {item?.User?.Fname} {item?.User?.Lname}
                                                                                        </p>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "12px",
                                                                                                color: "grey",
                                                                                            }}
                                                                                        >
                                                                                            {timeDifferenceInMinutes <= 60 ? (
                                                                                                <>
                                                                                                    {timeDifferenceInMinutes} Mins Ago
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {timeDifferenceInHours <= 24 ? (
                                                                                                        <>
                                                                                                            {timeDifferenceInHours} Hours
                                                                                                            Ago
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {timeDifferenceInDays <= 7 ? (
                                                                                                                <>
                                                                                                                    {timeDifferenceInDays} Days
                                                                                                                    Ago
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {moment(
                                                                                                                        item.createdAt
                                                                                                                    ).format("DD-MM-YYYY")}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    padding: "8px",
                                                                                    backgroundColor: "antiquewhite",
                                                                                }}
                                                                            >
                                                                                <h4>{item?.text}</h4>
                                                                            </div>

                                                                            <div className="p-2">
                                                                                <div className="emoji">
                                                                                    <div className="d-flex gap-2">
                                                                                        <img src="./img/like.png" alt="" />
                                                                                        <span className="fw-bold text-secondary">
                                                                                            {item?.likes?.length}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="d-flex gap-1 align-items-center"
                                                                                        style={{
                                                                                            fontSize: "16px",
                                                                                            color: "grey",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            onClick={() => {
                                                                                                handleShow1(item);
                                                                                            }}
                                                                                            style={{ cursor: "pointer" }}
                                                                                        >
                                                                                            <AiOutlineComment />{" "}
                                                                                            {item?.comments?.length}
                                                                                        </span>
                                                                                        <span>
                                                                                            <CiSaveDown2 /> {item?.Share?.length}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="react-0">
                                                                                    {item.likes.some(
                                                                                        (like) => like.userId === Customer?._id
                                                                                    ) ? (
                                                                                        <>
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    userlikes(item?._id);
                                                                                                }}
                                                                                            >
                                                                                                <AiTwotoneLike
                                                                                                    style={{ color: "#0466ff" }}
                                                                                                />
                                                                                                Likes
                                                                                            </span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    userlikes(item?._id);
                                                                                                }}
                                                                                            >
                                                                                                <BiLike />
                                                                                                Likes
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            handleClick(item?._id);
                                                                                        }}
                                                                                    >
                                                                                        <AiOutlineComment />
                                                                                        Comment
                                                                                    </span>
                                                                                    <span>
                                                                                        <WhatsappShareButton
                                                                                            onClick={() => PostShare(item)}
                                                                                            url={`http://localhost:3000/communityshare/${item?._id}`}
                                                                                        >
                                                                                            <WhatsappIcon size={32} round />
                                                                                            Share
                                                                                        </WhatsappShareButton>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {IsOpen && postdataid === item?._id ? (
                                                                            <>
                                                                                <div className="jgashgd_1 mb-4">
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        {Customer?.profileImage ? (
                                                                                            <>
                                                                                                <img
                                                                                                    src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "50px",
                                                                                                        borderRadius: "50%",
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <img
                                                                                                    src="./img/profile.jpg"
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "50px",
                                                                                                        borderRadius: "50%",
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                        <div>
                                                                                            <p className="fw-bold text-secondary fs-6">
                                                                                                {Customer?.Fname} {Customer?.Lname}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3">
                                                                                        <input
                                                                                            style={{
                                                                                                border: "none",
                                                                                                width: "100%",
                                                                                                height: "46px",
                                                                                                padding: "5px",
                                                                                                backgroundColor: "#ecf5fd",
                                                                                            }}
                                                                                            onChange={(e) =>
                                                                                                setCommenttext(e.target.value)
                                                                                            }
                                                                                            type="text"
                                                                                            placeholder="Comment here..."
                                                                                        />
                                                                                    </div>

                                                                                    <div>
                                                                                        <button
                                                                                            className="pink-btn w-100"
                                                                                            onClick={() => userComments(item?._id)}
                                                                                        >
                                                                                            POST
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="kalin">
                                                                            <div className="d-flex justify-content-between p-2 mb-2">
                                                                                <div className="d-flex gap-2 align-items-center">
                                                                                    {item?.customers?.profileImage ? (
                                                                                        <>
                                                                                            <img
                                                                                                src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: "50px",
                                                                                                    height: "50px",
                                                                                                    borderRadius: "50%",
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <img
                                                                                                src="./img/profile.jpg"
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: "50px",
                                                                                                    height: "50px",
                                                                                                    borderRadius: "50%",
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                    <div>
                                                                                        <p className="fw-bold text-secondary fs-6">
                                                                                            {item?.User?.Fname} {item?.User?.Lname}
                                                                                        </p>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "12px",
                                                                                                color: "grey",
                                                                                            }}
                                                                                        >
                                                                                            {timeDifferenceInMinutes <= 60 ? (
                                                                                                <>
                                                                                                    {timeDifferenceInMinutes} Mins Ago
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {timeDifferenceInHours <= 24 ? (
                                                                                                        <>
                                                                                                            {timeDifferenceInHours} Hours
                                                                                                            Ago
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {timeDifferenceInDays <= 7 ? (
                                                                                                                <>
                                                                                                                    {timeDifferenceInDays} Days
                                                                                                                    Ago
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {moment(
                                                                                                                        item.createdAt
                                                                                                                    ).format("DD-MM-YYYY")}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <img
                                                                                src={`https://hayyyak.com/PostImages/${item?.image}`}
                                                                                alt=""
                                                                                className="img-fluid mb-2"
                                                                                style={{
                                                                                    width: "500px",
                                                                                    height: "300px",
                                                                                }}
                                                                            />
                                                                            <div className="p-2">
                                                                                <div className="emoji">
                                                                                    <div className="d-flex gap-2">
                                                                                        <img src="./img/like.png" alt="" />
                                                                                        <span className="fw-bold text-secondary">
                                                                                            {item?.likes?.length}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="d-flex gap-1 align-items-center"
                                                                                        style={{
                                                                                            fontSize: "16px",
                                                                                            color: "grey",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            onClick={() => {
                                                                                                handleShow1(item);
                                                                                            }}
                                                                                            style={{ cursor: "pointer" }}
                                                                                        >
                                                                                            <AiOutlineComment />{" "}
                                                                                            {item?.comments?.length}
                                                                                        </span>
                                                                                        <span>
                                                                                            <CiSaveDown2 /> {item?.Share?.length}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="react-0">
                                                                                    {item.likes.some(
                                                                                        (like) => like.userId === Customer?._id
                                                                                    ) ? (
                                                                                        <>
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    userlikes(item?._id);
                                                                                                }}
                                                                                            >
                                                                                                <AiTwotoneLike
                                                                                                    style={{ color: "#0466ff" }}
                                                                                                />
                                                                                                Likes
                                                                                            </span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    userlikes(item?._id);
                                                                                                }}
                                                                                            >
                                                                                                <BiLike />
                                                                                                Likes
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            handleClick(item?._id);
                                                                                        }}
                                                                                    >
                                                                                        <AiOutlineComment />
                                                                                        Comment
                                                                                    </span>
                                                                                    <span>
                                                                                        <WhatsappShareButton
                                                                                            onClick={() => PostShare(item)}
                                                                                            url={`http://localhost:3000/communityshare/${item?._id}`}
                                                                                        >
                                                                                            <WhatsappIcon size={32} round />
                                                                                            Share
                                                                                        </WhatsappShareButton>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {IsOpen && postdataid === item?._id ? (
                                                                            <>
                                                                                <div className="jgashgd_1 mb-4">
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        {Customer?.profileImage ? (
                                                                                            <>
                                                                                                <img
                                                                                                    src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "50px",
                                                                                                        borderRadius: "50%",
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <img
                                                                                                    src="./img/profile.jpg"
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "50px",
                                                                                                        borderRadius: "50%",
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                        <div>
                                                                                            <p className="fw-bold text-secondary fs-6">
                                                                                                {Customer?.Fname} {Customer?.Lname}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3">
                                                                                        <input
                                                                                            style={{
                                                                                                border: "none",
                                                                                                width: "100%",
                                                                                                height: "46px",
                                                                                                padding: "5px",
                                                                                                backgroundColor: "#ecf5fd",
                                                                                            }}
                                                                                            onChange={(e) =>
                                                                                                setCommenttext(e.target.value)
                                                                                            }
                                                                                            type="text"
                                                                                            placeholder="Comment here..."
                                                                                        />
                                                                                    </div>

                                                                                    <div>
                                                                                        <button
                                                                                            className="pink-btn w-100"
                                                                                            onClick={() => userComments(item?._id)}
                                                                                        >
                                                                                            POST
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">

                                    <div className="row mobileserponsiver-100w m-1 ">


                                        {Posts?.filter((ele) => ele?.userId === item?.userId && ele.image !== null && ele.image !== undefined)?.map((item) => (
                                            <>
                                                <div className="col-lg-3">
                                                    <img src={`https://hayyyak.com/PostImages/${item?.image}`} alt="Post-Image" style={{ width: '-webkit-fill-available', height: '250px', borderRadius: '15px', imageRendering: 'pixelated' }} />
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </div>




            </Container>

            {/* Edit Profile */}
            {/* 
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='fb-profile'>
                        <img src="/img/login-img.jpg" alt="" />
                        <p>
                            <span> Edit Image</span>
                            <input type="file" />
                        </p>
                    </div>
                    <div className='row uihsdf_0'>
                        <div className='col-lg-6'>
                            <label>First Name</label><br />
                            <input type="text" />
                        </div>
                        <div className='col-lg-6'>
                            <label>Last Name</label><br />
                            <input type="text" />
                        </div>
                        <div className='col-lg-12'>
                            <label>Email</label><br />
                            <input type="text" />
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* Comment Modal */}
            <Modal size="md" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>{commentashow?.User?.Fname}'s Post</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="p-2">
                        <div className="d-flex gap-2 align-items-center mb-2">
                            {commentashow?.customers?.profileImage ? (
                                <>
                                    <img
                                        src={`https://hayyyak.com/Customer/${commentashow?.customers?.profileImage}`}
                                        alt=""
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        src="./img/profile.jpg"
                                        alt=""
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </>
                            )}
                            <div>
                                <p className="fw-bold text-secondary fs-6">
                                    {commentashow?.User?.Fname} {commentashow?.User?.Lname}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="comment_img">
                        {commentashow?.image ? (
                            <>
                                <img
                                    src={`https://hayyyak.com/PostImages/${commentashow?.image}`}
                                    alt=""
                                    className="mb-2"
                                    style={{ width: "-webkit-fill-available", height: "250px" }}
                                />
                                <h4 className=" p-2">{commentashow?.text}</h4>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        padding: "8px",
                                        backgroundColor: "antiquewhite",
                                    }}
                                >
                                    <h4>{commentashow?.text}</h4>
                                </div>
                            </>
                        )}
                        <div className="emoji">
                            <div className="d-flex gap-2">
                                <img
                                    src="./img/like.png"
                                    alt=""
                                    style={{ width: "20px", height: "20px" }}
                                />
                                <span className="fw-bold text-secondary">
                                    {commentashow?.likes?.length}
                                </span>
                            </div>
                            <div
                                className="d-flex gap-2 align-items-center fs-5"
                                style={{ fontSize: "16px", color: "grey" }}
                            >
                                <span>
                                    <AiOutlineComment /> {commentashow?.comments?.length}
                                </span>
                            </div>
                        </div>

                        <div>
                            {Comments?.map((item) => {
                                return (
                                    <div className="d-flex gap-2 align-items-start">
                                        {item?.userId?.profileImage ? (
                                            <>
                                                <img
                                                    src={`https://hayyyak.com/Customer/${item?.userId?.profileImage}`}
                                                    alt=""
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src="./img/profile.jpg"
                                                    alt=""
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </>
                                        )}
                                        <div
                                            style={{
                                                backgroundColor: "aliceblue",
                                                padding: "12px",
                                                borderRadius: "17px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <p className="fw-bold text-secondary fs-6">
                                                {item?.userId?.Fname} {item?.userId?.Lname}
                                            </p>
                                            <p>{item?.comment}</p>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
