import axios from "axios";
import { getHours } from "date-fns";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export const TaxiBook = () => {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const Customer = JSON.parse(sessionStorage.getItem("customer"));

  const location = useLocation();
  const {
    // Daily Ride
    item,
    TripType,
    picUpPlace,
    dropPlace,
    picUpDate,
    picUpTime,
    When,
    image,
    Category,
    price,
    DriverAllowance,
    CGST,
    IGST,
    SGST,
    WaitingCharge,
    NightDriverAllowance,
    TollCharge,
    ParkingCharge,

    // Rental
    SelectedCity,
    selectedPackage,

    // Airport
    AirportPicDrop,

    // Outstation
    ReturnpicUpDate,
    ReturnpicUpTime,
    OneRound,
  } = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // Book Taxi
  const [PaymentMethod, setPaymentMethod] = useState("");
  const BookTaxi = async () => {
    if (!Customer) {
      return notify1("Please Login");
    }
    if (!PaymentMethod) {
      return notify1("Please Select Payment Mode");
    }
    try {
      const config = {
        url: "/customer/taxi/Book_Taxi",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          userId: Customer?._id,
          TripType: TripType,
          picUpPlace: picUpPlace,
          dropPlace: dropPlace,
          When: When,
          picUpDate:
            When === "Now" ? new Date().toLocaleDateString() : picUpDate,
          picUpTime:
            When === "Now" ? new Date().toLocaleTimeString() : picUpTime,
          image: image,
          Category: Category,
          BookedPrice: price,
          PaymentMethod: PaymentMethod,
          DriverAllowance: DriverAllowance,
          WaitingCharge: WaitingCharge,
          NightDriverAllowance: NightDriverAllowance,
          TollCharge: TollCharge,
          ParkingCharge: ParkingCharge,

          // Rent
          SelectedCity: SelectedCity,
          RentPackage: selectedPackage,

          // Airport
          AirportPicDrop: AirportPicDrop,

          // OutStation
          ReturnpicUpDate: ReturnpicUpDate,
          ReturnpicUpTime: ReturnpicUpTime,
          OneRound: OneRound,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
      }
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container>
        <div className="row justify-content-between margin-bottom">
          <div className="col-lg-6">
            <div>
              <div className="d-flex align-items-start justify-content-center gap-2 mb-2">
                <img
                  src={`https://hayyyak.com/VehicleCategory/${image}`}
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div>
                  <span className="fs-5 fw-bold">{Category}</span>
                  <br />
                  <span>1 min away</span>
                </div>
              </div>
              <iframe
                // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d124408.30814365871!2d77.59450212929688!3d12.987220146923262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1695878776167!5m2!1sen!2sin"
                src={`https://www.google.com/maps/embed/v1/directions?origin=${encodeURIComponent(
                  picUpPlace
                )}&destination=${encodeURIComponent(dropPlace)}
                  `}
                width="100%"
                height="300"
                style={{ border: "0" }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div
              style={{
                border: "1px solid #00000042",
                width: "100%",
                padding: "10px",
              }}
            >
              <div>
                {TripType === "Out Station" && OneRound ? (
                  <div className="taxi-book">
                    <span>Oneway / Round Trip :</span>
                    <p>{OneRound}</p>
                  </div>
                ) : (
                  <></>
                )}

                {AirportPicDrop ? (
                  <div className="taxi-book">
                    <span>AIRPORT PICKUP/DROP :</span>
                    <p>{AirportPicDrop}</p>
                  </div>
                ) : (
                  <></>
                )}

                {SelectedCity ? (
                  <div className="taxi-book">
                    <span>CITY :</span>
                    <p>{SelectedCity}</p>
                  </div>
                ) : (
                  <></>
                )}

                {picUpPlace ? (
                  <div className="taxi-book">
                    <span>PICK UP LOCATION :</span>
                    <p>{picUpPlace}</p>
                  </div>
                ) : (
                  <></>
                )}

                <div className="taxi-book">
                  <span> {selectedPackage ? "PACKAGE" : "DROP"} :</span>
                  <p>
                    {dropPlace} {selectedPackage}
                  </p>
                </div>
                {When === "Now" ? (
                  <>
                    <div className="taxi-book">
                      <span>Date :</span>
                      <p>{new Date().toLocaleDateString()}</p>
                    </div>
                    <div className="taxi-book">
                      <span>Time :</span>
                      <p>{new Date().toLocaleTimeString()}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="taxi-book">
                      <span>Date :</span>
                      <p>{picUpDate}</p>
                    </div>
                    <div className="taxi-book">
                      <span>Time :</span>
                      <p>{moment(picUpTime, "HH:mm").format("HH:mm")}</p>
                    </div>
                  </>
                )}

                {ReturnpicUpDate ? (
                  <div className="taxi-book">
                    <span>Return Date :</span>
                    <p>{ReturnpicUpDate}</p>
                  </div>
                ) : (
                  <></>
                )}

                {ReturnpicUpTime ? (
                  <div className="taxi-book">
                    <span>Return Time :</span>
                    <p>{ReturnpicUpTime}</p>
                  </div>
                ) : (
                  <></>
                )}

                <div className="taxi-book">
                  <span>FARE :</span>
                  <p>{Number(price)?.toFixed(2)}</p>
                </div>
                <div className="taxi-book mb-4">
                  <span>PAY BY :</span>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ width: "70%" }}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option>Select Payment Mode </option>
                    <option value="Cash">Cash</option>
                    <option value="Online Payment">Online Payment</option>
                    <option value="Pay to Driver">Pay to Driver</option>
                  </Form.Select>
                </div>
                <div>
                  <button
                    className="header-search w-100"
                    onClick={() => BookTaxi()}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 row lalit p-0">
            <div className="col-lg-6 lalit-left">
              <h5>Inclusions (Included in the Price)</h5>
              <ul>
                <li>Driver Allowance ₹{DriverAllowance}</li>
                <li>CGST {CGST}%</li>
                <li>IGST {IGST}%</li>
                <li>SGST {SGST}%</li>
                <li>Toll Charges ₹{TollCharge}</li>
              </ul>
            </div>
            <div className="col-lg-6 p-0">
              <h5>Exclusions (Extra Charges)</h5>
              <ul>
                <li>
                  <div className="d-flex justify-content-between">
                    <span>Waiting Charges</span>
                    <span> ₹{WaitingCharge}</span>
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between">
                    <span>Night Driver Allowance</span>
                    <span>₹{NightDriverAllowance}</span>
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between">
                    <span>Parking Charges</span>
                    <span>₹{ParkingCharge}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/* Mobile Number Modal */}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src="./img/logo.jpeg"
              alt=""
              style={{ width: "100px", marginBottom: "10px" }}
            />
            <h5 className="fw-bold">Enter Your Mobile Number</h5>
            <p className="text-secondary mb-2">
              A 4-digit OTP will be sent on SMS
            </p>

            <div class="input-group mb-3 m-auto" style={{ width: "80%" }}>
              <span class="input-group-text" id="basic-addon1">
                <img src="./img/india.svg" alt="" />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="+91"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow1} variant="danger">
            Next
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* OTP Modal */}
      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Body>
          <div className="text-center">
            <img
              src="./img/logo.jpeg"
              alt=""
              style={{ width: "100px", marginBottom: "10px" }}
            />
            <h5 className="fw-bold">
              Enter Your <span className="text-danger">OTP</span>
            </h5>
            <p className="text-secondary mb-2">
              Please Enter 4-digit OTP Sent On Your Number
            </p>

            <div class="input-group mb-3 m-auto" style={{ width: "80%" }}>
              <input
                type="text"
                class="form-control"
                placeholder="Ex: 2654...."
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};
