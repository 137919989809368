import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import parse from "html-react-parser"

function AdminAbout() {

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [editdata, seteditdata] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); seteditdata(item) }


    const [Header, setHeader] = useState("")
    const [Description, setDescription] = useState("")
    const [Image, setImage] = useState("")

    useEffect(() => {
        AboutUs();
    }, [])

    const formdata = new FormData()


    // Add data
    const AdminAbout = async () => {
        formdata.append("titeles", Header);
        formdata.append("Description", Description);
        formdata.append("Image", Image);
        try {
            const config = {
                url: "/addaboutus",
                baseURL: "https://hayyyak.com/api/admin",
                method: "post",
                headers: { "content-type": "multipart/form-data" },
                data: formdata,
            }
            axios(config).then((res) => {
                if (res.status === 200) {
                    alert("Successfully added");
                    AboutUs();
                    handleClose()
                }
            })
        } catch (error) {
            console.log(error)
            alert(error.response.data.error)
        }
    }

    // Edit
    const EditAbout = async () => {
        formdata.append("titeles", Header);
        formdata.append("Description", Description);
        formdata.append("Image", Image);
        // alert(Header, Description, Image);
        try {
            const config = {
                url: "/editaboutus/" + editdata?._id,
                baseURL: "https://hayyyak.com/api/admin",
                method: "put",
                headers: { "content-type": "multipart/form-data" },
                data: formdata,
            }

            axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success)
                    AboutUs();
                    handleClose1()
                }
            })
        } catch (error) {
            console.log(error)
            alert(error.response.data.error)
        }
    }

    // Get Data
    const [aboutdetail, setaboutdetail] = useState([])
    const AboutUs = () => {
        axios
            .get('https://hayyyak.com/api/admin/customeraboutus')
            .then(function (response) {
                console.log(response.data);
                setaboutdetail(response.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // Delete Data 
    const [Data, setdata] = useState({})
    const deleteClose = () => setShow2(false)
    const deleteShow = (item) => { setShow2(true); setdata(item) }


    const DeleteAboutUs = async () => {
        try {
            const config = {
                url: 'admin/deleteaboutus/' + Data?._id,
                baseURL: 'https://hayyyak.com/api',
                method: 'DELETE',
                headers: { "content-type": "application-json" },
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success);
                    deleteClose()
                    // AboutUs()
                }
            })

        } catch (error) {
            alert(error.response.data.error)
        }
    }


    return (
        <div>
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">About Us :</p>
                    {aboutdetail.length ? <></> : <button className='pink-btn' onClick={handleShow} >Add AboutUs</button>}
                </div>

                {/* <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn">Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-describedby="basic-addon1" />
                    </div>

                </div> */}
                <div >
                    <Table responsive bordered className='admin-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Header</th>
                                <th style={{ width: '400px' }}>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {aboutdetail?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{++i}</td>
                                        <td><img src={`https://hayyyak.com/AboutUs/${item?.Image}`} alt="" style={{ width: '150px', height: '150px', objectFit: 'cover' }} /></td>
                                        <td>{parse(`<div>${item?.titeles}</div>`)}</td>
                                        <td>{parse(`<div>${item?.Description}</div>`)}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                <MdDelete className='text-danger' onClick={() => deleteShow(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add About Us Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add AboutUs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mt-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            type="file"
                            placeholder="Name"
                            id='upload'
                            accept='image/*'
                            className="vi_0"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Header :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Header}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setHeader(data);
                            }}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                            }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AdminAbout}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit About us */}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger' >Edit AboutUs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mt-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            type="file"
                            placeholder="Name"
                            id='upload'
                            accept='image/*'
                            className="vi_0"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Header :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Header}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setHeader(data);
                            }}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                            }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditAbout}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={deleteClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={deleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => DeleteAboutUs()}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>


        </div >
    )
}
export default AdminAbout
