import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react'
import parse from "html-react-parser"

function AdminTestimonial() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);
    const formData = new FormData();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [TestiImage, setTestiImage] = useState("")
    const [Title, setTitle] = useState("")
    const [Location, setLocation] = useState("")
    const [Description, setDescription] = useState("")

    const AddTestimonial = async () => {
        if (!TestiImage) {
            return notify1("Please Select Image")
        }
        if (!Title) {
            return notify1("Please Enter Title")
        }
        if (!Location) {
            return notify1("Please Enter Location")
        }
        if (!Description) {
            return notify1("Please Enter Description")
        }

        try {
            const config = {
                url: 'admin/home/addtestimonial',
                baseURL: "https://hayyyak.com/api/",
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    TestiImage: TestiImage,
                    Title: Title,
                    Location: Location,
                    Description: Description,
                }
            }
            await axios(config).then((res) => {
                notify(res.data.success)
                getTestimonial()
                handleClose()
                setTestiImage("")
                setTitle("")
                setLocation("")
                setDescription("")
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // get testimonial
    const [GetTesti, setGetTesti] = useState([]);
    const getTestimonial = async () => {
        try {
            const response = await axios.get("https://hayyyak.com/api/admin/home/gettestimonial");
            console.log(response.data);
            setGetTesti(response.data.success);
        } catch (error) {
            console.log(error);
        }
    }
    console.log("GetTesti", GetTesti);



    // Edit Testimonial
    const [EditTesti, setEditTesti] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); setEditTesti(item) }
    const EditTestimonial = async () => {
        try {
            formData.append("TestiImage", TestiImage); // Assuming TestiImage is the file input
            formData.append("Title", Title);
            formData.append("Location", Location);
            formData.append("Description", Description);

            const config = {
                url: `admin/home/edittestimonial/${EditTesti?._id}`,
                method: "put",
                baseURL: "https://hayyyak.com/api/",
                headers: { "content-type": "multipart/form-data" },
                data: formData,
            };

            const response = await axios(config);
            if (response.status === 200) {
                notify(response.data.success);
                getTestimonial();
                handleClose1();
                setTestiImage("")
                setTitle("")
                setLocation("")
                setDescription("")
            }
        } catch (error) {
            notify2(error.response?.data?.error || "An error occurred");
        }
    };

    // Delete Testimonial
    const [deletedata, setdeletedata] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setdeletedata(item) };
    const DeletAllPackage = async () => {
        try {
            const config = {
                url: "admin/home/deletetestimonial/" + deletedata?._id,
                baseURL: "https://hayyyak.com/api",
                method: "delete",
                headers: { "content-type": "application/json" },
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    getTestimonial()
                    handleClose2()
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    useEffect(() => {
        getTestimonial();
    }, []);

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Testimonial :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Testimonial</button>
                </div>

                {/* <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn">Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-describedby="basic-addon1" />
                    </div>

                </div> */}
                <div >
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Title</th>
                                <th style={{ width: '400px' }}>Description</th>
                                <th>Location</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {GetTesti?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <img src={`https://hayyyak.com/Testimonial/${item?.TestiImage}`}
                                                alt=""
                                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                            />
                                        </td>
                                        <td>{item?.Title}</td>
                                        <td>{parse(`<div>${item?.Description}</div>`)}</td>
                                        <td>{item?.Location}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Testimonial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label htmlFor='upload' className='fw-bold'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setTestiImage(e.target.files[0])}
                        />
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Title :</label>
                            <input type="text" placeholder='Model' className="vi_0" onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Location :</label>
                            <input type="text" placeholder='Location ' className="vi_0" onChange={(e) => setLocation(e.target.value)} />
                        </div>
                    </div>

                    <div className="mt-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddTestimonial}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Testimonial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label htmlFor='upload' className='fw-bold'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setTestiImage(e.target.files[0])}
                        />
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Title :</label>
                            <input type="text" placeholder='Model' className="vi_0" onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Location :</label>
                            <input type="text" placeholder='AC' className="vi_0" onChange={(e) => setLocation(e.target.value)} />
                        </div>
                    </div>

                    <div className="mt-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditTestimonial}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeletAllPackage}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminTestimonial
