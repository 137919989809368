import React from 'react'
import { Container } from 'react-bootstrap'

export const Invoice = () => {
    return (
        <div>

        </div>
    )
}

//jai driving invoice
{/* <Container className='my-3 text-center'
style={{
    width: "800px",
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    border: "10px solid #9d041b",
    padding: "15px"
}}>
<h5 className='text-uppercase'>Jai <span style={{ color: '#9d041b' }}> Driving</span> School</h5>
<h1 className='text-uppercase' style={{ color: '#9d041b' }}>Certificate</h1>
<p className='text-uppercase d-flex gap-2 align-items-center justify-content-center fs-3 fw-semibold'><span>-------</span> Of Compeltion <span>-------</span></p>
<p className='text-uppercase fs-5'>This Certificate Proudaly </p>
<p className='text-uppercase fs-5 mb-4'> Presented to </p>
<div style={{ borderBottom: '1px dashed #9d041b', width: '500px', margin: 'auto' }}>
    <h1 style={{ color: '#9d041b', marginBottom: '0px' }}>Ganesh kalal</h1>
</div>
<p className='fw-semibold mt-4 fs-6'>Has successfully completed the driving course.</p>
<p className='mb-4 text-secondary'>Exceptional perfomance and outstandng achievements in driving school. Your hard work, dedication, and perseverance have been instrumental in your success, and we are honored to recognize your accomplishments</p>

<div className='d-flex justify-content-between align-items-center'>
    <div className='text-center'>
        <p className='mb-0'>11/27/2023</p>
        <p style={{ height: '15px' }}>------------------------</p>
        <span className='fw-semibold'>DATE</span>
    </div>
    <img src="./img/dri.png" alt="" style={{ width: '140px', height: '120px' }} />
    <div className='text-center'>
        <p className='mb-0'>Ganesh</p>
        <p style={{ height: '15px' }}>------------------------</p>
        <span className='fw-semibold'>SIGNATURE</span>
    </div>
</div>
</Container> */}
