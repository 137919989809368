import axios from 'axios'
import moment from 'moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import { AiOutlineComment, AiOutlineHome, AiOutlineUserAdd } from 'react-icons/ai'
import { BsEmojiSmile, BsSearch, BsTag, BsThreeDots } from 'react-icons/bs'
import { CiSaveDown2 } from 'react-icons/ci'
import { ImCancelCircle } from 'react-icons/im'
import { MdCancelPresentation } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom/dist'

export const CommunityShare = () => {

    // const location = useLocation();
    // const { item } = location?.state

    // const [PostData, setPostData] = useState({})
    // useEffect(() => {
    //     if (item) {
    //         setPostData(item)
    //     }
    // }, [item])
    // console.log("PostData", PostData);



    const { id } = useParams();
    console.log("id", id);
    const [Product, setProduct] = useState({});
    const getsharedata = async () => {
        let res = await axios.get(
            "https://hayyyak.com/api/customer/getsharedata/" + id
        );
        if (res.status === 200) {
            setProduct(res.data.data);
        }
    };
    useEffect(() => {
        getsharedata()
    }, [])

    const postDate = new Date(Product.createdAt);
    const currentDate = new Date();

    const timeDifferenceInMinutes = Math.floor(
        (currentDate - postDate) / (1000 * 60)
    );
    const timeDifferenceInHours = Math.floor(
        (currentDate - postDate) / (1000 * 60 * 60)
    );
    const timeDifferenceInDays = Math.floor(
        (currentDate - postDate) / (1000 * 60 * 60 * 24)
    );

    console.log("Product", Product);

    return (
        <div>
            <div className='facebook'>
                <Container fluid>
                    <div className="row p-2 align-items-center justify-content-between" >
                        <div className="col-lg-3 d-flex gap-2 align-items-center">
                            <a href="/login"><img src="../img/logo.jpeg" alt="" style={{ height: '62px' }} /></a>
                        </div>

                        <div className="col-lg-3 d-flex gap-3 align-items-center">
                            <div >
                                {/* <span>Login</span> */}
                                <p>
                                    <a href="/login"
                                        style={{
                                            color: '#fff',
                                            padding: '10px',
                                            backgroundColor: '#c7244b',
                                            borderRadius: '10px'
                                        }}
                                        className='text-decoration-none'>
                                        Login
                                    </a>
                                </p>
                            </div>
                            <div >
                                {/* <span>Sign Up</span> */}
                                <p >
                                    <a href="/register" style={{
                                        backgroundColor: "#fff",
                                        color: '#000',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        fontWeight: '500'
                                    }} className='text-decoration-none'>Sign Up</a>
                                </p>
                            </div>
                        </div>
                    </div>


                </Container>
            </div>
            <Container>

                <div className='kalin' style={{ width: '500px', margin: 'auto' }}>
                    <div className='d-flex justify-content-between p-2 mb-2'>
                        <div className='d-flex gap-2 align-items-center'>
                            {Product?.userId?.profileImage ? (
                                <>
                                    <img
                                        src={`https://hayyyak.com/Customer/${Product?.userId?.profileImage}`}
                                        alt=""
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        src="../img/profile.jpg"
                                        alt=""
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </>
                            )}
                            <div>
                                <p className="fw-bold text-secondary fs-6">
                                    {Product?.userId?.Fname} {Product?.userId?.Lname}
                                </p>
                                <span
                                    style={{ fontSize: "12px", color: "grey" }}
                                >
                                    {timeDifferenceInMinutes <= 60 ? (
                                        <>{timeDifferenceInMinutes} Mins Ago</>
                                    ) : (
                                        <>
                                            {timeDifferenceInHours <= 24 ? (
                                                <>{timeDifferenceInHours} Hours Ago</>
                                            ) : (
                                                <>
                                                    {timeDifferenceInDays <= 7 ? (
                                                        <>
                                                            {timeDifferenceInDays} Days Ago
                                                        </>
                                                    ) : (
                                                        <>
                                                            {moment(Product.createdAt).format(
                                                                "DD-MM-YYYY"
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    <img
                        src={`https://hayyyak.com/PostImages/${Product?.image}`}
                        alt=""
                        className="img-fluid mb-2"
                        style={{
                            width: "500px",
                            height: "300px",
                        }}
                    />
                    <div className='p-2'>
                        <div className='row justify-content-between mb-2'>
                            <p className="fs-5 fw-bold col-lg-8">
                                {Product?.text}
                            </p>
                        </div>

                        {/* <div className="emoji">
                            <div className="d-flex gap-2">
                                <img src="./img/like.png" alt="" />
                                <span className="fw-bold text-secondary">
                                  45
                                </span>
                            </div>
                            <div
                                className="d-flex gap-1 align-items-center"
                                style={{ fontSize: "16px", color: "grey" }}
                            >
                                <span
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineComment />{" "}
                                  44
                                </span>
                                <span>
                                    <CiSaveDown2 /> 954
                                </span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Container>
        </div>
    )
}
