import React, { useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import * as XLSX from "xlsx";
import { toast, ToastContainer } from "react-toastify";
function AdminAddRooms() {
  let admin = JSON.parse(sessionStorage.getItem("admin"));
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // input file access
  const fileInputRef = useRef(null);
  const [amid, setamid] = useState("");

  const handleIconClick = (id) => {
    setamid(id);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };

  const [StaysDetails, setStaysDetails] = useState([]);
  const getStays = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/stays/staysrooms"
      );
      if (res.data && res.status === 200) {
        setStaysDetails(res.data.allstays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("StaysDetails", StaysDetails);

  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [StaysCat, setStaysCat] = useState("");
  const [RoomName, setRoomName] = useState("");
  const [adultroomprice, setadultroomprice] = useState();
  const [childrenroomprice, setchildrenroomprice] = useState();
  const [Adultdiscount, setAdultdiscount] = useState();
  const [Childrendiscount, setChildrendiscount] = useState();
  const [infantsdiscount, setinfantsdiscount] = useState();
  const [infants, setinfants] = useState();
  const [gst, setgst] = useState();
  const [Amenities, setAmenities] = useState("");
  const formdata = new FormData();
  const AddStaysDetails = async () => {
    if (!StaysCat) {
      return alert("Please Select Category....");
    }
    if (!RoomName) {
      return alert("Enter Room Name....");
    }
    if (!adultroomprice) {
      return alert("Enter adult room price....");
    }
    if (!childrenroomprice) {
      return alert("Enter children room price....");
    }
    if (!gst) {
      return alert("Enter gst discount....");
    }
    if (!Amenities) {
      return alert("Enter Amenities....");
    }

    try {
      const adultdiscountprice =
        adultroomprice - (adultroomprice * Adultdiscount) / 100;
      const childrendiscountprice =
        childrenroomprice - (childrenroomprice * Childrendiscount) / 100;
      const infantsdiscountprice = infants - (infants * infantsdiscount) / 100;

      formdata.append("StaysId", StaysCat);
      formdata.append("roomname", RoomName);

      formdata.append("adultprice", adultroomprice);
      formdata.append("adultdiscount", Adultdiscount);
      formdata.append("adultdiscountprice", adultdiscountprice);

      formdata.append("children", childrenroomprice);
      formdata.append("childrendiscount", Childrendiscount);
      formdata.append("childrendiscountprice", childrendiscountprice);

      formdata.append("infants", infants);
      formdata.append("infantsdiscount", infantsdiscount);
      formdata.append("infantsdiscountprice", infantsdiscountprice);

      formdata.append("gst", gst);
      formdata.append("amenities", Amenities);
      formdata.append("vendorId", admin?._id);
      const config = {
        url: "/vendor/stays/addrooms",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      const response = await axios(config);
      if (response.status === 200) {
        for (let i = 0; i < galleryImages.length; i++) {
          const config = {
            url: "/vendor/stays/roomimageupload",
            method: "put",
            baseURL: "https://hayyyak.com/api",
            headers: { "content-type": "multipart/form-data" },
            data: {
              roomid: response.data.data?._id,
              image: galleryImages[i]?.image,
            },
          };
          await axios(config);
        }
        alert("Add room Successfully");
        handleClose();
        roomsDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [DataView, setDataView] = useState({});
  const [bigImage, setbigImage] = useState({});
  const [RoomsDetails, setRoomsDetails] = useState([]);
  const roomsDetails = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/vendor/stays/roomsdetailbyid/${admin?._id}`
      );
      if (res.data && res.status === 200) {
        setRoomsDetails(res.data.rooms);
        setnochangedata(res.data.rooms);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete
  const [Data, setdata] = useState("");
  const DeleteRoom = async () => {
    try {
      const config = {
        url: "/vendor/stays/detelevendorroom/" + Data?._id,
        baseURL: "https://hayyyak.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          roomsDetails();
          handleClose4();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };
  const [editdata, seteditdata] = useState("");
  useEffect(() => {
    setAdultdiscount(editdata?.adultdiscount || "");
    setChildrendiscount(editdata?.childrendiscount || "");
    setinfantsdiscount(editdata?.infantsdiscount || "");
  }, [editdata]);
  const updateroomDetails = async () => {
    try {
      const finalAdultPrice = adultroomprice || editdata?.adultprice;
      const finalChildrenPrice = childrenroomprice || editdata?.children;
      const finalInfantsPrice = infants || editdata?.infants;

      const adultdiscountprice =
        finalAdultPrice - (finalAdultPrice * Adultdiscount) / 100;

      const childrendiscountprice =
        finalChildrenPrice - (finalChildrenPrice * Childrendiscount) / 100;
      const infantsdiscountprice =
        finalInfantsPrice - (finalInfantsPrice * infantsdiscount) / 100;
      const config = {
        url: "/vendor/stays/updateroomdetails/" + editdata?._id,
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "content-type": "application/json" },
        data: {
          StaysId: StaysCat,
          roomname: RoomName,
          adultprice: adultroomprice,
          adultdiscount: Adultdiscount,
          adultdiscountprice: adultdiscountprice,
          children: childrenroomprice,
          childrendiscount: Childrendiscount,
          childrendiscountprice: childrendiscountprice,
          infants: infants,
          infantsdiscount: infantsdiscount,
          infantsdiscountprice: infantsdiscountprice,
          gst: gst,
          amenities: Amenities,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        alert("Room Details Edited Successfully");
        roomsDetails();
        handleClose1();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteGalleryimage = async (data) => {
    try {
      const config = {
        url: "/stays/daleteRoomgalleryimages/" + data._id,
        method: "delete",
        baseURL: "https://hayyyak.com/api/vendor",
        header: { "content-type": "application/json" },
        data: {
          roomid: DataView?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Deleted Sucessfully");
          roomsDetails();
          setDataView(res.data.success);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const Editimage = async (img) => {
    const formdataa = new FormData();
    try {
      formdataa.append("Image", img);
      formdataa.append("galleryid", amid);
      formdataa.append("roomid", DataView?._id);
      const config = {
        url: "/vendor/stays/updateroomgalleryimages",
        method: "put",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "multipart/form-data" },
        data: formdataa,
      };
      await axios(config)
        .then((res) => {
          if (res.status === 200) {
            alert("Sucessfully Updated");
            roomsDetails();
            setDataView(res.data.success);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      alert("Cannot Be Added");
    }
  };

  useEffect(() => {
    getStays();
    roomsDetails();
  }, []);

  // Search Filter
  const [SearchItem, setSearchItem] = useState("");


  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setRoomsDetails(abc);
  };
  const exportexcel = () => {
    // Filter and format the data for export
    const dataForExport = RoomsDetails?.filter(
      (item) =>
        SearchItem === "" ||
        item?.StaysId?.propartyname
          ?.toLowerCase()
          .includes(SearchItem?.toLowerCase())
    ).map((item, i) => ({
      "S.No": i + 1,
      "Hotel Name": item?.StaysId?.propartyname,
      "Room Name": item?.roomname,
      "Room Amenities": item?.amenities.replace(/(<([^>]+)>)/gi, ""), // Strip HTML tags
      "Adult Price": `₹${item?.adultprice}`,
      "Children Price": `₹${item?.children}`,
      "Infants Price": `₹${item?.infants}`,
      "Adult Discount%": `${item?.adultdiscount}%`,
      "Children Discount%": `${item?.childrendiscount}%`,
      "Infants Discount%": `${item?.infantsdiscount}%`,
      "Adult Discount Price": `₹${item?.adultdiscountprice}`,
      "Children Discount Price": `₹${item?.childrendiscountprice}`,
      "Infants Discount Price": `₹${item?.infantsdiscountprice}`,
      "GST%": `${item?.gst}%`,
    }));

    // Create a worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rooms");

    // Export the Excel file
    XLSX.writeFile(wb, "rooms.xlsx");
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        draggable
        closeOnClick
        pauseOnHover
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Rooms List :</p>
          <div className="d-flex gap-3">
            <div className="export-btn">
              <button className="export-btn" onClick={exportexcel}>
                export
              </button>
            </div>
            <button className="pink-btn" onClick={handleShow}>
              Add New Room
            </button>
          </div>
        </div>

        <div className="row p-2 align-items-end justify-content-start mb-3 nbjhasd_0">
          {/* <div className="col-lg-2">
            <label>From :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => setfromd(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <label>To :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => settod(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <button className="pink-btn" onClick={searchDate}>
              Submit
            </button>
          </div> */}

          <div
            className="input-group col-lg-4"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>

        <div>
          <Table responsive bordered className="admin-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Hotel Name</th>
                <th>Room Name</th>
                <th>Room Images</th>
                <th>Room Amenities</th>
                <th>Actual Price</th>
                <th>Discount%</th>
                <th>Discount% Price</th>
                <th>GST% Price</th>
                <th>Total Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {RoomsDetails?.map((item, i) => {
                if (
                  SearchItem === "" ||
                  item?.StaysId?.propartyname
                    ?.toLowerCase()
                    .includes(SearchItem?.toLowerCase())
                )
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.StaysId?.propartyname}</td>
                      <td>{item?.roomname}</td>
                      <td>
                        <img
                          src={`https://hayyyak.com/VendorStay/${item?.roomimages?.[0]?.image}`}
                          alt=""
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                        <p
                          className="Ldhfh_0"
                          onClick={() => {
                            handleShow3();
                            setDataView(item);
                          }}
                        >
                          View More
                        </p>
                      </td>
                      <td>{parse(`<div>${item?.amenities}</div>`)}</td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {" "}
                          ₹{item?.adultprice}
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          ₹{item?.children}
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          ₹{item?.infants}
                        </span>{" "}
                      </td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {" "}
                          {item?.adultdiscount} %
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          {item?.childrendiscount} %
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          {item?.infantsdiscount} %
                        </span>{" "}
                      </td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {" "}
                          ₹{item?.adultdiscountprice?.toFixed(2)}
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          ₹{item?.childrendiscountprice?.toFixed(2)}
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          ₹{item?.infantsdiscountprice?.toFixed(2)}
                        </span>{" "}
                      </td>
                      <td>{item?.gst}%</td>
                      <td style={{ color: "green" }}>
                        ₹
                        {(
                          (item?.adultdiscountprice +
                            item?.childrendiscountprice +
                            item?.infantsdiscountprice) *
                          (1 + item?.gst / 100)
                        )?.toFixed(2)}
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit
                            className="text-primary fs-4"
                            onClick={() => {
                              handleShow1(item);
                            }}
                          />
                          <MdDelete
                            onClick={() => {
                              handleShow4();
                              setdata(item);
                            }}
                            className="text-danger fs-4"
                          />
                        </div>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* Add Modal */}
      <Modal show={show} onHide={handleClose} className="vendorli">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Room </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label className="fw-bold">Select Hotel :</label>
            <Form.Select
              onChange={(e) => setStaysCat(e.target.value)}
              aria-label="Default select example"
              className="vi_0"
            >
              <option>select hotel</option>
              {StaysDetails?.filter((ele) => ele.adminId === "admin")?.map(
                (item) => {
                  return (
                    <option value={item?._id}>{item?.propartyname}</option>
                  );
                }
              )}
            </Form.Select>
          </div>
          <div className="mb-2">
            <label className="fw-bold">Room Name :</label>
            <input
              type="text"
              placeholder="Enter Room Name"
              className="vi_0"
              onChange={(e) => setRoomName(e.target.value)}
            />
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Adult Price :</label>
              <input
                type="number"
                placeholder=" price"
                className="vi_0"
                onChange={(e) => setadultroomprice(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Adult Discount% :</label>
              <input
                type="number"
                placeholder="Discount%"
                className="vi_0"
                onChange={(e) => setAdultdiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Children Price :</label>
              <input
                type="number"
                placeholder=" price"
                className="vi_0"
                onChange={(e) => setchildrenroomprice(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Children Discount% :</label>
              <input
                type="number"
                placeholder="Discount%"
                className="vi_0"
                onChange={(e) => setChildrendiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Infant Price :</label>
              <input
                type="number"
                placeholder=" price"
                className="vi_0"
                onChange={(e) => setinfants(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Infant Discount% :</label>
              <input
                type="number"
                placeholder="Discount%"
                className="vi_0"
                onChange={(e) => setinfantsdiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">GST% :</label>
              <input
                type="text"
                placeholder="GST %"
                className="vi_0"
                onChange={(e) => setgst(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2">
            <label className="fw-bold">Room Amenities :</label>
            <CKEditor
              data={Amenities}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAmenities(data);
              }}
              editor={ClassicEditor}
            />
          </div>
          <div className="row mb-3 align-items-end">
            <div className="col-lg-9">
              <label className="fw-bold" htmlFor="upload">
                Room Images :
              </label>
              <input
                id="upload"
                type="file"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                multiple
                onChange={(e) => {
                  onImageChange2(e);
                  setImage(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-lg-3">
              <button className="pink-btn" onClick={AddGalleryImage}>
                Add
              </button>
            </div>
          </div>

          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>SL.No</th>
              <th>Image</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {galleryImages?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{
                          width: "100px",
                          cursor: "pointer",
                          imageRendering: "pixelated",
                        }}
                        src={item?.imgUrl}
                        alt=""
                        onClick={handleShow2}
                      />
                    </td>
                    <td>
                      <MdDelete
                        onClick={() => removeItem2(i)}
                        className="text-danger fs-4"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddStaysDetails}>
            Add Room
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal*/}
      <Modal show={show1} onHide={handleClose1} className="vendorli">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Room </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label className="fw-bold">Room Name :</label>
            <input
              placeholder={editdata?.roomname}
              type="text"
              className="vi_0"
              onChange={(e) => setRoomName(e.target.value)}
            />
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Adult Price :</label>
              <input
                type="number"
                placeholder={editdata?.adultprice}
                className="vi_0"
                onChange={(e) => setadultroomprice(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Adult Discount% :</label>
              <input
                type="number"
                placeholder={`${editdata?.adultdiscount}%`}
                className="vi_0"
                onChange={(e) => setAdultdiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Children Price :</label>
              <input
                type="number"
                placeholder={editdata?.children}
                className="vi_0"
                onChange={(e) => setchildrenroomprice(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Children Discount% :</label>
              <input
                type="number"
                placeholder={`${editdata?.childrendiscount}%`}
                className="vi_0"
                onChange={(e) => setChildrendiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Infant Price :</label>
              <input
                type="number"
                placeholder={editdata?.infants}
                className="vi_0"
                onChange={(e) => setinfants(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Infant Discount% :</label>
              <input
                type="number"
                placeholder={`${editdata?.infantsdiscount}%`}
                className="vi_0"
                onChange={(e) => setinfantsdiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">GST% :</label>
              <input
                type="text"
                placeholder={`${editdata?.gst}%`}
                className="vi_0"
                onChange={(e) => setgst(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2">
            <label className="fw-bold">Room Amenities :</label>
            <CKEditor
              data={
                Amenities || editdata?.amenities || "Enter amenities here..."
              }
              onChange={(event, editor) => {
                const data = editor.getData();
                setAmenities(data);
              }}
              editor={ClassicEditor}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={updateroomDetails}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Property Big Image Modal */}
      <Modal show={show2} onHide={handleClose2} className="mb-0" size="lg">
        <Modal.Body className="p-0">
          <img
            src={`https://hayyyak.com/VendorStay/${bigImage?.image}`}
            alt=""
            style={{
              width: "-webkit-fill-available",
              height: "500px",
              imageRendering: "pixelated",
            }}
            onClick={handleClose2}
          />
        </Modal.Body>
      </Modal>

      {/* Room Images Model */}
      <Modal show={show3} onHide={handleClose3} className="gallery_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Room Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {DataView?.roomimages?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{
                          width: "100px",
                          cursor: "pointer",
                          imageRendering: "pixelated",
                        }}
                        src={`https://hayyyak.com/VendorStay/${item?.image}`}
                        alt=""
                        onClick={() => {
                          handleShow2();
                          setbigImage(item);
                        }}
                      />
                    </td>
                    <td>
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            Editimage(e.target.files[0], item._id);
                          }}
                        />
                        <BiSolidEdit
                          className="text-primary fs-4"
                          type="file"
                          onClick={() => {
                            handleIconClick(item?._id);
                          }}
                        />
                        <MdDelete
                          className="text-danger fs-4"
                          onClick={() => {
                            DeleteGalleryimage(item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Model */}
      <Modal show={show4} onHide={handleClose4} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteRoom}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AdminAddRooms;
