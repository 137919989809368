import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Container } from "react-bootstrap/esm";
import ReactDatePicker from "react-datepicker";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import { FaHotel } from "react-icons/fa";
import HikingIcon from "@mui/icons-material/Hiking";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Carousel from "react-bootstrap/Carousel";
import { Diversity2TwoTone } from "@mui/icons-material";
import NavigationIcon from "@mui/icons-material/Navigation";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IoIosArrowDown } from "react-icons/io";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { DateRange } from "react-date-range";
import { format } from "date-fns";

export const Header = () => {
  const [Hotels, setHotels] = useState(true);
  const [Taxi, setTaxi] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Tour, setTour] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const [Adult, setAdult] = useState(0);
  const increase = () => {
    setAdult(Adult + 1);
  };
  const decrease = () => {
    if (Adult > 0) {
      setAdult(Adult - 1);
    }
  };

  const [Children, setChildren] = useState(0);
  const Childreninc = () => {
    setChildren(Children + 1);
  };
  const Childrendec = () => {
    if (Children > 0) {
      setChildren(Children - 1);
    }
  };

  const [Infants, setInfants] = useState(0);
  const incinfants = () => {
    setInfants(Infants + 1);
  };
  const decinfants = () => {
    if (Infants > 0) {
      setInfants(Infants - 1);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // get homeBanner
  const [homebannerVideo, sethomebannerVideo] = useState([]);
  const getBannerVideos = async () => {
    axios
      .get("https://hayyyak.com/api/admin/getbannervideos")
      .then(function (response) {
        console.log(response.data);
        sethomebannerVideo(response.data.banner);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // get Tour-Packages Category
  const [getTourCat, setgetTourCat] = useState([]);
  const [tourCat, setTourCat] = useState([]);
  const [value, setValue] = useState(null);
  const getTourcategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/tourpackage/gettourcategory")
      .then(function (response) {
        setgetTourCat(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("getTourCat", getTourCat);



  // get TourPackages
  const [City, setCity] = useState("");
  const [TripCity, setTripCity] = useState([]);
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourpackages = () => {
    axios
      .get("https://hayyyak.com/api/admin/gettrippackages")
      .then(function (response) {
        setTourpackages(response.data.getpackages);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const tourCatArray = Tourpackages?.filter((ele) => ele?.venderaddstatus === "Approved")?.map((category) => category.tripcategory);
    setTourCat(tourCatArray);
  }, [Tourpackages]);

  console.log("Tourpackages", Tourpackages);

  useEffect(() => {
    const tourpackages = Tourpackages?.filter(
      (ele) => ele.tripcategory === value && (ele.venderaddstatus === "Approved" || ele.AddedbyAdmin === "AddedByAdmin")
    )?.map((city) => city?.city);
    setTripCity(tourpackages);
  }, [Tourpackages, value]);

  // get Activities Category
  const [ACategory, setACategory] = useState("");
  const [ActivityCat, setActivityCat] = useState([]);
  const [GetActivitycat, setGetActivitycat] = useState([]);
  const GetActivityCategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/activity/getactivitycategory")
      .then(function (response) {
        console.log(response.data);
        setGetActivitycat(response.data.success);

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // get Activity
  const [ActivityShare, setActivityShare] = useState([]);
  const [GetActivity, setGetActivity] = useState([]);
  const GetActivityDetail = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/activity/getactivitydetail"
      );
      if (res.status === 200) {
        setGetActivity(res.data.getActivity);
        setActivityShare(res.data.getActivity);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const ActivityCatArray = GetActivity?.filter((ele) => ele?.vendorstatus === "Approved")?.map((category) => category?.activitycategories?.Title);
    setActivityCat(ActivityCatArray);
  }, [GetActivity]);

  const [ActivityLocation, setActivityLocation] = useState([]);
  const [activitydestination, setActivitydestination] = useState("");
  useEffect(() => {
    if (GetActivity && ACategory) {
      const filteredCategories = GetActivity?.filter(
        (ele) => ele.activitycategories.Title === ACategory)
      const filteredCategories1 = []
      for (let i = 0; i < filteredCategories?.length; i++) {
        for (let j = 0; j < filteredCategories[i]?.addlocation?.length; j++) {
          filteredCategories1.push(filteredCategories[i]?.addlocation[j].City)
        }
      }
      setActivityLocation(filteredCategories1); // Use setCheck instead of setcheck (capitalized)
    }
  }, [GetActivity, ACategory]);



  // Activity

  const [CheckData, setCheckData] = useState([]);

  useEffect(() => {
    if (ACategory && activitydestination) {
      const activityfilter = ActivityShare?.filter((ele) =>
        ele?.activitycategories?.Title === ACategory &&
        (ele?.addlocation?.some((ele2) => ele2?.City === activitydestination))
      );
      setCheckData(activityfilter)
    }
  }, [ACategory, activitydestination, ActivityShare]);

  //Hotels
  const [StaysDetails, setStaysDetails] = useState([]);
  const staysrooms = async () => {
    try {
      const res = await axios.get("https://hayyyak.com/api/vendor/stays/staysrooms");
      if (res.status === 200) {
        setStaysDetails(res.data.allstays);
      }

    } catch (error) {
      console.log(error);
    }
  };





  useEffect(() => {
    GetActivityCategory();
    GetActivityDetail();
    getTourpackages();
    getTourcategory();
    getBannerVideos();
    staysrooms();
  }, []);

  //Stays Search Content
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDateChange = (selectedDates) => {
    setDate([selectedDates.selection]);
  };
  const startdate = date[0]?.startDate
  const endDate = date[0]?.endDate

  const [selecetedCity, setselectedCity] = useState("");
  const stayslocation = {
    selecetedCity: selecetedCity,
    checkIndate: startdate,
    checkOutdate: endDate,
    Adult: Adult,
    Children: Children,
    Infants: Infants,
  }

  return (
    <div className=" " id="home-carousel-mainDiv" style={{ position: "relative" }}>
      <Container 
        style={{
          position: "absolute",
          zIndex: "999",
          top: "32%",
          left: "95px",
        }}
        className="fields-destination-cont"
      >
        <div className="gnednf">
          <div style={{ position: "relative" }} id='header-lists'>
            <div className="cat">
              <div
                onClick={() => {
                  setHotels(true);
                  setTaxi(false);
                  setActivity(false);
                  setIsOpen(false);
                }}
                className={Hotels ? "mainbuttonn" : " mainbutton"}
              >
                <span>
                  <FaHotel />
                </span>
                <p>Stays</p>
              </div>

              <div
                onClick={() => {
                  setHotels(false);
                  setTaxi(true);
                  setActivity(false);
                  setIsOpen(false);
                }}
                className={Taxi ? "mainbuttonn" : " mainbutton"}
              >
                <span>
                  <BsFillTaxiFrontFill />
                </span>
                <p>Taxi</p>
              </div>

              <div
                onClick={() => {
                  setHotels(false);
                  setTaxi(false);
                  setActivity(true);
                  setIsOpen(false);
                }}
                className={Activity ? "mainbuttonn" : " mainbutton"}
              >
                <span>
                  <HikingIcon />
                </span>
                <p>Activities</p>
              </div>

              <div
                onClick={() => {
                  setTaxi(false);
                  setHotels(false);
                  setActivity(false);
                  setTour(true);
                  setIsOpen(false);
                }}
                className={Activity ? "mainbuttonn" : " mainbutton"}
              >
                <span>
                  <AddHomeWorkIcon />
                </span>
                <p>Tour Packages</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="form-cn"
          id="header-list-form"
          style={{
            width: "90%",
            position: "relative",
            background: "rgb(255 255 255 / 84%);",
            padding: "15px",
            borderRadius: " 10px",
            margin: "50px auto 0px",
            display: "table",
            boxShadow: "rgba(29, 29, 33, 0.09) 0px 2px 12px -3px",
            transition: " all 0.5s ease-in-out 0s",
          }}
        >
          {Hotels ? (
            <>
              <div className="arrow-icon-1">
                <NavigationIcon style={{ fontSize: "30px" }} />
              </div>

              <div className="boxes" id="field-destination-boxes" style={{ position: "relative" }}>
                <div>
                  <div class="form-field field-destination">
                    <label for="destination">
                      <span>Destination:</span> City, Area, Landmark
                    </label>
                    <select
                      id="destination"
                      className="field-input"
                      onChange={(e) => setselectedCity(e.target.value)}
                    >
                      <option value={""}>Select City</option>
                      {StaysDetails?.map((city) => {
                        return (
                          <option value={city?.city}>{city?.city}</option>
                        )
                      })}

                    </select>
                  </div>
                </div>

                <div className="form-field field-date">
                  <label for="destination">
                    <span>Check In:</span>
                  </label>
                  <input type="text"
                    value={`${format(startdate, 'MM/dd/yyyy')}`}
                    onClick={() => {
                      setOpenDate(!openDate);
                      setIsOpen(false)
                    }}
                    className="field-input" id="check-in" />
                </div>


                <div className="form-field field-date">
                  <label for="destination">
                    <span>Check Out:</span>
                  </label>
                  <input type="text"
                    value={`${format(endDate, 'MM/dd/yyyy')}`}
                    className="field-input" id="check-in" />
                </div>

                <div className="form-field field-select">
                  <div className="select">
                    <span>Guest</span>
                    <div
                      onClick={()=>{
                        handleClick();
                        setOpenDate(false)
                      }}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <span style={{ fontSize: "10px" }}>
                        Adult {Adult}, Child {Children}, <br /> infants {Infants}

                      </span>
                      <span className="float-end">
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <Link to="/hotel" state={{ stayslocation: stayslocation }} className="text-decoration-none">
                    <button className="header-search">Search</button>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              {Taxi ? (
                <>
                  <div className="arrow-icon-2">
                    <NavigationIcon style={{ fontSize: "30px" }} />
                  </div>

                  <div className="boxes">
                    <div class="form-field field-destination-1">
                      <label for="destination">
                        <span>Picking Up:</span>{" "}
                      </label>
                      <input
                        type="text"
                        id="destination"
                        className="field-input"
                      />
                    </div>

                    <div class="form-field field-destination-1">
                      <label for="destination">
                        <span>Droping Off:</span>{" "}
                      </label>
                      <input
                        type="text"
                        id="destination"
                        className="field-input"
                      />
                    </div>

                    <div className="form-field field-date">
                      <label for="destination">
                        <span>Pick Up Date:</span>
                      </label>
                      <input
                        type="text"
                        className="field-input"
                        id="check-in"
                      />
                    </div>

                    <div className="form-field field-date">
                      <label for="destination">
                        <span>Drop Off Date:</span>
                      </label>
                      <input
                        type="text"
                        className="field-input"
                        id="check-in"
                      />
                    </div>

                    <div>
                      <a href="/taxi" className="text-decoration-none ">
                        <button className="header-search">Search</button>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {Activity ? (
                    <>
                      <div className="arrow-icon-3">
                        <NavigationIcon style={{ fontSize: "30px" }} />
                      </div>

                      <div className="boxes">
                        <div className="form-field field-select-1">
                          <div className="select">
                            <span>Select Packages</span>
                            <Stack spacing={1} sx={{ width: 250 }} className='Activity-inputfield'>
                              <Autocomplete
                                id="disable-close-on-select"
                                disableCloseOnSelect
                                options={ActivityCat}
                                value={ACategory}
                                onChange={(event, newACategory) => {
                                  setACategory(newACategory);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Packages Category"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div className="form-field field-select-1">
                          <div className="select">
                            <span>Select Destination</span>
                            <Stack spacing={1} sx={{ width: 250 }} className='Activity-inputfield'>
                              <Autocomplete
                                id="disable-close-on-select"
                                disableCloseOnSelect
                                options={[...new Set(ActivityLocation)]}
                                value={activitydestination}
                                onChange={(event, newactivitydestination) => {
                                  setActivitydestination(
                                    newactivitydestination
                                  );
                                }}

                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Packages Category"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        </div>

                        {CheckData?.map((item) => {
                          return (
                            <div>

                              <Link
                                to="/multipleactivity"
                                // state={{ ACategory: ACategory, activitydestination: activitydestination }}
                                state={{ item: item }}
                                className="text-decoration-none">
                                <button className="header-search">Search</button>
                              </Link>
                            </div>
                          )
                        })}

                      </div>
                    </>
                  ) : (
                    <>
                      {Tour ? (
                        <>
                          <div className="arrow-icon-4">
                            <NavigationIcon style={{ fontSize: "30px" }} />
                          </div>

                          <div className="boxes">
                            <div className="form-field field-select-1">
                              <span>Select Package</span>
                              <Stack spacing={1} sx={{ width: 250 }} className="tourpack-input-header">
                                <Autocomplete
                                  id="disable-close-on-select"
                                  disableCloseOnSelect
                                  options={tourCat}
                                  value={value}
                                  onChange={(event, newValue) => {
                                    setValue(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Packages Category"
                                      variant="standard"
                                    />
                                  )}
                                />
                              </Stack>
                            </div>

                            <div className="form-field field-select-1">
                              <div className="select">
                                <span>Your Destination</span>

                                <Stack spacing={1} sx={{ width: 250 }} className="tourpack-input-header">
                                  <Autocomplete
                                    id="disable-close-on-select"
                                    disableCloseOnSelect
                                    options={TripCity}
                                    value={City}
                                    onChange={(event, newCity) => {
                                      setCity(newCity);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Available Cities"
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </Stack>
                              </div>
                            </div>

                            <div>
                              <Link
                                to="/trippackage"
                                state={{ item: value, City: City }}
                                className="text-decoration-none"
                              >
                                <button className="header-search">
                                  Search
                                </button>
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {isOpen && (
          <>
            <div
              className="p-2 guests-part"
              style={{ background: "#fff", width: "300px", marginLeft: "auto" }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3 guests-parts-mb">
                <div>
                  <b>Adults</b>
                  <p style={{ fontSize: "12px" }}>Age 13+</p>
                </div>
                <div className="d-flex gap-2">
                  <RemoveIcon
                    onClick={() => decrease()}
                    className="increase-icon"
                  />
                  <span>{Adult}</span>
                  <span>
                    {" "}
                    <AddIcon
                      onClick={() => increase()}
                      className="increase-icon"
                    />
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-3 guests-parts-mb">
                <div>
                  <b>Children</b>
                  <p style={{ fontSize: "12px" }}>Age 2-12</p>
                </div>
                <div className="d-flex gap-2">
                  <RemoveIcon
                    onClick={() => Childrendec()}
                    className="increase-icon"
                  />
                  <span>{Children}</span>
                  <span>
                    {" "}
                    <AddIcon
                      onClick={() => Childreninc()}
                      className="increase-icon"
                    />
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-3 guests-parts-mb">
                <div>
                  <b>Infants</b>
                  <p style={{ fontSize: "12px" }}>Under 2</p>
                </div>
                <div className="d-flex gap-2">
                  <RemoveIcon
                    onClick={() => decinfants()}
                    className="increase-icon"
                  />
                  <span>{Infants}</span>
                  <span>
                    {" "}
                    <AddIcon
                      onClick={() => incinfants()}
                      className="increase-icon"
                    />
                  </span>
                </div>
              </div>

              {/* <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <b>Pets</b>
                  <p style={{ fontSize: "12px" }}>bring 1</p>
                </div>
                <div className="d-flex gap-2">
                  <RemoveIcon
                    onClick={() => decrease()}
                    className="increase-icon"
                  />
                  <span>{Adult}</span>
                  <span>
                    {" "}
                    <AddIcon
                      onClick={() => increase()}
                      className="increase-icon"
                    />
                  </span>
                </div>
              </div> */}
            </div>
          </>
        )}

        {openDate && (
          <div className="ghshg_000">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={date}
              className="date"
              minDate={new Date()}
            />
          </div>
        )}
      </Container>
      <div className="sgdfhsg_0">
        <Carousel fade>
          {homebannerVideo?.map((item, i) => {
            return (
              <Carousel.Item>
                <video
                  src={`https://hayyyak.com/Banner/${item?.bannerVideo}`}
                  muted
                  autoPlay
                  loop
                  type="video/mp4"
                  classname='homeHeader-carousel '
                  id='homeHeader-video'
                  style={{
                    height: "580px",
                    width: " 100%",
                    objectFit: "cover",
                  }}

                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
