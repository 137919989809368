import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import { BiPencil, BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { AiFillEye } from 'react-icons/ai'
import moment from 'moment'
import { IoIosWarning } from 'react-icons/io'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function AdminVehicleAllBooking() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);



    const [picUpPlace, setpicUpPlace] = useState("")
    const [dropPlace, setdropPlace] = useState("")
    const [picUpDate, setpicUpDate] = useState("")
    const [picUpTime, setpicUpTime] = useState("")
    const [BookedPrice, setBookedPrice] = useState("")
    const [DriverAllowance, setDriverAllowance] = useState("")
    const [WaitingCharge, setWaitingCharge] = useState("")
    const [NightDriverAllowance, setNightDriverAllowance] = useState("")
    const [TollCharge, setTollCharge] = useState("")
    const [ParkingCharge, setParkingCharge] = useState("")
    const [ReturnpicUpDate, setReturnpicUpDate] = useState("")
    const [ReturnpicUpTime, setReturnpicUpTime] = useState("")


    // Get Taxi Bookings
    const [AllBookingData, setAllBookingData] = useState([])
    const [ModelBookingData, setModelBookingData] = useState({})
    const GetAllBooking = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/taxi/getAllBooking"
            );
            if (res.status === 200) {
                setAllBookingData(res.data.success)
                setPagination(res.data.success)
                setnochangedata(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    console.log("AllBookingData", AllBookingData)

    useEffect(() => {
        GetAllBooking()
    }, [])


    // Delete Driver
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => { setShow2(true); setDeleteAirportData(id) }
    const [DeleteAirportData, setDeleteAirportData] = useState("");
    const DeleteBooking = async () => {
        try {
            const config = {
                url: "customer/taxi/deletebooking/" + DeleteAirportData,
                baseURL: "https://hayyyak.com/api/",
                method: "delete",
                headers: { "Content-Type": "application/json" }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose2()
                GetAllBooking()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    const AproveBooking = async (BookingId, BookingStatus) => {
        try {
            const config = {
                url: "customer/taxi/AproveBooking",
                baseURL: "https://hayyyak.com/api/",
                method: "put",
                headers: { "Content-Type": "application/json" },
                data: {
                    BookingId: BookingId,
                    BookingStatus: BookingStatus
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose()
                handleClose3()
                GetAllBooking()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    const handleClose1 = () => setShow1(false);
    const handleShow1 = (id) => { setShow1(true); seteditData(id) }
    const [editData, seteditData] = useState()
    const EditBooking = async () => {
        try {
            const config = {
                url: "customer/taxi/EditBooking/" + editData,
                baseURL: "https://hayyyak.com/api/",
                method: "put",
                headers: { "Content-Type": "application/json" },
                data: {
                    picUpPlace: picUpPlace,
                    dropPlace: dropPlace,
                    picUpDate: picUpDate,
                    picUpTime: picUpTime,
                    BookedPrice: BookedPrice,
                    DriverAllowance: DriverAllowance,
                    WaitingCharge: WaitingCharge,
                    NightDriverAllowance: NightDriverAllowance,
                    TollCharge: TollCharge,
                    ParkingCharge: ParkingCharge,
                    ReturnpicUpDate: ReturnpicUpDate,
                    ReturnpicUpTime: ReturnpicUpTime,
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose1()
                GetAllBooking()
            }
        } catch (error) {
            notify2(error.response.date.error)
        }
    }

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return notify1("Please select from date");
        if (!tod) return notify1("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setPagination(abc);
        setAllBookingData(abc);
    };


    // Search
    const [SearchItem, setSearchItem] = useState("");

    //Pagination
    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(pagination?.length / usersPerPage);
    const changePage = (selected) => {
        setPageNumber(selected);
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>

                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">All Bookings :</p>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-3" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-1 export-btn">
                        <ReactHTMLTableToExcel
                            id="export-to-excel"
                            table="Export-table"
                            filename="bookedActivity"
                            sheet="bookedActivity"
                            buttonText="Export"
                        />
                    </div>

                </div>
                <div >
                    <Table responsive bordered className='admin-table-head' id="Export-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Trip Type</th>
                                <th>Booking ID</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Email</th>
                                <th style={{ width: '400px' }}>From</th>
                                <th style={{ width: '400px' }}>To</th>
                                <th>Vehicle Category</th>
                                <th>Total km</th>
                                <th>Payment Method</th>
                                <th>Booked Price</th>
                                <th>Booking Details</th>
                                <th>Status</th>
                                <th>Actions</th>
                                {/* <th>PickUp/ Return Date</th>
                                <th>Time</th>
                                <th>Trip Type</th>
                                <th>Seat capacity</th>
                                <th>VehicleId</th>
                                <th>Driver Name</th>
                                <th>Driver Mobile</th>
                                <th>Total Amount</th>
                                <th>Payment Method</th>
                                <th>Payment Amount</th>
                                <th>Pick up place</th>
                                <th>Cancel Request</th>
                                <th>Trip Status	</th>
                                <th>Driver Status</th>
                                <th>Assign Driver</th>
                                 */}
                            </tr>
                        </thead>
                        <tbody>
                            {AllBookingData?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                if (
                                    SearchItem === "" ||
                                    Object.values(item).some(
                                        value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
                                    )
                                )
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item?.TripType}</td>
                                            <td>{item?._id}</td>
                                            <td>{item?.userId?.Fname} {item?.userId?.Lname}</td>
                                            <td>{item?.userId?.Mobile}</td>
                                            <td>{item?.userId?.Email}</td>
                                            <td>{item?.picUpPlace}</td>
                                            <td>{item?.dropPlace}</td>
                                            <td>{item?.Category}</td>
                                            <td>30Km</td>
                                            <td>{item?.PaymentMethod}</td>
                                            <td>₹{item?.BookedPrice}</td>
                                            <td><AiFillEye className='text-danger fs-4' style={{ cursor: "pointer" }} onClick={() => {
                                                handleShow()
                                                setModelBookingData(item)
                                            }} /> </td>
                                            <td>
                                                <div className="text-start d-flex gap-2 align-items-center" style={{ cursor: "pointer" }}>
                                                    {item?.BookingStatus === "Approved" ? (
                                                        <span className='unblock-user'>Approved</span>
                                                    ) : (<></>)}
                                                    {item?.BookingStatus === "Cancelled" ? (
                                                        <span className='block-user'>Cancelled</span>
                                                    ) : (<></>)}
                                                    {item?.BookingStatus === "Completed" ? (
                                                        <span className='unblock-user'>Completed</span>
                                                    ) : (<></>)}
                                                    {item?.BookingStatus === "Pending" ? (
                                                        <span className='unblock-user' style={{ backgroundColor: '#dba709' }}>Pending</span>
                                                    ) : (<></>)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                    <MdDelete className='text-danger' onClick={() => handleShow2(item?._id)} />
                                                    <BiSolidEdit className="text-primary fs-4" onClick={() => handleShow1(item?._id)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>)
                            })}

                        </tbody>
                    </Table>
                </div>
                <div className='my-3'>
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            color="primary"
                            onChange={(event, value) => {
                                changePage(value - 1)
                            }}
                        />
                    </Stack>
                </div>
            </Container>

            {/* Booking Details
 Modal */}
            <Modal size='lg' show={show} onHide={handleClose} className='amenties-modal'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Booking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered responsive>
                        <tbody>
                            <tr>
                                <td className='fw-bold'>Pick Up Place:</td>
                                <td>{ModelBookingData?.picUpPlace}</td>
                                <td className='fw-bold'>Drop :</td>
                                <td>{ModelBookingData?.dropPlace}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Booked Price :</td>
                                <td>₹{ModelBookingData?.BookedPrice}</td>
                                <td className='fw-bold'>Pickup Time :</td>
                                <td>{ModelBookingData?.picUpTime}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Vehicle Category :</td>
                                <td>{ModelBookingData?.Category}</td>
                                <td className='fw-bold'>Oneway / Round Trip :</td>
                                <td>{ModelBookingData?.OneRound}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Payment Method :</td>
                                <td>{ModelBookingData?.PaymentMethod}</td>
                                <td className='fw-bold'>Booking Date  :</td>
                                <td>{moment(ModelBookingData?.createdAt).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Toll Charges :</td>
                                <td>{ModelBookingData?.TollCharge}</td>
                                <td className='fw-bold'>Parking Charge :</td>
                                <td>{ModelBookingData?.ParkingCharge}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Waiting Charges :</td>
                                <td>{ModelBookingData?.WaitingCharge}</td>
                                <td className='fw-bold'>Trip Type :</td>
                                <td>{ModelBookingData?.TripType}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Driver Allowance :</td>
                                <td>{ModelBookingData?.DriverAllowance}</td>
                                <td className='fw-bold'>Night Driver Allowance :</td>
                                <td>{ModelBookingData?.NightDriverAllowance}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Return Pickup Date :</td>
                                <td>{ModelBookingData?.ReturnpicUpDate}</td>
                                <td className='fw-bold'>Return Pickup Time :</td>
                                <td>{ModelBookingData?.ReturnpicUpTime}</td>
                            </tr>
                            <tr>
                                <td className='fw-bold'>Pickup Date :</td>
                                <td>{ModelBookingData?.picUpDate}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>

                    {ModelBookingData?.BookingStatus === "Cancelled" ? (null) : (<>
                        {ModelBookingData?.BookingStatus === "Completed" ? (
                            <Button variant="danger">
                                View Invoice
                            </Button>
                        ) : (
                            <>
                                <Button variant="danger" onClick={handleShow3}>
                                    Cancel Booking
                                </Button>
                                {ModelBookingData?.BookingStatus !== "Approved" ? (
                                    <Button variant="success" onClick={() => AproveBooking(ModelBookingData?._id, "Approved")}>
                                        Approve Booking
                                    </Button>
                                ) : (<></>)}

                            </>
                        )}

                        {ModelBookingData?.BookingStatus === "Approved" ? (
                            <Button variant="success" onClick={() => AproveBooking(ModelBookingData?._id, "Completed")}>
                                Complete Booking
                            </Button>
                        ) : (
                            <></>
                        )}
                    </>)}
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Booking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Pickup Location :</label>
                            <input type="text" placeholder='Location' className="vi_0" onChange={(e) => setpicUpPlace(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Drop Location:</label>
                            <input type="text" placeholder='Location' className="vi_0" onChange={(e) => setdropPlace(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Pickup Date:</label>
                            <input type="date" className="vi_0" onChange={(e) => setpicUpDate(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Pickup Time:</label>
                            <input type="time" className="vi_0" onChange={(e) => setpicUpTime(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Booking Price:</label>
                            <input type="text" placeholder='Price' className="vi_0" onChange={(e) => setBookedPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Drive Allowance:</label>
                            <input type="text" placeholder='Drive Allowance' className="vi_0" onChange={(e) => setDriverAllowance(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Night Drive Allowance:</label>
                            <input type="text" placeholder='Night Drive Allowance' className="vi_0" onChange={(e) => setNightDriverAllowance(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Waiting Charge:</label>
                            <input type="text" placeholder='Waiting Charge' className="vi_0" onChange={(e) => setWaitingCharge(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Toll Charge:</label>
                            <input type="text" placeholder='Toll Charge' className="vi_0" onChange={(e) => setTollCharge(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Parking Charge:</label>
                            <input type="text" placeholder='Parking Charge' className="vi_0" onChange={(e) => setParkingCharge(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Return Pickup Date:</label>
                            <input type="date" placeholder='Return Pickup Date' className="vi_0" onChange={(e) => setReturnpicUpDate(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Return Pickup Time:</label>
                            <input type="time" placeholder='Return Pickup Time' className="vi_0" onChange={(e) => setReturnpicUpTime(e.target.value)} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditBooking}>
                        Save changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button onClick={DeleteBooking} variant="danger"><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

            {/* Booking Cancel Confirmation Model */}
            <Modal
                show={show3}
                onHide={handleClose3}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <span className='text-danger fs-1'><IoIosWarning /></span>
                        <p className='fw-bold text-dark mb-2 fs-4'>Are You Sure <br /> You Want To Cancel The Ride</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose3}>
                        No
                    </Button>
                    <Button onClick={() => AproveBooking(ModelBookingData?._id, "Cancelled")} variant="danger">Yes</Button>
                </Modal.Footer>
            </Modal>


        </div >
    )
}
export default AdminVehicleAllBooking
