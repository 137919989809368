import React, { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function AddStayAmenities() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Add Stay Amenities
  const formData = new FormData();
  const [Icon, setIcon] = useState("");
  const [AmenitiName, setAmenitiName] = useState("");
  const AddStayAmenities = async () => {
    if (!Icon) {
      return notify1("Please select Icon");
    }
    if (!AmenitiName) {
      return notify1("Please Enter Amenity Name");
    }
    try {
      formData.append("Icon", Icon);
      formData.append("AmenitiName", AmenitiName);

      const config = {
        url: "admin/stays/addstayamenities",
        baseURL: "https://hayyyak.com/api/",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      };
      const res = await axios(config);

      if (res.status === 200) {
        notify(res.data.success);
        handleClose();
        getStayAmenities();
        setAmenitiName("");
        setIcon("");
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // get Stay Amenities
  const [StayAmenities, setStayAmenities] = useState([]);
  const getStayAmenities = async () => {
    axios
      .get("https://hayyyak.com/api/admin/stays/getstayamenities")
      .then(function (response) {
        setStayAmenities(response.data.success);
        setPagination(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // edit Stay Amenities
  const [editAmeniti, seteditAmeniti] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditAmeniti(item);
  };
  const EditStayAmenities = async () => {
    try {
      const url = "admin/stays/editstayamenities/" + editAmeniti?._id;
      const config = {
        url: url,
        baseURL: "https://hayyyak.com/api/",
        method: "put",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Icon: Icon,
          AmenitiName: AmenitiName,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        getStayAmenities();
        handleClose1();
        setAmenitiName("");
        setIcon("");
      }
    } catch (error) {
      console.error("Error during EditStayAmenities:", error);
      notify2(error.response.data.error);
    }
  };

  // Delete Stay Amenities
  const [DeleteAmeniti, setDeleteAmeniti] = useState("");
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setDeleteAmeniti(item);
  };
  const deleteStayAmenity = async () => {
    try {
      const config = {
        url: "admin/stays/deletestayamenities/" + DeleteAmeniti?._id,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "content-type": "application/json" },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify("Deleted Successfully");
        getStayAmenities();
        handleClose2();
        getStayAmenities();
      }
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");
  const filteredItems = StayAmenities?.filter((item) => {
    const searchLower = SearchItem.toLowerCase();
    return (
      item?.AmenitiName?.toLowerCase().includes(searchLower) ||
      moment(item?.updatedAt).format("DD/MM/YYYY").toLowerCase().includes(searchLower)
    );
  });

  //Pagination
  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(pagination?.length / usersPerPage);
  const changePage = (selected) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    getStayAmenities();
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Amenities :</p>
          <button className="pink-btn" onClick={handleShow}>
            Add Amenities
          </button>
        </div>

        <div className="row p-2 align-items-end mb-3 nbjhasd_0">
          <div
            className="input-group col-lg-4"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>
        <div>
          <Table responsive bordered className="sdfsd-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Amenities Icon</th>
                <th>Amenities Name</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.slice(
                pagesVisited,
                pagesVisited + usersPerPage
              )?.map((item, i) => {             
               
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={`https://hayyyak.com/StayAmenities/${item?.Icon}`}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            imageRendering: "pixelated",
                          }}
                        />
                      </td>
                      <td>{item?.AmenitiName}</td>
                      <td>{moment(item?.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit
                            className="text-primary"
                            onClick={() => handleShow1(item)}
                          />
                          <MdDelete
                            className="text-danger"
                            onClick={() => handleShow2(item)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
             
              })}
            </tbody>
          </Table>
          <div className="my-3">
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                onChange={(event, value) => {
                  changePage(value - 1);
                }}
                color="primary"
              />
            </Stack>
          </div>
        </div>
      </Container>

      {/* Add Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" mb-2">
            <label className="fw-bold" htmlFor="upload">
              Choose Amenities Icon :
            </label>
            <input
              id="upload"
              accept="image/*"
              type="file"
              placeholder="Name"
              className="vi_0"
              onChange={(e) => setIcon(e.target.files[0])}
            />
          </div>

          <div>
            <label className="fw-bold">Amenities Name :</label>
            <input
              type="text"
              placeholder="Name"
              className="vi_0"
              onChange={(e) => setAmenitiName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddStayAmenities}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal*/}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" mb-2">
            <label className="fw-bold" htmlFor="upload">
              Choose Amenities Icon :
            </label>
            <input
              id="upload"
              accept="image/*"
              type="file"
              placeholder="Name"
              className="vi_0"
              onChange={(e) => setIcon(e.target.files[0])}
            />
          </div>

          <div>
            <label className="fw-bold">Amenities Name :</label>
            <input
              type="text"
              placeholder="Name"
              className="vi_0"
              onChange={(e) => setAmenitiName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditStayAmenities}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteStayAmenity}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AddStayAmenities;
