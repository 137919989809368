import React from "react";
import { Container } from "react-bootstrap";
import AdminSideBar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
function CommanDashboard(props) {
    return (
        <Container fluid >
            <div className="dash">
                <div className="d-flex me-0">
                    <div className=" p-0" >
                        <div className="left-side">
                            <AdminSideBar />
                        </div>
                    </div>

                    <div className=" p-0 right-h" style={{ width: '83%' }}>
                        <AdminHeader />
                        {props.children}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default CommanDashboard;
