import React, { useRef, useState } from "react";
import { Carousel, Container, Form, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Rating } from "react-simple-star-rating";
import { FaEdit } from "react-icons/fa";

function StayVendorList() {
  let admin = JSON.parse(sessionStorage.getItem("admin"));
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  // input file access
  const fileInputRef = useRef(null);
  const [amid, setamid] = useState("");
  const handleIconClick = (id) => {
    setamid(id);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputRef1 = useRef(null);
  const [amid1, setamid1] = useState("");
  const handleIconClick1 = (id) => {
    setamid1(id);
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    }
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const handleShow9 = () => setShow9(true);
  const handleClose9 = () => setShow9(false);

  const [bigImage, setbigImage] = useState({});

  const [RoomsImagesView, setRoomsImagesView] = useState({});
  const [Modelview, setModelview] = useState({});
  const [Modelroom, setModelroom] = useState({});
  const [ModelAminity, setModelAminity] = useState({});
  const [Category, setCategory] = useState({});
  const [StaysDetails, setStaysDetails] = useState([]);
  const staysrooms = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/stays/staysrooms"
      );
      if (res.status === 200) {
        setStaysDetails(res.data.allstays);
        setPagination(res.data.allstays);
        setnochangedata(res.data.allstays);
        setModelroom(res.data.allstays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Admin Aproove
  const AdminAproove = async (aprooveID) => {
    try {
      const response = await axios.put(
        `https://hayyyak.com/api/Vendor/Stays/admin-approved/${aprooveID}`
      );
      if (response.status === 200) {
        notify(response.data.success);
        staysrooms();
        handleClose();
      }
    } catch (error) {
      // Handle errors
      console.error("Error toggling status:", error);
      notify2("An error occurred while toggling status.");
    }
  };

  // Stays Activate
  const AdminActivate = async (activateID) => {
    try {
      const response = await axios.put(
        `https://hayyyak.com/api/Vendor/Stays/admin-activate/${activateID}`
      );
      if (response.status === 200) {
        notify(response.data.success);
        notify1(response.data.message);
        staysrooms();
      }
    } catch (error) {
      // Handle errors
      console.error("Error toggling status:", error);
      notify2("An error occurred while toggling status.");
    }
  };

  // Delete Stay
  const [Deletedata, setDeletedata] = useState("");
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setDeletedata(item);
  };
  const DeleteStay = async () => {
    try {
      const config = {
        url: "/admin/stays/deletestay/" + Deletedata?._id,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const response = await axios(config);
      if (response.status === 200) {
        notify(response.data.success);
        staysrooms();
        handleClose5();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Update Galley
  const formData = new FormData();
  const UpdateGalleryImages = async (img) => {
    formData.append("Image", img);
    formData.append("packageid", Modelview?._id);
    formData.append("galleryid", amid);
    try {
      const config = {
        url: "/admin/stays/staysgallerydetails",
        method: "put",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "multipart/form-data" }, // Corrected the typo in headers
        data: formData,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setModelview(response.data.success);
        notify("Updated Successfully");
        staysrooms();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteGallery = (id) => {
    const confirmDlete = window.confirm(
      "Are your sure you want to delete this item"
    );
    if (confirmDlete) {
      DeleteGalleryimage(id);
    }
  };

  //Delete gallery Image
  const DeleteGalleryimage = async (id) => {
    try {
      const config = {
        url: "/admin/stays/staysgallerydelete/" + id,
        method: "delete",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          stayid: Modelview?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify("Successfully Delete");
          setModelview(res.data.success);
          staysrooms();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const handledeleteAmenity = (id) => {
    const confirmDlete = window.confirm(
      "Are your sure you want to delete this item"
    );
    if (confirmDlete) {
      deleteAmenity(id);
    }
  };
  // Delete Stay Amenities
  const deleteAmenity = async (id) => {
    try {
      const config = {
        url: "admin/stays/aminitiesdelete/" + id,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "content-type": "application/json" },
        data: {
          stayid: ModelAminity?._id,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify("Deleted Successfully");
        setModelAminity(res.data.success);
        staysrooms();
      }
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  const handleDeleteStayCategory = (id) => {
    const confirmDlete = window.confirm(
      "Are your sure you want to delete this item"
    );
    if (confirmDlete) {
      DeleteStayCategory(id);
    }
  };
  // Delete StayCategory
  const DeleteStayCategory = async (id) => {
    try {
      const config = {
        url: "admin/stays/categoriesdelete/" + id,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "Content-Type": "application/json" },
        data: {
          stayid: Category?._id,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify("Deleted Successfully");
        setCategory(res.data.success);
        staysrooms();
      }
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  // Update Room details

  const [roomName, setroomName] = useState("");
  const [adultPrice, setadultPrice] = useState("");
  const [adultdiscount, setadultdiscount] = useState("");
  const [children, setchildren] = useState("");
  const [childrendiscount, setchildrendiscount] = useState("");
  const [gst, setgst] = useState("");
  const [amenities, setamenities] = useState("");

  const handleClose8 = () => setShow8(false);
  const handleShow8 = (id) => {
    setShow8(true);
    setroomId(id);
  };
  const [roomId, setroomId] = useState({});

  const UpdateRoomDetails = async () => {
    const adultdiscountprice = adultPrice - (adultPrice * adultdiscount) / 100;
    const childrendiscountprice =
      children - (children * childrendiscount) / 100;

    try {
      const config = {
        url: `vendor/stays/updateroomdetails/${roomId}`,
        baseURL: "https://hayyyak.com/api/",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          StaysId: Modelroom?._id,
          roomname: roomName,
          adultprice: adultPrice,
          adultdiscount: adultdiscount,
          adultdiscountprice: adultdiscountprice,
          children: children,
          childrendiscount: childrendiscount,
          childrendiscountprice: childrendiscountprice,
          gst: gst,
          amenities: amenities,
        },
      };
      const res = await axios(config);

      if (res.status === 200) {
        notify(res.data.success);
        console.log("sdwe", res.data.rooms);
        setModelroom(res.data.rooms);
        staysrooms();
        handleClose8();
        handleClose6();
        setroomName("");
        setadultPrice("");
        setadultdiscount("");
        setchildren("");
        setchildrendiscount("");
        setgst("");
        setamenities("");
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const handleDeleteRoom = (id) => {
    const confirmDelete = window.confirm(
      "Are your sure you want delete this Room"
    );
    if (confirmDelete) {
      DeleteRoom(id);
    }
  };

  // Delete Room
  const DeleteRoom = async (id) => {
    try {
      const config = {
        url: "vendor/stays/deleteroom/" + id,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        staysrooms();
        handleClose6();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Update Room Gallery Images
  const UpdateRoomGallery = async (img) => {
    try {
      formData.append("image", img);
      formData.append("roomid", RoomsImagesView?._id);
      formData.append("galleryid", amid1);
      const config = {
        url: "vendor/stays/updateroomgalleryimages",
        baseURL: "https://hayyyak.com/api/",
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        setRoomsImagesView(res.data.success);
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Delete Room gallery Image
  const DeleteRoomGallery = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure want to delete this item"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      const config = {
        url: "/vendor/stays/daleteRoomgalleryimages/" + id,
        method: "delete",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          roomid: RoomsImagesView?._id,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify("Image Deleted Successfully...");
        setRoomsImagesView(res.data.success);
      }
    } catch (error) {
      console.log(error);
      notify2(error.response.data.error);
    }
  };

  // Delete Review
  const DeleteReview = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure want to delete this item"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      const config = {
        url: "/vendor/stays/detelereview/" + id,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "Content-Type": "application/json" },
        data: {
          StayID: Modelview?._id,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose9();
        staysrooms();
      }
    } catch (error) {
      notify1(error.response.data.error);
    }
  };

  useEffect(() => {
    staysrooms();
  }, []);

  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setPagination(abc);
    setStaysDetails(abc);
  };

  // Faq

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [FaqQuestion, setFaqQuestion] = useState("");
  const [FaqAnswer, setFaqAnswer] = useState("");
  const [FaqId, setFaqId] = useState({});
  useEffect(() => {
    setFaqQuestion(FaqId?.question || "");
    setFaqAnswer(FaqId?.answer || "");
  }, [FaqId]);

  const FaqEdit = async () => {
    try {
      const config = {
        url: "/vendor/stays/editfaq",
        method: "put",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          staysId: Modelview?._id,
          faqID: FaqId?._id,
          question:FaqQuestion,
          answer:FaqAnswer,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        setModelview(res.data.updateFAQ)
        handleClose10()
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");

  //Pagination
  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(pagination?.length / usersPerPage);
  const changePage = (selected) => {
    setPageNumber(selected);
  };


  console.log("StaysDetails",StaysDetails);
  
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Stays List :</p>
          <Link to="/addhotel">
            <button className="pink-btn" onClick={handleShow}>
              Add Stays
            </button>
          </Link>
        </div>

        <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
          <div className="col-lg-2">
            <label>From :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => setfromd(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <label>To :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => settod(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <button className="pink-btn" onClick={searchDate}>
              Submit
            </button>
          </div>

          <div
            className="input-group col-lg-4"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>

        <div>
          <Table responsive bordered className="admin-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Property Name</th>
                <th>Starting Price</th>
                <th>Registered Date</th>
                <th>Property Images</th>
                <th>Room Details</th>
                <th>Amenities</th>
                <th>Category</th>
                <th>More Details</th>
                <th>Status</th>
                <th>Last Update</th>
                <th>Reviews</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {StaysDetails?.slice(
                pagesVisited,
                pagesVisited + usersPerPage
              )?.map((item, i) => {
                if (
                  SearchItem === "" ||
                  item?.propartyname
                    ?.toLowerCase()
                    .includes(SearchItem?.toLowerCase())
                )
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.propartyname}</td>
                      <td>₹{item?.startingprice}</td>
                      <td>{moment(item?.createdAt).format("MM/DD/YYYY")}</td>
                      <td>
                        <img
                          src={`https://hayyyak.com/VendorStay/${item?.propartfullimage}`}
                          alt=""
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                        <p
                          className="Ldhfh_0"
                          onClick={() => {
                            handleShow3();
                            setModelview(item);
                          }}
                        >
                          View More
                        </p>
                      </td>
                      <td>
                        <span
                          className="fs-3 text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          <AiFillEye
                            onClick={() => {
                              handleShow6();
                              setModelroom(item);
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <button
                          className="pink-btn"
                          onClick={() => {
                            handleShow4();
                            setModelAminity(item);
                          }}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="pink-btn"
                          onClick={() => {
                            handleShow7();
                            setCategory(item);
                          }}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <span
                          className="fs-3 text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          <AiFillEye
                            onClick={() => {
                              handleShow();
                              setModelview(item);
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <div>
                          <div>
                            {!item?.adminId ? (
                              <>
                                {item?.Status === "Pending" ? (
                                  <span
                                    className="unblock-user"
                                    style={{ backgroundColor: "#dba709" }}
                                  >
                                    Pending
                                  </span>
                                ) : (
                                  <span className="unblock-user">Approved</span>
                                )}
                                <br />
                                <br />
                                <span className="unblock-user">
                                  Added By Vendor
                                </span>
                              </>
                            ) : (
                              <>
                                {item?.Status === "Pending" ? (
                                  <span
                                    className="unblock-user"
                                    style={{ backgroundColor: "#dba709" }}
                                  >
                                    Pending
                                  </span>
                                ) : (
                                  <span className="unblock-user">Approved</span>
                                )}
                                <br />
                                <br />
                                <span className="unblock-user">
                                  Added By Admin
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>{moment(item?.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <div className="fs-4 text-danger">
                          <AiFillEye
                            onClick={() => {
                              handleShow9();
                              setModelview(item);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 fs-4 align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            onClick={() => AdminActivate(item?._id)}
                            className={
                              item?.isBlock ? "unblock-user" : "block-user"
                            }
                          >
                            {item?.isBlock ? "Activated" : "Deactivated"}
                          </span>
                          <Link state={{ item: item }} to="/edithotel">
                            <BiSolidEdit className="text-primary fs-4" />
                          </Link>
                          <MdDelete
                            className="text-danger fs-4"
                            onClick={() => handleShow5(item)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
          <div className="my-3">
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                onChange={(event, value) => {
                  changePage(value - 1);
                }}
                color="primary"
              />
            </Stack>
          </div>
        </div>
      </Container>

      {/* Vendor Details Modal */}
      <Modal show={show} onHide={handleClose} size="xl" className="vendorli">
        <Modal.Header closeButton>
          <Modal.Title>
            Profile <span className="text-danger">Details </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Table responsive bordered>
              <tbody>
                {!Modelview?.adminId ? (
                  <>
                    {Modelview?.Vendor?.map((item, i) => {
                      return (
                        <>
                          <tr>
                            <td className="fw-bold">Vendor Name :</td>
                            <td>
                              {item?.Fname} {item?.Lname}
                            </td>
                            <td className="fw-bold">Vendor Number :</td>
                            <td>{item?.Mobile}</td>
                            <td className="fw-bold">Vendor Email :</td>
                            <td>{item?.Email}</td>
                          </tr>
                          <tr>
                            <td></td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                {!Modelview?.adminId ? (
                  <tr>
                    <td className="fw-bold">GST Number :</td>
                    <td>{Modelview?.gst}</td>
                  </tr>
                ) : (
                  <tr>
                    <td className="fw-bold">GST Number :</td>
                    <td>{Modelview?.gst}</td>
                  </tr>
                )}

                <tr>
                  <td className="fw-bold">Adhar card :</td>
                  <td>
                    <img
                      src={`https://hayyyak.com/VendorStay/${Modelview?.aadhar}`}
                      alt=""
                      style={{
                        imageRendering: "pixelated",
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td className="fw-bold">Pan card :</td>
                  <td>
                    <img
                      src={`https://hayyyak.com/VendorStay/${Modelview?.pancard}`}
                      alt=""
                      style={{
                        imageRendering: "pixelated",
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="fw-bold">GST Document :</td>
                  <td>
                    <img
                      src={`https://hayyyak.com/VendorStay/${Modelview?.gstdocument}`}
                      alt=""
                      style={{
                        imageRendering: "pixelated",
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td className="fw-bold">Property Document :</td>
                  <td>
                    <img
                      src={`https://hayyyak.com/VendorStay/${Modelview?.propartydoc}`}
                      alt=""
                      style={{
                        imageRendering: "pixelated",
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div>
            <p className="fs-3 mb-2">
              Property <span className="text-danger">Details </span>
            </p>
            <Table responsive bordered className="mb-2">
              <tbody>
                <tr>
                  <td className="fw-bold">Property Name :</td>
                  <td>{Modelview?.propartyname}</td>
                  <td className="fw-bold ">Property Location :</td>
                  <td className="w-40">{Modelview?.location}</td>
                </tr>

                <tr>
                  <td className="fw-bold">Starting Price :</td>
                  <td>{Modelview?.startingprice}</td>
                  <td className="fw-bold">Single / Multiple Rooms :</td>
                  <td>{Modelview?.porparty}</td>
                </tr>

                <tr>
                  <td className="fw-bold">Rooms :</td>
                  <td>{Modelview?.totalroom}</td>
                  <td className="fw-bold">Property Address:</td>
                  <td>{Modelview?.propartyaddress}</td>
                </tr>

                <tr>
                  <td className="fw-bold">Check In Time :</td>
                  <td>{Modelview?.checkin}</td>
                  <td className="fw-bold">Check Out Time :</td>
                  <td>{Modelview?.checkout}</td>
                </tr>

                <tr>
                  <td className="fw-bold">Terms & Conditions :</td>
                  <td>{parse(`<div>${Modelview?.termsandcondition}</div>`)}</td>
                  <td className="fw-bold">Property Description :</td>
                  <td width="40%">
                    {parse(`<div>${Modelview?.description}</div>`)}
                  </td>
                </tr>

                <tr>
                  <td className="fw-bold">Suggestion :</td>
                  <td>{parse(`<div>${Modelview?.suggesition}</div>`)}</td>
                  <td className="fw-bold">House Rules :</td>
                  <td>{parse(`<div>${Modelview?.Houserules}</div>`)}</td>
                </tr>

                <tr>
                  <td className="fw-bold">Near by Locations :</td>
                  <td>{parse(`<div>${Modelview?.Nearbyplaces}</div>`)}</td>
                  <td className="fw-bold">Things To Do :</td>
                  <td>{parse(`<div>${Modelview?.ThingToDo}</div>`)}</td>
                </tr>
              </tbody>
            </Table>
            <p className="text-danger fw-bold">FAQ :</p>
            <Table
              bordered
              className="admin-table-head"
              style={{ width: "500px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Question / Answer</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Modelview?.faq?.map((item, i) => (
                  <tr>
                    <td>{i + 1}.</td>
                    <td>
                      <div>
                        <p className="fw-bold  d-flex gap-2 align-items-start">
                          Q {parse(`<div>${item?.question}</div>`)}?
                        </p>
                        <p className="d-flex gap-2 align-items-start">
                          A {parse(`<div>${item?.answer}</div>`)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <FaEdit
                          style={{
                            color: "green",
                            fontFamily: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFaqId(item);
                            handleShow10();
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          {Modelview?.Status === "Approved" ? (
            <></>
          ) : (
            <>
              <Button
                variant="danger"
                onClick={() => AdminAproove(Modelview?._id)}
              >
                Approve
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      {/* Documents big image Modal*/}
      <Modal show={show1} onHide={handleClose1} className="mb-0" size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <img
            src={`https://hayyyak.com/VendorStay/${Modelview?.gstdocument}`}
            alt=""
            style={{
              width: "-webkit-fill-available",
              imageRendering: "pixelated",
            }}
          />
        </Modal.Body>
      </Modal>

      {/* Property Big Image Modal */}
      <Modal show={show2} onHide={handleClose2} className="mb-0" size="lg">
        <Modal.Body className="p-0">
          <img
            src={`https://hayyyak.com/VendorStay/${bigImage?.image}`}
            alt=""
            style={{
              width: "-webkit-fill-available",
              height: "500px",
              imageRendering: "pixelated",
            }}
            onClick={handleClose2}
          />
        </Modal.Body>
      </Modal>

      {/* Gallery Image Model */}
      <Modal show={show3} onHide={handleClose3} className="gallery_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {Modelview?.gallery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                          imageRendering: "pixelated",
                        }}
                        src={`https://hayyyak.com/VendorStay/${item?.image}`}
                        alt=""
                        onClick={() => {
                          handleShow2();
                          setbigImage(item);
                        }}
                      />
                    </td>
                    <td>
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            UpdateGalleryImages(e.target.files[0], item._id);
                          }}
                        />
                        <BiSolidEdit
                          className="text-primary fs-4"
                          type="file"
                          onClick={() => handleIconClick(item?._id)}
                        />
                        <MdDelete
                          className="text-danger fs-4"
                          onClick={() => {
                            handleDeleteGallery(item?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Amenities Model */}
      <Modal show={show4} onHide={handleClose4} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {ModelAminity?.Amenities?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{ width: "80px" }}
                        src={`https://hayyyak.com/StayAmenities/${item?.Icon}`}
                        alt=""
                      />
                    </td>
                    <td>{item?.AmenitiName}</td>
                    <td>
                      <MdDelete
                        className="text-danger fs-4"
                        role="button"
                        onClick={() => {
                          handledeleteAmenity(item?._id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Category Model */}
      <Modal show={show7} onHide={handleClose7} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {Category?.StaysCategory?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{ width: "80px" }}
                        src={`https://hayyyak.com/StayCategory/${item?.Icon}`}
                        alt=""
                      />
                    </td>
                    <td>{item?.Category}</td>
                    <td>
                      <MdDelete
                        className="text-danger fs-4"
                        role="button"
                        onClick={() => handleDeleteStayCategory(item?._id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose7}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Model */}
      <Modal show={show5} onHide={handleClose5} className="anties_mdl">
        <Modal.Header closeButton>
          {/* <Modal.Title className="text-danger">Amenities</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose5}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteStay}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Rooms Model */}
      <Modal
        show={show6}
        onHide={handleClose6}
        className="anties_mdl"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Room Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive bordered className="admin-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th style={{ width: "300px" }}>Room Images</th>
                <th>Hotel Name</th>
                <th>Room Name</th>
                <th>Room Amenities</th>
                <th>Actual Price</th>
                <th>Discount%</th>
                <th>Discount% Price</th>
                <th>GST%</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Modelroom?.Rooms?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <div className="sgdffhjd_0">
                        <Carousel>
                          {item?.roomimages?.map((item) => {
                            return (
                              <Carousel.Item interval={1000}>
                                <img
                                  src={`https://hayyyak.com/VendorStay/${item?.image}`}
                                  alt=""
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    imageRendering: "pixelated",
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    </td>
                    <td>{Modelroom?.propartyname}</td>
                    <td>{item?.roomname}</td>
                    <td>{parse(`<div>${item?.amenities}</div>`)}</td>
                    <td>
                      Adult:{" "}
                      <span style={{ color: "green", fontSize: "18px" }}>
                        {item?.adultprice}
                      </span>{" "}
                      ,<br />
                      Children:{" "}
                      <span style={{ color: "green" }}>
                        {item?.children}
                      </span>{" "}
                    </td>
                    <td>
                      Adult:{" "}
                      <span style={{ color: "green", fontSize: "18px" }}>
                        {item?.adultdiscount}%
                      </span>{" "}
                      ,<br />
                      Children:{" "}
                      <span style={{ color: "green" }}>
                        {item?.childrendiscount}%
                      </span>{" "}
                    </td>
                    <td>
                      Adult:{" "}
                      <span style={{ color: "green", fontSize: "18px" }}>
                        {item?.adultdiscountprice}
                      </span>{" "}
                      ,<br />
                      Children:{" "}
                      <span style={{ color: "green" }}>
                        {item?.childrendiscountprice}
                      </span>{" "}
                    </td>
                    <td>{item?.gst} % </td>
                    <td>
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <BiSolidEdit
                          className="text-primary fs-4"
                          onClick={() => {
                            handleShow8(item?._id);
                            setRoomsImagesView(item);
                          }}
                        />
                        <MdDelete
                          className="text-danger fs-4"
                          onClick={() => handleDeleteRoom(item?._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose6}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Room details Model */}
      <Modal show={show8} onHide={handleClose8} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Room Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-lg-12 mb-2">
              <label className="fw-bold">Room Name : </label>
              <input
                type="text"
                className="vi_0"
                placeholder={RoomsImagesView?.roomname}
                onChange={(e) => setroomName(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Adult Price : </label>
              <input
                type="text"
                className="vi_0"
                placeholder={RoomsImagesView?.adultprice}
                onChange={(e) => setadultPrice(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Children Price : </label>
              <input
                type="text"
                className="vi_0"
                placeholder={RoomsImagesView?.children}
                onChange={(e) => setchildren(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Adult Discount% : </label>
              <input
                type="text"
                className="vi_0"
                placeholder={RoomsImagesView?.adultdiscount}
                onChange={(e) => setadultdiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">Children Discount% : </label>
              <input
                type="text"
                className="vi_0"
                placeholder={RoomsImagesView?.childrendiscount}
                onChange={(e) => setchildrendiscount(e.target.value)}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="fw-bold">GST % : </label>
              <input
                type="text"
                className="vi_0"
                placeholder={RoomsImagesView?.gst}
                onChange={(e) => setgst(e.target.value)}
              />
            </div>
            <div className="col-lg-12 mb-2">
              <label className="fw-bold">Room Amenities : </label>
              <CKEditor
                editor={ClassicEditor}
                data={amenities}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setamenities(data);
                }}
              />
            </div>
          </div>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {RoomsImagesView?.roomimages?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={`https://hayyyak.com/VendorStay/${item?.image}`}
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              UpdateRoomGallery(e.target.files[0], item._id);
                            }}
                          />
                          <BiSolidEdit
                            className="text-primary fs-4"
                            onClick={() => handleIconClick1(item?._id)}
                          />
                          <MdDelete
                            className="text-danger fs-4"
                            onClick={() => DeleteRoomGallery(item?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose8}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => UpdateRoomDetails()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Review Model */}
      <Modal show={show9} onHide={handleClose9} className="amenties-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            Customer <span className="text-danger">Reviews</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Modelview?.StarRatings?.map((item) => (
            <>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <div className="d-flex gap-2 align-items-center mb-2">
                    {item?.UserImage ? (
                      <>
                        <img
                          src={`https://hayyyak.com/Customer/${item?.UserImage}`}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src="./img/profile.jpg"
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    )}
                    <div style={{ fontSize: "14px" }}>
                      <p className="fw-bold ">
                        {item?.Fname}{" "}
                        <span className="text-danger">{item?.Lname}</span>
                      </p>
                      <Rating
                        className="StarSize"
                        readonly={true}
                        initialValue={item?.Rating}
                      />
                    </div>
                  </div>
                  <p style={{ fontSize: "14x" }}>{item?.Review}</p>
                </div>
                <span
                  className="text-danger fs-5"
                  style={{ cursor: "pointer" }}
                >
                  <MdDelete
                    onClick={() => {
                      DeleteReview(item?._id);
                    }}
                  />
                </span>
              </div>
              <hr />
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose9}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show10}
        onHide={handleClose10}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Label className="mb-3 fw-bold">Question :</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={FaqQuestion}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFaqQuestion(data);
                }}
              />
              <br/>


              <Form.Label className="mb-3 fw-bold">Answer :</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={FaqAnswer}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFaqAnswer(data);
                }}
              />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose10}>
            Close
          </Button>
          <Button variant="danger" onClick={FaqEdit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default StayVendorList;
