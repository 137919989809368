import React, { useEffect, useState } from "react";
// import ReactLoading from "react-loading";
// import Home from "../Home/Home";
// import "./Loader.css"

function PageLoader({ children }) {

    const [done, setDone] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setDone(false)
        }, 1000)
    }, [])

    return (
        <>
            <div >
                {done ? (
                    <div
                        style={{
                            position: "absolute",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <img src="./img/page-loader.gif" alt="pageloader"  />
                    </div>
                ) : (
                    <>{children}</>
                )}
            </div >
        </>
    );
}

export default PageLoader;
