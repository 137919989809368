import React, { useState } from 'react'
import { Button, Container, Form, Modal, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FaLock, FaLockOpen } from 'react-icons/fa6'
import { useEffect } from 'react'
import axios from 'axios'
import moment from 'moment/moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'


function AdminContact() {
    const [show, setShow] = useState();
    const handleClose = () => setShow(false);
    const handleShow = (item) => { setShow(true); setdata(item); }

    const [ContactList, setContactList] = useState([])
    useEffect(() => {
        getCustomerContastlist()
    }, [])
    const getCustomerContastlist = async () => {
        try {
            const res = await axios.get("https://hayyyak.com/api/customer/getcontactcustomer")
            if (res.status == 200) {
                setContactList(res.data.getdata)
                setnochangedata(res.data.getdata)
            }
        } catch (error) {
            alert(error.response.data.error)
        }
    }

    const [Data, setdata] = useState("")
    const DeleteContact = async () => {
        try {
            const config = {
                url: "customer/deletecontact/" + Data,
                baseURL: "https://hayyyak.com/api",
                method: "DELETE",
                Headers: { "Content-Type": "application-json" },

            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success);
                    handleClose()
                    getCustomerContastlist()
                }
            })
        } catch (error) {
            alert(error.response.data.error)
        }
    }

    // Search
    const [SearchItem, setSearchItem] = useState("");

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setContactList(abc);
    };

    return (
        <div>
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Enquiry :</p>
                    {/* <button className='pink-btn' onClick={handleShow}>Add Activity</button> */}
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <Table responsive bordered className='admin-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Email</th>
                                <th style={{ width: '400px' }}>Message</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ContactList?.map((item, i) => {
                                if (SearchItem === "" ||
                                    Object.values(item).some(
                                        value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
                                    )
                                )
                                    return (
                                        <>
                                            <tr>
                                                <td>{++i}</td>
                                                <td>{item?.Name}</td>
                                                <td>{item?.Number}</td>
                                                <td>{item?.Email}</td>
                                                <td>{item?.Message}</td>
                                                <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                                <td>
                                                    <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                        <MdDelete className='text-danger' onClick={() => handleShow(item?._id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                            })}
                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Delete Modal */}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => DeleteContact()}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminContact
