import React from "react";
import { BsFacebook } from "react-icons/bs";
import { AiFillYoutube, AiOutlineTwitter } from "react-icons/ai";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";

import { FaSquarePhone } from "react-icons/fa6";
export const TopNavbar = () => {
  return (
    <div>
      <Container fluid style={{ background: "rgb(0, 0, 0)", padding: "2px", position: 'relative', zIndex: '1' }}>
        <Container className="top-navbar">
          <div className="d-flex gap-3">
            <a href="https://maps.app.goo.gl/Cc8UAmJP5qeZcWg7A" className="text-decoration-none text-white" >
              <span><FaLocationDot className="top-navbar-icon" /> </span>
              <span> Kozhikode, Kerala</span>
            </a>
            <a href="tel:9876543210" className="text-decoration-none text-white">
              <span><FaSquarePhone className="top-navbar-icon" /> </span>
              <span>9876543210</span>
            </a>
            |
            <div className="d-flex gap-2 " style={{alignItems:"center"}}>
              <span>
                <BsFacebook />
              </span>
              <span>
                <AiFillYoutube />
              </span>
              <span>
                <AiOutlineTwitter />
              </span>
            </div>
          </div>

          <div className="d-flex gap-2">
            <a href="/SelectVendor">
              <Button className="top-navbar-btn sign-in-btn">Vendor</Button>
            </a>
            {/* <a href='/register'>
                            <Button className='top-navbar-btn sign-in-btn'>Sign Up</Button>
                        </a>
                        <a href='/profile'>
                            <Button className='top-navbar-btn sign-in-btn'>Profile</Button>
                        </a> */}
          </div>
        </Container>
      </Container>
    </div>
  );
};
