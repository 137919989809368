import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Rating, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { FaPen } from "react-icons/fa";
import { MdDirections } from "react-icons/md";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
function ThingaToDoMainView() {
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);
  const location = useLocation();
  const ThingstoDo = location.state;
  const navigate = useNavigate();
  const [value1, setValue1] = React.useState(0);
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [ViewImage, setViewImage] = useState({});
  const [ThingsToDoById, setThingsToDoById] = useState({});
  const getbyId = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/admin/thingstodo/getthingstodobyId/${ThingstoDo.item?._id}`
      );
      if (res.status === 200) {
        setThingsToDoById(res.data.Thingsdetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("ThingsToDoById", ThingsToDoById);

  useEffect(() => {
    window.scrollTo(0, 0);
    getbyId();
  }, []);

  const [GalleryImages, setGalleryImages] = useState("");
  const AddImageGallery = async () => {
    try {
      const configGallery = {
        url: "/thingstodo/Addgallery",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          thingsid: ThingstoDo?.item?._id,
          packageimages: GalleryImages,
        },
      };
      let res = await axios(configGallery);
      if (res.status === 200) {
        notify(res.data.sucess);
        getbyId();
        setGalleryImages("");
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [KindofQues, setKindofQues] = useState("");
  const [Visitdate, setVisitdate] = useState("");
  const [review, setreview] = useState("");
  const [reviewtitle, setreviewtitle] = useState("");
  const addreview = async () => {
    if (!Customer) {
      return alert("please login..");
    }
    try {
      const config = {
        url: "/addreview",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo",
        headers: { "Content-Type": "application/json" },
        data: {
          id: ThingsToDoById?._id,
          userId: Customer?._id,
          stars: value1,
          visitkind: KindofQues,
          visitdate: Visitdate,
          review: review,
          titlereview: reviewtitle,
          reviewname: Customer?.Fname,
          reviewaddress: Customer?.address,
          reviewimage: Customer?.profileImage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.message);
        handleClose();
        getbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [Question, setQuestion] = useState("");
  const currentDate = new Date();
  const addQuestion = async () => {
    if (!Customer) {
      return alert("Please login...")
    }
    try {
      const config = {
        url: "addpackquestion/" + ThingsToDoById?._id,
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo/",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          questions: Question,
          name: Customer?.Fname,
          address: Customer?.address,
          quesimage1: Customer?.profileImage,
          updatedAt: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.message);
        handleClose1();
        getbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [Questionid, setQuestionid] = useState("")
  const [Answer, setAnswer] = useState("");
  const currentDate1 = new Date();
  const addAnswer = async () => {
    if (!Customer) {
      return alert("Please login...")
    }
    try {
      const config = {
        url: "addpackanswer/" + ThingsToDoById?._id,
        method: "put",
        baseURL: "https://hayyyak.com/api/admin/thingstodo/",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          answers: Answer,
          questId: Questionid?._id,
          name: Customer?.Fname,
          address: Customer?.address,
          ansimage: Customer?.profileImage,
          updatedAt: currentDate1,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.message);
        handleClose2();
        getbyId();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container>
        <div className="row mb-4">
          <div>
            {" "}
            <h2>{ThingsToDoById?.thingstodoname}</h2>
          </div>

          <div className="col-lg-8 mb-2">
            <div>
              <img
                className="big_img_dispaly"
                // src={ViewImage ? ViewImage : "./img/activity-img.jpeg"}
                src={
                  ViewImage
                    ? ViewImage
                    : `https://hayyyak.com/Thingstodo/${ThingsToDoById?.packagegallery[0]?.packageimages}`
                }
                alt=""
              />
            </div>

            <div className="d-flex mt-1 gap-1 clk_img_overF">
              {ThingsToDoById.packagegallery?.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      setViewImage(
                        `https://hayyyak.com/Thingstodo/${item?.packageimages}`
                      );
                    }}
                  >
                    <img
                      className={`clk_img ${
                        ViewImage ===
                        `https://hayyyak.com/Thingstodo/${item?.packageimages}`
                          ? "clk_active"
                          : ""
                      }`}
                      src={`https://hayyyak.com/Thingstodo/${item?.packageimages}`}
                      alt=""
                    />
                  </div>
                );
              })}

              {/* <div
                onClick={() => {
                  setViewImage("./img/destination-img-3.jpg");
                }}
              >
                <img
                  className={`clk_img ${
                    ViewImage === "./img/destination-img-3.jpg"
                      ? "clk_active"
                      : ""
                  }`}
                  src="./img/destination-img-3.jpg"
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setViewImage("./img/destination-img-4.jpg");
                }}
              >
                <img
                  className={`clk_img ${
                    ViewImage === "./img/destination-img-4.jpg"
                      ? "clk_active"
                      : ""
                  }`}
                  src="./img/destination-img-4.jpg"
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setViewImage("./img/destination-img-5.jpg");
                }}
              >
                <img
                  className={`clk_img ${
                    ViewImage === "./img/destination-img-5.jpg"
                      ? "clk_active"
                      : ""
                  }`}
                  src="./img/destination-img-5.jpg"
                  alt=""
                />
              </div> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <div className="cafe_img ">
                <p className="fw-normal fs-4">Call</p>
                <p className="text-danger fs-5 mb-2">
                  +91{ThingsToDoById?.phonenumber}
                </p>
                <p className="fw-normal fs-4">Direction</p>
                <p className="mb-2 text-muted">
                  {ThingsToDoById?.thingstodoaddress},
                  {ThingsToDoById?.thingstodocity},
                  {ThingsToDoById?.thingstodostate},
                  {ThingsToDoById?.thingstodocountry}
                </p>
                {/* <button
              target = "_blank"
              onClick={()=>navigate(`${ThingstoDo?.item?.website}`)}
              className='pink-btn__2'>
              <MdDirections className='fs-4' />
              {" "} Direction</button> */}
                <a
                  href={ThingsToDoById?.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pink-btn__2"
                >
                  <MdDirections className="fs-4" /> Direction
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="p-2">
            <p className="fs-2 fw-semibold">Places to Visit in Bangalore</p>
          </div>
          {ThingsToDoById?.visitpackages?.map((item) => {
            return (
              <div className="col-lg-4">
                <div className="cafe_img">
                  <div
                    onClick={() =>
                      navigate("/things_todo_review", { state: { thingsToDoId: ThingsToDoById._id, item } })
                    }
                    className="text-decoration-none"
                  >
                    <img
                      src={`https://hayyyak.com/Thingstodo/${item?.gallery?.[0]?.images}`}
                      alt=""
                    />
                    <div className="mb-2">
                      <p className="fw-semibold fs-5 text-dark">
                        {item?.visitname}
                      </p>
                      <Box>
                        <Rating
                          name="read-only"
                          value={4}
                          readOnly
                          className="fs-6"
                        />
                      </Box>
                      <div className="flex justify-content-between">
                        <p className="text-muted">
                          From{" "}
                          <span className="text-danger fs-5 fw-bold">
                            ₹{item?.price}
                          </span>
                        </p>
                        {/* <p className="text-muted">Historic Place</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className="col-lg-4">
            <div className="cafe_img">
              <Link to="/thingatodo_mainview" className="text-decoration-none">
                <img src="./img/things-img-1.jpg" alt="" />
                <div className="mb-2">
                  <p className="fw-semibold fs-5 text-dark">Mysore Palace</p>
                  <Box>
                    <Rating
                      name="read-only"
                      value={4}
                      readOnly
                      className="fs-6"
                    />
                  </Box>
                  <div className="flex justify-content-between">
                    <p className="text-muted">
                      From{" "}
                      <span className="text-danger fs-5 fw-bold">₹9220</span>
                    </p>
                    <p className="text-muted">Historic Place</p>
                  </div>
                </div>
              </Link>

             
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cafe_img">
              <Link to="/thingatodo_mainview" className="text-decoration-none">
                <img src="./img/things-img-1.jpg" alt="" />
                <div className="mb-2">
                  <p className="fw-semibold fs-5 text-dark">Mysore Palace</p>
                  <Box>
                    <Rating
                      name="read-only"
                      value={4}
                      readOnly
                      className="fs-6"
                    />
                  </Box>
                  <div className="flex justify-content-between">
                    <p className="text-muted">
                      From{" "}
                      <span className="text-danger fs-5 fw-bold">₹9220</span>
                    </p>
                    <p className="text-muted">Historic Place</p>
                  </div>
                </div>
              </Link>

             
            </div>
          </div> */}
        </div>

        <div className="col-lg-8">
          <p className="fw-semibold fs-4 mb-2">Contribute</p>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Write Review" value="1" />
                  <Tab label="Upload Photo" value="2" />
                  <Tab label="Q&A" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <button className="pink-btn mb-3" onClick={handleShow}>
                  Add Review
                </button>
                {ThingsToDoById?.packagereview?.map((item) => {
                  return (
                    <div>
                      <div className="flex gap-2 align-items-center">
                        <img
                          src={`https://hayyyak.com/Customer/${item?.reviewimage}`}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p className="fw-semibold">{item?.reviewname}</p>
                          <Box>
                            <Rating
                              name="read-only"
                              value={item?.stars}
                              readOnly
                              className="fs-6"
                            />
                          </Box>
                        </div>
                      </div>
                      <h6>{item?.titlereview}</h6>
                      <p className="text-secondary fs-6">
                        {item?.review}</p>
                    </div>
                  );
                })}
              </TabPanel>
              <TabPanel value="2">
                <div className="d-flex gap-4 mb-2">
                  <input
                    onChange={(e) => setGalleryImages(e.target.files[0])}
                    type="file"
                    accept="images/*"
                    className="form-control w-50"
                  />
                  <button onClick={AddImageGallery} className="pink-btn">
                    Add Photos
                  </button>
                </div>
                {/* <img
                  src="./img/things-img-3.jpg"
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                /> */}
              </TabPanel>
              <TabPanel value="3">
                <button className="pink-btn mb-3" onClick={handleShow1}>
                  Add Question
                </button>
                {ThingsToDoById?.packageQuesandans?.map((item) => {
                  return (
                    <div>
                      <div className="flex gap-2 align-items-center">
                        <img
                          // src="./img/aadhaar-card.webp"
                          src={`https://hayyyak.com/Customer/${item?.quesimage1}`}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p className="fw-semibold">{item?.name} </p>
                        </div>
                      </div>
                      <h6>
                        Q. {item?.questions} &nbsp;
                        <b
                          style={{
                            color: "green",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            handleShow2();
                            setQuestionid(item)
                          }}
                        >
                          answer
                          <FaPen />{" "}
                        </b>
                      </h6>
                      {item?.answer?.map((item1) => {
                        return (
                          <p className="text-secondary fs-6">
                            <span style={{ color: "green" }}>Ans.</span>
                            {item1?.answers}
                          </p>
                        )
                      })}
                    </div>
                  );
                })}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Add <span className="text-danger">Review</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <p className="fw-semibold">How would you rate your experience?</p>
              <Box>
                <Rating
                  name="simple-controlled"
                  value={value1}
                  onChange={(event, newValue) => {
                    setValue1(newValue);
                  }}
                />
              </Box>
            </div>
            <div className=" mt-2">
              <label className="fw-bold">What kind of visit was this ?</label>
              <Form.Select
                onChange={(e) => setKindofQues(e.target.value)}
                className="vi_0"
              >
                <option>select</option>
                <option value="Business">Business</option>
                <option value="Couples">Couples</option>
                <option value="Family(teens)">Family(teens)</option>
                <option value="Friends">Friends</option>
                <option value="Solo">Solo</option>
                <option value="Family">Family</option>
              </Form.Select>
            </div>
            <div className=" mt-2">
              <label className="fw-bold">When did you visit ?</label>
              <Form.Control
                type="date"
                onChange={(e) => setVisitdate(e.target.value)}
                className="vi_0"
              />
            </div>
            <div className=" mt-2">
              <label className="fw-bold">Write Your review..</label>
              <Form.Control
                onChange={(e) => setreview(e.target.value)}
                className="vi_0"
                type="text"
                placeholder="Write Your review.."
              />
            </div>
            <div className=" mt-2">
              <label className="fw-bold">Title this review..</label>
              <Form.Control
                onChange={(e) => setreviewtitle(e.target.value)}
                className="vi_0"
                type="text"
                placeholder="Title this review.."
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={addreview}>
            Add Review
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Add <span className="text-danger">Question</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="write your question..?"
                onChange={(e) => setQuestion(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={addQuestion}>
            Add Question
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Add <span className="text-danger">Answer</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="write your answer.."
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="danger" onClick={addAnswer}>
            Add Question
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ThingaToDoMainView;
