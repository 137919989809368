import React, { useEffect, useState } from "react";
import { Container, Dropdown, Form } from "react-bootstrap";
import {
  AiFillHeart,
  AiOutlineCamera,
  AiOutlineComment,
  AiOutlineUsergroupAdd,
  AiTwotoneLike,
} from "react-icons/ai";
import { BsTag, BsThreeDots } from "react-icons/bs";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { BiLike } from "react-icons/bi";
import { BiEdit, BiSolidDashboard } from "react-icons/bi";
import { CiLocationOn, CiSaveDown2 } from "react-icons/ci";
import { MdCancelPresentation, MdDelete } from "react-icons/md";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Carousel from "react-multi-carousel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import EmojiPicker from "emoji-picker-react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";

export const Community = () => {
  const [save, setSave] = useState(true);
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [postdataid, setpostdataid] = useState("");
  const [IsOpen, setIsOpen] = useState(false);
  const handleClick = (data) => {
    setpostdataid(data);
    setIsOpen(!IsOpen);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // For Post Content
  const [isemojipicker, setisemojipicker] = useState(false);
  const [Likes, setLikes] = useState(true);
  const formData = new FormData();
  const [Text, setText] = useState("");
  const [Image, setImage] = useState("");
  const [Privecy, setPrivecy] = useState("");
  const postcontent = async () => {
    if (!Privecy) {
      return notify1("Please select post privacy");
    }
    if (!Image && !Text) {
      return notify1("Atleast Enter text Or Image");
    }

    try {
      formData.append("userId", Customer?._id);
      formData.append("text", Text);
      formData.append("image", Image);
      formData.append("privecy", Privecy);

      const config = {
        url: "/customer/customerpost",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallposts();
          setText("");
          setImage("");
        }
      });
    } catch (error) {
      notify(error.response.data.error);
    }
  };

  // Get Post
  const [Posts, setPosts] = useState([]);
  const getallposts = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/getallcustomerpost"
      );
      if (res.status === 200) {
        setPosts(res.data.getallpost);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // UserLikes
  const userlikes = async (Postid) => {
    try {
      const config = {
        url: "/customer/userlikes",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          postId: Postid,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          getallposts();
          notify(res.data.success);
        }
      });
    } catch (error) {
      notify(error.response.data.error);
    }
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setcommentashow(item);
    setpostid(item?._id);
  };
  const [commentashow, setcommentashow] = useState({});
  // user Comments
  const [Commenttext, setCommenttext] = useState("");
  const userComments = async (Postid) => {
    try {
      const config = {
        url: "/customer/usercomments",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          postId: Postid,
          comment: Commenttext,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallposts();
          setIsOpen(false);
          getallcomments();
        }
      });
    } catch (error) {
      notify(error.response.data.error);
    }
  };

  // Getcomments
  const [postid, setpostid] = useState("");
  const [Comments, setComments] = useState([]);
  const getallcomments = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/customer/getallcustomercomments/${postid}`
      );
      if (res.status === 200) {
        setComments(res.data.comments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Post
  const DeletePost = async (DeletePostId) => {
    try {
      const config = {
        url: "/customer/deletecustomerpost/" + DeletePostId,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallposts();
        }
      });
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  //Total Post
  const [TotalPost, setTotalPost] = useState([]);
  useEffect(() => {
    const totalpostuser = Posts?.filter(
      (ele) => ele.userId === Customer._id
    ).length;
    setTotalPost(totalpostuser);
  }, [Posts, Customer]);

  // Total Likes
  const [TotalLikes, setTotalLikes] = useState([]);
  useEffect(() => {
    let userLikes = 0;
    Posts.filter((item) => item.userId === Customer._id).forEach((val) => {
      userLikes += val.likes.length;
    });
    setTotalLikes(userLikes);
  }, [Posts, Customer]);

  useEffect(() => {
    getallposts();
    getSharePost();
  }, []);

  useEffect(() => {
    if (postid) {
      getallcomments();
    }
  }, [postid]);

  // Delete Comment
  const DeleteCommment = async (userId) => {
    try {
      const config = {
        url: "/customer/deletecustomercomment/" + userId,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "content-type": "applicaion/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          getallcomments();
          getallposts();
        }
      });
    } catch (error) {
      notify2(error.res.data.error);
    }
  };

  // Post Share
  // const [userId, setuserId] = useState("");
  const PostShare = async (id) => {
    try {
      const config = {
        url: "/customer/postshare",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          postid: id?._id,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        // notify(res.data.success);
        getallposts();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // get share post count

  const [postdetails, setpostdetails] = useState([]);
  const getSharePost = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/customer/getpostshare/`
      );
      if (res.status === 200) {
        setpostdetails(res.data.getdata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const imagelength = Posts?.filter(
    (ele) =>
      ele.customers?._id === Customer?._id &&
      ele.image !== null &&
      ele.image !== undefined
  )?.length;

  //Search
  const [SearchItem1, setSearchItem1] = useState("");

  //Register User
  const [resgisterUser, setRegisterUser] = useState([]);

  const GetRegisterUser = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/registeruser"
      );
      if (res.status === 200) {
        setRegisterUser(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // Get friends recivied req and send req
  const [friends, setfriends] = useState([]);
  const getfriendrequest = () => {
    axios
      .get(
        "https://hayyyak.com/api/customer/getFririndlistByuserId/" +
        Customer?._id
      )
      .then(function (response) {
        setfriends(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("friends", friends);

  useEffect(() => {
    GetRegisterUser();
    getfriendrequest();
  }, []);

  // Send Request

  const addfriendreq = async (id) => {
    try {
      const config = {
        url: "/addfriendreq",
        method: "post",
        baseURL: "https://hayyyak.com/api/customer",
        headers: { "Content-Type": "application/json" },
        data: {
          senderId: Customer?._id,
          receiverId: id,
          status: "Pending",
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify("Friend request sent");
      }
    } catch (error) {
      console.log(error);
      notify1("Friend request already sent");
    }
  };

  return (
    <div className=""  >
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="row gap-3 Mobile_responsive-Navbar-community-main ">
          <div className="col-lg-1 Mobile_responsive-Navbar-community  ">
            <div className="facebook-left">
              <ul>
                <li>
                  <Link
                    to="/community"
                    className="text-decoration-none text-light"
                  >
                    {" "}
                    <BiSolidDashboard />{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/coummunityprofile"
                    className="text-decoration-none text-light"
                  >
                    <AiOutlineUsergroupAdd />
                  </Link>
                </li>
                <li>
                  <Link to="/chat" className="text-decoration-none text-light">
                    <Stack spacing={2} direction="row">
                      <Badge badgeContent={0} color="secondary">
                        <IoChatbubbleEllipsesOutline className="text-light fs-3" />
                      </Badge>
                    </Stack>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-10 ">
            <div className="row justify-content-between mb-4">
              <div className="col-lg-3">
                <div className="jkghsidf_0">
                  {Customer?.profileImage ? (
                    <>
                      <img
                        src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src="./img/profile.jpg"
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          marginBottom: "10px",
                        }}
                      />
                    </>
                  )}

                  <p className="fs-5 fw-bold">
                    {Customer?.Fname} {Customer?.Lname}{" "}
                    <span className="text-danger">
                      <AiFillHeart />
                    </span>
                  </p>
                  <p className="mb-3">{Customer?.Email}</p>
                  <div className="d-flex gap-3 justify-content-center mt-3 mb-3">
                    <div>
                      <span className="fs-5 fw-bold">{TotalLikes}</span>
                      <p className="text-secondary">Likes</p>
                    </div>
                    <div>
                      <span className="fs-5 fw-bold">{TotalPost}</span>
                      <p className="text-secondary">Posts</p>
                    </div>
                  </div>

                  <div>
                    <Link
                      to="/coummunityprofile"
                      className="text-decoration-none"
                    >
                      {" "}
                      <button className="pink-btn">View Profile</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 scroll-cat" style={{ overflow: "auto" }} >
                <div className="jgashgd_1 mb-4">
                  <div className="d-flex gap-3 align-items-center mb-2">
                    <p className="fs-5 fw-bold">Create Post</p>
                    <Box style={{ width: "45%" }}>
                      <FormControl fullWidth>
                        <NativeSelect
                          style={{ border: "none", fontSize: "12px" }}
                          defaultValue={30}
                          inputProps={{
                            name: "age",
                            id: "uncontrolled-native",
                          }}
                          onChange={(e) => setPrivecy(e.target.value)}
                        >
                          <option>Select Privacy</option>
                          <option value="Public">Public</option>
                          {/* <option value="Friends">Friends</option> */}
                          <option value="Only Me">Only Me</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </div>

                  <div className="mb-3">
                    <input
                      style={{
                        border: "none",
                        width: "100%",
                        height: "46px",
                        padding: "5px",
                        backgroundColor: "#ecf5fd",
                      }}
                      type="text"
                      placeholder="Write something here..."
                      onChange={(e) => setText(e.target.value)}
                    />
                  </div>

                  <div className="feel">
                    <div className="album">
                      <AiOutlineCamera />
                      <span>Album</span>
                      <input
                        onChange={(e) => setImage(e.target.files[0])}
                        type="file"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      className="pink-btn w-100"
                      onClick={() => postcontent()}
                    >
                      POST
                    </button>
                  </div>
                </div>
                {Posts?.map((item) => {
                  const postDate = new Date(item.createdAt);
                  const currentDate = new Date();

                  const timeDifferenceInMinutes = Math.floor(
                    (currentDate - postDate) / (1000 * 60)
                  );
                  const timeDifferenceInHours = Math.floor(
                    (currentDate - postDate) / (1000 * 60 * 60)
                  );
                  const timeDifferenceInDays = Math.floor(
                    (currentDate - postDate) / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <>
                      {item?.text && item?.image ? (
                        <>
                          <div className="kalin">
                            <div className="d-flex justify-content-between p-2 mb-2">
                              <Link
                                state={{ item: item }}
                                to={
                                  item?.userId === Customer?._id
                                    ? "/coummunityprofile"
                                    : "/community_friend"
                                }
                                className="text-decoration-none"
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  {item?.customers?.profileImage ? (
                                    <>
                                      <img
                                        src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src="./img/profile.jpg"
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </>
                                  )}
                                  <div>
                                    <p className="fw-bold text-secondary fs-6">
                                      {item?.User?.Fname} {item?.User?.Lname}
                                    </p>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "grey",
                                      }}
                                    >
                                      {timeDifferenceInMinutes <= 60 ? (
                                        <>{timeDifferenceInMinutes} Mins Ago</>
                                      ) : (
                                        <>
                                          {timeDifferenceInHours <= 24 ? (
                                            <>
                                              {timeDifferenceInHours} Hours Ago
                                            </>
                                          ) : (
                                            <>
                                              {timeDifferenceInDays <= 7 ? (
                                                <>
                                                  {timeDifferenceInDays} Days
                                                  Ago
                                                </>
                                              ) : (
                                                <>
                                                  {moment(
                                                    item.createdAt
                                                  ).format("DD-MM-YYYY")}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                              {item?.customers?._id === Customer?._id ? (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    style={{
                                      background: "none",
                                      border: "none",
                                    }}
                                  >
                                    <BsThreeDots className="text-dark" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => DeletePost(item?._id)}
                                    >
                                      <BsTag /> Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                ""
                              )}
                            </div>

                            <img
                              src={`https://hayyyak.com/PostImages/${item?.image}`}
                              alt=""
                              className="img-fluid mb-2"
                              style={{
                                width: "500px",
                                height: "300px",
                              }}
                            />
                            <div className="p-2">
                              <div className="row justify-content-between mb-2">
                                <p className=" col-lg-8">
                                  {item?.text}
                                </p>
                              </div>
                              <div className="emoji">
                                <div className="d-flex gap-2">
                                  <img src="./img/like.png" alt="" />
                                  <span className="fw-bold text-secondary">
                                    {item?.likes?.length}
                                  </span>
                                </div>
                                <div
                                  className="d-flex gap-1 align-items-center"
                                  style={{ fontSize: "16px", color: "grey" }}
                                >
                                  <span
                                    onClick={() => {
                                      handleShow1(item);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <AiOutlineComment />{" "}
                                    {item?.comments?.length}
                                  </span>
                                  <span>
                                    <CiSaveDown2 /> {item?.Share?.length}
                                  </span>
                                </div>
                              </div>
                              <div className="react-0">
                                {item.likes.some(
                                  (like) => like.userId === Customer?._id
                                ) ? (
                                  <>
                                    <span
                                      onClick={() => {
                                        userlikes(item?._id);
                                      }}
                                    >
                                      <AiTwotoneLike
                                        style={{ color: "#0466ff" }}
                                      />
                                      Likes
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      onClick={() => {
                                        userlikes(item?._id);
                                      }}
                                    >
                                      <BiLike />
                                      Likes
                                    </span>
                                  </>
                                )}
                                <span
                                  onClick={() => {
                                    handleClick(item?._id);
                                  }}
                                >
                                  <AiOutlineComment />
                                  Comment
                                </span>
                                <span>
                                  <WhatsappShareButton
                                    onClick={() => PostShare(item)}
                                    url={`http://localhost:3000/communityshare/${item?._id}`}
                                  >
                                    <WhatsappIcon size={32} round />
                                    Share
                                  </WhatsappShareButton>
                                </span>
                                {/* </Link> */}
                              </div>
                            </div>
                          </div>
                          {IsOpen && postdataid === item?._id ? (
                            <>
                              <div className="jgashgd_1 mb-4">
                                <div className="d-flex gap-2 align-items-center">
                                  {Customer?.profileImage ? (
                                    <>
                                      <img
                                        src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src="./img/profile.jpg"
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </>
                                  )}
                                  <div>
                                    <p className="fw-bold text-secondary fs-6">
                                      {Customer?.Fname} {Customer?.Lname}
                                    </p>
                                    {/* <span style={{ fontSize: "12px", color: "grey" }}>
                              {timeDifferenceInMinutes <= 60 ? (
                                <>{timeDifferenceInMinutes} Mins Ago</>
                              ) : (
                                <>
                                  {timeDifferenceInHours <= 24 ? (
                                    <>{timeDifferenceInHours} Hours Ago</>
                                  ) : (
                                    <>
                                      {timeDifferenceInDays <= 7 ? (
                                        <>{timeDifferenceInDays} Days Ago</>
                                      ) : (
                                        <>
                                          {moment(item.createdAt).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </span> */}
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <input
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      height: "46px",
                                      padding: "5px",
                                      backgroundColor: "#ecf5fd",
                                    }}
                                    onChange={(e) =>
                                      setCommenttext(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Comment here..."
                                  />
                                </div>

                                <div>
                                  <button
                                    className="pink-btn w-100"
                                    onClick={() => userComments(item?._id)}
                                  >
                                    POST
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {item?.text ? (
                            <>
                              <div className="kalin">
                                <div className="d-flex justify-content-between p-2 mb-2">
                                  <Link
                                    state={{ item: item }}
                                    to={
                                      item?.userId === Customer?._id
                                        ? "/coummunityprofile"
                                        : "/community_friend"
                                    }
                                    className="text-decoration-none"
                                  >
                                    <div className="d-flex gap-2 align-items-center">
                                      {item?.customers?.profileImage ? (
                                        <>
                                          <img
                                            src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src="./img/profile.jpg"
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      )}
                                      <div>
                                        <p className="fw-bold text-secondary fs-6">
                                          {item?.User?.Fname}{" "}
                                          {item?.User?.Lname}
                                        </p>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "grey",
                                          }}
                                        >
                                          {timeDifferenceInMinutes <= 60 ? (
                                            <>
                                              {timeDifferenceInMinutes} Mins Ago
                                            </>
                                          ) : (
                                            <>
                                              {timeDifferenceInHours <= 24 ? (
                                                <>
                                                  {timeDifferenceInHours} Hours
                                                  Ago
                                                </>
                                              ) : (
                                                <>
                                                  {timeDifferenceInDays <= 7 ? (
                                                    <>
                                                      {timeDifferenceInDays}{" "}
                                                      Days Ago
                                                    </>
                                                  ) : (
                                                    <>
                                                      {moment(
                                                        item.createdAt
                                                      ).format("DD-MM-YYYY")}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                  {item?.customers?._id === Customer?._id ? (
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                      >
                                        <BsThreeDots className="text-dark" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => DeletePost(item?._id)}
                                        >
                                          <BsTag /> Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: "8px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <p>{item?.text}</p>
                                </div>

                                <div className="p-2">
                                  <div className="emoji">
                                    <div className="d-flex gap-2">
                                      <img src="./img/like.png" alt="" />
                                      <span className="fw-bold text-secondary">
                                        {item?.likes?.length}
                                      </span>
                                    </div>
                                    <div
                                      className="d-flex gap-1 align-items-center"
                                      style={{
                                        fontSize: "16px",
                                        color: "grey",
                                      }}
                                    >
                                      <span
                                        onClick={() => {
                                          handleShow1(item);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <AiOutlineComment />{" "}
                                        {item?.comments?.length}
                                      </span>
                                      <span>
                                        <CiSaveDown2 /> {item?.Share?.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="react-0">
                                    {item.likes.some(
                                      (like) => like.userId === Customer?._id
                                    ) ? (
                                      <>
                                        <span
                                          onClick={() => {
                                            userlikes(item?._id);
                                          }}
                                        >
                                          <AiTwotoneLike
                                            style={{ color: "#0466ff" }}
                                          />
                                          Likes
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          onClick={() => {
                                            userlikes(item?._id);
                                          }}
                                        >
                                          <BiLike />
                                          Likes
                                        </span>
                                      </>
                                    )}
                                    <span
                                      onClick={() => {
                                        handleClick(item?._id);
                                      }}
                                    >
                                      <AiOutlineComment />
                                      Comment
                                    </span>
                                    <span>
                                      <WhatsappShareButton
                                        onClick={() => PostShare(item)}
                                        url={`http://localhost:3000/communityshare/${item?._id}`}
                                      >
                                        <WhatsappIcon size={32} round />
                                        Share
                                      </WhatsappShareButton>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {IsOpen && postdataid === item?._id ? (
                                <>
                                  <div className="jgashgd_1 mb-4">
                                    <div className="d-flex gap-2 align-items-center">
                                      {Customer?.profileImage ? (
                                        <>
                                          <img
                                            src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src="./img/profile.jpg"
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      )}
                                      <div>
                                        <p className="fw-bold text-secondary fs-6">
                                          {Customer?.Fname} {Customer?.Lname}
                                        </p>
                                        {/* <span style={{ fontSize: "12px", color: "grey" }}>
                              {timeDifferenceInMinutes <= 60 ? (
                                <>{timeDifferenceInMinutes} Mins Ago</>
                              ) : (
                                <>
                                  {timeDifferenceInHours <= 24 ? (
                                    <>{timeDifferenceInHours} Hours Ago</>
                                  ) : (
                                    <>
                                      {timeDifferenceInDays <= 7 ? (
                                        <>{timeDifferenceInDays} Days Ago</>
                                      ) : (
                                        <>
                                          {moment(item.createdAt).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </span> */}
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <input
                                        style={{
                                          border: "none",
                                          width: "100%",
                                          height: "46px",
                                          padding: "5px",
                                          backgroundColor: "#ecf5fd",
                                        }}
                                        onChange={(e) =>
                                          setCommenttext(e.target.value)
                                        }
                                        type="text"
                                        placeholder="Comment here..."
                                      />
                                    </div>

                                    <div>
                                      <button
                                        className="pink-btn w-100"
                                        onClick={() => userComments(item?._id)}
                                      >
                                        POST
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <div className="kalin">
                                <div className="d-flex justify-content-between p-2 mb-2">
                                  <Link
                                    state={{ item: item }}
                                    to={
                                      item?.userId === Customer?._id
                                        ? "/coummunityprofile"
                                        : "/community_friend"
                                    }
                                    className="text-decoration-none"
                                  >
                                    <div className="d-flex gap-2 align-items-center">
                                      {item?.customers?.profileImage ? (
                                        <>
                                          <img
                                            src={`https://hayyyak.com/Customer/${item?.customers?.profileImage}`}
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src="./img/profile.jpg"
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      )}
                                      <div>
                                        <p className="fw-bold text-secondary fs-6">
                                          {item?.User?.Fname}{" "}
                                          {item?.User?.Lname}
                                        </p>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "grey",
                                          }}
                                        >
                                          {timeDifferenceInMinutes <= 60 ? (
                                            <>
                                              {timeDifferenceInMinutes} Mins Ago
                                            </>
                                          ) : (
                                            <>
                                              {timeDifferenceInHours <= 24 ? (
                                                <>
                                                  {timeDifferenceInHours} Hours
                                                  Ago
                                                </>
                                              ) : (
                                                <>
                                                  {timeDifferenceInDays <= 7 ? (
                                                    <>
                                                      {timeDifferenceInDays}{" "}
                                                      Days Ago
                                                    </>
                                                  ) : (
                                                    <>
                                                      {moment(
                                                        item.createdAt
                                                      ).format("DD-MM-YYYY")}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                  {item?.customers?._id === Customer?._id ? (
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                      >
                                        <BsThreeDots className="text-dark" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => DeletePost(item?._id)}
                                        >
                                          <BsTag /> Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    //   <Dropdown>
                                    //   <Dropdown.Toggle
                                    //     id="dropdown-basic"
                                    //     style={{ background: "none", border: "none" }}
                                    //   >
                                    //     <BsThreeDots className="text-dark" />
                                    //   </Dropdown.Toggle>

                                    //   <Dropdown.Menu>
                                    //     <Dropdown.Item href="#">
                                    //       <BsTag /> Save
                                    //     </Dropdown.Item>
                                    //   </Dropdown.Menu>
                                    // </Dropdown>
                                    ""
                                  )}
                                </div>

                                <img
                                  src={`https://hayyyak.com/PostImages/${item?.image}`}
                                  alt=""
                                  className="img-fluid mb-2"
                                  style={{
                                    width: "500px",
                                    height: "300px",
                                  }}
                                />
                                <div className="p-2">
                                  {/* <div className="row justify-content-between mb-2">
                            <p className="fs-5 fw-bold col-lg-8">                          
                              {item?.text}
                            </p>
                            <span
                              onClick={() => setSave(!save)}
                              className="col-lg-1 save-tag"
                            >
                              {save ? (
                                <>
                                  <BsTag />
                                </>
                              ) : (
                                <>
                                  <BsFillTagFill />
                                </>
                              )}{" "}
                            </span>
                          </div>  */}
                                  <div className="emoji">
                                    <div className="d-flex gap-2">
                                      <img src="./img/like.png" alt="" />
                                      <span className="fw-bold text-secondary">
                                        {item?.likes?.length}
                                      </span>
                                    </div>
                                    <div
                                      className="d-flex gap-1 align-items-center"
                                      style={{
                                        fontSize: "16px",
                                        color: "grey",
                                      }}
                                    >
                                      <span
                                        onClick={() => {
                                          handleShow1(item);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <AiOutlineComment />{" "}
                                        {item?.comments?.length}
                                      </span>
                                      <span>
                                        <CiSaveDown2 /> {item?.Share?.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="react-0">
                                    {item.likes.some(
                                      (like) => like.userId === Customer?._id
                                    ) ? (
                                      <>
                                        <span
                                          onClick={() => {
                                            userlikes(item?._id);
                                          }}
                                        >
                                          <AiTwotoneLike
                                            style={{ color: "#0466ff" }}
                                          />
                                          Likes
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          onClick={() => {
                                            userlikes(item?._id);
                                          }}
                                        >
                                          <BiLike />
                                          Likes
                                        </span>
                                      </>
                                    )}
                                    <span
                                      onClick={() => {
                                        handleClick(item?._id);
                                      }}
                                    >
                                      <AiOutlineComment />
                                      Comment
                                    </span>
                                    <span>
                                      <WhatsappShareButton
                                        onClick={() => PostShare(item)}
                                        url={`http://localhost:3000/communityshare/${item?._id}`}
                                      >
                                        <WhatsappIcon size={32} round />
                                        Share
                                      </WhatsappShareButton>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {IsOpen && postdataid === item?._id ? (
                                <>
                                  <div className="jgashgd_1 mb-4">
                                    <div className="d-flex gap-2 align-items-center">
                                      {Customer?.profileImage ? (
                                        <>
                                          <img
                                            src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src="./img/profile.jpg"
                                            alt=""
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </>
                                      )}
                                      <div>
                                        <p className="fw-bold text-secondary fs-6">
                                          {Customer?.Fname} {Customer?.Lname}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <input
                                        style={{
                                          border: "none",
                                          width: "100%",
                                          height: "46px",
                                          padding: "5px",
                                          backgroundColor: "#ecf5fd",
                                        }}
                                        onChange={(e) =>
                                          setCommenttext(e.target.value)
                                        }
                                        type="text"
                                        placeholder="Comment here..."
                                      />
                                    </div>

                                    <div>
                                      <button
                                        className="pink-btn w-100"
                                        onClick={() => userComments(item?._id)}
                                      >
                                        POST
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>

              <div className="col-lg-4">
                <div className="jgashgd_1">
                  <div className="d-flex gap-3">
                    <p className="fs-5 fw-bold mb-4">Gallery</p>
                    <span>{imagelength} Photos</span>
                  </div>
                  <div className="row justify-content-between">
                    {Posts?.filter(
                      (ele) =>
                        ele.customers?._id === Customer?._id &&
                        ele.image !== null &&
                        ele.image !== undefined
                    )
                      ?.slice(0, 15)
                      ?.map((item) => {
                        return (
                          <div className="col-lg-4 mb-2">
                            <img
                              src={`https://hayyyak.com/PostImages/${item?.image}`}
                              alt=""
                              className="fb-gallery"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                {/* <div className="jgashgd_1 mt-4">
                  <div className="d-flex gap-3">
                    <p className="fs-5 fw-bold mb-2">Suggesion Friends</p>

                 
                  </div>
                  <div className="mb-2">
                    <Form className="d-flex mt-2">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                        onChange={(e) => setSearchItem1(e.target.value)}
                      />
                    </Form>
                  </div>
                  <div className="row justify-content-between">
                    {resgisterUser?.slice(0, 5)?.map((item) => {
                      if (
                        SearchItem1 === "" ||
                        Object.values(item).some((value) =>
                          String(value)
                            .toLowerCase()
                            .includes(SearchItem1.toLowerCase())
                        )
                      )
                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  margin: "8px",
                                }}
                                src={
                                  item?.profileImage
                                    ? `https://hayyyak.com/Customer/${item?.profileImage}`
                                    : "./img/profile.jpg"
                                }
                                alt=""
                              />
                              <div>
                                <span className="chat fw-semibold activity-h2">
                                  {item?.Fname}
                                  {item?.Lname}
                                </span>{" "}
                                <br />
                                <p
                                  onClick={() => addfriendreq(item?._id)}
                                  style={{
                                    backgroundColor: "#e34e7e",
                                    padding: "4px",
                                    borderRadius: "5px",
                                    color: "white",
                                    textAlign: "-webkit-center",
                                    width: "123px",
                                  }}
                                >
                                  Add Friend
                                </p>
                              </div>
                            </div>
                          </>
                        );
                    })}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* Stroy Modal */}
      {/* <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ganesh Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img
              src="./img/fb-bg-2.jpg"
              alt=""
              style={{ width: "100%", height: "350px", objectFit: "cover" }}
            />
          </div>
        </Modal.Body>
      </Modal> */}

      {/* Comment Modal */}
      <Modal size="md" show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>{commentashow?.User?.Fname}'s Post</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-2">
            <div className="d-flex gap-2 align-items-center mb-2">
              {commentashow?.customers?.profileImage ? (
                <>
                  <img
                    src={`https://hayyyak.com/Customer/${commentashow?.customers?.profileImage}`}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src="./img/profile.jpg"
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </>
              )}
              <div>
                <p className="fw-bold text-secondary fs-6">
                  {commentashow?.User?.Fname} {commentashow?.User?.Lname}
                </p>
              </div>
            </div>
          </div>

          <div className="comment_img">
            {commentashow?.image ? (
              <>
                <img
                  src={`https://hayyyak.com/PostImages/${commentashow?.image}`}
                  alt=""
                  className="mb-2"
                  style={{ width: "-webkit-fill-available", height: "250px" }}
                />
                <h4 className=" p-2">{commentashow?.text}</h4>
              </>
            ) : (
              <>
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <h4>{commentashow?.text}</h4>
                </div>
              </>
            )}
            <div className="emoji">
              <div className="d-flex gap-2">
                <img
                  src="./img/like.png"
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                />
                <span className="fw-bold text-secondary">
                  {commentashow?.likes?.length}
                </span>
              </div>
              <div
                className="d-flex gap-2 align-items-center fs-5"
                style={{ fontSize: "16px", color: "grey" }}
              >
                <span>
                  <AiOutlineComment /> {commentashow?.comments?.length}
                </span>
              </div>
            </div>

            <div>
              {Comments?.map((item) => {
                return (
                  <div className="d-flex gap-2 align-items-start">
                    {item?.userId?.profileImage ? (
                      <>
                        <img
                          src={`https://hayyyak.com/Customer/${item?.userId?.profileImage}`}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src="./img/profile.jpg"
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        backgroundColor: "aliceblue",
                        padding: "12px",
                        borderRadius: "17px",
                        marginBottom: "5px",
                      }}
                    >
                      <p className="fw-bold text-secondary fs-6">
                        {item?.userId?.Fname} {item?.userId?.Lname}
                      </p>
                      <p>{item?.comment}</p>
                    </div>
                    <div>
                      {/* {item?.userId?._id === Customer?._id  ? <></> : <></>} */}
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          style={{ background: "none", border: "none" }}
                        >
                          <BsThreeDots className="text-dark" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              DeleteCommment(item?._id);
                            }}
                            className="d-flex align-items-center gap-2"
                          >
                            <MdDelete className="text-danger" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
