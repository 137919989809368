import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";

function Subscription() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const [getSubscription, setgetSubscription] = useState([]);
    const GetAdminNotification = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/getsubscription"
            );
            if (res.status === 200) {
                setgetSubscription(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    // Add Vechicle Category
    const [PacakageName, setPacakageName] = useState("");
    const [Price, setPrice] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const AddSubscription = async () => {
        if (!PacakageName) {
            return notify1("Please Select PacakageName");
        }
        if (!Price) {
            return notify1("Please Enter Price");
        }
        if (!startDate) {
            return notify1("Please Enter Start Date");
        }
        if (!endDate) {
            return notify1("Please Enter End Date");
        }
        try {
            const config = {
                url: "/addsubscription",
                baseURL: "https://hayyyak.com/api/admin",
                method: "post",
                header: { "content-type": "application/json" },
                data: {
                    PacakageName: PacakageName,
                    Price: Price,
                    startDate: startDate,
                    endDate: endDate,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    handleClose();
                    GetAdminNotification()
                }
            });
        } catch (error) {
            notify2(error.response.data.error);
        }
    };

    // // get


    useEffect(() => {
        GetAdminNotification();
    }, []);
    function getDayDifference(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);

        // Calculate the difference in days
        const difference = momentDate2.diff(momentDate1, 'days');

        return difference;
    }
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className="d-flex justify-content-between align-items-center pt-4 pb-2 mb-3">
                    <p className="text-danger fs-4 fw-semibold">Subscription :</p>
                    <button className="pink-btn" onClick={handleShow}>
                        Add
                    </button>
                </div>

                <div>
                    <div className="row">
                        {getSubscription?.map((ele) => (
                            <div className="col-lg-4">
                                <div
                                    className={`card mb-4 rounded-3 ${ele?.PacakageName === "Silver"
                                        ? "Ouhads_01"
                                        : ele?.PacakageName === "Gold"
                                            ? "Ouhads_02"
                                            : ele?.PacakageName === "Premium"
                                                ? "Ouhads_03"
                                                : ""
                                        }`}
                                >
                                    <div class="card-header py-3 text-white">
                                        <h4 class="my-0 fw-normal">{ele?.PacakageName}</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">
                                            ₹{ele?.Price}
                                            <small class="text-muted fw-light">/{getDayDifference(ele?.startDate, ele?.endDate)} Days</small>
                                        </h1>
                                        {/* <ul class=" mt-3 mb-2">
                                            <li>Start Date : {ele?.startDate}</li>
                                            <li>End Date : {ele?.endDate}</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>

            {/* Add Activity Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">Add Things To Do</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className=" mt-2 col-lg-12">
                            <label className="fw-bold">Select Pacakge :</label>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => setPacakageName(e.target.value)}
                            >
                                <option>Select</option>
                                <option value="Silver">Silver</option>
                                <option value="Gold">Gold</option>
                                <option value="Premium">Premium</option>
                            </Form.Select>
                        </div>
                        <div className=" mt-2 col-lg-12">
                            <label className="fw-bold">Price :</label>
                            <input
                                type="text"
                                placeholder="Price"
                                className="vi_0"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                        <div className=" mt-2 col-lg-12">
                            <label className="fw-bold">Start Date :</label>
                            <input
                                type="date"
                                className="vi_0"
                                onChange={(e) => setstartDate(e.target.value)}
                            />
                        </div>
                        <div className=" mt-2 col-lg-12">
                            <label className="fw-bold">End Date :</label>
                            <input
                                type="date"
                                className="vi_0"
                                onChange={(e) => setendDate(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddSubscription}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Activity */}
            {/* <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className=" mt-2 col-lg-12">
                            <label className='fw-bold'>Select Pacakge :</label>
                            <Form.Select aria-label="Default select example">
                                <option>Select</option>
                                <option value="Silver">Silver</option>
                                <option value="Gold">Gold</option>
                                <option value="Premium">Premium</option>
                            </Form.Select>
                        </div>
                        <div className=" mt-2 col-lg-12">
                            <label className='fw-bold'>Price :</label>
                            <input
                                type="text"
                                placeholder="Price"
                                className="vi_0"
                            // onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                        <div className=" mt-2 col-lg-12">
                            <label className='fw-bold'>Start Date :</label>
                            <input
                                type="date"
                                className="vi_0"
                            // onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                        <div className=" mt-2 col-lg-12">
                            <label className='fw-bold'>End Date :</label>
                            <input
                                type="date"
                                className="vi_0"
                            // onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger"
                        onClick={EditNotification}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
}
export default Subscription;
