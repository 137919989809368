import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';

export const VendorThingsProfile = () => {

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const ThingsVendor = JSON.parse(sessionStorage.getItem("ThingsVendor"));

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const deleteClose = () => setShow2(false)
    const deleteShow = () => setShow2(true)

    const [Fname, setFname] = useState("");
    const [Lname, setLname] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Email, setEmail] = useState("");
    // Edit Vendor
    const [editData, seteditData] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (item) => { setShow(true); seteditData(item) }
    const EditThingsVendor = async () => {
        try {
            const config = {
                url: `/EditthingsVendor/${editData}`,
                baseURL: "https://hayyyak.com/api/admin/thingstodo",
                method: "put",
                headers: { "Content-Type": "application/json" },
                data: {
                    Fname: Fname,
                    Lname: Lname,
                    Mobile: Mobile,
                    Email: Email
                },
            };

            const res = await axios(config);

            if (res.status === 200) {
                sessionStorage.setItem("ThingsVendor", JSON.stringify(res.data.user));
                notify(res.data.success);
                handleClose();
            }
        } catch (error) {
            notify2(error.response.data.error);
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <h1 class="display-6 text-center mb-3">Profile <span className='text-danger'>Details</span></h1>

                <div className='mb-5'>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td className='fw-bold '>First <span className="text-danger">Name :</span> </td>
                                <td>{ThingsVendor?.Fname} </td>
                                <td className='fw-bold '>Last <span className="text-danger">Name :</span> </td>
                                <td>{ThingsVendor?.Lname} </td>
                            </tr>
                            <tr>
                                <td className='fw-bold '>Mobile <span className="text-danger">Number :</span> </td>
                                <td>{ThingsVendor?.Mobile} </td>
                                <td className='fw-bold '>Email : </td>
                                <td>{ThingsVendor?.Email} </td>
                            </tr>
                        </tbody>
                    </Table>
                    <button className='pink-btn' onClick={() => handleShow(ThingsVendor?._id)}>Edit Profile</button>
                </div>
            </Container>

            {/* Edit Profile Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit <span className='text-danger'>Profile</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-4">
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>First Name :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setFname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Last Name:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setLname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Mobile Number:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setMobile(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Email :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditThingsVendor}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={deleteClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={deleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger"><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}
