import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react'
import parse from "html-react-parser"
import moment from 'moment'

function AdminBlog() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Add Blog
    const [BlogImage, setBlogImage] = useState("")
    const [BlogTitle, setBlogTitle] = useState("")
    const [BlogDate, setBlogDate] = useState("")
    const [BlogDescription, setBlogDescription] = useState("")
    const [BlogCity, setBlogCity] = useState("")
    const formData = new FormData();
    const AddBlog = async () => {
        if (!BlogImage) {
            return notify1("Please Select Image")
        }
        if (!BlogTitle) {
            return notify1("Please Enter Title")
        }
        if (!BlogDate) {
            return notify1("Please Enter Date")
        }
        if (!BlogDescription) {
            return notify1("Please Enter Description")
        }
        if (!BlogCity) {
            return notify1("Please Enter City Name")
        }

        try {
            formData.append("BlogImage", BlogImage);
            formData.append("BlogTitle", BlogTitle);
            formData.append("BlogDate", BlogDate);
            formData.append("BlogDescription", BlogDescription);
            formData.append("BlogCity", BlogCity);
            const config = {
                url: "admin/addblog",
                baseURL: 'https://hayyyak.com/api/',
                method: "post",
                Headers: { "content-type": "multipart/form-data" },
                data: formData
            }
            await axios(config).then((res) => {
                notify(res.data.success)
                GetBlog()
                handleClose()
                setBlogImage("")
                setBlogTitle("")
                setBlogDate("")
                setBlogDescription("")
                setBlogCity("")
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // get blog
    const [blogdata, setblogdata] = useState([])
    const GetBlog = async () => {
        axios
            .get("https://hayyyak.com/api/admin/getblog")
            .then(function (response) {
                setblogdata(response.data.success)
                setnochangedata(response.data.success)
            }).catch(function (error) {
                console.log(error)
            })
    }
    console.log("blogdata", blogdata);

    // Edit blog
    const [EditBlogData, setEditBlogData] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); setEditBlogData(item) }
    const EditBlog = async () => {
        try {
            formData.append("BlogImage", BlogImage);
            formData.append("BlogTitle", BlogTitle);
            formData.append("BlogDate", BlogDate);
            formData.append("BlogDescription", BlogDescription);
            formData.append("BlogCity", BlogCity);
            const config = {
                url: 'admin/editblog/' + EditBlogData?._id,
                baseURL: 'https://hayyyak.com/api/',
                method: 'put',
                Headers: { "content-type": "multipart/form-data" },
                data: formData
            }
            await axios(config).then((res) => {
                notify(res.data.success)
                GetBlog()
                handleClose1()
                setBlogImage("")
                setBlogTitle("")
                setBlogDate("")
                setBlogDescription("")
                setBlogCity("")
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Delete 
    const [DeleteBlogData, setDeleteBlogData] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setDeleteBlogData(item) }
    const DeleteBlog = async () => {
        try {
            const config = {
                url: '/admin/deleteblog/' + DeleteBlogData?._id,
                baseURL: 'https://hayyyak.com/api',
                method: 'delete',
                Headers: { 'content-type': 'application/json' }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    GetBlog();
                    handleClose2()
                }
            })
        } catch (error) {
            notify2(error.res.data.error)
        }
    }

    // Search
    const [SearchItem, setSearchItem] = useState("");

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setblogdata(abc);
    };

    useEffect(() => {
        GetBlog()
    }, [])
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Blog :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Blog</button>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <Table responsive bordered className='admin-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Date</th>
                                <th>City</th>
                                <th style={{ width: '400px' }}>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogdata?.map((item, i) => {
                                if (SearchItem === "" ||
                                    Object.values(item).some(
                                        value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
                                    )
                                )
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                <img src={`https://hayyyak.com/Blog/${item?.BlogImage}`}
                                                    alt=""
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                />
                                            </td>
                                            <td>{item?.BlogTitle}</td>
                                            <td>{item?.BlogDate}</td>
                                            <td>{item?.BlogCity}</td>
                                            <td>{parse(`<div>${item?.BlogDescription}</div>`)}</td>
                                            <td>
                                                <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                    <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                    <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>)
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                            accept='image/*'
                            onChange={(e) => setBlogImage(e.target.files[0])}
                        />
                    </div>

                    <div className='mb-2'>
                        <label className='fw-bold'>Title :</label>
                        <input type="text" placeholder='title' className="vi_0" onChange={(e) => setBlogTitle(e.target.value)} />
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Date :</label>
                            <input type="date" className="vi_0" onChange={(e) => setBlogDate(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>City :</label>
                            <input type="text" placeholder='city' className="vi_0" onChange={(e) => setBlogCity(e.target.value)} />
                        </div>
                    </div>

                    <div className="mt-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor}
                            data={BlogDescription}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setBlogDescription(data);
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddBlog}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                            accept='image/*'
                            onChange={(e) => setBlogImage(e.target.files[0])}
                        />
                    </div>

                    <div className='mb-2'>
                        <label className='fw-bold'>Title :</label>
                        <input type="text" placeholder='title' className="vi_0" onChange={(e) => setBlogTitle(e.target.value)} />
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Date :</label>
                            <input type="date" className="vi_0" onChange={(e) => setBlogDate(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>City :</label>
                            <input type="text" placeholder='city' className="vi_0" onChange={(e) => setBlogCity(e.target.value)} />
                        </div>
                    </div>

                    <div className="mt-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor}
                            data={BlogDescription}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setBlogDescription(data);
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditBlog}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeleteBlog}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminBlog
