import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
export const ThingsToDo = () => {

  const navigate = useNavigate();

  // get Things ToDo 
  const [ThingatoDo, setThingatoDo] = useState([]);
  console.log("ThingatoDo", ThingatoDo);
  const GetAdminThingsTodo = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/thingstodo/Getthingstodo"
      );
      if (res.status === 200) {
        setThingatoDo(res.data.sucess);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetAdminThingsTodo();
  }, []);

  //Search Filter
const [SearchItem, setSearchItem] = useState("")
const filteredItems = ThingatoDo?.filter((item) => {
  const searchLower = SearchItem.toLowerCase();
  return (
    item?.thingstodoname?.toLowerCase().includes(searchLower) 
  );
});
  return (
    <div>
      <div className="mb-4 things-todo-Divmb">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(././img/things-to-do-bg.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "350px",
              width: "100%",
            }}
            className="things-todo-topImg"
          >
            <div className="activity-text">
              <div className="bread" id="things-todo-bread">
                <h2 className="text-uppercase fw-bold">Things To Do</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Things To Do</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <p
          className="fs-1 activity-h2 mb-3 things-todo-Divmb"
          style={{ borderBottom: "1px solid #d81d4a", width: "fit-content" }}
        >
          Things To Do
        </p>
        <div className="row justify-content-between mb-4 things-todo-Divmb">
          <div className="col-lg-6">
            <p className="fs-2 fw-semibold">Places to Visit in Bangalore</p>
          </div>
          <div className="col-lg-4">
            <InputGroup className="mb-3 things-todo-Divmb">
              <InputGroup.Text id="basic-addon1">
                <CiSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search...."
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e)=>setSearchItem(e.target.value)}
              />
            </InputGroup>
          </div>
        </div>

        <div className="row mb-4">
          {filteredItems?.filter((ele) => ele.isApproved === true)?.map((item, i) => {
            return (<>
              <div className="col-lg-4">
                <div className="cafe_img">
                  <div onClick={() => navigate("/thingatodo_mainview", { state: { item } })} className="text-decoration-none">
                    <img src={`https://hayyyak.com/Thingstodo/${item?.packagegallery?.[0]?.packageimages}`} alt="" />
                    <div className="mb-2">
                      <p className="fw-semibold fs-5 text-dark">{item?.thingstodoname}</p>
                      <Box>
                        <Rating
                          name="read-only"
                          value={4}
                          readOnly
                          className="fs-6"
                        />
                      </Box>
                      <div className="flex justify-content-between">
                        {/* <p className="text-muted">
                        From{" "}
                        <span className="text-danger fs-5 fw-bold">₹9220</span>
                      </p> */}
                        <p className="text-muted">{item?.thingstodoaddress},{item?.thingstodocity}</p>
                      </div>
                    </div>
                  </div>

                  <div onClick={() => navigate("/things_todo_details", { state: { item } })}>
                    <button
                      className="pink-btn"
                      style={{ padding: "7px", fontSize: "12px" }}
                    >
                      See Tours
                    </button>
                  </div>
                </div>
              </div>
            </>)
          })}

          {/* <div className="col-lg-4">
            <div className="cafe_img">
              <Link to="/things_todo_review" className="text-decoration-none">
                <img src="./img/things-img-2.jpg" alt="" />
                <div className="mb-2">
                  <p className="fw-semibold fs-5 text-dark">Bannerughatta</p>
                  <Box>
                    <Rating
                      name="read-only"
                      value={4}
                      readOnly
                      className="fs-6"
                    />
                  </Box>
                  <div className="flex justify-content-between">
                    <p className="text-muted">
                      From{" "}
                      <span className="text-danger fs-5 fw-bold">₹9220</span>
                    </p>
                    <p className="text-muted">Historic Place</p>
                  </div>
                </div>
              </Link>
              <Link to="/things_todo_details">
                <button
                  className="pink-btn"
                  style={{ padding: "4px 6px", fontSize: "12px" }}
                >
                  See Tours
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cafe_img">
              <Link to="/things_todo_review" className="text-decoration-none">
                <img src="./img/things-img-3.jpg" alt="" />
                <div className="mb-2">
                  <p className="fw-semibold fs-5 text-dark">Iskon Temple</p>
                  <Box>
                    <Rating
                      name="read-only"
                      value={4}
                      readOnly
                      className="fs-6"
                    />
                  </Box>
                  <div className="flex justify-content-between">
                    <p className="text-muted">
                      From{" "}
                      <span className="text-danger fs-5 fw-bold">₹9220</span>
                    </p>
                    <p className="text-muted">Historic Place</p>
                  </div>
                </div>
              </Link>
              <Link to="/things_todo_details">
                <button
                  className="pink-btn"
                  style={{ padding: "4px 6px", fontSize: "12px" }}
                >
                  See Tours
                </button>
              </Link>
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};
