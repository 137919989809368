import React, { useEffect, useMemo, useState, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { GrSend } from "react-icons/gr";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

export const Vendoractivitychat = () => {
  const notify2 = (data) => toast.error(data);
  const Customer = JSON.parse(sessionStorage.getItem("activityvendor"));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [GroupData, setGroupData] = useState({});
  const [Grouptext, setGrouptext] = useState("");
  const currentDate = new Date();
  const chatContainerRef = useRef(null);

  const groupsendmessage = async (id) => {
    try {
      const config = {
        url: "/addVendortocustomerchatchat",
        method: "post",
        baseURL: "https://hayyyak.com/api/customer",
        headers: { "content-type": "application/json" },
        data: {
          text: Grouptext,
          createdAt: currentDate,
          user: {
            _id: Customer?._id,
            name: Customer.Fname,
            avthar: Customer?.profileImage,
          },
          receiver: {
            _id: GroupData?._id,
          },
          type: 'text',
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setGrouptext("");
        getchatchat();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [SearchItem, setSearchItem] = useState("");
  const [data, setdata] = useState([]);

  useEffect(() => {
    getchat();
  }, []);

  const getchat = () => {
    try {
      axios
        .get(
          `https://hayyyak.com/api/customer/getVendorchatchat/` +
          `${Customer?._id}`
        )
        .then(function (response) {
          setdata(response.data.users);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [datachat, setdatachat] = useState([]);

  useEffect(() => {
    let intervalId;

    if (Object.keys(GroupData).length > 0 && Object.keys(Customer).length > 0) {
      intervalId = setInterval(() => {
        getchatchat();
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [GroupData, Customer]);

  const getchatchat = () => {
    try {
      axios
        .get(
          `https://hayyyak.com/api/customer/getVendortocustomerchatchat/` +
          `${Customer?._id}` +
          `/` +
          `${GroupData?._id}`
        )
        .then(function (response) {
          setdatachat(response.data.chat);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [datachat]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="row mb-5">
          <div
            className="col-lg-3"
            style={{
              overflow: "hidden",
              overflowY: "scroll",
              height: "auto",
              maxHeight: "620px",
            }}
          >
            <>
              <Form className="d-flex mt-2">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
              </Form>

              <ul
                style={{ listStyle: "none", paddingLeft: "0px" }}
                className="chat-box"
              >
                {data?.map((item) => {
                  if (
                    SearchItem === "" ||
                    Object.values(item).some((value) =>
                      String(value)
                        .toLowerCase()
                        .includes(SearchItem.toLowerCase())
                    )
                  )
                    return (
                      <li
                        onClick={() => {
                          setGroupData(item);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              margin: "8px",
                            }}
                            src={
                              item?.avthar
                                ? `https://hayyyak.com/Customer/${item?.avthar}`
                                : "./img/profile.jpg"
                            }
                            alt=""
                          />
                          <div>
                            <span className="chat fw-semibold activity-h2">
                              {item?.name}
                            </span>{" "}
                            <br />
                          </div>
                        </div>
                      </li>
                    );
                })}
              </ul>
            </>
          </div>

          {Object.keys(GroupData).length === 0 ? (
            <div
              className="d-flex justify-content-center align-items-center m-auto flex-column"
              style={{ width: "fit-content" }}
            >
              <img
                src="./img/Logo-3-video.gif"
                alt=""
                className="animate-logo"
              />
              <p
                className="activity-h2"
                style={{
                  width: "fit-content",
                  textAlign: "center",
                  fontSize: "35px",
                }}
              >
                Communicate with Your <br /> Friends and Family
              </p>
            </div>
          ) : (
            <div className="col-lg-8">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      margin: "8px",
                    }}
                    src={
                      GroupData?.avthar
                        ? `https://hayyyak.com/Customer/${GroupData?.avthar}`
                        : "./img/profile.jpg"
                    }
                    alt=""
                  />
                  <div>
                    <span
                      style={{ fontSize: "22px" }}
                      className="fw-bold activity-h2"
                    >
                      {GroupData?.name}
                    </span>{" "}
                    <br />
                    <br />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "27px",
                    color: "red",
                    margin: "19px",
                    cursor: "pointer",
                  }}
                ></div>
              </div>

              <hr />
              <div
                className="scroll-cat"
                style={{
                  overflow: "hidden",
                  overflowY: "scroll",
                  height: "400px",
                }}
                ref={chatContainerRef}
              >
                <div className="row justify-content-between">
                  {datachat?.map((item) => {
                    return (
                      <React.Fragment key={item._id}>
                        {item?.receiver?._id === Customer?._id ? (
                          <div className="col-7 mb-3">
                            <p
                              style={{
                                backgroundColor: "#e8e8e8",
                                fontSize: "14px",
                                padding: "26px",
                                borderRadius: "10px",
                              }}
                            >
                              {item?.text}
                            </p>
                            <p
                              className="text-start"
                              style={{ fontSize: "12px" }}
                            >
                              {moment(item.createdAt).format(
                                "Do MMMM YY, hh:mm A"
                              )}
                            </p>
                          </div>
                        ) : (
                          <div className="col-7 mb-3 ms-auto">
                            <p
                              style={{
                                backgroundColor: "rgb(255 195 209)",
                                fontSize: "14px",
                                padding: "26px",
                                borderRadius: "10px",
                              }}
                            >
                              {item?.text}
                            </p>
                            <p
                              className="text-end"
                              style={{ fontSize: "12px" }}
                            >
                              {moment(item.createdAt).format(
                                "Do MMMM YY, hh:mm A"
                              )}
                            </p>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div style={{ position: "relative" }} className="mb-3">
                <input
                  value={Grouptext}
                  onChange={(e) => setGrouptext(e.target.value)}
                  type="text"
                  placeholder="Enter text here"
                  style={{ width: "100%", height: "60px", padding: "10px" }}
                />
                <span
                  onClick={() => {
                    groupsendmessage(GroupData?._id);
                  }}
                  style={{
                    position: "absolute",
                    right: "21px",
                    bottom: "10px",
                    fontSize: "27px",
                    cursor: "pointer",
                  }}
                >
                  <GrSend />
                </span>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Vendoractivitychat;
