import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    MdDashboardCustomize,
    MdOutlineFlight,
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowLeft,
    MdOutlinePriceChange,
    MdOutlineReviews,
    MdQuestionAnswer,
} from "react-icons/md";
import { IoMdChatboxes } from "react-icons/io";
import { LuLayoutDashboard } from "react-icons/lu";
import { SiPowerpages } from "react-icons/si";
import { GrServices, GrUserAdmin } from "react-icons/gr";
import { FaFileWaveform, FaHotel, FaUsersLine } from "react-icons/fa6";
import HikingIcon from "@mui/icons-material/Hiking";
import { colors } from "@mui/material";
import {
    BsFillBusFrontFill,
    BsFillChatLeftQuoteFill,
    BsFillChatTextFill,
    BsFillEnvelopeAtFill,
    BsFillPersonBadgeFill,
    BsFillTaxiFrontFill,
    BsFillTrainFrontFill,
    BsImages,
} from "react-icons/bs";
import { Collapse } from "react-bootstrap";
import {
    BiSolidContact,
    BiSolidDownArrow,
    BiSolidUpArrow,
} from "react-icons/bi";
import { AiFillHome } from "react-icons/ai";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { CgProfile } from "react-icons/cg";

function VendorHotelSide() {

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);

    const [acc, setacc] = useState(true);
    const [acc1, setacc1] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [ContactList, setContactList] = useState([]);
    const getCustomerContastlist = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/getcontactcustomer"
            );
            if (res.status == 200) {
                setContactList(res.data.getdata);
            }
        } catch (error) {
            alert(error.response.data.error);
        }
    };
    useEffect(() => {
        getCustomerContastlist();
    }, []);

    return (
        <>
            <div className="si09">
                <div style={{ borderBottom: "1px solid #fff" }}>
                    <img
                        src="./img/logo.jpeg"
                        alt="adminlogo"
                        style={{ width: "100%", height: "75px" }}
                    />
                </div>

                <ul className="side-bar">
                    <Link to="/vendor_hotel_dashboard">
                        <li
                            className={`a-ele ${acc ? "active-0" : ""}`}
                            onClick={() => {
                                setacc(true);
                            }}
                        >
                            <div className="side-bar-item-0">
                                <span>
                                    <LuLayoutDashboard style={{ fontSize: "16px" }} />
                                </span>
                                <span>Dashboard</span>
                            </div>
                        </li>
                    </Link>
                    <Link to="/vendor_hotel_subscription">
                        <li
                            className={`a-ele ${acc ? "active-0" : ""}`}
                            onClick={() => {
                                setacc(true);
                            }}
                        >
                            <div className="side-bar-item-0">
                                <span>
                                    <MdOutlinePriceChange style={{ fontSize: "16px" }} />
                                </span>
                                <span>Subscription</span>
                            </div>
                        </li>
                    </Link>
                    {/* /propertyname */}
                    {/* <Link to="/updateprofile">
                        <li
                            className={`a-ele ${acc ? "active-0" : ""}`}
                            onClick={() => {
                                setacc(true);
                            }}
                        >
                            <div className="side-bar-item-0">
                                <span>
                                    <CgProfile style={{ fontSize: "16px" }} />
                                </span>
                                <span>Update Property</span>
                            </div>
                        </li>
                    </Link> */}

                    <li className="nav-item a-ele">
                        <a className="side-bar-item" onClick={() => setOpen5(!open5)}>
                            <AiFillHome style={{ fontSize: "16px" }} />
                            <p> Stays </p>
                            {!open5 ? (
                                <i>
                                    <BiSolidDownArrow />
                                </i>
                            ) : (
                                <i>
                                    <BiSolidUpArrow />
                                </i>
                            )}
                        </a>
                        <Collapse in={open5}>
                            <ul className="side-bar-item-ul">
                                <li className="">
                                    <Link to="/vendor_add_hotel" className="">
                                        <p className="side-bar-item-2">Stays List </p>
                                    </Link>
                                    <Link to="/vendoraddrooms" className="">
                                        <p className="side-bar-item-2">Add Stays Rooms</p>
                                    </Link>
                                    <Link to="/Vendor_Booked_Stays" className="">
                                        <p className="side-bar-item-2">Booked Stays</p>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>

                    <li>
                        <div style={{ position: "fixed", left: "5%", bottom: "13px" }}>
                            <Link to={"/Vendor_stay_chat"}>
                                <IoMdChatboxes style={{
                                    fontSize: "65px",
                                    color: "white",
                                    cursor: "pointer"
                                }} />
                            </Link>

                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default VendorHotelSide

{/* <Link to="/apartmenttype" className="">
<p className="side-bar-item-2">Add Hotel</p>
</Link> */}