import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';

export const VendorRestaurantProfile = () => {

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const RestaurantVendor = JSON.parse(sessionStorage.getItem("RestaurantVendor"));

    const [show, setShow] = useState(false);

    const [Fname, setFname] = useState("");
    const [Lname, setLname] = useState("");
    const [Mobile, setMobile] = useState();
    const [Email, setEmail] = useState("");
    // Edit Vendor
    const [editData, seteditData] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (item) => { setShow(true); seteditData(item) }
    const EditRestaurantVendor = async () => {
        try {
            const config = {
                url: `/EditrestoVendor/${editData}`,
                baseURL: "https://hayyyak.com/api/admin/resto",
                method: "put",
                headers: { "Content-Type": "application/json" },
                data: {
                    Fname: Fname,
                    Lname: Lname,
                    Mobile: Mobile,
                    Email: Email
                },
            };

            const res = await axios(config);

            if (res.status === 200) {
                sessionStorage.setItem("RestaurantVendor", JSON.stringify(res.data.user));
                notify(res.data.success);
                handleClose();
            }
        } catch (error) {
            notify2(error.response.data.error);
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <h1 class="display-6 text-center mb-3">Profile <span className='text-danger'>Details</span></h1>

                <div className='mb-5'>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td className='fw-bold '>First <span className="text-danger">Name :</span> </td>
                                <td>{RestaurantVendor?.Fname} </td>
                                <td className='fw-bold '>Last <span className="text-danger">Name :</span> </td>
                                <td>{RestaurantVendor?.Lname} </td>
                            </tr>
                            <tr>
                                <td className='fw-bold '>Mobile <span className="text-danger">Number :</span> </td>
                                <td>{RestaurantVendor?.Mobile} </td>
                                <td className='fw-bold '>Email : </td>
                                <td>{RestaurantVendor?.Email} </td>
                            </tr>
                        </tbody>
                    </Table>
                    <button className='pink-btn' onClick={() => handleShow(RestaurantVendor?._id)}>Edit Profile</button>
                </div>
            </Container>

            {/* Edit Profile Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit <span className='text-danger'>Profile</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-4">
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>First Name :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setFname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Last Name:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setLname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Mobile Number:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setMobile(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Email :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditRestaurantVendor}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}
