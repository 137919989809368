import React, { useEffect, useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Country, State, City } from "country-state-city";
import parse from "html-react-parser"
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function AddToDoThingsVendor() {
  const ThingToDoVendor = JSON.parse(sessionStorage.getItem("ThingsVendor"));
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);
  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);

  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountryList(countries);
  }, []);

  useEffect(() => {
    if (Country1) {
      const countryCode = Country1.split(",")[0]; // Extracting the country code
      const states = State.getStatesOfCountry(countryCode);
      setStateList(states);
    }
  }, [Country1]);
  useEffect(() => {
    if (Country1 && State1) {
      const countryCode = Country1.split(",")[0]; // Extracting the country code
      const stateCode = State1.split(",")[0]; // Extracting the country code
      const cities = City.getCitiesOfState(countryCode, stateCode);
      setCityList(cities);
    }
  }, [Country1, State1]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ViewsubGallery, setViewsubGallery] = useState({})
  // Add Gallery Image
  const [Image, setImage] = useState(null); // Changed from "" to null
  const [Gallery, setGallery] = useState([]);
  const [imageURL2, setimageURL2] = useState(null);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]); // Update Image state with the selected file
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImage = async () => {
    if (!Image) {
      return alert("Please, Select Image");
    }
    try {
      const obj = {
        image1: Image, // Use Image state here
        imgUrl: imageURL2,
      };
      setGallery([...Gallery, obj]); // Update Gallery state properly
      setImage(null); // Reset Image state after adding to Gallery
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem2 = (val) => {
    const updatedGallery = [...Gallery];
    updatedGallery.splice(val, 1);
    setGallery(updatedGallery); // Update Gallery state after removing item
  };

  // Add Sub Packages Things to do
  const [SubPackageName, setSubPackageName] = useState("");
  const [SubPackageType, setSubPackageType] = useState("");
  const [Price, setPrice] = useState(0);
  const [AgeLimit, setAgeLimit] = useState("");
  const [Duration, setDuration] = useState("");
  const [TicketType, setTicketType] = useState("");
  const [LanguageType, setLanguageType] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Logitude, setLogitude] = useState("");
  const [Address, setAddress] = useState("");
  const [Number, setNumber] = useState();
  const [Email, setEmail] = useState("");
  const [About, setAbout] = useState("");
  const [Include, setInclude] = useState("");
  const [Expect, setExpect] = useState("");
  const [Departure, setDeparture] = useState("");
  const [Accessibility, setAccessibility] = useState("");
  const [AdditionalInfo, setAdditionalInfo] = useState("");
  const [cancellationpolicy, setCancellationpolicy] = useState("");
  const [FAQ, setFAQ] = useState("");
  const [Help, setHelp] = useState("");
  const [SubPackage, setSubPackage] = useState([]);

  const [SubPackageGallery, setSubPackageGallery] = useState([]);
  const [Image1, setImage1] = useState(null); // Changed from "" to null
  const [imageURL3, setimageURL3] = useState(null);
  const onImageChange3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage1(event.target.files[0]); // Update Image state with the selected file
      setimageURL3(URL.createObjectURL(event.target.files[0]));
    }
  };


  const AddSubPackageGalleryImage = async () => {
    if (!Image1) {
      return alert("Please, Select Image");
    }
    try {
      const obj = {
        image: Image1, // Use Image state here
        imgUrl: imageURL3,
      };
      setSubPackageGallery([...SubPackageGallery, obj]); // Update Gallery state properly
      setImage1(null); // Reset Image state after adding to Gallery
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSubGallery = (val) => {
    const updatedsubGallery = [...SubPackageGallery];
    updatedsubGallery.splice(val, 1);
    setSubPackageGallery(updatedsubGallery); // Update Gallery state after removing item
  };

  const AddSubPackages = async () => {
    try {
      if (!SubPackageName.trim()) {
        return notify("Sub-package name is required.");
      }
      if (!SubPackageType.trim()) {
        return notify("Sub-package type is required.");
      }
      if (!Price || isNaN(Price) || Price <= 0) {
        return notify("Please enter a valid price.");
      }
      if (!AgeLimit || isNaN(AgeLimit) || AgeLimit < 0) {
        return notify("Please enter a valid age limit.");
      }
      if (!Duration.trim()) {
        return notify("Duration is required.");
      }
      if (!TicketType.trim()) {
        return notify("Ticket type is required.");
      }
      if (!LanguageType.trim()) {
        return notify("Language type is required.");
      }
      if (!Latitude || isNaN(Latitude)) {
        return notify("Please enter a valid latitude.");
      }
      if (!Logitude || isNaN(Logitude)) {
        return notify("Please enter a valid longitude.");
      }
      if (!Address.trim()) {
        return notify("Address is required.");
      }
      if (!Number.trim() || !/^\d{10}$/.test(Number)) {
        return notify("Please enter a valid phone number.");
      }
      if (!Email.trim() || !/^\S+@\S+\.\S+$/.test(Email)) {
        return notify("Please enter a valid email address.");
      }
      if (!About.trim()) {
        return notify("Description is required.");
      }
      if (!Include.trim()) {
        return notify("What's included is required.");
      }
      if (!Expect.trim()) {
        return notify("What to expect is required.");
      }
      if (!Departure.trim()) {
        return notify("Departure and return details are required.");
      }
      if (!Accessibility.trim()) {
        return notify("Accessibility details are required.");
      }
      if (!AdditionalInfo.trim()) {
        return notify("Additional info is required.");
      }
      if (!cancellationpolicy.trim()) {
        return notify("Cancellation policy is required.");
      }
      if (!FAQ.trim()) {
        return notify("FAQ is required.");
      }
      if (!Help.trim()) {
        return notify("Help details are required.");
      }
      // if (!SubPackageGallery || SubPackageGallery.length === 0) {
      //   return notify("Please add at least one image to the gallery.");
      // }
      const obj = {
        visitname: SubPackageName,
        visittype: SubPackageType,
        price: Price,
        agelimit: AgeLimit,
        Duration: Duration,
        tickettype: TicketType,
        languagetype: LanguageType,
        latitude: Latitude,
        longitude: Logitude,
        address: Address,
        phonenumber1: Number,
        email1: Email,
        visitdescription: About,
        whatsinclude: Include,
        whattoexcept: Expect,
        Departureandreturn: Departure,
        accessibility: Accessibility,
        addinfo: AdditionalInfo,
        cancelpolicy: cancellationpolicy,
        Faq: FAQ,
        Help: Help,
        SubPackageGallery: SubPackageGallery,
      };
      setSubPackage([...SubPackage, obj]); // Update Gallery state properly
      // setImage(null); // Reset Image state after adding to Gallery
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem3 = (val) => {
    const updatedGallery = [...SubPackage];
    updatedGallery.splice(val, 1);
    setSubPackage(updatedGallery); // Update Gallery state after removing item
  };
  const SubPackageGalleryRemove = (val) => {

    const updatedGallery = [...SubPackage];
    updatedGallery.forEach((item) => {
      if (item.SubPackageGallery) {
        item.SubPackageGallery.splice(val, 1);
      }
    });
    // updatedGallery?.SubPackageGallery.splice(val, 1);
    setSubPackage(updatedGallery); // Update Gallery state after removing item
  };

  const [PlaceName, setPlaceName] = useState("");
  const [MoblileNo, setMoblileNo] = useState("");
  const [PropartyAdd, setPropartyAdd] = useState("");
  const [ThingstodoPincode, setThingstodoPincode] = useState("");
  const [LattitudeTTD, setLattitudeTTD] = useState("");
  const [LongitudeTTD, setLongitudeTTD] = useState("");
  const [PropertyLink, setPropertyLink] = useState("");
  const [ThingatodoEmail, setThingatodoEmail] = useState("");
  const AddThingsToDo = async () => {

    if (!PlaceName) {
      return alert("Please enter the place name.");
    }
  
    if (!MoblileNo) {
      return alert("Please enter the mobile number.");
    } else if (!/^\d{10}$/.test(MoblileNo)) {
      return alert("Mobile number must be 10 digits.");
    }
  
    if (!Country1) {
      return alert("Please select the country.");
    }
  
    if (!State1) {
      return alert("Please select the state.");
    }
  
    if (!City1) {
      return alert("Please select the city.");
    }
  
    if (!PropartyAdd) {
      return alert("Please enter the property address.");
    }
  
    if (!ThingstodoPincode) {
      return alert("Please enter the pincode.");
    }
  
    if (!LattitudeTTD) {
      return alert("Please enter the latitude.");
    }
  
    if (!LongitudeTTD) {
      return alert("Please enter the longitude.");
    }
  
    if (!PropertyLink) {
      return alert("Please enter the property link.");
    }
  
    if (!ThingatodoEmail) {
      return alert("Please enter the email address.");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ThingatodoEmail)) {
      return alert("Please enter a valid email address.");
    }
  
    if (SubPackage?.length === 0) {
      return alert("Please fill the subpackage.");
    }
    try {
      const obj = {
        vendorthingsid: ThingToDoVendor?._id,
        thingstodoname: PlaceName,
        phonenumber: MoblileNo,
        thingstodocountry: Country1,
        thingstodostate: State1,
        thingstodocity: City1,
        thingstodoaddress: PropartyAdd,
        pincode: ThingstodoPincode,
        latitude: LattitudeTTD,
        longitude: LongitudeTTD,
        website: PropertyLink,
        email: ThingatodoEmail,
        visitpackages: SubPackage,
      }
      const ThingsToDo ={
        url:"addthingstodo",
        baseURL:"https://hayyyak.com/api/admin/thingstodo/",
        method:"post",
        headers:{"content-type":"application/json"},
        data:obj,
      }
      const res = await axios(ThingsToDo);
      if (res.status === 200) {
        notify(res.data.msg);
        window.location.assign("/vendor_addthings_todo")
      }
    } catch (error) {
      notify(error.response.data.error)
    }
  }
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center pt-4 pb-2 mb-3">
          <p className="text-danger fs-4 fw-semibold">: Add Things To Do :</p>
        </div>

        <div className="row">
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">Place Name :</label>
            <input
              type="text"
              placeholder="Place Name"
              className="vi_0"
              onChange={(e) => setPlaceName(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">Mobile Number :</label>
            <input
              type="text"
              placeholder="Number"
              className="vi_0"
              onChange={(e) => setMoblileNo(e.target.value)}
              maxLength={10}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">Country :</label>
            <Form.Select
              onChange={(e) => setCountry1(e.target.value)}
              className="vi_0"
            >
              <option>Select</option>
              {CountryList?.map((country) => {
                return (
                  <option value={`${country?.isoCode},${country?.name}`}>
                    {country?.name}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">State :</label>
            <Form.Select
              onChange={(e) => setState1(e.target.value)}
              className="vi_0"
            >
              <option>Select</option>
              {StateList?.map((state) => {
                return (
                  <option value={`${state?.isoCode},${state?.name}`}>
                    {state?.name}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">City :</label>
            <Form.Select
              onChange={(e) => setCity1(e.target.value)}
              className="vi_0"
            >
              <option>Select</option>
              {CityList?.map((city) => {
                return <option value={city?.name}>{city?.name}</option>;
              })}
            </Form.Select>
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Porperty Address :</label>
            <input
              type="text"
              placeholder="Porperty Address"
              className="vi_0"
              onChange={(e) => setPropartyAdd(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Pincode :</label>
            <input
              type="text"
              placeholder="Enter Pincode"
              className="vi_0"
              onChange={(e) => setThingstodoPincode(e.target.value)}
              maxLength={6}
            />
          </div>

          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Latitude :</label>
            <input
              type="text"
              placeholder="Latitude "
              className="vi_0"
              onChange={(e) => setLattitudeTTD(e.target.value)}
            />
          </div>

          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Logitude :</label>
            <input
              type="text"
              placeholder="Logitude"
              className="vi_0"
              onChange={(e) => setLongitudeTTD(e.target.value)}
            />
          </div>

          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">Property Location(MAP Link) :</label>
            <input
              type="Link"
              placeholder="paste Link"
              className="vi_0"
              onChange={(e) => setPropertyLink(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold">Email :</label>
            <input
              type="email"
              placeholder="enter email"
              className="vi_0"
              onChange={(e) => setThingatodoEmail(e.target.value)}
            />
          </div>

          {/* <div className="d-flex gap-3">
        <div className="col-lg-7  mt-4">
          <Table className="sdfsd-table-head" bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Gallery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        src={item?.imgUrl}
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </td>
                    <td className="text-danger fs-5">
                      <MdDelete
                        style={{ cursor: "pointer" }}
                        onClick={() => removeItem2(i)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className=" col-lg-5 d-flex align-items-center">
          <div>
            <label className="fw-bold">Gallery Image :</label>
            <input
              type="file"
              className="vi_0"
              onChange={(e) => {
                onImageChange2(e);
                setImage(e.target.files[0]);
              }}
            />
          </div>
          <button className="pink-btn" onClick={AddGalleryImage}>
            Add
          </button>
        </div>
      </div> */}

          <div className="d-flex justify-content-center align-items-center pt-4 pb-2 mb-3">
            <p className="text-danger fs-4 fw-semibold">
              -: Sub Packages Add Things To Do :-
            </p>
          </div>

          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Package name :</label>
            <input
              type="text"
              placeholder="Package name"
              className="vi_0"
              onChange={(e) => setSubPackageName(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Package type :</label>
            <input
              type="text"
              placeholder="Package type"
              className="vi_0"
              onChange={(e) => setSubPackageType(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Package Price :</label>
            <input
              type="number"
              placeholder="Package price"
              className="vi_0"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Age Limit :</label>
            <input
              type="text"
              placeholder="Age Limit"
              className="vi_0"
              onChange={(e) => setAgeLimit(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Duration:</label>
            <input
              type="text"
              placeholder="Duration"
              className="vi_0"
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Ticket Type:</label>
            <input
              type="text"
              placeholder="ticket type"
              className="vi_0"
              onChange={(e) => setTicketType(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Language Type:</label>
            <input
              type="text"
              placeholder="language type"
              className="vi_0"
              onChange={(e) => setLanguageType(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Latitude:</label>
            <input
              type="text"
              placeholder="Latitude"
              className="vi_0"
              onChange={(e) => setLatitude(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Longitude:</label>
            <input
              type="text"
              placeholder="Longitude"
              className="vi_0"
              onChange={(e) => setLogitude(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Address:</label>
            <input
              type="text"
              placeholder="Address"
              className="vi_0"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Phone Number:</label>
            <input
              type="number"
              placeholder="phone number"
              className="vi_0"
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div className=" mt-2 col-lg-4">
            <label className="fw-bold"> Email:</label>
            <input
              type="email"
              placeholder="email"
              className="vi_0"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">About :</label>
            <CKEditor
              editor={ClassicEditor}
              data={About}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAbout(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">What's Included :</label>
            <CKEditor
              editor={ClassicEditor}
              data={Include}
              onChange={(event, editor) => {
                const data = editor.getData();
                setInclude(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">What to Expect :</label>
            <CKEditor
              editor={ClassicEditor}
              data={Expect}
              onChange={(event, editor) => {
                const data = editor.getData();
                setExpect(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">Departure Return :</label>
            <CKEditor
              editor={ClassicEditor}
              data={Departure}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDeparture(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">Accessibility :</label>
            <CKEditor
              editor={ClassicEditor}
              data={Accessibility}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAccessibility(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">Additional Information :</label>
            <CKEditor
              editor={ClassicEditor}
              data={AdditionalInfo}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdditionalInfo(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">Cancellation policy :</label>
            <CKEditor
              editor={ClassicEditor}
              data={cancellationpolicy}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCancellationpolicy(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">FAQ :</label>
            <CKEditor
              editor={ClassicEditor}
              data={FAQ}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFAQ(data);
              }}
            />
          </div>
          <div className=" mt-2 col-lg-6">
            <label className="fw-bold">Help :</label>
            <CKEditor
              editor={ClassicEditor}
              data={Help}
              onChange={(event, editor) => {
                const data = editor.getData();
                setHelp(data);
              }}
            />
          </div>

          <div className="col-lg-6 mt-4"></div>

          {/* <div className="col-lg-6  mt-4">
        <Table className="sdfsd-table-head" bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {SubPackageGallery?.map((item, i) => {
              return(
                <tr>
                <td>{i+1}.</td>
                <td>
                  <img
                    src={item?.imgUrl}
                    alt=""
                    style={{ width: "80px", height: "80px" }}
                  />
                </td>
                <td className="text-danger fs-5">
                  <MdDelete
                  style={{color:"red",cursor:"pointer"}}
                  onClick={()=>deleteSubGallery(i)}
                   />
                </td>
              </tr>
              )
            
            })}
          </tbody>
        </Table>
      </div>

      <div className=" col-lg-6 d-flex align-items-start mt-4">
        <div>
          <label className="fw-bold">Gallery Images :</label>
          <input
            type="file"
            accept="image/*"
            className="vi_0"
            onChange={(e) => {
              onImageChange3(e);
              setImage1(e.target.files[0]);
            }}
            multiple
          />
        </div>
        <button
          className="pink-btn"
          onClick={AddSubPackageGalleryImage}
        >
          Add
        </button>
      </div> */}

          <div className="d-flex justify-content-center align-items-center pt-4 pb-2 mb-3">
            <p className="text-danger fs-4 fw-semibold">
              <Button onClick={AddSubPackages}>ADD</Button>
            </p>
          </div>

          <div
            className="mb-3"
            style={{ overflow: "hidden", overflowX: "scroll" }}
          >
            <Table className="sdfsd-table-head" bordered>
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Package Name</th>
                  <th>Package Type</th>
                  <th>Package price</th>
                  <th>Age Limit</th>
                  <th>Duration</th>
                  <th>Ticket Type</th>
                  <th>Language Type</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Phone No</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>About</th>
                  <th>Include</th>
                  <th>Departure</th>
                  <th>Accessibility</th>
                  <th>Additional Information</th>
                  <th>FAQ</th>
                  <th>Help</th>
                  {/* <th>Gallery</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {SubPackage?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.visitname}</td>
                      <td>{item?.visittype}</td>
                      <td>{item?.price}</td>
                      <td>{item?.agelimit}</td>
                      <td>{item?.Duration}</td>
                      <td>{item?.tickettype}</td>
                      <td>{item?.languagetype}</td>
                      <td>{item?.latitude}</td>
                      <td>{item?.longitude}</td>
                      <td>{item?.phonenumber1}</td>
                      <td>{item?.address}</td>
                      <td>{item?.email1}</td>
                      <td>{parse(`<div>${item?.visitdescription}</div>`)}</td>
                      <td>{parse(`<div>${item?.whatsinclude}</div>`)}</td>
                      <td>{parse(`<div>${item?.Departureandreturn}</div>`)}</td>
                      <td>{parse(`<div>${item?.accessibility}</div>`)}</td>
                      <td>{parse(`<div>${item?.addinfo}</div>`)}</td>
                      <td>{parse(`<div>${item?.Faq}</div>`)}</td>
                      <td>{parse(`<div>${item?.Help}</div>`)}</td>
                      {/* <td><FiEye
               style={{color:"green"}}
               onClick={()=>{
                handleShow();
                setViewsubGallery(item);
              }
              }
               /></td> */}
                      <td>
                        <MdDelete style={{ color: "red", cursor: "pointer" }}
                          onClick={() => removeItem3(i)}
                        />
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-center align-items-center pt-4 pb-2 mb-3">
            <p className="text-danger fs-4 fw-semibold">
              <Button onClick={AddThingsToDo}>SUBMIT </Button>
            </p>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="sdfsd-table-head text-center " bordered>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ViewsubGallery?.SubPackageGallery?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={item?.imgUrl}
                          alt=""
                          style={{ width: "80px", height: "80px" }}
                        />
                      </td>
                      <td className="text-danger fs-5 d-flex gap-5 justify-content-center">
                        <MdDelete
                          style={{ cursor: "pointer" }}
                          onClick={() => (SubPackageGalleryRemove(i))}
                        />


                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  )
}

export default AddToDoThingsVendor