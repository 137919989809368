import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { MdDirections } from 'react-icons/md';
import Carousel from "react-multi-carousel";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

export const RestaurantandCafeDetails = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();
    const { state: item } = location;


    const openMap = (latitude, longitude) => {
        const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(mapUrl, '_blank');
      };
    return (
        <div>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} swipeable={true}>
                {item?.Gallery?.map((item) => {
                    return (
                        <div>
                            <img src={`https://hayyyak.com/Restoandcafes/${item?.image1}`} alt="" className='w-100' style={{ height: '300px' }} />
                        </div>
                    )
                })}
                {/* <div><img src="./img/cafe-1.avif" alt="" className='w-100' style={{ height: '300px' }} /></div>
                <div><img src="./img/cafe-2.avif" alt="" className='w-100' style={{ height: '300px' }} /></div>
                <div><img src="./img/cafe-3.avif" alt="" className='w-100' style={{ height: '300px' }} /></div>
                <div><img src="./img/cafe-2.avif" alt="" className='w-100' style={{ height: '300px' }} /></div> */}
            </Carousel>
            <Container>
                <div className="row">
                    <div className='col-lg-8 mb-3'>
                        <p className='fw-semibold fs-2'>{item?.restoname}</p>
                        <p className='text-muted fs-5'>{parse(`<div>${item?.kitchentype}</div>`)}</p>
                        <p className='text-secondary fs-5'>{item?.Address},{item?.city},{item?.state},{item?.Country}</p>
                        <div className="flex gap-4 mb-3">
                            <p className='text-danger'>Open : </p>
                            <p>{parse(`<div>${item?.restoopens}</div>`)}</p>
                        </div>

                        <div>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example" className='cafe_tabss'>
                                            <Tab label="Overview" value="1" className='fs-5' />
                                            <Tab label="Photos" value="2" className='fs-5' />
                                            <Tab label="Menu" value="3" className='fs-5' />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <p className='fw-normal fs-5 mb-3 text-dark'>About This Place</p>
                                        <div className='cusins mb-4  '>
                                            <p className='mb-2 text-danger text-uppercase'>Cuisines :</p>
                                            <div className="flex flex-wrap gap-3">
                                                <span className='text-muted'>{parse(`<div>${item?.kitchentype}</div>`)}</span>
                                                {/* <span className='text-muted'>North Indian,</span>
                                                <span className='text-muted'>Pasta,</span>
                                                <span className='text-muted'>Asian,</span> */}
                                            </div>
                                        </div>
                                        <div className='cusins mb-4 ' id='popular-dishes'>
                                            <p className='mb-2 text-danger text-uppercase'>Popular Dishes :</p>
                                            <span className='fs-6 text-muted'>{parse(`<div>${item?.populardishes}</div>`)}</span>
                                        </div>
                                        <div className='cusins mb-4 ' >
                                            <p className='mb-2 text-danger text-uppercase'>People Say This Place Is Known For :</p>
                                            <span className='fs-6 text-muted text-end '>{parse(`<div>${item?.placeknownfor}</div>`)}</span>
                                        </div>
                                        <div className='cusins '>
                                            <p className='mb-2 text-danger text-uppercase'>Facilities :</p>
                                            <span className='fs-6 text-muted'>{parse(`<div>${item?.Facilities}</div>`)}</span>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <p className='fw-normal fs-5 mb-3 text-dark'>{item?.restoname}</p>
                                        <div className="row">
                                            {item?.Gallery?.map((item) => {
                                                return (
                                                    <div className="col-lg-4 cafe_food_img">
                                                        <img src={`https://hayyyak.com/Restoandcafes/${item?.image1}`} alt="" />
                                                    </div>
                                                )
                                            })}
                                            {/* <div className="col-lg-4 cafe_food_img">
                                                <img src="./img/cafe-1.avif" alt="" />
                                            </div>
                                            <div className="col-lg-4 cafe_food_img">
                                                <img src="./img/cafe-2.avif" alt="" />
                                            </div>
                                            <div className="col-lg-4 cafe_food_img">
                                                <img src="./img/cafe-3.avif" alt="" />
                                            </div> */}
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <p className='fw-normal fs-5 mb-3 text-dark'>{item?.restoname}</p>
                                        <div className="row">
                                            {item?.Menu?.map((item) => {
                                                return (
                                                    <div className="col-lg-4 cafe_food_img">

                                                        <a href="./img/menu-1.avif" target="_blank" rel="noopener noreferrer">
                                                            <img src={`https://hayyyak.com/Restoandcafes/${item?.image}`} alt="" />
                                                        </a>
                                                    </div>
                                                )
                                            })}

                                            {/* <div className="col-lg-4 cafe_food_img">
                                                 <a href="./img/menu-1.avif" target="_blank" rel="noopener noreferrer">
                                                   <img src="./img/menu-1.avif" alt="" />
                                                 </a>
                                             </div>
                                            <div className="col-lg-4 cafe_food_img">
                                                 <a href="./img/menu-2.avif" target="_blank" rel="noopener noreferrer"><img src="./img/menu-2.avif" alt="" /></a>
                                            </div>
                                             <div className="col-lg-4 cafe_food_img">
                                                <a href="./img/menu-3.avif" target="_blank" rel="noopener noreferrer"><img src="./img/menu-3.avif" alt="" /></a>
                                             </div> */}
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-2">
                        <div className='cafe_img'>
                            <p className='fw-normal fs-4'>Call</p>
                            <p className='text-danger fs-5 mb-2'>+9{item?.mobile}</p>
                            <p className='fw-normal fs-4'>Direction</p>
                            <p className='mb-2 text-muted'>{item?.Address},{item?.city},{item?.state}</p>
                            <button 
                            onClick={()=>openMap(item?.Latitude, item?.Longitude)}
                            className='pink-btn__2'><MdDirections className='fs-4' />Direction</button>
                        </div>
                    </div>

                </div>


            </Container>
        </div>
    )
}
