import React from 'react'
import { Container } from 'react-bootstrap'
import VendorHotelHeader from './VendorHotelHeader'
import VendorHotelSide from './VendorHotelSide'

function VendorHotelComman(props) {
    return (
        <>
            <Container fluid >
                <div className="dash">
                    <div className="d-flex me-0">
                        <div className=" p-0" >
                            <div className="left-side">
                                <VendorHotelSide />
                            </div>
                        </div>

                        <div className=" p-0 right-h" style={{ width: '83%' }}>
                            <VendorHotelHeader />
                            {props.children}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default VendorHotelComman