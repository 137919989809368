import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import HikingIcon from "@mui/icons-material/Hiking";
import { Collapse } from "react-bootstrap";
import {
    BiSolidContact,
    BiSolidDownArrow,
    BiSolidUpArrow,
} from "react-icons/bi";
import axios from "axios";
import { IoRestaurantSharp } from "react-icons/io5";
import { MdOutlinePriceChange } from "react-icons/md";

function V_Restaurant_Side() {

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);

    const [acc, setacc] = useState(true);
    const [acc1, setacc1] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [ContactList, setContactList] = useState([]);
    const getCustomerContastlist = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/getcontactcustomer"
            );
            if (res.status == 200) {
                setContactList(res.data.getdata);
            }
        } catch (error) {
            alert(error.response.data.error);
        }
    };
    useEffect(() => {
        getCustomerContastlist();
    }, []);
    return (
        <>
            <div className="si09">
                <div style={{ borderBottom: "1px solid #fff" }}>
                    <img
                        src="./img/logo.jpeg"
                        alt="adminlogo"
                        style={{ width: "100%", height: "75px" }}
                    />
                </div>

                <ul className="side-bar">
                    <Link to="/vendor_restaurant_dashboard">
                        <li
                            className={`a-ele ${acc ? "active-0" : ""}`}
                            onClick={() => {
                                setacc(true);
                            }}
                        >
                            <div className="side-bar-item-0">
                                <span>
                                    <LuLayoutDashboard style={{ fontSize: "16px" }} />
                                </span>
                                <span>Dashboard</span>
                            </div>
                        </li>
                    </Link>
                    <Link to="/vendor_restaurant_subscription">
                        <li
                            className={`a-ele ${acc ? "active-0" : ""}`}
                            onClick={() => {
                                setacc(true);
                            }}
                        >
                            <div className="side-bar-item-0">
                                <span>
                                    <MdOutlinePriceChange style={{ fontSize: "16px" }} />
                                </span>
                                <span>Subscription</span>
                            </div>
                        </li>
                    </Link>

                    <Link to="/Vendor_Restaurant_Cafe">
                        <li
                            className={`a-ele ${acc ? "active-0" : ""}`}
                            onClick={() => {
                                setacc(true);
                            }}
                        >
                            <div className="side-bar-item">
                                <span>
                                    <IoRestaurantSharp style={{ fontSize: "16px" }} />
                                </span>
                                <span>Restaurant / Cafe</span>
                            </div>
                        </li>
                    </Link>


                </ul>
            </div>
        </>
    )
}

export default V_Restaurant_Side