import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VTaxiLogin() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.error(data);
    const notify2 = (data) => toast.warn(data);

    const [show, setShow] = useState();
    const [show1, setShow1] = useState();
    const [show2, setShow2] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [VTaxiEmail, setVTaxiEmail] = useState("");
    const [VTaxiPassword, setVTaxiPassword] = useState("");

    const VendorTaxiLogin = async () => {
        if (!VTaxiEmail && !VTaxiPassword) {
            notify2("Please fill all the fields");
        } else {
            try {
                const config = {
                    url: "/vendortaxilogin",
                    method: "post",
                    baseURL: "https://hayyyak.com/api/vendor",
                    data: {
                        Email: VTaxiEmail,
                        Password: VTaxiPassword,
                    },
                };
                await axios(config).then(function (res) {
                    console.log("res.status", res.status);
                    if ((res.status = 200)) {
                        notify("Login successfully");
                        sessionStorage.setItem("user", JSON.stringify(res.data.user));
                        window.location.assign("/vendor_activity_dashboard");
                    } else {
                        notify1(res.data.error);
                    }
                });
            } catch (error) {
                console.log("error", error);
                notify1(error.response.data.error);
            }
        }
    };

    return (
        <div>
            <div
                style={{
                    backgroundImage: 'url("./img/header-bg-img-3.jpg")',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "100vh",
                    width: "100vw",
                    padding: "68px",
                }}
            >
                <ToastContainer
                    position="top-center"
                    autoClose={30000}
                    closeOnClick
                    pauseOnHover
                    draggable
                />
                <Container>
                    <div className="login-bg">
                        <div className="login mb-4">
                            <h4>
                                Sign <span className="text-danger"> In</span>
                            </h4>
                            <p className="text-secondary"> <span className="text-danger"> Vendor Taxi </span> Enter Your Details </p>
                        </div>

                        <div className="mb-4">
                            <InputGroup className="mb-4">
                                <Form.Control
                                    className="login-input"
                                    type="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setVTaxiEmail(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup>
                                <Form.Control
                                    className="login-input"
                                    type="password"
                                    placeholder="Password"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setVTaxiPassword(e.target.value)}
                                />
                            </InputGroup>
                        </div>

                        <div className="mb-4">
                            <Button
                                className="header-search"
                                style={{ width: "100%" }}
                                onClick={VendorTaxiLogin}
                            >
                                Submit
                            </Button>
                        </div>

                        <div className="text-center mb-4">
                            <p>
                                <span
                                    onClick={handleShow}
                                    style={{ color: "#d81d4a", cursor: "pointer" }}
                                >
                                    Forgot Password
                                </span>{" "}
                                | Are you a new user ? |{" "}
                                <a
                                    href="/V_Taxi_Register"
                                    style={{
                                        color: "#d81d4a",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                    }}
                                >
                                    Register
                                </a>
                            </p>
                        </div>
                    </div>
                </Container>
            </div>

            {/* Forgot Password Modal */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="login mb-4">
                        <h4>
                            Forgot <span className="text-danger">Password</span>
                        </h4>
                        <p className="text-secondary">Enter Your Email</p>
                    </div>

                    <InputGroup className="mb-4">
                        <Form.Control
                            className="login-input"
                            type="email"
                            placeholder="Email Id"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <div className="mb-4">
                        <Button
                            onClick={handleShow1}
                            className="header-search"
                            style={{ width: "100%" }}
                        >
                            Send Mail
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* OTP Modal */}

            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="login mb-4">
                        <h4>
                            Enter Your <span className="text-danger">OTP</span>
                        </h4>
                    </div>
                    <InputGroup className="mb-4">
                        <Form.Control
                            className="login-input"
                            type="text"
                            placeholder="Enter OTP"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <div className="mb-4">
                        <Button
                            onClick={handleShow2}
                            className="header-search"
                            style={{ width: "100%" }}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* New Password */}

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="login mb-4">
                        <h4>
                            Enter New <span className="text-danger"> Password </span>
                        </h4>
                    </div>
                    <InputGroup className="mb-4">
                        <Form.Control
                            className="login-input"
                            type="password"
                            placeholder="New Password"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <InputGroup className="mb-4">
                        <Form.Control
                            className="login-input"
                            type="password"
                            placeholder="Confirm Password"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <div className="mb-4">
                        <Button className="header-search" style={{ width: "100%" }}>
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default VTaxiLogin;
