import React, { useEffect, useState } from 'react'
import {Container} from 'react-bootstrap'
import HikingIcon from '@mui/icons-material/Hiking';
import axios from 'axios';
function V_Things_Dashboard() {
    const ThingToDoVendor = JSON.parse(sessionStorage.getItem("ThingsVendor"));
    const [ThingatoDo, setThingatoDo] = useState([]);
    const GetAdminThingsTodo = async () => {
      try {
        const res = await axios.get(
          "https://hayyyak.com/api/admin/thingstodo/getthingstodonamebyId/" + ThingToDoVendor?._id
        );
        if (res.status === 200) {
          setThingatoDo(res.data.Thingsdetails);
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      GetAdminThingsTodo();
    }, []);

    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-5 text-light p-3 mb-3"
                    style={{
                        backgroundColor: "rgb(71 171 21)",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold"> Things To Do</h6>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <HikingIcon className="fs-2" />
                        <span className="fs-2 fw-bold">{ThingatoDo?.length}</span>
                    </div>
                    <p>{ThingatoDo?.length} Booking of Activity</p>
                </div>
            </div>
        </Container>
    )
}

export default V_Things_Dashboard