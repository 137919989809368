import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useEffect } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'

export const EditNewStay = () => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [seleceCategory, setseleceCategory] = useState([]);

    const handleSelectCategory = (event) => {
        const {
            target: { value },
        } = event;
        setseleceCategory(value);
    };
    const [selectedAmenities, setSelectedAmenities] = useState([]);

    const handleSelectChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedAmenities(value);
    };

    const location = useLocation();
    const Update = location.state?.data;

    console.log("Update", Update);

    const [PropartyName, setPropartyName] = useState("");
    const [PropartyAddress, setPropartyAddress] = useState("");
    const [Location, setlocation] = useState("");
    const [StartingPrice, setStartingPrice] = useState("");
    const [Totalrooms, setTotalrooms] = useState("");
    const [Property, setProperty] = useState("");
    const [Checkin, setCheckin] = useState("");
    const [CheckOut, setCheckOut] = useState("");
    const [Description, setDescription] = useState("");
    const [termsandcondition, settermsandcondition] = useState("");
    const [Suggesition, setSuggesition] = useState("");
    const [Propartyfullimage, setPropartyfullimage] = useState("");
    const [NearByLoc, setNearByLoc] = useState("");
    const [HouseRules, setHouseRules] = useState("");
    const [ThingToDo, setThingToDo] = useState("");

    const UpdateStaysDetails = async () => {
        const formdata = new FormData();
        try {
            formdata.append("propartyname", PropartyName);
            formdata.append("propartyaddress", PropartyAddress);
            formdata.append("location", Location);
            formdata.append("startingprice", StartingPrice);
            formdata.append("totalroom", Totalrooms);
            formdata.append("porparty", Property);
            formdata.append("checkin", Checkin);
            formdata.append("checkout", CheckOut);
            formdata.append("description", Description);
            formdata.append("termsandcondition", termsandcondition);
            formdata.append("suggesition", Suggesition);
            formdata.append("propartfullimage", Propartyfullimage);
            formdata.append("staysId", Update?._id);
            formdata.append("Amenities", JSON.stringify(selectedAmenities));
            formdata.append("StaysCategory", JSON.stringify(seleceCategory));
            const config = {
                url: "/vendor/stays/updatestaydetails/" + Update?._id,
                baseURL: "https://hayyyak.com/api",
                method: "put",
                headers: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            const response = await axios(config);
            if (response.status === 200) {
                alert("Updated Successfully");
                window.location.assign("/vendor_add_hotel");
            }
        } catch (error) {
            alert(error.response.data.error);
        }
    };

    // Get Stays Category
    const [StayCategory, setStayCategory] = useState([]);
    const getStayCategory = async () => {
        axios
            .get("https://hayyyak.com/api/admin/stays/getstaycategory")
            .then(function (res) {
                console.log(res.data);
                setStayCategory(res.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // get Stay Amenities
    const [StayAmenities, setStayAmenities] = useState([]);
    const getStayAmenities = async () => {
        axios
            .get("https://hayyyak.com/api/admin/stays/getstayamenities")
            .then(function (response) {
                setStayAmenities(response.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        getStayCategory();
        getStayAmenities();
    }, []);


    return (
        <div>
            <Container>
                <div >
                    <h1 class="display-6 text-center mb-3">Edit <span className='text-danger'>Property</span></h1>
                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Name:</label>
                            <input
                                type="text"
                                className='form-control login-input'
                                placeholder={Update?.propartyname}
                                onChange={(e) =>
                                    setPropartyName(e.target.value)
                                }
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Address :</label>
                            <input
                                type="text"
                                className='form-control login-input'
                                placeholder={Update?.propartyaddress}
                                onChange={(e) =>
                                    setPropartyAddress(e.target.value)
                                }
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Location (Link):</label>
                            <input
                                type="text"
                                className='form-control login-input'
                                placeholder={Update?.location}
                                onChange={(e) => setlocation(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Starting Price  :</label>
                            <input
                                type="text"
                                placeholder={Update?.startingprice}
                                className='form-control login-input'
                                onChange={(e) =>
                                    setStartingPrice(e.target.value)
                                }
                            />
                        </div>


                    </div>

                    <div className="row mb-4 ">
                        <div className="col-lg-3">
                            <div>

                                <label className="fw-bold">Amenities :</label>
                                <FormControl sx={{ m: 1, width: 245, height: 43 }}>
                                    <InputLabel id="demo-multiple-checkbox-label">
                                        Amenities
                                    </InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedAmenities}
                                        onChange={handleSelectChange}
                                        input={<OutlinedInput label="Amenities" />}
                                        renderValue={(selected) => selected.map((amenity) => amenity.AmenitiName).join(", ")}
                                    >
                                        {StayAmenities?.map((amenity) => (
                                            <MenuItem key={amenity._id} value={amenity}>
                                                <Checkbox checked={selectedAmenities.some((selected) => selected._id === amenity._id)} />
                                                <ListItemText primary={amenity.AmenitiName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </div>
                        </div>

                        <div className="col-lg-3">
                            <label className="fw-bold">Category :</label>
                            <FormControl sx={{ m: 1, width: 245, height: 43 }}>
                                <InputLabel id="demo-multiple-checkbox-label">
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={seleceCategory}
                                    onChange={handleSelectCategory}
                                    input={<OutlinedInput label="Category" />}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) => selected.map((category) => category.Category).join(", ")}
                                >
                                    {StayCategory?.map((category) => (
                                        <MenuItem key={category?._id} value={category}>
                                            <Checkbox checked={seleceCategory?.some((selected) => selected._id === category._id)} />
                                            <ListItemText primary={category?.Category} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Total Rooms:</label>
                            <input
                                type="text"
                                placeholder={Update?.totalroom}
                                className='form-control login-input'
                                onChange={(e) =>
                                    setTotalrooms(e.target.value)
                                }
                            />
                        </div>

                        <div className="col-lg-3">
                            <label className='fw-bold'>Single / Multiple Property :</label>
                            <Form.Select
                                onChange={(e) => setProperty(e.target.value)}
                                aria-label="Default select example" className='login-input'>
                                <option>Select </option>
                                <option value="Single Property">Single Property</option>
                                <option value="Multiple Property">Multiple Property</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className='mb-4 row'>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Check In Time:</label>
                            <input
                                onChange={(e) => setCheckin(e.target.value)}
                                placeholder={Update?.checkin}
                                type="time" className='form-control login-input' />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Check Out Time :</label>
                            <input
                                placeholder={Update?.checkout}

                                onChange={(e) => setCheckOut(e.target.value)}
                                type="time" className='form-control login-input' />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Property Descritpion:</label>
                            <CKEditor
                                data={Description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                }}
                                editor={ClassicEditor} />
                        </div>
                        <div className="col-lg-6 ">
                            <label className='fw-bold'>Terms & Conditions:</label>
                            <CKEditor
                                data={termsandcondition}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    settermsandcondition(data);
                                }}
                                editor={ClassicEditor} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Suggestion:</label>
                            <CKEditor
                                data={Suggesition}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSuggesition(data);
                                }}
                                editor={ClassicEditor} />
                        </div>
                        <div className="col-lg-6 mb-2 ">
                            <label className="fw-bold">
                                Near By Location:
                            </label>
                            <CKEditor
                                data={NearByLoc}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setNearByLoc(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">House Rules:</label>
                            <CKEditor
                                data={HouseRules}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHouseRules(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">Things To Do:</label>
                            <CKEditor
                                data={ThingToDo}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setThingToDo(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div>
                                    <label className='fw-bold'>Property Full Image :</label>
                                    <input
                                        onChange={(e) =>
                                            setPropartyfullimage(e.target.files[0])
                                        }
                                        type="file" multiple className='form-control login-input' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <button
                    className='pink-btn mb-3'
                    onClick={() => UpdateStaysDetails()}
                >Save Changes</button>
            </Container>
        </div>
    )
}
