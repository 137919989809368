import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { Country, State, City } from "country-state-city";
import { useEffect } from 'react'
import parse from "html-react-parser"
import { useLocation } from 'react-router-dom'

const EditHotel = () => {

    const location1 = useLocation()
    const { item } = location1.state
    console.log("item,,", item);

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);
    //Location
    const [Country1, setCountry1] = useState("")
    const [State1, setState1] = useState("")
    const [City1, setCity1] = useState("")
    const CountryList = Country.getAllCountries();
    const StateList = State.getStatesOfCountry(Country1);
    const CityList = City.getCitiesOfState(Country1, State1);

    const [Aadhar, setAadhar] = useState("");
    const [PanCard, setPanCard] = useState("");
    const [Gst, setGst] = useState("");
    const [GstDocument, setGstDocument] = useState("");
    const [PropartyDoc, setPropartyDoc] = useState("");
    const [PropartyName, setPropartyName] = useState("");
    const [PropartyAddress, setPropartyAddress] = useState("");
    const [location, setlocation] = useState("");
    const [StartingPrice, setStartingPrice] = useState("");
    const [Checkin, setCheckin] = useState("");
    const [CheckOut, setCheckOut] = useState("");
    const [Description, setDescription] = useState("");
    const [termsandcondition, settermsandcondition] = useState("");
    const [Suggesition, setSuggesition] = useState("");
    const [Propartyfullimage, setPropartyfullimage] = useState("");
    const [NearByLoc, setNearByLoc] = useState("");
    const [HouseRules, setHouseRules] = useState("");
    const [ThingToDo, setThingToDo] = useState("");

    const EditStaysDetails = async () => {
        const formdata = new FormData();
        try {
            formdata.append("aadhar", Aadhar);
            formdata.append("pancard", PanCard);
            formdata.append("gst", Gst);
            formdata.append("gstdocument", GstDocument);
            formdata.append("propartydoc", PropartyDoc);
            formdata.append("propartyname", PropartyName);
            formdata.append("propartyaddress", PropartyAddress);
            formdata.append("location", location);
            formdata.append("startingprice", StartingPrice);
            formdata.append("checkin", Checkin);
            formdata.append("checkout", CheckOut);
            formdata.append("description", Description);
            formdata.append("termsandcondition", termsandcondition);
            formdata.append("suggesition", Suggesition);
            formdata.append("propartfullimage", Propartyfullimage);
            formdata.append("adminId", "admin");
            formdata.append("country", Country1);
            formdata.append("state", State1);
            formdata.append("city", City1);
            formdata.append("Nearbyplaces", NearByLoc);
            formdata.append("Houserules", HouseRules);
            formdata.append("ThingToDo", ThingToDo);
            const config = {
                url: "/vendor/stays/updatestaydetails/" + item?._id,
                baseURL: "https://hayyyak.com/api",
                method: "put",
                headers: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            const response = await axios(config);
            if (response.status === 200) {
                notify("Stays Edited Successfully");
                setTimeout(() => {
                    return window.location.assign('/stayvendorlist');
                }, 1000)
            }
        } catch (error) {
            notify2(error.response.data.error);
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <div >
                    <h1 class="display-6 text-center mb-3">Edit <span className='text-danger'>Property</span></h1>

                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className="fw-bold">Aadhar card :</label>
                            <input
                                type="file"
                                multiple
                                className="form-control login-input"
                                onChange={(e) => setAadhar(e.target.files[0])}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold">Pan card :</label>
                            <input
                                type="file"
                                multiple
                                className="form-control login-input"
                                onChange={(e) => setPanCard(e.target.files[0])}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold">GST Number:</label>
                            <input
                                type="text"
                                placeholder={item?.gst}
                                className="form-control login-input"
                                onChange={(e) => setGst(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold">GST Document :</label>
                            <input
                                type="file"
                                multiple
                                className="form-control login-input"
                                onChange={(e) =>
                                    setGstDocument(e.target.files[0])
                                }
                            />
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className="fw-bold">
                                Property Document :
                            </label>
                            <input
                                type="file"
                                className="form-control login-input"
                                onChange={(e) =>
                                    setPropartyDoc(e.target.files[0])
                                }
                            />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Name:</label>
                            <input type="text" placeholder={item?.propartyname} className='form-control login-input' onChange={(e) => setPropartyName(e.target.value)} />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Address :</label>
                            <input type="text" placeholder={item?.propartyaddress} className='form-control login-input' onChange={(e) => setPropartyAddress(e.target.value)} />
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>Property Location (Link):</label>
                            <input type="text" placeholder={item?.location} className='form-control login-input' onChange={(e) => setlocation(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className="fw-bold">
                                Country Name:
                            </label>
                            <select

                                className="form-control login-input"
                                onChange={(e) => setCountry1(e.target.value)}
                            >
                                <option>Select Country</option>
                                {CountryList.map((Country) => (
                                    <option value={Country.isoCode}>
                                        {Country.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>State Name :</label>
                            <select

                                className="form-control login-input"
                                onChange={(e) => setState1(e.target.value)}
                            >
                                <option>Select State</option>
                                {StateList.map((state) => (
                                    <option value={state.isoCode}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <label className='fw-bold'>City Name:</label>
                            <select
                                className="form-control login-input"
                                onChange={(e) => setCity1(e.target.value)}
                            >
                                <option>Select City</option>
                                {CityList.map((city) => (
                                    <option value={city.isoCode}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-lg-3">
                            <label className='fw-bold'>Starting Price  :</label>
                            <input type="text" placeholder={item?.startingprice} className='form-control login-input' onChange={(e) => setStartingPrice(e.target.value)} />
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-lg-3">
                            <label className='fw-bold'>Check In Time:</label>
                            <input type="time" className='form-control login-input' onChange={(e) => setCheckin(e.target.value)} />
                        </div>
                        <div className="col-lg-3 ">
                            <label className='fw-bold'>Check Out Time :</label>
                            <input type="time" className='form-control login-input' onChange={(e) => setCheckOut(e.target.value)} />
                        </div>
                    </div>
                    <div className='mb-4 row'>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">
                                Property Description:
                            </label>
                            <CKEditor
                                data={Description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>

                        <div className="col-lg-6 ">
                            <label className="fw-bold">
                                Terms & Conditions:
                            </label>
                            <CKEditor
                                data={termsandcondition}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    settermsandcondition(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6">
                            <label className="fw-bold">Suggestion:</label>
                            <CKEditor
                                data={Suggesition}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSuggesition(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2 ">
                            <label className="fw-bold">
                                Near By Location:
                            </label>
                            <CKEditor
                                data={NearByLoc}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setNearByLoc(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">House Rules:</label>
                            <CKEditor
                                data={HouseRules}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHouseRules(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className="fw-bold">Things To Do:</label>
                            <CKEditor
                                data={ThingToDo}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setThingToDo(data);
                                }}
                                editor={ClassicEditor}
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div>
                                    <label className="fw-bold">
                                        Property Full Image :
                                    </label>
                                    <input
                                        type="file"
                                        multiple
                                        className="form-control login-input"
                                        onChange={(e) =>
                                            setPropartyfullimage(e.target.files[0])
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='pink-btn mb-3 ' onClick={EditStaysDetails}>Save Changes</button>
            </Container>
        </div>
    )
}

export default EditHotel;