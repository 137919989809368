import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-carousel/lib/styles.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavbarScrol } from "./Components/NavbarScrol";
import { TopNavbar } from "./Components/TopNavbar";
import { Header } from "./Components/Header";
import { Home } from "./Components/Home";
import { Footer } from "./Components/Footer";
import { Login } from "./Components/Login";
import { Register } from "./Components/Register";
import { Contact } from "./Components/Contact";
import { Activity } from "./Components/Activity";
import { ActivityDetails } from "./Components/ActivityDetails";
import PageLoader from "./Components/PageLoader";
import { BookingPage } from "./Components/BookingPage";
import { Profile } from "./Components/Profile";
import { Hotel } from "./Components/Hotel";
import { HotelDetails } from "./Components/HotelDetails";
import { Taxi } from "./Components/Taxi";
import { TaxiBook } from "./Components/TaxiBook";
import { TripPackage } from "./Components/TripPackage";
import { TripPackageDetails } from "./Components/TripPackageDetails";
import { Community } from "./Components/Community/Community";
import { Chat } from "./Components/Community/Chat";
import { CoummunityProfile } from "./Components/Community/CoummunityProfile";
import { CommunityHeader } from "./Components/Community/CommunityHeader";
import { AboutUs } from "./Components/AboutUs";
// Vendor

// import SideBar from "./Components/Vendor/SideBar";
// import AdminHeader from "./Components/Vendor/AdminHeader.";
// import AdminLogin from "./Components/Vendor/VendorLogin";
import Dashboard from "./Components/Vendor/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import SelectVendor from "./Components/Vendor/SelectVendor";
import Vregister from "./Components/Vendor/VendorStays/Vregister";
import Vlogin from "./Components/Vendor/VendorStays/Vlogin";
import VendorHotelComman from "./Components/Vendor/VendorStays/VendorHotelComman";
import VendorHotelDashboard from "./Components/Vendor/VendorStays/VendorHotelDashboard";
import VendorAddHotel from "./Components/Vendor/VendorStays/VendorAddHotel";
// import Vendorchat from "./Components/Vendor/VendorStays/Vendorchat";
// Admin
import Admindashboard from "./Components/Admin/AdminDashboard";
import CommanDashboard from "./Components/Admin/CommanDashboard";
import AddActivity from "./Components/Admin/AddActivity";
import AddHotel from "./Components/Admin/AddHotel";
import AddTourPackage from "./Components/Admin/AddTourPackage";
import AdminContact from "./Components/Admin/AdminContactUs";
import { AdminLogin } from "./Components/Admin/AdminLogin";
import PackagesList from "./Components/Admin/PackagesList";
import AdminAbout from "./Components/Admin/AdminAbout";
import ActivityCategory from "./Components/Admin/ActivityCategory";
import BookedTourPackages from "./Components/Admin/BookedTourPackages";
import VenderAdminpanel from "./Components/Vendor/VendorTour/VendorTourSide";
import { MultipleActivity } from "./Components/MultipleActivity";
import RegisteredUser from "./Components/Admin/RegisteredUser";
import VActivityLogin from "./Components/Vendor/VendorActivity/VActivityLogin";
import VActivityRegister from "./Components/Vendor/VendorActivity/VActivityRegister";
import Vendor_Tour_Dashboard from "./Components/Vendor/VendorTour/Vendor_Tour_Dashboard";
import VendorTourComman from "./Components/Vendor/VendorTour/VendorTourComman";
import V_Activity_Comman from "./Components/Vendor/VendorActivity/V_Activity_Comman";
import V_Activity_Dashboard from "./Components/Vendor/VendorActivity/V_Activity_Dashboard";
import V_Taxi_Comman from "./Components/Vendor/VendorTaxi/V_Taxi_Comman";
import V_Taxi_Dashboard from "./Components/Vendor/VendorTaxi/V_Taxi_Dashboard";
import VTaxiRegister from "./Components/Vendor/VendorTaxi/VTaxiRegister";
import VTaxiLogin from "./Components/Vendor/VendorTaxi/VTaxiLogin";
import VTourLogin from "./Components/Vendor/VendorTour/VTourLogin";
import VTourRegister from "./Components/Vendor/VendorTour/VTourRegister";
import VendorAddPack from "./Components/Vendor/VendorTour/VendorAddPack";
import VendorPackList from "./Components/Vendor/VendorTour/VendorPackList";
// import VendorHotelComman from "./Components/Vendor/VendorHotelComman";
// import VendorHotelDashboard from "./Components/Vendor/VendorHotelDashboard";
import VendorActivityCategory from "./Components/Vendor/VendorActivity/VendorActivityCategory";
import VendorAddActivity from "./Components/Vendor/VendorActivity/VendorAddActivity";
import BookedActivity from "./Components/Admin/BookedActivity";
import AdminHome from "./Components/Admin/AdminHome";
import AddAllPackages from "./Components/Admin/AddAllPackages";
import DiscountBanner from "./Components/Admin/DiscountBanner";
import TourCategory from "./Components/Admin/TourCategory";
import { Blog } from "./Components/Blog";
import AdminTestimonial from "./Components/Admin/AdminTestimonial";
import AdminBlog from "./Components/Admin/AdminBlog";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";
import AdminHotelCategory from "./Components/Admin/AdminStaysCate";
import { UpdateProfile } from "./Components/Vendor/VendorStays/UpdateProfile";
import StayVendorList from "./Components/Admin/StayVendorList";
import { VendorStaysProfile } from "./Components/Vendor/VendorStays/VendorStaysProfile";
import { AddNewStay } from "./Components/Vendor/VendorStays/AddNewStay";
import { HotelReview } from "./Components/HotelReview";
import VendorAddRooms from "./Components/Vendor/VendorStays/VendorAddRooms";
import VendorStaysReview from "./Components/Vendor/VendorStays/VendorStaysReview";
import { EditNewStay } from "./Components/Vendor/VendorStays/EditNewStay";
import AddStayAmenities from "./Components/Admin/AddStayAmenities";
import AddStayCategory from "./Components/Admin/AddStayCategory";
import AdminStayList from "./Components/Admin/AdminStayList";
import EditHotel from "./Components/Admin/EditHotel";
import { BookedStays } from "./Components/Admin/BookedStays";
import { Invoice } from "./Components/invoice";
import AdminFooter from "./Components/Admin/AdminFooter";
import { VendorActivityProfile } from "./Components/Vendor/VendorActivity/VendorActivityProfile";
import { VendorTourProfile } from "./Components/Vendor/VendorTour/VendorTourProfile";
import { CommunityShare } from "./Components/Community/CommunityShare";
import AdminAddRooms from "./Components/Admin/AdminAddRooms";
import AdminVehicle from "./Components/Admin/AdminVehicle";
import AdminVehicleDriver from "./Components/Admin/AdminVehicleDriver";
import AdminVehicleCategory from "./Components/Admin/AdminVehicleCategory";
import AdminVehicleModel from "./Components/Admin/AdminVehicleModel";
import AdminVehicleAirpot from "./Components/Admin/AdminVehicleAirpot";
import AdminVehicleOutStatiion from "./Components/Admin/AdminVehicleOutStation";
import AdminVehicleRental from "./Components/Admin/AdminVehicalRental";
import AdminVehicleDailyRide from "./Components/Admin/AdminVehicleDailyRide";
import AdminVehicleAllBooking from "./Components/Admin/AdminVehicleAllBooking";
import { ActivityReview } from "./Components/ActivityReview";
import { TripPackageReview } from "./Components/TripPackageReview";
import VendorBookedActivity from "./Components/Vendor/VendorActivity/VendorBookedActivity";
import VBookedTourPack from "./Components/Vendor/VendorTour/VTourBookedPack";
import { VendorBookedStays } from "./Components/Vendor/VendorStays/VendorBookedStay";
import { CommunityFriend } from "./Components/Community/CommunityFriend";
import AdminNotification from "./Components/Admin/AdminNotification";
import { RestaurantsAndCafe } from "./Components/RestaurantsAndCafe";
import { RestaurantandCafeDetails } from "./Components/RestaurantandCafeDetails";
import AdminRestaurantandCafe from "./Components/Admin/AdminRestaurantandCafe";
import V_Restaurant_Comman from "./Components/Vendor/VendorRestaurantAndCafe/V_Restaurant_Comman";
import VendorRestaurantAndCafe from "./Components/Vendor/VendorRestaurantAndCafe/VendorRestaurantAndCafe";
import V_Restaurant_Dashboard from "./Components/Vendor/VendorRestaurantAndCafe/V_Restaurant_Dashboard";
import VRestaurantLogin from "./Components/Vendor/VendorRestaurantAndCafe/V_Restaurant_login";
import VRestaurantRegister from "./Components/Vendor/VendorRestaurantAndCafe/V_Restaurant_resgister";
import { ThingsToDo } from "./Components/ThingsToDo";
import { ThingsToDoReview } from "./Components/ThingsToDoReview";
import { ThingsToDoDetails } from "./Components/ThingsToDoDetails";
import AdminThingsToDo from "./Components/Admin/AdminThingsToDo";
import VendorAddThingsToDo from "./Components/Vendor/VendorThingsToDo/VendorAddThingsToDo";
import VThingsLogin from "./Components/Vendor/VendorThingsToDo/V_Things_Login";
import VThingsRegister from "./Components/Vendor/VendorThingsToDo/V_Things_Register";
import V_Things_Dashboard from "./Components/Vendor/VendorThingsToDo/V_Things_Dashboard";
import V_Things_Comman from "./Components/Vendor/VendorThingsToDo/V_Things_Comman";
import Subscription from "./Components/Admin/Subcription";
import VendorStaySubscription from "./Components/Vendor/VendorStays/VendorStaySubscription";
import VendorActivitySubscription from "./Components/Vendor/VendorActivity/V_Activity_Subscription";
import VendorTourSubscription from "./Components/Vendor/VendorTour/V_Tour_Subscription";
import V_Restaurant_Subscription from "./Components/Vendor/VendorRestaurantAndCafe/V_Restaurant_Subscription";
import V_Things_Subscription from "./Components/Vendor/VendorThingsToDo/V_Things_Subscription";
import VendorSubscription from "./Components/Admin/VendorSubscription";
import { VendorThingsProfile } from "./Components/Vendor/VendorThingsToDo/V_Things_Profile";
import { VendorRestaurantProfile } from "./Components/Vendor/VendorRestaurantAndCafe/V_Restaurant_Profile";
import AllStayVendorList from "./Components/Admin/AllStayVendorList";
import AllActivityVendorList from "./Components/Admin/AllActivityVendorList";
import AllTourVendorList from "./Components/Admin/AllTourVendorList";
import AllRestaurantVendorList from "./Components/Admin/AllRestaurantVendor";
import AllThingsVendorList from "./Components/Admin/AllThingsVendorList";
import ThingaToDoMainView from "./Components/ThingaToDoMainView";
import AddThingsToDo from "./Components/Admin/AddThingsToDo";
import AddToDoThingsVendor from "./Components/Vendor/VendorThingsToDo/AddToDoThingsVendor";
import AdminChat from "./Components/Admin/AdminChat";
import { Vendorchat } from "./Components/Vendor/VendorStays/Vendorchat";
import { Vendoractivitychat } from "./Components/Vendor/VendorActivity/Vendoractivitychat";
import { Vendortourchat } from "./Components/Vendor/VendorTour/Vendortourchat";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import './Styles/HomeRes.css'
import './Components/Vendor/styles/VenderResponsive.css'

// ======================== Responsive CSS Start ==============================

import "./Styles/CommunityRes.css";
// ======================== Responsive CSS End ================================

function App() {
  return (
    <div className="App">
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol /> <Header /> <Home />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Login />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/register"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Register />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Contact />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/activity"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Activity />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/multipleactivity"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <MultipleActivity />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/activitydetails"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <ActivityDetails />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/activity_review"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <ActivityReview />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/bookingpage"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <BookingPage />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Profile />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/hotel"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Hotel />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/hoteldetails"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <HotelDetails />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/taxi"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Taxi />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/taxibook"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <TaxiBook />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/trippackage"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <TripPackage />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/trippackagedetails"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <TripPackageDetails />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/trippackage_review"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <TripPackageReview />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/community"
              element={
                <>
                  <PageLoader>
                    <CommunityHeader />
                    <Community />
                    {/* <Footer /> */}
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/community_friend"
              element={
                <>
                  <PageLoader>
                    <CommunityHeader />
                    <CommunityFriend />
                    {/* <Footer /> */}
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/chat"
              element={
                <>
                  <PageLoader>
                    <CommunityHeader />
                    <Chat />
                    {/* <Footer /> */}
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/aboutus"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <AboutUs />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/blog"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Blog />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/privacypolicy"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <PrivacyPolicy />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/hotelreview"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <HotelReview />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/coummunityprofile"
              element={
                <>
                  <PageLoader>
                    <CommunityHeader />
                    <CoummunityProfile />
                  </PageLoader>
                </>
              }
            />

            <Route
              path="/communityshare/:id"
              element={
                <>
                  <CommunityShare />
                </>
              }
            />

            <Route
              path="/ "
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <Invoice />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/restaurant_cafe"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <RestaurantsAndCafe />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/things_todo"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <ThingsToDo />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/things_todo_details"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <ThingsToDoDetails />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/thingatodo_mainview"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <ThingaToDoMainView />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/things_todo_review"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <ThingsToDoReview />
                    <Footer />
                  </PageLoader>
                </>
              }
            />
            <Route
              path="/restaurant_cafe_details"
              element={
                <>
                  <PageLoader>
                    <TopNavbar />
                    <NavbarScrol />
                    <RestaurantandCafeDetails />
                    <Footer />
                  </PageLoader>
                </>
              }
            />

            {/* Vendor */}

            {/* Vendor Activity Panel */}
            <Route
              path="/vendor_activity_dashboard"
              element={
                <V_Activity_Comman children={<V_Activity_Dashboard />} />
              }
            />
            <Route
              path="/vendor_activity_subscription"
              element={
                <V_Activity_Comman children={<VendorActivitySubscription />} />
              }
            />
            <Route
              path="/Vendor_Activity_Category"
              element={
                <V_Activity_Comman children={<VendorActivityCategory />} />
              }
            />
            <Route
              path="/Vendor_Add_Activity"
              element={<V_Activity_Comman children={<VendorAddActivity />} />}
            />
            <Route
              path="/Vendor_activity_chat"
              element={<V_Activity_Comman children={<Vendoractivitychat />} />}
            />
            <Route
              path="/V_Booked_Activity"
              element={
                <V_Activity_Comman children={<VendorBookedActivity />} />
              }
            />
            <Route
              path="/V_Activity_Profile"
              element={
                <V_Activity_Comman children={<VendorActivityProfile />} />
              }
            />
            <Route path="/V_Activity_Login" element={<VActivityLogin />} />
            <Route
              path="/V_Activity_Register"
              element={<VActivityRegister />}
            />

            {/* Vendor Taxi Panel */}
            <Route
              path="/vendor_taxi_dashboard"
              element={<V_Taxi_Comman children={<V_Taxi_Dashboard />} />}
            />
            <Route path="/V_Taxi_Login" element={<VTaxiLogin />} />
            <Route path="/V_Taxi_Register" element={<VTaxiRegister />} />

            {/* Vendor Tour Panel */}
            <Route
              path="/vendor_tour_dashboard"
              element={
                <VendorTourComman children={<Vendor_Tour_Dashboard />} />
              }
            />
            <Route
              path="/vendor_tour_subscription"
              element={
                <VendorTourComman children={<VendorTourSubscription />} />
              }
            />
            <Route
              path="/vendoraddpack"
              element={<VendorTourComman children={<VendorAddPack />} />}
            />
            <Route
              path="/vendorpacklist"
              element={<VendorTourComman children={<VendorPackList />} />}
            />
            <Route
              path="/V_Tour_Profile"
              element={<VendorTourComman children={<VendorTourProfile />} />}
            />
            <Route
              path="/Vendor_tour_chat"
              element={<VendorTourComman children={<Vendortourchat />} />}
            />

            <Route
              path="/V_Booked_Tourpack"
              element={<VendorTourComman children={<VBookedTourPack />} />}
            />
            <Route path="/V_Tour_Login" element={<VTourLogin />} />
            <Route path="/V_Tour_Register" element={<VTourRegister />} />

            {/* Vendor Hotel Panel */}

            <Route
              path="/vendor_hotel_dashboard"
              element={
                <VendorHotelComman children={<VendorHotelDashboard />} />
              }
            />
            <Route
              path="/vendor_hotel_subscription"
              element={
                <VendorHotelComman children={<VendorStaySubscription />} />
              }
            />
            <Route
              path="/vendor_add_hotel"
              element={<VendorHotelComman children={<VendorAddHotel />} />}
            />
            <Route
              path="/Vendor_stay_chat"
              element={<VendorHotelComman children={<Vendorchat />} />}
            />

            <Route
              path="/updateprofile"
              element={<VendorHotelComman children={<UpdateProfile />} />}
            />
            <Route
              path="/vendorstaysprofile"
              element={<VendorHotelComman children={<VendorStaysProfile />} />}
            />
            <Route
              path="/addnewstay"
              element={<VendorHotelComman children={<AddNewStay />} />}
            />
            <Route
              path="/editnewstay"
              element={<VendorHotelComman children={<EditNewStay />} />}
            />
            <Route
              path="/vendoraddrooms"
              element={<VendorHotelComman children={<VendorAddRooms />} />}
            />
            <Route
              path="/vendorstaysreview"
              element={<VendorHotelComman children={<VendorStaysReview />} />}
            />
            <Route
              path="/Vendor_Booked_Stays"
              element={<VendorHotelComman children={<VendorBookedStays />} />}
            />

            {/* Vendor Restaurant and Cafe */}
            <Route
              path="/Vendor_Restaurant_Login"
              element={<VRestaurantLogin />}
            />
            <Route
              path="/Vendor_Restaurant_Register"
              element={<VRestaurantRegister />}
            />

            <Route
              path="/Vendor_Restaurant_Profile"
              element={
                <V_Restaurant_Comman children={<VendorRestaurantProfile />} />
              }
            />
            <Route
              path="/Vendor_Restaurant_Cafe"
              element={
                <V_Restaurant_Comman children={<VendorRestaurantAndCafe />} />
              }
            />
            <Route
              path="/vendor_restaurant_dashboard"
              element={
                <V_Restaurant_Comman children={<V_Restaurant_Dashboard />} />
              }
            />
            <Route
              path="/vendor_restaurant_subscription"
              element={
                <V_Restaurant_Comman children={<V_Restaurant_Subscription />} />
              }
            />

            {/* Vendor Things To Do */}
            <Route path="/Vendor_Things_Login" element={<VThingsLogin />} />
            <Route
              path="/Vendor_Things_Register"
              element={<VThingsRegister />}
            />
            <Route
              path="/Vendor_Things_Profile"
              element={<V_Things_Comman children={<VendorThingsProfile />} />}
            />
            <Route
              path="/vendor_Things_Dashbaord"
              element={<V_Things_Comman children={<V_Things_Dashboard />} />}
            />
            <Route
              path="/vendor_addthings_todo"
              element={<V_Things_Comman children={<VendorAddThingsToDo />} />}
            />
            <Route
              path="/addtodothingsvendor"
              element={<V_Things_Comman children={<AddToDoThingsVendor />} />}
            />
            <Route
              path="/vendor_Things_Subscription"
              element={<V_Things_Comman children={<V_Things_Subscription />} />}
            />

            {/* Chaitra madam code vendor*/}

            <Route path="/SelectVendor" element={<SelectVendor />} />
            <Route path="/Vlogin" element={<Vlogin />} />
            <Route path="/Vregister" element={<Vregister />} />

            {/* Admin Panel */}
            <Route
              path="/admin"
              element={
                <>
                  <AdminLogin />
                </>
              }
            />
            <Route
              path="/admindashboard"
              element={<CommanDashboard children={<Admindashboard />} />}
            />
            <Route
              path="/AdminChat"
              element={<CommanDashboard children={<AdminChat />} />}
            />
            <Route
              path="/addactivity"
              element={<CommanDashboard children={<AddActivity />} />}
            />
            <Route
              path="/addhotel"
              element={<CommanDashboard children={<AddHotel />} />}
            />
            <Route
              path="/addtourpackage"
              element={<CommanDashboard children={<AddTourPackage />} />}
            />
            <Route
              path="/packagesist"
              element={<CommanDashboard children={<PackagesList />} />}
            />
            <Route
              path="/bookedtourpackages"
              element={<CommanDashboard children={<BookedTourPackages />} />}
            />

            <Route
              path="/admincontact"
              element={<CommanDashboard children={<AdminContact />} />}
            />
            <Route
              path="/adminabout"
              element={<CommanDashboard children={<AdminAbout />} />}
            />

            <Route
              path="/activitycategory"
              element={<CommanDashboard children={<ActivityCategory />} />}
            />
            <Route
              path="/registereduser"
              element={<CommanDashboard children={<RegisteredUser />} />}
            />
            <Route
              path="/bookedactivity"
              element={<CommanDashboard children={<BookedActivity />} />}
            />
            <Route
              path="/adminhomepage"
              element={<CommanDashboard children={<AdminHome />} />}
            />
            <Route
              path="/addallpackages"
              element={<CommanDashboard children={<AddAllPackages />} />}
            />
            <Route
              path="/discountbanner"
              element={<CommanDashboard children={<DiscountBanner />} />}
            />
            <Route
              path="/tourcategory"
              element={<CommanDashboard children={<TourCategory />} />}
            />
            <Route
              path="/admintestimonial"
              element={<CommanDashboard children={<AdminTestimonial />} />}
            />
            <Route
              path="/adminblog"
              element={<CommanDashboard children={<AdminBlog />} />}
            />
            <Route
              path="/adminhotelcategory"
              element={<CommanDashboard children={<AdminHotelCategory />} />}
            />
            <Route
              path="/stayvendorlist"
              element={<CommanDashboard children={<StayVendorList />} />}
            />
            <Route
              path="/addstayamenities"
              element={<CommanDashboard children={<AddStayAmenities />} />}
            />
            <Route
              path="/addstaycategory"
              element={<CommanDashboard children={<AddStayCategory />} />}
            />
            <Route
              path="/adminstaylist"
              element={<CommanDashboard children={<AdminStayList />} />}
            />
            <Route
              path="/edithotel"
              element={<CommanDashboard children={<EditHotel />} />}
            />
            <Route
              path="/bookedstays"
              element={<CommanDashboard children={<BookedStays />} />}
            />
            <Route
              path="/admin_footer"
              element={<CommanDashboard children={<AdminFooter />} />}
            />
            <Route
              path="/admin_add_rooms"
              element={<CommanDashboard children={<AdminAddRooms />} />}
            />
            <Route
              path="/admin_vehicle"
              element={<CommanDashboard children={<AdminVehicle />} />}
            />
            <Route
              path="/admin_vehicle_Driver"
              element={<CommanDashboard children={<AdminVehicleDriver />} />}
            />
            <Route
              path="/admin_vehicle_category"
              element={<CommanDashboard children={<AdminVehicleCategory />} />}
            />
            <Route
              path="/admin_vehicle_model"
              element={<CommanDashboard children={<AdminVehicleModel />} />}
            />
            <Route
              path="/admin_vehicle_airport"
              element={<CommanDashboard children={<AdminVehicleAirpot />} />}
            />
            <Route
              path="/admin_vehicle_outStation"
              element={
                <CommanDashboard children={<AdminVehicleOutStatiion />} />
              }
            />
            <Route
              path="/admin_vehicle_rental"
              element={<CommanDashboard children={<AdminVehicleRental />} />}
            />
            <Route
              path="/admin_vehicle_dailyride"
              element={<CommanDashboard children={<AdminVehicleDailyRide />} />}
            />
            <Route
              path="/admin_vehicle_allbooking"
              element={
                <CommanDashboard children={<AdminVehicleAllBooking />} />
              }
            />
            <Route
              path="/admin_notification"
              element={<CommanDashboard children={<AdminNotification />} />}
            />
            <Route
              path="/admin_restaurant_cafe"
              element={
                <CommanDashboard children={<AdminRestaurantandCafe />} />
              }
            />
            <Route
              path="/admin_things_todo"
              element={<CommanDashboard children={<AdminThingsToDo />} />}
            />
            <Route
              path="/addthingstodo"
              element={<CommanDashboard children={<AddThingsToDo />} />}
            />
            <Route
              path="/Subscription"
              element={<CommanDashboard children={<Subscription />} />}
            />
            <Route
              path="/Vendor_Subscription"
              element={<CommanDashboard children={<VendorSubscription />} />}
            />
            <Route
              path="/All_Stay_Vendor"
              element={<CommanDashboard children={<AllStayVendorList />} />}
            />
            <Route
              path="/All_Activity_Vendor"
              element={<CommanDashboard children={<AllActivityVendorList />} />}
            />
            <Route
              path="/All_Tour_Vendor"
              element={<CommanDashboard children={<AllTourVendorList />} />}
            />
            <Route
              path="/All_Restaurant_Vendor"
              element={
                <CommanDashboard children={<AllRestaurantVendorList />} />
              }
            />
            <Route
              path="/All_Things_Vendor"
              element={<CommanDashboard children={<AllThingsVendorList />} />}
            />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
