import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useEffect } from 'react'


function AdminFooter() {

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Add Footer
    const [Image, setImage] = useState("")
    const [Header, setHeader] = useState("")
    const [Description, setDescription] = useState("")
    const AddFooter = async () => {
        try {
            const config = {
                url: "/admin/home/addfooter",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    Image: Image,
                    Header: Header,
                    Description: Description
                }
            }
            const response = await axios(config)
            if (response.status === 200) {
                notify(response.data.success)
                handleClose()
                setImage("")
                setHeader("")
                setDescription("")
                GetFooter()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Get footer
    const [getFooterData, setgetFooterData] = useState([])
    const GetFooter = async () => {
        axios
            .get("https://hayyyak.com/api/admin/home/getfooter")
            .then(function (response) {
                setgetFooterData(response.data.success)
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    console.log("getFooterData", getFooterData)

    // edit Stay Amenities
    const formData = new FormData();
    const [editFooter, seteditFooter] = useState({})
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); seteditFooter(item) }
    const Editfooter = async () => {
        try {

            formData.append('Image', Image);
            formData.append('Header', Header);
            formData.append('Description', Description);

            const config = {
                url: "admin/home/editfooter/" + editFooter?._id,
                baseURL: "https://hayyyak.com/api/",
                method: "put",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            };

            const res = await axios(config);

            if (res.status === 200) {
                notify(res.data.success);
                GetFooter();
                handleClose1();
                setImage("")
                setHeader("")
                setDescription("")
            }
        } catch (error) {
            console.error("Error during Editfooter:", error);
            notify2(error.response?.data?.error || "An error occurred");
        }
    };

    // Delete Stay Amenities
    const [DeleteFooter, setDeleteFooter] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setDeleteFooter(item) }
    const deleteFooter = async () => {
        try {
            const config = {
                url: "admin/home/deleteFooter/" + DeleteFooter?._id,
                baseURL: "https://hayyyak.com/api/",
                method: "delete",
                headers: { 'content-type': 'application/json' },
            }
            const res = await axios(config);
            if (res.status === 200) {
                notify("Deleted Successfully");
                handleClose2()
                GetFooter();
            }
        } catch (error) {
            notify2(error.res.data.error)
        }
    }


    useEffect(() => {
        GetFooter()
    }, [])

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Footer (Tips Before travel):</p>
                    {getFooterData?.length < 3 ? (<>
                        <button className='pink-btn' onClick={handleShow}>Add Footer</button>
                    </>) : (<></>)}
                </div>


                <div >
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Header</th>
                                <th width="40%">Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getFooterData?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{++i}</td>
                                        <td><img src={`https://hayyyak.com/Footer/${item?.Image}`} alt="" style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></td>
                                        <td>{item?.Header}</td>
                                        <td>{item?.Description}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Footer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            accept='image/*'
                            id='upload'
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>

                    <div className="mb-3">
                        <label className='fw-bold'>Header :</label>
                        <input type="text" placeholder='header' className="vi_0" onChange={(e) => setHeader(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className='fw-bold'>Description :</label>
                        <input type="text" placeholder='Description...' className="vi_0" onChange={(e) => setDescription(e.target.value)} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddFooter}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Footer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            accept='image/*'
                            id='upload'
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>

                    <div className="mb-3">
                        <label className='fw-bold'>Header :</label>
                        <input type="text" placeholder='header' className="vi_0" onChange={(e) => setHeader(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className='fw-bold'>Description :</label>
                        <input type="text" placeholder='Description...' className="vi_0" onChange={(e) => setDescription(e.target.value)} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={Editfooter}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="warning" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteFooter}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminFooter
