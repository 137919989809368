import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { AiOutlineRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { useState } from 'react';
import axios from 'axios';
import parse from "html-react-parser"

export const AboutUs = () => {

    useEffect(() => {
        AboutUs();
    }, [])


    const [counterOn, setCounterOn] = useState(false);
    const [about, setaboutdetail] = useState([])

    const AboutUs = async () => {
        try {
            let res = await axios.get('https://hayyyak.com/api/admin/customeraboutus')
            if (res.status === 200) {
                setaboutdetail(res.data.success)
            }
        } catch (error) {
            setaboutdetail(error.response.data.error)
        }
    }



    return (
        <div>
            <div className='mb-5 about-mb-main'>
                <div className='activity-bg'>
                    <div
                        style={{
                            backgroundImage: 'url(././img/aboutus-bg.jpg)',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'fixed',
                            height: "350px",
                            width: "100%",
                        }}
                        className='about-top-activity'
                    >
                        <div className="activity-text">
                            <div className='bread'>
                                <h2 className='text-uppercase fw-bold'>About Us</h2>
                                <div className='d-flex align-items-center gap-2'>
                                    <Link to='/' className='breadcrumb-active'><span>Home </span></Link>
                                    <span><AiOutlineRight /></span>
                                    <span className='' style={{color:"#D81D4A", fontWeight:"bold"}}>About Us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Container>
                {about?.map((item) => {
                    return (<>
                        <div className='row mb-4 about-right-Mdiv'>
                            <div className="col-lg-6 about-us">
                                <h3>{parse(`<div>${item?.titeles}</div>`)} <span>Tripeloo!</span></h3>
                                <p className='text-justify'>{parse(`<div>${item?.Description}</div>`)}</p>
                                <button className='pink-btn mb-2'>CALL TO US : +91 987654321</button>
                            </div>
                            <div className="col-lg-6">
                                <img src={`https://hayyyak.com/AboutUs/${item?.Image}`} alt="" className="about-right-img" style={{ width: '-webkit-fill-available', height: '350px' }} />
                            </div>
                        </div>
                    </>)
                })}

            </Container>
            <div className='about-counter mb-4'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='d-flex gap-2 align-items-center'>
                                <ScrollTrigger
                                    onEnter={() => setCounterOn(true)}
                                    onExit={() => setCounterOn(false)}
                                >
                                    <span>
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={240}
                                                duration={3}
                                                delay={0}
                                            />
                                        )}
                                    </span>
                                </ScrollTrigger>
                                <h4>Packages</h4>
                            </div>
                            <p>Vivamus nec tortor bibendum risus placerat vulputate at gravida ante</p>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex gap-2 align-items-center'>
                                <ScrollTrigger
                                    onEnter={() => setCounterOn(true)}
                                    onExit={() => setCounterOn(false)}
                                >
                                    <span>
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={960}
                                                duration={3}
                                                delay={0}
                                            />
                                        )}
                                    </span>
                                </ScrollTrigger>
                                <h4>Places</h4>
                            </div>
                            <p>Vivamus nec tortor bibendum risus placerat vulputate at gravida ante</p>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex gap-2 align-items-center'>
                                <ScrollTrigger
                                    onEnter={() => setCounterOn(true)}
                                    onExit={() => setCounterOn(false)}
                                >
                                    <span>
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={400}
                                                duration={3}
                                                delay={0}
                                            />
                                        )}

                                    </span>
                                </ScrollTrigger>
                                <h4>Events</h4>
                            </div>
                            <p>Vivamus nec tortor bibendum risus placerat vulputate at gravida ante</p>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex gap-2 align-items-center'>
                                <ScrollTrigger
                                    onEnter={() => setCounterOn(true)}
                                    onExit={() => setCounterOn(false)}
                                >
                                    <span>
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={120}
                                                duration={3}
                                                delay={0}
                                            />
                                        )}
                                    </span>
                                </ScrollTrigger>
                                <h4>Hotels</h4>
                            </div>
                            <p>Vivamus nec tortor bibendum risus placerat vulputate at gravida ante</p>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
