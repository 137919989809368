import React from "react";
import { Container } from "react-bootstrap";

function SelectVendor() {
  return (
    <div
      style={{
        backgroundColor: "rgb(216 29 74)",
        height: "100vh",
        // width: "100vw",
        padding: "106px 0px",
      }}

      className="selectVendor-main"
    >
      <Container>
        <div>
          <div className="row align-items-center justify-content-evenly">
            <div
              className="stay-div col-lg-3"
              onClick={() => window.location.assign("/Vlogin")}
            >
              <img src="./img/vendor-icon-1.png" />
              <p>STAYS</p>
              <span>Stay's,Hotel's,Apartment's,Resorts's, etc....</span>
            </div>

            <div
              className="stay-div col-lg-3"
              onClick={() => window.location.assign("/V_Activity_Login")}
            >
              <img src="./img/vendor-icon-2.png" />
              <p>Activity</p>
              <span>
                Hot Air Baloon, Jungle Safari, Ocean Kayak, Trekking, Scuba
                Diving, etc...
              </span>
            </div>

            <div
              className="stay-div col-lg-3"
              onClick={() => window.location.assign("/V_Tour_Login")}
            >
              <img src="./img/vendor-icon-3.png" />
              <p>Tour Package</p>
              <span>Trip, etc...</span>
            </div>

            <div
              className="stay-div col-lg-3 mt-3"
              onClick={() => window.location.assign("/Vendor_Restaurant_Login")}
            >
              <img src="./img/vendor-icon-4.png" />
              <p className="fs-6">Restaurant / Cafe</p>  
              <span>Restaurant, Cafe...</span>
            </div>

            <div
              className="stay-div col-lg-3 mt-3"
              onClick={() => window.location.assign("/Vendor_Things_Login")}
            >
              <img src="./img/vendor-icon-5.png" />
              <p>Things To Do</p>
              <span>Near by things...</span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SelectVendor;
