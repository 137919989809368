import React, { useEffect, useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

function AdminHome() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    // Add banner
    const [BannerVideo, setBannerVideo] = useState("")
    const AddHomeBanner = async () => {
        if (!BannerVideo) {
            return notify1("Please Select Banner")
        }
        try {
            const formData = new FormData();
            formData.append('bannerVideo', BannerVideo);
            const config = {
                method: 'post',
                url: 'admin/addhomebanner',
                baseURL: 'https://hayyyak.com/api',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify("Video Added Succesfully..!")
                    getBannerVideos()
                    handleClose()
                    setBannerVideo("")
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Get Banner
    const [homebannerVideo, sethomebannerVideo] = useState([])
    const getBannerVideos = async () => {
        axios
            .get("https://hayyyak.com/api/admin/getbannervideos")
            .then(function (response) {
                console.log(response.data);
                sethomebannerVideo(response.data.banner)
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    console.log("bannerVideo", homebannerVideo)


    useEffect(() => {
        getBannerVideos()
    }, [])

    // Delete
    const [deleteBanner, setdeleteBanner] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setdeleteBanner(item) };
    const DeletHomeBanner = async () => {
        try {
            const config = {
                url: "admin/deletehomebanner/" + deleteBanner?._id,
                baseURL: "https://hayyyak.com/api",
                method: "delete",
                headers: { "content-type": "application/json" },
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    getBannerVideos()
                    handleClose2()
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                closeOnClick
                pauseOnHover
                draggable
            />

            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Home :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Banner</button>
                </div>

                {/* <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn">Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-describedby="basic-addon1" />
                    </div>

                </div> */}
                <div >
                    <Table responsive bordered className='sdfsd-table-head text-center' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Video Banner</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homebannerVideo?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <video src={`https://hayyyak.com/Banner/${item?.bannerVideo}`} muted autoPlay loop type='video/mp4' style={{
                                                height: "150px",
                                                width: " 90%",
                                                objectFit: "cover"
                                            }} />
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                {/* <BiSolidEdit className='text-primary' onClick={handleShow1} /> */}
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}
                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose File :</label>
                        <input
                            type="file"
                            id="upload"
                            accept='video/*'
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setBannerVideo(e.target.files[0])}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddHomeBanner}>
                        Add Banner
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            {/* <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Taxi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold'>Choose File :</label>
                        <input
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose1}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeletHomeBanner}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminHome
