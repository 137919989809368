import React from "react";
import { Accordion, Container, Form, InputGroup, Table } from "react-bootstrap";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineFieldTime, AiOutlineRight } from "react-icons/ai";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IoIosArrowDown } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageGallery from "react-image-gallery";
import TimePicker from "react-time-picker";

export const ActivityDetails = () => {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const location = useLocation();
  const { item } = location.state;
  console.log("itemmmmmmm", item);

  const { ACategory, activitydestination } = location.state;
  console.log("checking", ACategory, activitydestination);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [Adult, setAdult] = useState(1);
  const adultinc = () => {
    setAdult(Adult + 1);
  };
  const adultdec = () => {
    if (Adult > 1) {
      setAdult(Adult - 1);
    }
  };

  const [Children, setChildren] = useState(0);
  const childreninc = () => {
    setChildren(Children + 1);
  };
  const childrendec = () => {
    if (Children > 0) {
      setChildren(Children - 1);
    }
  };

  // const [Pets, setPets] = useState(0);
  // const Petsinc = () => {
  //   setPets(Pets + 1);
  // };
  // const Petsdec = () => {
  //   if (Pets > 0) {
  //     setPets(Pets - 1);
  //   }
  // };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Booking Activity
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const [Checkindate, setCheckindate] = useState();
  const [CheckinTime, setCheckinTime] = useState();

  // getactivity packages
  const [GetActivity, setGetActivity] = useState([]);
  const GetActivityDetail = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/activity/getactivitydetail"
      );
      if (res.status === 200) {
        setGetActivity(res.data.getActivity);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("GetActivity", GetActivity);
  const [Activitycatdetails, setActivitycatdetails] = useState({});

  console.log("Activitycatdetails", Activitycatdetails);
  useEffect(() => {
    if (ACategory && activitydestination) {
      const matchingActivities = GetActivity.filter(
        (activity) => activity.activitycategories.Title === ACategory
      );

      const matchingactivitylocation = GetActivity?.addlocation?.filter(
        (location) => location?.Location === activitydestination
      );

      const Activitycategory =
        matchingActivities.length > 0
          ? {
              activitycategories: {
                ...matchingActivities[0].activitycategories,
              },
            }
          : {};
      setActivitycatdetails(Activitycategory);
    }
  }, [GetActivity, ACategory, activitydestination]);

  // Gellery images
  const images =
    item?.Gallery?.map((ele) => ({
      original: `https://hayyyak.com/ActivityCategory/${ele?.image}`,
      thumbnail: `https://hayyyak.com/ActivityCategory/${ele?.image}`,
    })) || [];

  // Navgition to next page
  const adultAmount = item?.act?.Adulttotal * Adult;
  const childrenAmount = item?.act?.Childrentotal * Children;
  const navigate = useNavigate();
  const GoToReviewPage = () => {
    if (!Checkindate) {
      return notify1("Please Select Date");
    }
    if (!CheckinTime) {
      return notify1("Please Select Time");
    }
    if (!Adult) {
      return notify1("Please Select atleast one adult");
    }
    const dataToPass = {
      item: item,
      adultCount: Adult,
      childrenCount: Children,
      Date: Checkindate,
      Time: CheckinTime,
      adultAmount: adultAmount,
      childrenAmount: childrenAmount,
    };
    navigate("/activity_review", { state: dataToPass });
  };

  useEffect(() => {
    GetActivityDetail();
  }, []);

  return (
    <div>
      <div className="mb-4 activityDetails-div-mb">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(../img/activity-bg.jpg)",
              backgroundPosition: "initial",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "350px",
              width: "100%",
            }}
            className="activityDetails-top"
          >
            <div className="activity-text">
              <div className="bread" id="activityDetails-bread">
                <h2 className="text-uppercase fw-bold">Activities</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Activities Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />

      <Container>
        <div className="row align-items-center">
          <div className="col-lg-6 mb-2">
            <img                                            
              src={`https://hayyyak.com/ActivityCategory/${item?.img}`}
              alt=""
              className="activity-img  activityDetails-img"
            />
          </div>

          <div className="col-lg-6">
            <div className=" mb-4 activityDetails-div-mb">
              {/* <h2>Grand  <span style={{ color: 'rgb(244, 54, 79)' }}>Hot Air Baloon </span>Trips</h2> */}
              <h2 className="activity-h2">
                {parse(`<div>${item?.title}</div>`)}
              </h2>
              <p className="text-dark">
                {parse(`<div>${item?.description}</div>`)}
              </p>
            </div>

            <div className="d-flex gap-4 align-items-center activity-cart">
              <div
                style={{
                  borderRight: "1px solid #00000047",
                  padding: "8px",
                }}
              >
                <span className="text-light" style={{ fontSize: "12px" }}>
                  <CiLocationOn /> {item?.act?.City} {item?.act?.State}{" "}
                  {item?.act?.Country}
                </span>
                <p className="mb-1 fw-semibold"> {item?.act?.Location}</p>
                <span className="text-light" style={{ fontSize: "15px" }}>
                  {/* <BsFillCalendarCheckFill /> Trip Days: {item?.act?.Day}{" "} */}
                  <AiOutlineFieldTime /> Duration: {item?.act?.Timing}
                </span>
              </div>
              <div>
                <span className="text-light" style={{ fontSize: "12px" }}>
                  {" "}
                  Starts From <s className="fw-bold">₹{item?.act?.Price}</s>
                </span>
                <p>
                  {" "}
                  <span style={{ color: "#e92c44", fontWeight: "bold" }}>
                    ₹{item?.act?.Adulttotal}
                  </span>{" "}
                  / person
                </p>
              </div>
              <div className="activity-offer">
                {item?.act?.AdultDiscount}%Off
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 activityDetails-div-mb ">
          <div className="col-lg-8 mb-2">
            <h5>Overview</h5>
            <p className="text-secondary mb-4">
              {parse(`<div>${item?.overview}</div>`)}
            </p>

            <div
              style={{
                border: "1px solid #80808080",
                marginBottom: "30px",
              }}
              className=" activityDetails-div-mb"
            >
              <Table responsive>
                <thead className="activity-table">
                  <tr>
                    <th>Activity Details</th>
                    <th></th>
                  </tr>
                  {/* <tr>
                                    <th style={{ width: '200px' }}><BsFillCalendarCheckFill /> Stars Date	</th>
                                    <th >{moment(item?.act?.ActvitySDate).format("DD/MM/YYYY")} </th>
                                </tr>
                                <tr>
                                    <th ><AiOutlineFieldTime /> End Date</th>
                                    <th >{moment(item?.act?.ActvityEDate).format("DD/MM/YYYY")}</th>
                                </tr> */}
                </thead>
                <tbody>
                  <tr>
                    <td className="fw-bold" style={{ width: "175px" }}>
                      Activity Highlights :{" "}
                    </td>
                    <td>{parse(`<div>${item?.tours}</div>`)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <ImageGallery items={images} className="activity-details-trekking-image" />
          </div>
          <div className="col-lg-4">
            <div
              style={{
                border: "1px solid rgb(221, 221, 221)",
                borderRadius: "12px",
                padding: "24px",
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
                marginBottom: "20px",
              }}
              className=" activityDetails-div-mb"
            >
              <div className="out_0">
                <p>Starting From</p>
                <h6>
                  ₹{item?.act?.Adulttotal * Adult}{" "}
                  <span>
                    * {Adult} / <s>{item?.act?.Price}</s> per Adult
                  </span>
                  , <br />₹{item?.act?.Childrentotal * Children}{" "}
                  <span>
                    * {Children} / <s>{item?.act?.Children}</s> per children
                  </span>
                  , <br />
                  <hr />
                </h6>
                <div className="mb-2">
                  <div className="bhjbsdf_0">
                    <span>Total Amount</span>
                    <s>
                      ₹
                      {item?.act?.Price * Adult +
                        item?.act?.Children * Children}
                    </s>
                  </div>
                  <div className="bhjbsdf_0">
                    <span>Discount Amount (%)</span>
                    <p className="mb-0">
                      {" "}
                      ₹
                      {(
                        item?.act?.Price * Adult +
                        item?.act?.Children * Children -
                        (item?.act?.Adulttotal * Adult +
                          item?.act?.Childrentotal * Children)
                      ).toFixed(2)}{" "}
                      <div style={{ fontSize: "12px" }}>
                        (
                        {item?.act?.AdultDiscount + item?.act?.ChildrenDiscount}
                        %off)
                      </div>
                    </p>
                  </div>
                  <hr />
                  <div className="bhjbsdf_0">
                    <span> Final Total</span>
                    {Adult + Children}
                    <p className="text-danger fw-bold">
                      {" "}
                      ₹
                      {item?.act?.Adulttotal * Adult +
                        item?.act?.Childrentotal * Children}{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex mb-2">
                  <div className="warner">
                    <label>Date</label> <br />
                    <DatePicker
                      minDate={new Date()}
                      onChange={(date) =>
                        setCheckindate(date.format("DD/MM/YYYY"))
                      }
                      placeholder="Add Date"
                      style={{
                        border: "none",
                        padding: "3px",
                        width: "145px",
                      }}
                    />
                  </div>
                  <div className="warner">
                    <label>Time</label> <br />
                    <input
                      type="time"
                      placeholder="Time"
                      style={{ border: "none", padding: "3px", width: "145px" }}
                      onChange={(e) => setCheckinTime(e.target.value)}
                    />
                  </div>
                </div>

                <div className="warner-out">
                  <p>Guest</p>
                  <div onClick={handleClick}>
                    <span>
                      {" "}
                      <span className="text-danger fw-bold">{Adult} </span>{" "}
                      Adult,{" "}
                    </span>
                    <span>
                      {" "}
                      <span className="text-danger fw-bold">
                        {Children}{" "}
                      </span>{" "}
                      Children,{" "}
                    </span>
                    {/* <s>{Pets} pet</s                                          pan> */}
                    <span className="float-end">
                      <IoIosArrowDown />
                    </span>
                  </div>
                </div>

                {isOpen && (
                  <>
                    <div className="p-2">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Adults</b>
                          <p style={{ fontSize: "12px" }}>Age 13+</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => adultdec()}
                            className="increase-icon"
                          />
                          <span>{Adult}</span>
                          <span>
                            {" "}
                            <AddIcon
                              onClick={() => adultinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Children</b>
                          <p style={{ fontSize: "12px" }}>Age 2-12</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => childrendec()}
                            className="increase-icon"
                          />
                          <span>{Children}</span>
                          <span>
                            {" "}
                            <AddIcon
                              onClick={() => childreninc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div>
                  <button
                    className="header-search"
                    style={{ width: "100%", height: "44px" }}
                    onClick={GoToReviewPage}
                  >
                    Review Booking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-5 ActivityDetails-accordian ">
          <Accordion defaultActiveKey={null} style={{ width: "800px" }} className="ActivityDetails-accordian">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <h4 className="over-taxi" style={{ fontSize: "17px" }}  >
                  Terms and Condition
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <div>{parse(`<div>${item?.Terms}</div>`)}</div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="mb-3">
          <Accordion defaultActiveKey={null} style={{ width: "800px" }}  className="ActivityDetails-accordian">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                  Suggestion
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <div>{parse(`<div>${item?.Suggestion}</div>`)}</div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="mb-3">
          <Accordion defaultActiveKey={null} style={{ width: "800px" }} className="ActivityDetails-accordian">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                  Cancelletion Policy
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <div>{parse(`<div>${item?.Policy}</div>`)}</div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* <div className="mb-3">
          <Accordion defaultActiveKey={null} style={{ width: "800px" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                  FAQ
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion defaultActiveKey={null}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                        Lorem ipsum dolor sit amet.
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ipsam, vel! Natus repudiandae cum itaque a ratione atque
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div> */}
      </Container>
    </div>
  );
};
