import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import parse from "html-react-parser"

export const VendorStaysProfile = () => {


    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const StayVendor = JSON.parse(sessionStorage.getItem("Stays"));
    console.log("StayVendor", StayVendor);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const deleteClose = () => setShow2(false)
    const deleteShow = () => setShow2(true)

    // get Stays Proparty
    const [BigImage, setBigImage] = useState({});
    const [StaysDetails, setStaysDetails] = useState([]);
    const getStays = async () => {
        try {
            const res = await axios.get(
                `https://hayyyak.com/api/vendor/stays/staysdetailbyid/${StayVendor?._id}`
            );
            if (res.data && res.status === 200) {
                setStaysDetails(res.data.stays);
            }
        } catch (error) {
            console.log(error);
        }
    };

    console.log("StaysDetails", StaysDetails);
    console.log("StaysDetails", StaysDetails);

    useEffect(() => {
        getStays()
    }, [])

    const [Fname, setFname] = useState("");
    const [Lname, setLname] = useState("");
    const [Mobile, setMobile] = useState();
    const [Email, setEmail] = useState("");
    const [VendorImage, setVendorImage] = useState("");
    const [Description, setDescription] = useState("");
    // Edit Vendor
    const [editData, seteditData] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (item) => { setShow(true); seteditData(item) }
    const EditVendorDetails = async () => {
        try {
            const config = {
                url: `/editvendordetails/${editData}`,
                baseURL: "https://hayyyak.com/api",
                method: "put",
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    Fname: Fname,
                    Lname: Lname,
                    Mobile: Mobile,
                    Email: Email,
                    VendorImage: VendorImage,
                    Description: Description
                },
            };

            const res = await axios(config);

            if (res.status === 200) {
                sessionStorage.setItem("Stays", JSON.stringify(res.data.user));
                notify(res.data.success);
                handleClose();
            }
        } catch (error) {
            notify2(error.response.data.error);
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <h1 class="display-6 text-center mb-3">Profile <span className='text-danger'>Details</span></h1>

                <div className='mb-4'>
                    <Table >
                        <tbody>
                            <tr>
                                <td className='fw-bold '>First <span className="text-danger">Name :</span> </td>
                                <td>{StayVendor?.Fname} </td>
                                <td className='fw-bold '>Last <span className="text-danger">Name :</span> </td>
                                <td>{StayVendor?.Lname} </td>
                            </tr>
                            <tr>
                                <td className='fw-bold '>Mobile <span className="text-danger">Number :</span> </td>
                                <td>{StayVendor?.Mobile} </td>
                                <td className='fw-bold '>Email : </td>
                                <td>{StayVendor?.Email} </td>
                            </tr>
                            <tr>
                                <td className='fw-bold '>Profile <span className="text-danger">Image :</span> </td>
                                <td><img src={`https://hayyyak.com/VendorStay/${StayVendor?.VendorImage}`} alt="" style={{ width: '70px', height: '70px', borderRadius: '50%', objectFit: 'cover' }} /> </td>
                                <td className='fw-bold '>Description :</td>
                                <td width="500px">{parse(`<div>${StayVendor?.Description}</div>`)}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <button className='pink-btn' onClick={() => handleShow(StayVendor?._id)}>Update Profile</button>
                </div>

                <p className='fs-4 mb-2 fw-bold'>Stays <span className='text-danger'> List :</span></p>
                <Table bordered className='sdfsd-table-head'>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Property Name</th>
                            <th>GST Number</th>
                            <th>GST Documnet</th>
                            <th>Adhaar Card</th>
                            <th>Pan Card</th>
                            <th>Property Document</th>
                        </tr>
                    </thead>
                    <tbody>
                        {StaysDetails?.map((item, i) => {
                            return (<>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item?.propartyname}</td>
                                    <td>{item?.gst}</td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.gstdocument}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.aadhar}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.pancard}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.propartydoc}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                </tr>
                            </>)
                        })}
                    </tbody>
                </Table>

            </Container>

            {/* Edit Profile Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit <span className='text-danger'>Profile</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row ">
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>First Name :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setFname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Last Name:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setLname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Mobile Number:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setMobile(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Email :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="col-lg-12 mb-2">
                            <label className='fw-bold'>Profile Image :</label>
                            <input onChange={(e) => setVendorImage(e.target.files[0])} accept='image/*' type="file" multiple className='form-control login-input' />
                        </div>
                        <div className="col-lg-12">
                            <label className='fw-bold'>Description :</label>
                            <CKEditor editor={ClassicEditor}
                                data={Description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditVendorDetails}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Delete Modal */}
            {/* <Modal
                show={show2}
                onHide={deleteClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={deleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger"><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal> */}

            {/* Property Big Image Modal */}
            {/* <Modal show={show3} onHide={handleClose3} className="mb-0" size="lg">
                <Modal.Body className="p-0">
                    <img
                        src={`https://hayyyak.com/VendorStay/${BigImage?.image}`}
                        alt=""
                        style={{
                            width: "-webkit-fill-available",
                            imageRendering: "pixelated",
                            height: '500px'
                        }}
                        onClick={handleClose3}
                    />
                </Modal.Body>
            </Modal> */}

        </div>
    )
}
