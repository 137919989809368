import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { City, Country, State } from "country-state-city";
import moment from "moment";
import { Pagination, Stack } from "@mui/material";
import { AiFillEye } from "react-icons/ai";
import parse from "html-react-parser";
import Carousel from "react-multi-carousel";

function VendorPackList() {
  const TourVendor = JSON.parse(sessionStorage.getItem("TourId"));

  // carousal
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // input file access
  const fileInputRef = useRef(null);
  const [amid, setamid] = useState("");

  const handleIconClick = (id) => {
    // Trigger the click event of the hidden file input
    setamid(id);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setdata(item);
  };

  const [show4, setShow4] = useState(false);
  const [Data1, setData1] = useState("");
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setData1(item);
  };

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData2(item);
  };

  const [show6, setshow6] = useState(false);
  const handleClose6 = () => setshow6(false);
  const handleShow6 = () => setshow6(true);
  const [DataView, setDataView] = useState({});

  const [show7, setshow7] = useState(false);
  const handleClose7 = () => setshow7(false);
  const handleShow7 = () => setshow7(true);

  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1);

  //Update
  const [packageName, setpackageName] = useState("");
  // const [tripLocation, settripLocation] = useState("");
  const [description, setdescription] = useState("");
  const [adultprice, setadultprice] = useState();
  const [childrenprice, setchildrenprice] = useState();
  const [petsprice, setpetsprice] = useState();
  const [adultdiscount, setadultdiscount] = useState();
  const [childrendiscount, setchildrendiscount] = useState();
  const [petsdiscount, setpetsdiscount] = useState();
  const [SiteSeeing, setSiteSeeing] = useState("");
  const [Hotels, setHotels] = useState("");
  const [Transport, setTransport] = useState("");
  const [days, setdays] = useState("");
  const [nights, setnights] = useState("");
  const [location, setlocation] = useState("");
  const [packageid, setpackageid] = useState("");
  const [Suggestion, setSuggestion] = useState();
  const [termsandcondition, settermsandcondition] = useState();
  const [policy, setpolicy] = useState();

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setpackageid(item);
  };

  const UpdatePackages = async () => {
    try {
      let adultofferprice = adultprice
        ? adultprice
        : Tourpackages?.tripcost -
          (adultprice
            ? adultprice
            : Tourpackages?.tripcost * adultdiscount
            ? adultdiscount
            : Tourpackages?.tripdiscount) /
            100;
      let childrenofferprice =
        childrenprice - (childrenprice * childrendiscount) / 100;
      let petofferprice = petsprice - (petsprice * petsdiscount) / 100;

      const config = {
        url: "/admin/updatetrippackages/" + packageid,
        method: "put",
        baseURL: "https://hayyyak.com/api",
        headers: { "content-type": "application/json" },
        data: {
          tripname: packageName ? packageName : Tourpackages?.tripname,
          tripdescription: description
            ? description
            : Tourpackages?.tripdescription,
          tripcost: adultprice ? adultprice : Tourpackages?.tripcost,
          tripdiscount: adultdiscount
            ? adultdiscount
            : Tourpackages?.tripdiscount,
          childrencost: childrenprice
            ? childrenprice
            : Tourpackages?.childrencost,
          childrendiscount: childrendiscount
            ? childrendiscount
            : Tourpackages?.childrendiscount,
          petscost: petsprice ? petsprice : Tourpackages?.petscost,
          petdiscount: petsdiscount ? petsdiscount : Tourpackages?.petdiscount,
          country: Country1 ? Country1 : Tourpackages?.country,
          state: State1 ? State1 : Tourpackages?.state,
          city: City1 ? City1 : Tourpackages?.city,
          tripduration: days ? days : Tourpackages?.tripduration,
          tripnight: nights ? nights : Tourpackages?.tripnight,
          location: location ? location : Tourpackages?.location,
          signtseeing: SiteSeeing ? SiteSeeing : Tourpackages?.signtseeing,
          hotels: Hotels ? Hotels : Tourpackages?.hotels,
          transport: Transport ? Transport : Tourpackages?.transport,
          offerprice: adultofferprice
            ? adultofferprice
            : Tourpackages?.offerprice,
          childrenoffer: childrenofferprice
            ? childrenofferprice
            : Tourpackages?.childrenoffer,
          petoffer: petofferprice ? petofferprice : Tourpackages?.petoffer,
          termsandcondition: termsandcondition
            ? termsandcondition
            : Tourpackages?.termsandcondition,
          Suggestion: Suggestion ? Suggestion : Tourpackages?.Suggestion,
          policy: policy ? policy : Tourpackages?.policy,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getTourPackages();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // Update Iternery
  const [day, setday] = useState("");
  const [todayplan, settodayplan] = useState("");
  // const [TourExeclusion, setTourExeclusion] = useState("");
  // const [TourEventdate, setTourEventdate] = useState("");
  const [ViewSchedules, setViewSchedules] = useState({});
  const [Data2, setData2] = useState("");
  const UpdateIternery = async (ViewSchedule) => {
    try {
      const config = {
        url: "/admin/UpdateIternary/" + Data2,
        method: "put",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          schId: ViewSchedule?._id,
          day: day,
          todaysplan: todayplan,
          // exclusion: TourExeclusion,
          // eventDate: TourEventdate,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Updated Successfully");
          setViewSchedule(res.data.success);
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Get Tour Packages
  const [ViewSchedule, setViewSchedule] = useState({});
  const [Viewgallery, setViewgallery] = useState({});
  const [ViewIternery, setViewIternery] = useState({});
  const [Tourpackages, setTourpackages] = useState([]);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(res.data.getpackages);
        setnochangedata(
          res.data.getpackages?.filter((ele) => ele.TourId === TourVendor?._id)
        );
        setPagination(
          res.data.getpackages?.filter((ele) => ele.TourId === TourVendor?._id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Delete trip
  const [Data, setdata] = useState("");
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "admin/daletetrippackages/" + Data,
        baseURL: "https://hayyyak.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getTourPackages();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getTourPackages();
  }, []);

  // Update Gallery
  const formdata = new FormData();
  const UpdateGalleryImages = async (img, id) => {
    formdata.append("image", img);
    formdata.append("packageid", Viewgallery?._id);
    formdata.append("galleryid", amid);
    try {
      const config = {
        url: "/admin/updategalleryimages",
        method: "put",
        baseURL: "https://hayyyak.com/api",
        headers: { "Content-Type": "multipart/form-data" }, // Corrected the typo in headers
        data: formdata,
      };
      const response = await axios(config);
      if (response.status === 200) {
        alert("Updated Successfully");
        handleClose();
        getTourPackages();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteGalleryimage = (id) => {
    const confirmCheck = window.confirm(
      "Are you sure you want to delete this item"
    );
    if (confirmCheck) {
      DeleteGalleryimage(id);
    }
  };
  //Delete gallery Image
  const DeleteGalleryimage = async (id) => {
    try {
      const config = {
        url: "/admin/deletegalleryimage/" + id,
        method: "delete",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          tourid: Viewgallery?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          setViewgallery(res.data.success);
          getTourPackages();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const handleDeleteItinerary = (id) => {
    const confirmCheck = window.confirm(
      "Are your sure your want to delete this item"
    );
    if (confirmCheck) {
      DeleteItinerary(id);
    }
  };
  //Delete Tour Schedule
  const DeleteItinerary = async (id) => {
    try {
      const config = {
        url: "/admin/Deleteiternary/" + id,
        method: "delete",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          tourid: ViewSchedule?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          setViewSchedule(res.data.success);
          handleClose();
          getTourPackages();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const handleDeleteFaq = (id) => {
    const confirmCheck = window.confirm(
      "Are your sure your want to delete this item"
    );
    if (confirmCheck) {
      DeleteFaq(id);
    }
  };
  //Delete Tour Schedule
  const DeleteFaq = async (id) => {
    try {
      const config = {
        url: "/admin/Deletefaq/" + id,
        method: "delete",
        baseURL: "https://hayyyak.com/api",
        header: { "content-type": "application/json" },
        data: {
          tourid: ViewIternery?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          setViewIternery(res.data.success);
          getTourPackages();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setTourpackages(abc);
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");

  //Pagination
  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(pagination?.length / usersPerPage);
  const changePage = (selected) => {
    setPageNumber(selected);
  };

  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const addFAQ = async () => {
    try {
      const config = {
        url: "/admin/addfaq",
        method: "put",
        baseURL: "https://hayyyak.com/api",
        headers:{"content-type":"application/json"},
        data:{
          packageId:ViewIternery?._id,
          question:Question,
          answer:Answer,
        }
      };
      let res = await axios(config);
      if(res.status === 200){
        alert(res.data.success);
        handleClose7();
        setQuestion("");
        setAnswer("");
        setViewIternery(res.data.faq);
      }
    } catch (error) {
      alert(error.response.data.error)
    }
  };

  return (
    <>
      <div>
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
            <p className="text-danger fs-4 fw-semibold">Tour Package List :</p>
            {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
          </div>

          <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
            <div className="col-lg-2">
              <label>From :</label>
              <Form.Control
                type="date"
                aria-describedby="basic-addon1"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>

            <div className="col-lg-2">
              <label>To :</label>
              <Form.Control
                type="date"
                aria-describedby="basic-addon1"
                onChange={(e) => settod(e.target.value)}
              />
            </div>

            <div className="col-lg-2">
              <button className="pink-btn" onClick={searchDate}>
                Submit
              </button>
            </div>

            <div
              className="input-group col-lg-4"
              style={{ width: "auto", height: "35px", marginTop: "20px" }}
            >
              <span class="input-group-text" id="basic-addon1">
                <BsSearch />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                aria-describedby="basic-addon1"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Table responsive bordered className="admin-table-head">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Trip Name</th>
                  <th style={{ width: "400px" }}>Description</th>
                  <th>Country, State, City</th>
                  <th>Location</th>
                  <th>Discount</th>
                  <th>Price</th>
                  <th>Gallery Image</th>
                  <th>More Details</th>
                  <th>Itinerary</th>
                  <th>FAQ</th>
                  <th>Status</th>
                  <th>Package Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Tourpackages?.filter((ele) => ele.TourId === TourVendor?._id)
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((item, i) => {
                    if (
                      SearchItem === "" ||
                      Object.values(item).some((value) =>
                        String(value)
                          .toLowerCase()
                          .includes(SearchItem.toLowerCase())
                      )
                    )
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <img
                              src={`https://hayyyak.com/Packages/${item?.GalleryImage[0]?.image}`}
                              alt=""
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{item?.tripname}</td>
                          <td>
                            <div style={{
                              overflow:"hidden",
                              height:"150px",
                              overflowY:"scroll",
                              textAlign:"justify",
                              padding:"4px"
                              }}>
                            {item?.tripdescription}
                            </div>                         
                            </td>
                          <td>
                            {item?.country} {item?.state} {item?.city}
                          </td>
                          <td>
                            <div style={{
                              overflow:"hidden",
                              height:"150px",
                              overflowY:"scroll",
                              textAlign:"justify",
                              padding:"4px"
                            }}>
                              {item?.location}
                            </div>
                           </td>
                          <td>{item?.tripdiscount}%off</td>
                          <td>₹{item?.tripcost}</td>
                          <td>
                            <button
                              className="pink-btn"
                              onClick={() => {
                                handleShow();
                                setViewgallery(item);
                              }}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <span
                              className="fs-3 text-danger"
                              onClick={() => {
                                handleShow6(item);
                                setDataView(item);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <AiFillEye />
                            </span>
                          </td>
                          <td>
                            <span
                              className="fs-3 text-danger"
                              onClick={() => {
                                handleShow2();
                                setViewSchedule(item);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <AiFillEye />
                            </span>
                          </td>
                          <td>
                            <span
                              className="fs-3 text-danger"
                              onClick={() => {
                                handleShow4();
                                setViewIternery(item);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <AiFillEye />
                            </span>
                          </td>
                          <td>
                            {item?.venderaddstatus === "pending" ? (
                              <>
                                <span
                                  className="unblock-user"
                                  style={{ backgroundColor: "#dba709" }}
                                >
                                  pending
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="unblock-user">Approved</span>
                              </>
                            )}
                          </td>
                          <td>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            <div
                              className="d-flex gap-2 fs-4"
                              style={{ cursor: "pointer" }}
                            >
                              <BiSolidEdit
                                className="text-primary"
                                onClick={() => {
                                  handleShow1(item?._id);
                                  setViewSchedules(item);
                                }}
                              />
                              <MdDelete
                                className="text-danger"
                                onClick={() => handleShow3(item?._id)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                  })}
              </tbody>
            </Table>
          </div>
          <div className="my-3">
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                onChange={(event, value) => {
                  changePage(value - 1);
                }}
                color="primary"
              />
            </Stack>
          </div>
        </Container>

        {/* Gallery Image Model */}
        <Modal show={show} onHide={handleClose} className="anties_mdl">
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Gallery Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered className="sdfsd-table-head">
              <thead>
                <th>Sl.No</th>
                <th>Image</th>
                <th>Action</th>
              </thead>
              <tbody>
                {Viewgallery?.GalleryImage?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          style={{
                            width: "80px",
                            height: "100px",
                            imageRendering: "pixelated",
                          }}
                          src={`https://hayyyak.com/Packages/${item?.image}`}
                          alt=""
                        />
                      </td>
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              UpdateGalleryImages(e.target.files[0], item?._id);
                            }}
                          />
                          <BiSolidEdit
                            className="text-primary fs-4"
                            type="file"
                            onClick={() => handleIconClick(item?._id)}
                          />
                          <MdDelete
                            onClick={() => {
                              handleDeleteGalleryimage(item?._id);
                            }}
                            className="text-danger"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Iternery */}
        <Modal
          show={show2}
          onHide={handleClose2}
          size="lg"
          className="anties_mdl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Itinerary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive bordered className="sdfsd-table-head">
              <thead>
                <th>Days</th>
                <th>Plan</th>
                <th>Action</th>
              </thead>
              <tbody>
                {ViewSchedule?.itinerary?.map((item) => {
                  return (
                    <tr>
                      <td className="fw-bold text-uppercase">{item?.day}</td>
                      <td>{parse(`<div>${item?.todaysplan}</div>`)}</td>
                      <td>
                        {" "}
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit
                            className="text-primary"
                            onClick={() => {
                              handleShow5(item?._id);
                              setViewSchedules(item);
                            }}
                          />
                          <MdDelete
                            className="text-danger"
                            onClick={() => {
                              handleDeleteItinerary(item?._id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose2}>
              Close
            </Button>
            {/* <Button variant="danger" onClick={handleClose2}>
              Save
            </Button> */}
          </Modal.Footer>
        </Modal>

        {/* Edit Packages */}
        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Edit Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-6">
                <div className=" mt-2">
                  <label className="fw-bold">Trip Name :</label>
                  <input
                    value={packageName}
                    type="text"
                    placeholder={ViewSchedules?.tripname}
                    className="vi_0"
                    onChange={(e) => setpackageName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className=" mt-2">
              <label className="fw-bold">Description :</label>
              <input
                value={childrenprice}
                onChange={(e) => setdescription(e.target.value)}
                as="area"
                placeholder={ViewSchedules?.tripdescription}
                className="vi_0"
              />
            </div>
            <div className="row">
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Adult Price :</label>
                  <input
                    value={adultprice}
                    onChange={(e) => setadultprice(e.target.value)}
                    type="text"
                    placeholder={ViewSchedules?.tripcost}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Children Price :</label>
                  <input
                    value={childrenprice}
                    onChange={(e) => setchildrenprice(e.target.value)}
                    type="text"
                    placeholder={ViewSchedules?.childrencost}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Infant Price :</label>
                  <input
                    value={petsprice}
                    onChange={(e) => setpetsprice(e.target.value)}
                    type="text"
                    placeholder={ViewSchedules?.petscost}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Adult Discount% :</label>
                  <input
                    value={adultdiscount}
                    onChange={(e) => setadultdiscount(e.target.value)}
                    type="text"
                    placeholder={`${ViewSchedules?.tripdiscount} %`}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Children Discount% :</label>
                  <input
                    value={childrendiscount}
                    onChange={(e) => setchildrendiscount(e.target.value)}
                    type="text"
                    placeholder={`${ViewSchedules?.childrendiscount} %`}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Infant Discount% :</label>
                  <input
                    value={petsdiscount}
                    onChange={(e) => setpetsdiscount(e.target.value)}
                    type="text"
                    placeholder={`${ViewSchedules?.petdiscount} %`}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className="do-sear mt-2">
                  <label className="fw-bold">
                    Country
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="cc1"
                    id="cc1"
                    class="vi_0"
                    onChange={(e) => setCountry1(e.target.value)}
                  >
                    <option>{ViewSchedules?.country}</option>
                    {CountryList?.map((Country) => (
                      <option value={Country?.isoCode}>{Country?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">State :</label>
                  <select
                    name="cc1"
                    id="cc1"
                    class="vi_0"
                    onChange={(e) => setState1(e.target.value)}
                  >
                    <option>{ViewSchedules?.state}</option>
                    {StateList?.map((state1) => (
                      <option value={state1?.isoCode}>{state1?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">City :</label>
                  <select
                    name="cc1"
                    id="cc1"
                    class="vi_0"
                    onChange={(e) => setCity1(e.target.value)}
                  >
                    <option>{ViewSchedules?.city}</option>
                    {CityList?.map((city1) => (
                      <option value={city1?.name}>{city1?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold"> Siteseeing :</label>
                  <select
                    name="cc1"
                    id="cc1"
                    class="vi_0"
                    onChange={(e) => setSiteSeeing(e.target.value)}
                  >
                    <option>{ViewSchedules?.signtseeing}</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold"> Hotels :</label>
                  <select
                    name="cc1"
                    id="cc1"
                    class="vi_0"
                    onChange={(e) => setHotels(e.target.value)}
                  >
                    <option>{ViewSchedules?.hotels}</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold"> Transport :</label>
                  <select
                    name="cc1"
                    id="cc1"
                    class="vi_0"
                    onChange={(e) => setTransport(e.target.value)}
                  >
                    <option>{ViewSchedules?.transport}</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold"> Days :</label>
                  <input
                    onChange={(e) => setdays(e.target.value)}
                    type="text"
                    placeholder={`${ViewSchedules?.tripduration}Days`}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Night:</label>
                  <input
                    onChange={(e) => setnights(e.target.value)}
                    type="text"
                    placeholder={`${ViewSchedules?.tripnight}Days`}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Location:</label>
                  <input
                    onChange={(e) => setlocation(e.target.value)}
                    type="text"
                    placeholder={`${ViewSchedules?.location}`}
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Terms & Conditions:</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={termsandcondition}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      settermsandcondition(data);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Suggestion:</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={Suggestion}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSuggestion(data);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className=" mt-2">
                  <label className="fw-bold">Policy:</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={policy}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setpolicy(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="danger" onClick={UpdatePackages}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* FAQ model */}
        <Modal
          show={show4}
          onHide={handleClose4}
          size="lg"
          className="anties_mdl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-end mb-2">
              {" "}
              <Button
                variant="danger"
                onClick={() => {
                  handleShow7();
                }}
              >
                Add
              </Button>
            </div>
            <Table bordered className="sdfsd-table-head">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Questions / Answer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ViewIternery?.faq?.map((item, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <div>
                        <p className="fw-bold d-flex gap-2 align-items-start">
                          Q {parse(`<div> ${item?.question}</div>`)}?
                        </p>
                        <p className="d-flex gap-2 align-items-start">
                          A {parse(`<div>${item?.answer}</div>`)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        className="d-flex gap-2 fs-4"
                        style={{ cursor: "pointer" }}
                      >
                        <MdDelete
                          className="text-danger"
                          onClick={() => {
                            handleDeleteFaq(item?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show7} onHide={handleClose7} className="anties_mdl">
          <Modal.Header closeButton>
            <Modal.Title className="text-danger"> ADD FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Question</label>
              <Form.Control 
              type="text" 
              placeholder="Enter Question.."
              onChange={(e)=>setQuestion(e.target.value)}
              value={Question}
              />
              <label>Answer</label>
              <Form.Control 
              type="text" 
              placeholder="Enter Answer.."
              onChange={(e)=>setAnswer(e.target.value)}
              value={Answer}
               />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose7}>
              Close
            </Button>
            <Button variant="danger" onClick={addFAQ}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Update Iternery */}
        <Modal show={show5} onHide={handleClose5} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Update <span className="text-danger">Itinerary</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <div>
                <label className="fw-bold text-danger">Day :</label>
                <br />
                <Form.Control
                  className="inputdesign"
                  placeholder={ViewSchedules?.day}
                  type="text"
                  onChange={(e) => setday(e.target.value)}
                />
              </div>
              <div>
                <label className="fw-bold text-danger">Plan :</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={todayplan}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    settodayplan(data);
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose5}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => UpdateIternery(ViewSchedule)}
            >
              Updates
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
                src="./img/delete-btn.png"
                alt=""
              />
              <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
              <p>This data will be removed permanently</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleClose3}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => DeleteTrip()}>
              <FontAwesomeIcon icon={faCancel} className=" me-2" />
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Details Model */}
        <Modal
          size="lg"
          show={show6}
          onHide={handleClose6}
          className="vendorli"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              TourPackage <span className="text-danger">Details</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive className="mb-4">
              <thead>
                <tr>
                  <th className="text-danger">Image</th>
                  <th className="text-danger">Package Name</th>
                  <th className="text-danger" width="45%">
                    Description
                  </th>
                  <th className="text-danger">Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src={`https://hayyyak.com/Packages/${DataView?.GalleryImage?.[0]?.image}`}
                      alt=""
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </td>
                  <td>
                    <h6>{DataView?.tripname}</h6>
                  </td>
                  <td>{DataView?.tripdescription}</td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      {DataView?.tripduration} / {DataView?.tripnight}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>

            <p className="fw-bold text-danger">Address :</p>
            <Table className="mb-4" responsive bordered>
              <tbody>
                <tr>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    City :
                  </td>
                  <td>{DataView?.city}</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    State :
                  </td>
                  <td>{DataView?.state}</td>
                </tr>
                <tr>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Country :
                  </td>
                  <td>{DataView?.country}</td>
                </tr>
              </tbody>
            </Table>

            <p className="fw-bold text-danger">Package Details :</p>
            <Table responsive bordered>
              <tbody>
                <tr>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Adult :
                  </td>
                  <td>{DataView?.tripcost?.toFixed(2)}</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Discount :
                  </td>
                  <td>{DataView?.tripdiscount}%</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Offer Price :
                  </td>
                  <td>{DataView?.offerprice}</td>
                </tr>
                <tr>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Children :
                  </td>
                  <td>{DataView?.childrencost?.toFixed(2)}</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Discount :
                  </td>
                  <td>{DataView?.childrendiscount} %</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Offer Price :
                  </td>
                  <td>{DataView?.childrenoffer}</td>
                </tr>
                <tr>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Infant :
                  </td>
                  <td>{DataView?.petscost?.toFixed(2)}</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Discount :
                  </td>
                  <td>{DataView?.petdiscount} %</td>
                  <td className="fw-bold" style={{ width: "160px" }}>
                    Offer Price :
                  </td>
                  <td>{DataView?.petoffer}</td>
                </tr>
              </tbody>
            </Table>
            <Table bordered className="mb-4">
              <tbody>
                <tr>
                  <td className="fw-bold" width="20%">
                    Terms and Conditions :
                  </td>
                  <td>{parse(`<div>${DataView?.termsandcondition}</div>`)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Suggestions :</td>
                  <td>{parse(`<div>${DataView?.Suggestion}</div>`)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Policy :</td>
                  <td>{parse(`<div>${DataView?.policy}</div>`)}</td>
                </tr>
              </tbody>
            </Table>

            <p className="fw-bold text-danger">Gallery :</p>
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={true}
              transitionDuration={500}
            >
              {DataView?.GalleryImage?.map((item, i) => {
                return (
                  <div>
                    <img
                      style={{
                        width: "100%",
                        height: "200px",
                        imageRendering: "pixelated",
                      }}
                      src={`https://hayyyak.com/Packages/${item?.image}`}
                    />
                  </div>
                );
              })}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default VendorPackList;
