import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import { BsFillChatDotsFill, BsSearch, BsWhatsapp } from "react-icons/bs";
// import { GrContact } from "react-icons/gr";
function AdminHeader() {
    const logOut = () => {
        sessionStorage.removeItem("admin");
        sessionStorage.removeItem("token");
        window.location.assign("/admin");
    };
    let admin = JSON.parse(sessionStorage.getItem("admin"));
    // if (!admin) {
    //   alert("Please login");
    //   window.location.assign("/admin");
    // } else
    return (
        <>
            <div
                className="header"
                style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
                <Navbar expand="lg" style={{ marginLeft: "4rem" }}>
                    <Container fluid>
                        <Navbar.Brand href="">
                            {admin?.profile ? (
                                <img
                                    src={`https://hayyyak.com/admin/${admin?.profile}`}
                                    alt="logo"
                                    style={{
                                        width: "75px",
                                        height: "75px",
                                        borderRadius: "50%",
                                        border: "2px solid black",
                                    }}
                                />
                            ) : (
                                <BiUserCircle style={{ fontSize: "35px", color: 'rgb(217 164 4)' }} />
                            )}

                            <div
                                style={{
                                    fontSize: "17px",
                                    fontWeight: "600",
                                    color: "#fff",
                                }}
                            >
                                {admin?.name}{" "}
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="ms-auto my-2 my-lg-0 "
                                navbarScroll
                            >
                                {/* <div className='d-flex justify-content-end align-items-center me-2 '>
                                    <div className="input-group search-width" style={{ width: '250px' }}>
                                        <span className="input-group-text" id="basic-addon1"><BsSearch /></span>
                                        <input type="text" className="form-control " placeholder="Search" aria-describedby="basic-addon1" />
                                    </div>
                                </div> */}

                                <Nav.Link href="" onClick={logOut} style={{ color: '#fff', border: '2px solid #fff', borderRadius: '10px' }}>
                                    <span>LOGOUT</span>
                                    <BiLogOutCircle
                                        style={{ fontSize: "25px", color: "rgb(255 192 0)" }}
                                    />
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}

export default AdminHeader;
