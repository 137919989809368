import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap/esm";
import Carousel from "react-grid-carousel";
import { TourPackages } from "./TourPackages";
import { TiTickOutline } from "react-icons/ti";
import axios from "axios";
import parse from "html-react-parser";
import { Testimonial } from "./Testimonial";
import { Link } from "react-router-dom";

export const Home = () => {
  // Get All Packages
  const [AddPackages, setAddPackages] = useState([]);
  const getAllPackages = async () => {
    axios
      .get("https://hayyyak.com/api/admin/home/getallpackage")
      .then(function (response) {
        console.log(response.data);
        setAddPackages(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // get Discount Banner
  const [DiscountBanner, steDiscountBanner] = useState([]);
  const getDiscountBanner = async () => {
    axios
      .get("https://hayyyak.com/api/admin/home/getdiscountbanner")
      .then(function (response) {
        console.log(response.data);
        steDiscountBanner(response.data.Discount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Get Tour Packages

  const [Tourpackages, setTourpackages] = useState([]);
  const [Tourpackages1, setTourpackages1] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(res.data.getpackages);

        let val = [...res.data.getpackages];
        val.shift();
        setTourpackages1(val);
      }
    } catch (error) {
      console.log(error);
    }
    setisLoading(false);
  };

  // Get activity
  const [GetActivity, setGetActivity] = useState([]);
  const GetActivityDetail = async () => {
    try {
      let response = await axios.get(
        "https://hayyyak.com/api/admin/activity/getactivitydetail"
      );
      if (response.status === 200) {
        setGetActivity(response.data.getActivity);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get Stays
  const [StaysDetails, setStaysDetails] = useState([]);
  const staysrooms = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/stays/staysrooms"
      );
      if (res.status === 200) {
        setStaysDetails(res.data.allstays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPackages();
    getDiscountBanner();
    getTourPackages();
    GetActivityDetail();
    staysrooms();
  }, []);

  return (
    <div>
      {/* PACKAGES */}

      <Container className="mb-4 " id="explore-world-carousel">
        <div className="text-center mb-4">
          <h2 className="home-all-headings">
            Explore <span>The World</span> Now!
          </h2>
          <p className="text-secondary">
            World's leading Travel website, Over 300,000+ Users worldwide.
          </p>
        </div>

        <Carousel cols={4} rows={1} gap={20} loop>
          {AddPackages?.map((item) => (
            <Carousel.Item key={item.id}>
              <div className="text-center packages package-1">
                <div>
                  <h2 className="sub-head">
                    {item?.AllPackageHeader} <br />{" "}
                    <span className="main-head">{item?.AllPackageTitle}</span>
                  </h2>
                </div>
                {/* <div>
                  <a href="/bookingpage" style={{ textDecoration: "none" }}>
                    <button className="package-btn">Book Now</button>
                  </a>
                </div> */}
                <div>
                  <img
                    src={`https://hayyyak.com/AllPackages/${item?.AllPackageImage}`}
                    alt=""
                    className="package-img"
                  />
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

      {/* Things To Do */}
      {/*<HomeThingsToDoPackage />*/}

      {/* TOP DESTINATION */}
      <Container className="margin-bottom" id='top-destination-main'>
        <div className="text-center mb-4 top-destination-heading">
          <h2 className="home-all-headings">
            Top <span>Destinations</span>{" "}
          </h2>
          <p className="text-secondary">
            World's leading Hotel Booking website,Over 30,000 Hotel rooms
            worldwide.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3" style={{ position: "relative" }}>
            <a href="/trippackage">
              {isLoading ? (
                <div>loading...</div>
              ) : (
                <div className="kaka">
                  <div className="gnjet">
                    <div>
                      <p className="fs-5">{Tourpackages?.length} Packages</p>
                    </div>
                    <div>
                      <h5>India</h5>
                    </div>
                  </div>
                  <img
                    src={`https://hayyyak.com/Packages/${Tourpackages[0]?.GalleryImage[0]?.image}`}
                    // src="./img/destination-img-2.jpg"
                    alt=""
                    className="destination-img"
                  />
                </div>
              )}
            </a>
          </div>

          <div className="col-lg-6">
            <div className="row">
              {isLoading ? (
                <>nulll</>
              ) : (
                Tourpackages1?.slice(0, 2)?.map((item, i) => {
                  return (
                    <div
                      className="col-lg-6 mb-3"
                      style={{ position: "relative" }}
                    >
                      <a href="/trippackage">
                        <div className="mamu">
                          <div className="gnjet">
                            <div>
                              <p className="fs-5">
                                {Tourpackages?.length} Packages
                              </p>
                            </div>
                            <div>
                              <h5>India</h5>
                            </div>
                          </div>
                          <img
                            src={`https://hayyyak.com/Packages/${item.GalleryImage[0]?.image}`}
                            alt=""
                            className="destination-img"
                          />
                        </div>
                      </a>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* TOUR PACKAGES */}
      <TourPackages />

      {/* DISCOUNT BANNER */}

      {/* <div className='margin-bottom' style={{
                position: "relative",
            }}>
                <div className='discount-bg'>
                    <div
                        style={{
                            backgroundImage: "url('./img/discount-bg.jpg')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'initial',
                            backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'fixed',
                            height: '450px',
                            padding: '20px'
                        }}>
                        <div className='discount-text'>
                            <Container>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div>
                                            <div className='d-flex gap-1 fs-4 discount-star'>
                                                <span><AiFillStar /></span>
                                                <span><AiFillStar /></span>
                                                <span><AiFillStar /></span>
                                                <span><AiFillStar /></span>
                                                <span><AiFillStar /></span>
                                            </div>
                                            <h2 className='discount-head'>STANDARDIZED BUDGET PACKAGES</h2>
                                            <hr />
                                            <span className='discount-price'>₹99/-</span>
                                            <div className='row justify-content-evenly'>
                                                <div className="col-lg-2 discount-icon">
                                                    <img src="./img/discount-icon-1.png" alt="" />
                                                    <span>Free WIFI</span>
                                                </div>
                                                <div className="col-lg-2 discount-icon">
                                                    <img src="./img/discount-icon-2.png" alt="" />
                                                    <span>Breakfast</span>
                                                </div>
                                                <div className="col-lg-2 discount-icon">
                                                    <img src="./img/discount-icon-3.png" alt="" />
                                                    <span>Pool</span>
                                                </div>
                                                <div className="col-lg-2 discount-icon">
                                                    <img src="./img/discount-icon-4.png" alt="" />
                                                    <span>Television</span>
                                                </div>
                                                <div className="col-lg-2 discount-icon">
                                                    <img src="./img/discount-icon-5.png" alt="" />
                                                    <span>GYM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6" style={{ position: 'relative' }}>
                                        <div className="go-bg">
                                            <span className='go'>Go</span>
                                            <br />
                                            <span className='go-stay'>Stays</span>
                                        </div>

                                        <div className='discount-offer'>
                                            <p className='or-1'>Get</p>
                                            <p className='or-2'>70%</p>
                                            <p className='or-3'>off</p>
                                            <p className='or-4'>use code : RG5481WERQ</p>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="margin-bottom" id="discount-carousel-main">
        <Carousel>
          {DiscountBanner?.map((item) => (
            <Carousel.Item interval={1000}>
              <img
                src={`https://hayyyak.com/DiscountBanner/${item?.OfferBanner}`}
                className="discount-carousel"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      {/* TOP SIGHT  */}
      <Container className="margin-bottom " id="top-sight-main">
        <div className="text-center mb-5 top-sight-heading">
          <h2 className="home-all-headings">
            Top <span>Sight Seeing</span> In This Month
          </h2>
          <p className="text-secondary">
            World's leading tour and travels Booking website,Over 30,000
            packages worldwide. Book travel packages and enjoy your holidays
            with distinctive experience
          </p>
        </div>

        <div className="row top-sight-packages justify-content-between">
          {Tourpackages?.slice(0, 4)?.map((item) => {
            return (
              <div className="col-lg-6 top-sight row justify-content-between mb-4">
                <img
                  src={`https://hayyyak.com/Packages/${item?.GalleryImage[0]?.image}`}
                  alt=""
                  className="col-lg-6 p-0"
                />
                <div className="col-lg-5 p-0 top-sight-packages-text">
                  <h3>
                    <span>{item?.tripcategory}</span> <br />
                    {item?.tripduration} Days / {item?.tripnight} Nights
                  </h3>
                  <p>{item?.tripdescription?.slice(0, 180)}... </p>
                  <Link
                    state={{ item: item }}
                    to="/trippackagedetails"
                    className="text-decoration-none sight-button"
                  >
                    <button className="pink-btn">More Info</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </Container>

      {/* TOP BRANDING */}

      <Container className="margin-bottom" id="top-branding-main">
        <div className="text-center mb-5 top-branding-heading">
          <h2 className="home-all-headings">
            Top <span>Branding</span> For This Month
          </h2>
          <p className="text-secondary">
            Book travel packages and enjoy your holidays with distinctive
            experience
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="brand-head">
              <h5>
                Top Branding <span>Stays</span>
              </h5>
            </div>
            <div>
              <ul
                style={{
                  listStyle: "none",
                  padding: "5px",
                  border: "1px solid #00000021",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                {StaysDetails?.slice(0, 5)?.map((item) => {
                  return (
                    <>
                      <li>
                        <Link
                          state={{ item: item }}
                          to="/hoteldetails"
                          className="top-brand"
                        >
                          <img
                            src={`https://hayyyak.com/VendorStay/${item?.propartfullimage}`}
                            alt=""
                          />
                          <div className="top-hotels-city">
                            <h5>{item?.propartyname}</h5>{" "}
                            <span>City: {item?.city}</span>
                          </div>
                          <p className="rate">
                            {item?.Rooms[0]?.adultdiscount}%off
                          </p>
                        </Link>
                      </li>
                      <hr />
                    </>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="brand-head">
              <h5>
                Top Branding <span>Packages</span>
              </h5>
            </div>
            <div>
              <ul
                style={{
                  listStyle: "none",
                  padding: "5px",
                  border: "1px solid #00000021",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                {Tourpackages?.slice(0, 5)?.map((item) => {
                  return (
                    <>
                      <li>
                        <Link
                          state={{ item: item }}
                          to="/trippackagedetails"
                          className="top-brand"
                        >
                          <img
                            src={`https://hayyyak.com/Packages/${item?.GalleryImage[0]?.image}`}
                            alt=""
                          />
                          <div className="top-hotels-city ps-3" >
                            <h5>{item?.tripcategory}</h5>{" "}
                            <span>Location: {item?.city}</span>
                          </div>
                          <p className="rate">{item?.tripdiscount}%off</p>
                        </Link>
                      </li>
                      <hr />
                    </>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="brand-head">
              <h5>
                Top Branding <span>Activities</span>
              </h5>
            </div>
            <div>
              <ul
                style={{
                  listStyle: "none",
                  padding: "5px",
                  border: "1px solid #00000021",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                {GetActivity?.slice(0, 5)?.map((item) => {
                  return (
                    <>
                      <li>
                        <Link
                          state={{ item: item }}
                          to="/multipleactivity"
                          className="top-brand"
                        >
                          <img
                            src={`https://hayyyak.com/ActivityCategory/${item?.activitycategories?.Image}`}
                            alt=""
                          />
                          <div className="jdhfg_01 top-hotels-city ps-4" >
                            <h5>
                              {parse(
                                `<div>${item?.activitycategories?.Title}</div>`
                              )}
                            </h5>{" "}
                            <span>Location: {item?.addlocation[0]?.City}</span>
                          </div>
                          <p className="rate">
                            {item?.addlocation[0]?.AdultDiscount}%off
                          </p>
                        </Link>
                      </li>
                      <hr />
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/* Testimonial */}
      <Testimonial />

      {/* Mobile App */}

      <div
        style={{
          backgroundImage: 'url("./img/cloud-bg.png")',
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <div className="row gap-5 home-mobile-app">
            <div className="col-lg-6">
              <img src="./img/mobile-app.png" alt="" className="img-fluid" />
            </div>
            <div className="col-lg-5 mobile-app">
              <h1>Have you tried our mobile app?</h1>
              <p className="mb-4">
                World's leading tour and travels Booking website,Over 30,000
                packages worldwide. Book travel packages and enjoy your holidays
                with distinctive experience
              </p>
              <ul>
                <li className="d-flex gap-2 mb-2">
                  <i>
                    <TiTickOutline />
                  </i>
                  <p className="fw-semibold">Easy Hotel Booking</p>
                </li>
                <li className="d-flex gap-2 mb-2">
                  <i>
                    <TiTickOutline />
                  </i>
                  <p className="fw-semibold"> Tour and Travel Packages</p>
                </li>
                <li className="d-flex gap-2 mb-2">
                  <i>
                    <TiTickOutline />
                  </i>
                  <p className="fw-semibold">Package Reviews and Ratings</p>
                </li>
                <li className="d-flex gap-2 mb-2">
                  <i>
                    <TiTickOutline />
                  </i>
                  <p className="fw-semibold">
                    Manage your Bookings, Enquiry and Reviews
                  </p>
                </li>
              </ul>
              <div>
                <img src="./img/android-app.png" alt="" />
                <img src="./img/ios-app.png" alt="" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
