import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal, Table } from 'react-bootstrap'
import { BsFillBusFrontFill } from 'react-icons/bs'
import { FaHotel } from 'react-icons/fa'
import HikingIcon from '@mui/icons-material/Hiking';
import axios from 'axios';
import { AiFillEye } from 'react-icons/ai';
import parse from 'html-react-parser'

function V_Activity_Dashboard() {
    const ActivityVendor = JSON.parse(sessionStorage.getItem("activityvendor"))

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => { setShow1(true); };


    const [bookedActivity, setbookedactivity] = useState([]);
    const [Traveler, setTraveler] = useState({});
    const GetBookedActivity = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/activity/getbookedactivity"
            );
            if (res.status === 200) {
                setbookedactivity(res.data.bookedActivity);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        GetBookedActivity()
    }, [])

    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-5 text-light p-3 mb-3"
                    style={{
                        backgroundColor: "rgb(71 171 21)",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold"> ACTIVITY</h6>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <HikingIcon className="fs-2" />
                        <span className="fs-2 fw-bold">{bookedActivity?.filter((ele) => ele?.ActivityId?.actiitiyid === ActivityVendor?._id)?.length}</span>
                    </div>
                    <p>{bookedActivity?.filter((ele) => ele?.ActivityId?.actiitiyid === ActivityVendor?._id)?.length} Booking of Activity</p>
                </div>
            </div>

            <div>
                <Table responsive bordered className="admin-table-head" id="Export-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Number</th>
                            <th>Activity Name</th>
                            <th>Activity Location</th>
                            <th>Travellers</th>
                            <th>Booking Date</th>
                            <th>Total Person</th>
                            <th>Actual Price</th>
                            <th>Discount(%)</th>
                            <th>Discount Price</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookedActivity?.filter((ele) => ele?.ActivityId?.actiitiyid === ActivityVendor?._id)?.slice(0, 5)?.map((item, i) => {
                            return (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item?.userId?.Fname} {item?.userId?.Lname}</td>
                                    <td>{item?.userId?.Email} </td>
                                    <td>{item?.userId?.Mobile} </td>
                                    <td>{parse(`<div>${item?.Title}</div>`)}</td>
                                    <td>
                                        {item?.location} <br />
                                        Country:{" "}
                                        <span style={{ color: "#d81d4a", fontSize: "18px" }}>
                                            {item?.country}
                                        </span>{" "}
                                        ,<br />
                                        State:{" "}
                                        <span style={{ color: "#d81d4a" }}>
                                            {item?.state}
                                        </span>{" "}
                                        ,<br />
                                        City:{" "}
                                        <span style={{ color: "#d81d4a" }}>
                                            {item?.city}
                                        </span>
                                    </td>
                                    <td>
                                        <AiFillEye
                                            style={{ cursor: 'pointer' }}
                                            className="text-danger fs-5"
                                            onClick={() => {
                                                setTraveler(item)
                                                handleShow1()
                                            }}
                                        />
                                    </td>
                                    <td>{item?.checkindate}</td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {item?.adultquantity}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {item?.childrenquantity}
                                        </span>{" "}
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            ₹{(item?.adultprice) / (1 - item?.adultdiscount / 100)}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            ₹{(item?.childrenprice) / (1 - item?.childrendiscount / 100)}
                                        </span>{" "}
                                        ,<br />
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            {item?.adultdiscount} %
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            {item?.childrendiscount} %
                                        </span>{" "}
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            ₹ {item?.adultprice}{" "}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            ₹ {item?.childrenprice}{" "}
                                        </span>{" "}
                                    </td>
                                    <td style={{ color: "green", fontSize: "18px" }}>₹{item?.AllTotalamount}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>


            {/* Traveller Model */}
            <Modal
                show={show1}
                onHide={handleClose1}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p className="fw-bold">Total Trvelers : <span className="text-danger">{Traveler?.TravelersData?.length}</span></p>
                    <Table bordered className="sdfsd-table-head">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Traveler Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Traveler?.TravelersData?.map((item, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item?.TravellerFirstName} {item?.TravellerLastName}</td>
                                </tr>
                            ))}
                            <tr>
                                <td className="fw-bold">Contact Details :</td>
                                <td>
                                    Email:{" "}
                                    <span style={{ color: "green", fontSize: "18px" }}>
                                        {Traveler?.TravelerEmalil}
                                    </span>{" "}
                                    ,<br />
                                    Number:{" "}
                                    <span style={{ color: "green" }}>
                                        {Traveler?.TravelerNumber}
                                    </span>{" "}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose1}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default V_Activity_Dashboard