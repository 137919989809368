import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import { BsSearch } from 'react-icons/bs';


function VendorSubscription() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show2, setShow2] = useState(false);




    // // get vehicle model
    const [getsubscriptions, setgetsubscriptions] = useState([]);
    const GetVendorSubscriptions = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/vendor/VendorGetSubscription"
            )
            if (res.status === 200) {
                setgetsubscriptions(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    console.log("getsubscriptions", getsubscriptions);
    // // Delete 
    const [Data, setData] = useState({});
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => { setShow2(true); setData(id); }

    const DeleteSubscription = async () => {
        try {
            const config = {
                url: "/DeleteSubscription/" + Data,
                baseURL: "https://hayyyak.com/api/vendor",
                method: 'delete',
                headers: { "Content-Type": "application/json" },
            };
            const response = await axios(config);
            if (response.status === 200) {
                notify(response.data.success);
                GetVendorSubscriptions();
                handleClose2();
            }
        } catch (error) {
            notify2(error.response ? error.response.data.error : "An error occurred");
        }
    };

    // Block and Unblock
    // const BlockVendor = async (blockId) => {
    //     try {
    //         const res = await axios.put(`https://hayyyak.com/api/BlockVendor/${blockId}`)
    //         if (res.status === 200) {
    //             notify(res.data.success)
    //             notify1(res.data.msg)
    //             GetVendorSubscriptions()
    //         }
    //     } catch (error) {
    //         notify2(error.response.data.error)
    //     }
    // }

    const [SearchItem, setSearchItem] = useState("");
    const SearchVendorSubscription = getsubscriptions.filter(item =>
        SearchItem === "" ||
        Object.values(item).some(
            value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
        )
    );

    useEffect(() => {
        GetVendorSubscriptions()
    }, [])

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Vendor Subscriptions :</p>
                    <div className="input-group col-lg-4 w-auto" >
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>
                </div>


                <div >
                    <Table responsive bordered className='sdfsd-table-head'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Vendor Type</th>
                                <th>Vendor Name</th>
                                <th>Vendor Number</th>
                                <th>Package Name</th>
                                <th>Package Price</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SearchVendorSubscription?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item?.VendorType}</td>
                                        <td>{item?.VendorName}</td>
                                        <td>{item?.VendorNumber}</td>
                                        <td>{item?.PackageName}</td>
                                        <td>₹{item?.PackagePrice}</td>
                                        <td>{item?.StartDate}</td>
                                        <td>{item?.EndDate}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                {/* <span
                                                    onClick={() => BlockVendor(item?.VendorID)}
                                                    className={item?.BlockVendor ? 'block-user' : 'unblock-user'}>
                                                    {item?.BlockVendor ? 'Block' : 'Unblock'}
                                                </span> */}
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item?._id)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}
                        </tbody>
                    </Table>
                </div>
            </Container>


            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeleteSubscription} ><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default VendorSubscription
