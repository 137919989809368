import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import HikingIcon from '@mui/icons-material/Hiking';
import axios from 'axios';

function V_Restaurant_Dashboard() {
    const RestaurantVendor = JSON.parse(sessionStorage.getItem("RestaurantVendor"))
    const [RestoCafe, setRestoCafe] = useState([]);
    const GetByIdRestaurantCafe = async () => {
      try {
        const res = await axios.get(
          `https://hayyyak.com/api/admin/resto/getrestoDetailsById/${RestaurantVendor?._id}`
        );
        if (res.status === 200) {
          setRestoCafe(res.data.resto);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      GetByIdRestaurantCafe();
    }, []);

    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-5 text-light p-3 mb-3"
                    style={{
                        backgroundColor: "rgb(205 175 26)",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold"> Restaurant and Cafe</h6>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <HikingIcon className="fs-2" />
                        <span className="fs-2 fw-bold">{RestoCafe?.length}</span>
                    </div>
                    <p>{RestoCafe?.length} Restaurant and Cafe</p>
                </div>
            </div>
        </Container>
    )
}

export default V_Restaurant_Dashboard