import React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
export const ThingsToDoDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ThingstoDo = location.state;
  console.log("ThingstoDo", ThingstoDo);
  return (
    <div>
      <Container>
        <h1>{ThingstoDo?.item?.thingstodoname}</h1>
        {/* <Box>
        <Rating name="read-only" value={4} readOnly className='fs-6' />
        </Box> */}

        <div>
          <span className="fs-5 fw-semibold my-4 ">
            {ThingstoDo?.item?.visitpackages?.length} places{" "}
          </span>
          <div className="row mb-3 mt-2 ">
            {ThingstoDo?.item?.visitpackages?.map((item, i) => {
              return (
                <div className="col-lg-4">
                  <div className="cafe_img">
                    <div
                      onClick={() => navigate("/things_todo_review", { state: { item } })}
                      className="text-decoration-none"
                    >
                      <img
                        src={`https://hayyyak.com/Thingstodo/${item?.gallery?.[0]?.images}`}
                        alt=""
                      />
                      <div className="mb-2">
                        <p className="fw-semibold fs-5 text-dark">
                          {i + 1}.{item?.visitname}
                        </p>
                        <Box>
                          <Rating
                            name="read-only"
                            value={4}
                            readOnly
                            className="fs-6"
                          />
                        </Box>
                        <p>
                          Open Now{" "}
                          <span className="text-danger">4:15 AM - 5:00 AM</span>{" "}
                        </p>
                        <div className="flex justify-content-between">
                          <p className="text-muted">
                            From{" "}
                            <span className="text-danger fs-5 fw-bold">
                              ₹{item?.price}
                            </span>
                          </p>
                          {/* <p className="text-muted">Historic Place</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};
