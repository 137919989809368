import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useEffect } from 'react'


function AddAllPackages() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    // Add All Packages
    const [AddPackageImage, setAddPackageImage] = useState("")
    const [AddPackageHeader, setAddPackageHeader] = useState("")
    const [AddPackageTitle, setAddPackageTitle] = useState("")
    const AddAllPackages = async () => {
        if (!AddPackageImage) {
            return notify1("Please Select Image")
        }
        if (!AddPackageHeader) {
            return notify1("Please Enter Header")
        }
        if (!AddPackageTitle) {
            return notify1("Please Enter Title")
        }
        try {
            const config = {
                url: "admin/home/addallpackages",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "content-type": "multipart/form-data" },
                data: {
                    AllPackageImage: AddPackageImage,
                    AllPackageHeader: AddPackageHeader,
                    AllPackageTitle: AddPackageTitle,
                }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    getAllPackages()
                    handleClose()
                    setAddPackageImage("")
                    setAddPackageHeader("")
                    setAddPackageTitle("")
                }
            })
        } catch (error) {
            notify1(error.response.data.error)
        }
    }

    // Get All Packages
    const [AddPackages, setAddPackages] = useState([])
    const getAllPackages = async () => {
        axios
            .get("https://hayyyak.com/api/admin/home/getallpackage")
            .then(function (response) {
                console.log(response.data);
                setAddPackages(response.data.success)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    console.log("AddPackagesssssss???", AddPackages);

    // Edit
    const [EditPackages, setEditPackages] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); setEditPackages(item) }
    const EditAllPackages = async () => {
        try {
            const config = {
                url: "admin/home/editallpackages/" + EditPackages?._id,
                baseURL: "https://hayyyak.com/api/",
                method: "put",
                headers: { "conetnt-type": "multipart/form-data" },
                data: {
                    AllPackageImage: AddPackageImage,
                    AllPackageHeader: AddPackageHeader,
                    AllPackageTitle: AddPackageTitle,
                }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    getAllPackages()
                    handleClose1()
                    setAddPackageImage("")
                    setAddPackageHeader("")
                    setAddPackageTitle("")
                }
            })
        } catch (error) {
            notify1(error.res.data.error)
        }
    }


    // Delete
    const [deletePackage, setdeletePackage] = useState("")
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setdeletePackage(item) };
    const DeletAllPackage = async () => {
        try {
            const config = {
                url: "admin/home/deletepackage/" + deletePackage?._id,
                baseURL: "https://hayyyak.com/api",
                method: "delete",
                headers: { "content-type": "application/json" },
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    getAllPackages()
                    handleClose2()
                }
            })
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    useEffect(() => {
        getAllPackages()
    }, [])

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Explore The World Now :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Packages</button>
                </div>

                {/* <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn">Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-describedby="basic-addon1" />
                    </div>

                </div> */}
                <div >
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Header</th>
                                <th>Title</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AddPackages?.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <img src={`https://hayyyak.com/AllPackages/${item?.AllPackageImage}`} alt="" width="100px" height="100px" />
                                        </td>
                                        <td>{item?.AllPackageHeader}</td>
                                        <td>{item?.AllPackageTitle}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setAddPackageImage(e.target.files[0])}
                        />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold'>Header :</label>
                        <input type="text" placeholder='Header' className="vi_0" onChange={(e) => setAddPackageHeader(e.target.value)} />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold'>Title :</label>
                        <input type="text" placeholder='Title' className="vi_0" onChange={(e) => setAddPackageTitle(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddAllPackages}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Choose Image :</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            onChange={(e) => setAddPackageImage(e.target.files[0])}
                        />
                    </div>
                    <div className="mb-2">
                        <label className='fw-bold'>Header :</label>
                        <input type="text" placeholder='Header' className="vi_0" onChange={(e) => setAddPackageHeader(e.target.value)} />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold'>Title :</label>
                        <input type="text" placeholder='Title' className="vi_0" onChange={(e) => setAddPackageTitle(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditAllPackages}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeletAllPackage}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AddAllPackages
