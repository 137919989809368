import React from "react";
import { Container } from "react-bootstrap";
import { FiFacebook } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { BsTwitter } from "react-icons/bs";
import { TfiYoutube } from "react-icons/tfi";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import parse from "html-react-parser";

export const Footer = () => {
  // Get footer
  const [getFooterData, setgetFooterData] = useState([]);
  const GetFooter = async () => {
    axios
      .get("https://hayyyak.com/api/admin/home/getfooter")
      .then(function (response) {
        setgetFooterData(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("getFooterData", getFooterData);

  // about
  const [aboutdetail, setaboutdetail] = useState([]);
  const AboutUs = async () => {
    try {
      let res = await axios.get(
        "https://hayyyak.com/api/admin/customeraboutus"
      );
      if (res.status === 200) {
        setaboutdetail(res.data.success);
      }
    } catch (error) {
      setaboutdetail(error.response.data.error);
    }
  };
  console.log("aboutdetail", aboutdetail);

  useEffect(() => {
    GetFooter();
    AboutUs();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: 'url("./img/blue-bg.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
        }}
      >
        <Container className="p-5 footer-container">
          <div className="row">
            <div className="col-lg-4 tips">
              <h3 className="tips-head">TIPS BEFORE TRAVEL</h3>
              {getFooterData?.map((item) => {
                return (
                  <>
                    <div className="d-flex gap-4 mb-4">
                      <img
                        src={`https://hayyyak.com/Footer/${item?.Image}`}
                        alt=""
                      />
                      <div>
                        <h5 className="fw-bold">{item?.Header}</h5>
                        <p>{item?.Description}</p>
                      </div>
                    </div>
                  </>
                );
              })}

              {/* <div className='d-flex gap-4 mb-4'>
                                <img src="./img/tips-2.png" alt="" />
                                <div>
                                    <h5 className='fw-bold'>Register with your embassy</h5>
                                    <p>Mauris efficitur, ante sit amet rhoncus malesuada, orci justo sollicitudin.</p>
                                </div>
                            </div>

                            <div className='d-flex gap-4 mb-4'>
                                <img src="./img/tips-3.png" alt="" />
                                <div>
                                    <h5 className='fw-bold'>Always have local cash</h5>
                                    <p>Aliquam pretium id justo eget tristique. Aenean feugiat vestibulum blandit.</p>
                                </div>
                            </div> */}
            </div>

            <div className="col-lg-8 ">
              <h3 className="tips-head">ABOUT US</h3>

              <div className="d-flex gap-4 mb-4 align-items-start">
                <img
                  src={`https://hayyyak.com/AboutUs/${aboutdetail[0]?.Image}`}
                  alt=""
                  className="footer-aboutus-img"
                  style={{ width: "15%", height: "90px" }}
                />
                <div>
                  <p className="">
                    {parse(
                      `<div>${aboutdetail[0]?.Description?.slice(
                        0,
                        380
                      )}....</div>`
                    )}
                  </p>
                  <a href="/aboutus" className="text-decoration-none">
                    <button className="pink-btn">Know More</button>
                  </a>
                </div>
              </div>

              <h3 className="tips-head">ARRANGEMENT & HELPS</h3>

              <div className="row mb-3 arrangement-images-part">
                <div className="col-lg-6 ">
                  <img
                    src="./img/aggrement-img-1.png"
                    alt=""
                    className="aggreement-img"
                    style={{ width: "250px", height: "60px" }}
                  />
                </div>
                <div className="col-lg-6 ">
                  <img
                    src="./img/aggrement-img-2.png"
                    alt=""
                    className="aggreement-img"
                    style={{ width: "250px", height: "60px" }}
                  />
                </div>
              </div>
              <div className="row arrangement-images-part">
                <div className="col-lg-6 ">
                  <img
                    src="./img/aggrement-img-3.png"
                    alt=""
                    className="aggreement-img"
                    style={{ width: "250px", height: "60px" }}
                  />
                </div>
                <div className="col-lg-6 ">
                  <img
                    src="./img/aggrement-img-4.png"
                    alt=""
                    className="aggreement-img"
                    style={{ width: "250px", height: "60px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="footer-1">
        <Container>
          {/* <div className="row justify-content-between mb-5">
                        <div className="col-lg-4"
                            style={{
                                backgroundImage: 'url("./img/footer-img-1.png")',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                padding: '22px',
                                width: '250px'
                            }}>
                            <div className='disco text-center'>
                                <h3>30% <span>OFF</span></h3>
                                <h5 className='text-light'>Eiffel Tower,Rome</h5>
                                <p className='text-light mb-2'>valid only for 24th Dec</p>
                                <button className='pink-btn'>Book Now</button>
                            </div>
                        </div>

                        <div className="col-lg-4"
                            style={{
                                backgroundImage: 'url("./img/footer-img-2.png")',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                padding: '22px',
                                width: '250px'
                            }}>
                            <div className='disco text-center'>
                                <h3>42% <span>OFF</span></h3>
                                <h5 className='text-light'>Colosseum,Burj Al Arab</h5>
                                <p className='text-light mb-2'>valid only for 18th Nov</p>
                                <button className='pink-btn'>Book Now</button>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <h5 className='mb-4' style={{ color: 'rgb(172, 173, 175)' }}>
                                MOST POPULAR VACATIONS
                            </h5>

                            <ul className='location'>
                                <li>
                                    <a href="">Angkor Wat</a>
                                </li>
                                <li>
                                    <a href="">Buckingham Palace</a>
                                </li>
                                <li>
                                    <a href="">High Line</a>
                                </li>
                                <li>
                                    <a href="">Sagrada Família</a>
                                </li>
                                <li>
                                    <a href="">Statue of Liberty </a>
                                </li>
                                <li>
                                    <a href="">High Line</a>
                                </li>
                                <li>
                                    <a href="">Sagrada Família</a>
                                </li>
                                <li>
                                    <a href="">Statue of Liberty </a>
                                </li>
                                <li>
                                    <a href="">Angkor Wat</a>
                                </li>
                                <li>
                                    <a href="">Buckingham Palace</a>
                                </li>
                                <li>
                                    <a href="">High Line</a>
                                </li>
                                <li>
                                    <a href="">Sagrada Família</a>
                                </li>
                                <li>
                                    <a href="">Statue of Liberty </a>
                                </li>
                                <li>
                                    <a href="">High Line</a>
                                </li>
                                <li>
                                    <a href="">Sagrada Família</a>
                                </li>
                                <li>
                                    <a href="">Statue of Liberty </a>
                                </li>
                            </ul>

                        </div>
                    </div> */}

          <div className="row footer1-maindiv">
            <div className="col-lg-3">
              <h5 className="mb-4 footer1-heading" style={{ color: "rgb(172, 173, 175)" }}>
                HOLIDAY TOUR & TRAVELS
              </h5>
              <p style={{ color: "rgb(172 173 175 / 52%)" }}>
                {parse(
                  `<div>${aboutdetail[0]?.Description?.slice(0, 140)}....</div>`
                )}
              </p>
            </div>

            <div className="col-lg-3">
              <h5 className="mb-4 footer1-heading" style={{ color: "rgb(172, 173, 175)" }}>
                ADDRESS & CONTACT INFO
              </h5>
              <p style={{ color: "rgb(172 173 175 / 52%)" }}>
                28800 Orchard Lake Road, Suite 180 Farmington Hills, U.S.A.
                Landmark : Next To Airport
              </p>
              <h5 style={{ color: "rgb(172, 173, 175)" }}>
                PHONE: +91 9876543210
              </h5>
            </div>

            <div className="col-lg-3">
              <h5 className="mb-4 footer1-heading" style={{ color: "rgb(172, 173, 175)" }}>
                SUPPORT & HELP
              </h5>
              <ul className="footer-nav">
                <li>
                  <a href="/aboutus">About Us</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/privacypolicy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3">
              <h5 className="mb-4 footer1-heading" style={{ color: "rgb(172, 173, 175)" }}>
                FOLLOW WITH US
              </h5>
              <p style={{ color: "rgb(172 173 175 / 52%)" }}>
                Join the thousands of other There are many variations of
                passages of Lorem Ipsum available
              </p>
              <ul
                className="d-flex gap-3 footer-social-icons"
                style={{ listStyle: "none", padding: "5px" }}
              >
                <li>
                  <a href="" className="fs-5">
                    <FiFacebook />
                  </a>
                </li>
                <li>
                  <a href="" className="fs-5">
                    <FcGoogle />
                  </a>
                </li>
                <li>
                  <a href="" className="fs-5">
                    <BsTwitter />
                  </a>
                </li>
                <li>
                  <a href="" className="text-danger fs-5">
                    <TfiYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      {/* <div className="copy">
        <Container>
          <p>&copy; Copyright 2023. ParNets All Rights Reserved.</p>
        </Container>
      </div> */}
    </>
  );
};
