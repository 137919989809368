import React, { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";

function TourCategory() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Add Tour Category
  const [TourCategory, setTourCategory] = useState("");
  const AddTourCategory = async () => {
    if (!TourCategory) {
      return notify1("Please Enter Categoty Name");
    }
    try {
      const config = {
        url: "tourpackage/addtourcategory",
        baseURL: "https://hayyyak.com/api/admin",
        method: "post",
        header: { "content-type": "application/json" },
        data: {
          TourCategory: TourCategory,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          setTourCategory("")
          getTourcategory();
          handleClose();
        }
      });
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Get Tour Category Data
  const [getTourCat, setgetTourCat] = useState([]);
  const getTourcategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/tourpackage/gettourcategory")
      .then(function (response) {
        setgetTourCat(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Delete
  const [Data, setData] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteTourCategory = async () => {
    try {
      const config = {
        url: "/deletetourcategory/" + Data?._id,
        baseURL: "https://hayyyak.com/api/admin/tourpackage",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const response = await axios(config);
      if (response.status === 200) {
        notify(response.data.success);
        getTourcategory();
        handleClose2();
      }
    } catch (error) {
      notify2(error.response ? error.response.data.error : "An error occurred");
    }
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");

  useEffect(() => {
    getTourcategory();
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">
            Tour Package Category :
          </p>
          <button className="pink-btn" onClick={handleShow}>
            Add Category
          </button>
        </div>

        <div className="row p-2 align-items-end  mb-3 nbjhasd_0">
          <div
            className="input-group col-lg-4"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>
        <div>
          <Table responsive bordered className="sdfsd-table-head text-center">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Category Name</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {getTourCat?.map((item, i) => {
                if (
                  SearchItem === "" ||
                  item?.TourCategory?.toLowerCase()?.includes(
                    SearchItem?.toLowerCase()
                  )
                ) {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.TourCategory}</td>
                      <td>{moment(item?.createdAt).format("MM/DD/YYYY")}</td>
                      <td>
                        <div className=" fs-4" style={{ cursor: "pointer" }}>
                          <MdDelete
                            className="text-danger"
                            onClick={() => handleShow2(item)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                }
                return null; // or any other default value if the condition is not met
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* Add Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" mb-2">
            <label className="fw-bold">Category Name :</label>
            <input
              type="text"
              placeholder="Category Name...!"
              className="vi_0"
              onChange={(e) => setTourCategory(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddTourCategory}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal*/}
      {/* <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Taxi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold'>Choose Image :</label>
                        <input
                            type="file"
                            placeholder="Name"
                            className="vi_0"
                        />
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Name :</label>
                            <input type="text" placeholder='Name' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Category :</label>
                            <Form.Select aria-label="Default select example" className='vi_0'>
                                <option>Select Category</option>
                                <option value="1">Hatchback</option>
                                <option value="2">Sedan</option>
                                <option value="3">Suv</option>
                                <option value="3">Innova</option>
                                <option value="3">Inova Crysta</option>
                                <option value="3">Tempo Traveler</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Model :</label>
                            <input type="text" placeholder='Model' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Ac / Non-Ac :</label>
                            <input type="text" placeholder='AC' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Color :</label>
                            <input type="text" placeholder='Color' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Fuel Type:</label>
                            <input type="text" placeholder='Fuel' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle BasePrice :</label>
                            <input type="text" placeholder='Price' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Trip Type:</label>
                            <input type="text" placeholder='Trip' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Luggage :</label>
                            <input type="text" placeholder='Luggage' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Id:</label>
                            <input type="text" placeholder='ID' className="vi_0" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Night Time :</label>
                            <input type="time" placeholder='Time' className="vi_0" />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Night Charge:</label>
                            <input type="text" placeholder='Charges' className="vi_0" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose1}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}

      {/* Delete Modal */}

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteTourCategory}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default TourCategory;
