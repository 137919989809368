import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from 'moment'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function AdminVehicleRental() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [RentCity, setRentCity] = useState("")
    const [RentPackage, setRentPackage] = useState("")
    const [RentVehicleCatrgoey, setRentVehicleCatrgoey] = useState("")
    const [RentVehicleModel, setRentVehicleModel] = useState("")
    const [RentVehicleType, setRentVehicleType] = useState("")
    const [RentFuelType, setRentFuelType] = useState("")
    const [RentPerKmPrice, setRentPerKmPrice] = useState("")
    const [RentPerHrPrice, setRentPerHrPrice] = useState("")
    const [RentPackagePrice, setRentPackagePrice] = useState("")
    const [RDriverAllowance, setRDriverAllowance] = useState("")
    const [RNightDriveAllowance, setRNightDriveAllowance] = useState("")
    const [RentportCGST, setRentportCGST] = useState("")
    const [RentportSGST, setRentportSGST] = useState("")
    const [RentportIGST, setRentportIGST] = useState("")
    const [RentWaitingCharge, setRentWaitingCharge] = useState("")
    const [RentBaseKmPrice, setRentBaseKmPrice] = useState("")
    const [RentPerMPrice, setRentPerMPrice] = useState("")
    const [Cancellation, setCancellation] = useState("")

    // Add  
    const AddRental = async () => {
        try {
            if (
                !RentCity ||
                !RentPackage ||
                !RentVehicleCatrgoey ||
                !RentVehicleModel ||
                !RentVehicleType ||
                !RentFuelType ||
                !RentPerKmPrice ||
                !RentPerHrPrice ||
                !RentPackagePrice ||
                !RDriverAllowance ||
                !RNightDriveAllowance ||
                !RentportCGST ||
                !RentportSGST ||
                !RentportIGST ||
                !RentWaitingCharge ||
                !RentBaseKmPrice ||
                !RentPerMPrice ||
                !Cancellation
            ) {
                notify1("Please fill in all the fields");
                return false;
            }
            const config = {
                url: "/admin/taxi/addRental",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "Content-Type": "application/json" },
                data: {
                    RentCity: RentCity,
                    RentPackage: RentPackage,
                    RentVehicleCatrgoey: RentVehicleCatrgoey,
                    RentVehicleModel: RentVehicleModel,
                    RentVehicleType: RentVehicleType,
                    RentFuelType: RentFuelType,
                    RentPerKmPrice: RentPerKmPrice,
                    RentPerHrPrice: RentPerHrPrice,
                    RentPackagePrice: RentPackagePrice,
                    RDriverAllowance: RDriverAllowance,
                    RNightDriveAllowance: RNightDriveAllowance,
                    RentportCGST: RentportCGST,
                    RentportSGST: RentportSGST,
                    RentportIGST: RentportIGST,
                    RentWaitingCharge: RentWaitingCharge,
                    RentBaseKmPrice: RentBaseKmPrice,
                    RentPerMPrice: RentPerMPrice,
                    Cancellation: Cancellation,
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose()
                GetRental()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Get 
    const [RentalPackageData, setRentalPackageData] = useState([])
    const GetRental = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/taxi/getRental"
            );
            if (res.status === 200) {
                setRentalPackageData(res.data.success)
                setnochangedata(res.data.success)
                setPagination(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }
    console.log("RentalPackageData", RentalPackageData);
    // get vehicle model
    const [getVehicleModelData, setgetVehicleModelData] = useState([]);
    const GetVehicleModel = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/taxi/getvehiclemodel"
            )
            if (res.status === 200) {
                setgetVehicleModelData(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetRental()
        GetVehicleModel()
    }, [])

    // Edit 
    const [EditRentalData, setEditRentalData] = useState({})
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (id) => { setShow1(true); setEditRentalData(id) }
    const EditRental = async () => {
        try {
            const config = {
                url: "/admin/taxi/editRental/" + EditRentalData,
                baseURL: "https://hayyyak.com/api",
                method: "put",
                headers: { "Content-Type": "application/json" },
                data: {
                    RentCity: RentCity,
                    RentPackage: RentPackage,
                    RentVehicleCatrgoey: RentVehicleCatrgoey,
                    RentVehicleModel: RentVehicleModel,
                    RentVehicleType: RentVehicleType,
                    RentFuelType: RentFuelType,
                    RentPerKmPrice: RentPerKmPrice,
                    RentPerHrPrice: RentPerHrPrice,
                    RentPackagePrice: RentPackagePrice,
                    RDriverAllowance: RDriverAllowance,
                    RNightDriveAllowance: RNightDriveAllowance,
                    RentportCGST: RentportCGST,
                    RentportSGST: RentportSGST,
                    RentportIGST: RentportIGST,
                    RentWaitingCharge: RentWaitingCharge,
                    RentBaseKmPrice: RentBaseKmPrice,
                    RentPerMPrice: RentPerMPrice,
                    Cancellation: Cancellation,
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose1()
                GetRental()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Delete 
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => { setShow2(true); setDeleteRentalData(id) }
    const [DeleteRentalData, setDeleteRentalData] = useState("");
    const DeleteRental = async () => {
        try {
            const config = {
                url: "admin/taxi/deleteRental/" + DeleteRentalData,
                baseURL: "https://hayyyak.com/api/",
                method: "delete",
                headers: { "Content-Type": "application/json" }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose2()
                GetRental()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return notify1("Please select from date");
        if (!tod) return notify1("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setPagination(abc);
        setRentalPackageData(abc);
    };


    // Search
    const [SearchItem, setSearchItem] = useState("");

    //Pagination
    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(pagination?.length / usersPerPage);
    const changePage = (selected) => {
        setPageNumber(selected);
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Rental Package :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Package</button>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-3" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-1 export-btn">
                        <ReactHTMLTableToExcel
                            id="export-to-excel"
                            table="Export-table"
                            filename="bookedActivity"
                            sheet="bookedActivity"
                            buttonText="Export"
                        />
                    </div>
                </div>

                <div >
                    <Table responsive bordered className='admin-table-head' id="Export-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>City</th>
                                <th>Package Name</th>
                                <th>Vehicle Category</th>
                                <th>Vehicle Model</th>
                                <th>Vehicle Type</th>
                                <th>Fuel Type</th>
                                <th>Package Price</th>
                                <th>Base Hr</th>
                                <th>Base KM</th>
                                <th>Base KM Price</th>
                                <th>First Extra KM</th>
                                <th>First Extra KM Price</th>
                                <th>Per Minute Price</th>
                                {/* <th>Driver Allowances</th> */}
                                {/* <th>Night Driver Allowances <br /> (10:00 pm - 06:00 am)</th> */}
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>IGST</th>
                                <th>Waiting Charges</th>
                                <th>Cancellation policy</th>
                                <th>Package Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {RentalPackageData?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                if (
                                    SearchItem === "" ||
                                    Object.values(item).some(
                                        value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
                                    )
                                )
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item?.RentCity}</td>
                                            <td>{item?.RentPackage}</td>
                                            <td>{item?.RentVehicleCatrgoey}</td>
                                            <td>{item?.RentVehicleModel}</td>
                                            <td>{item?.RentVehicleType}</td>
                                            <td>{item?.RentFuelType}</td>
                                            <td>{item?.RentPackagePrice}</td>
                                            <td>{item?.RentPerHrPrice}</td>
                                            <td>{item?.RentPerKmPrice}</td>
                                            <td>{item?.RentBaseKmPrice}</td>
                                            <td>{item?.RDriverAllowance}</td>
                                            <td>{item?.RNightDriveAllowance}</td>
                                            <td>{item?.RentPerMPrice}</td>
                                            <td>{item?.RentportCGST}</td>
                                            <td>{item?.RentportSGST}</td>
                                            <td>{item?.RentportIGST}</td>
                                            <td>{item?.RentWaitingCharge}</td>
                                            <td>{item?.Cancellation}</td>
                                            <td>{item?.createdAt}</td>
                                            <td>
                                                <div className="d-flex gap-4 fs-4" style={{ cursor: 'pointer' }}>
                                                    <BiSolidEdit className='text-primary' onClick={() => handleShow1(item?._id)} />
                                                    <MdDelete className='text-danger' onClick={() => handleShow2(item?._id)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>)
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className='my-3'>
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            color="primary"
                            onChange={(event, value) => {
                                changePage(value - 1)
                            }}
                        />
                    </Stack>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal size='lg' show={show} onHide={handleClose} className='amenties-modal'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>City :</label>
                            <input type="text" placeholder='city' className="vi_0" onChange={(e) => setRentCity(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Package Name :</label>
                            <input type="text" placeholder='Package' className="vi_0" onChange={(e) => setRentPackage(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Vehicle Category :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentVehicleCatrgoey(e.target.value)}>
                                <option>Open this select menu</option>
                                {getVehicleModelData?.map((item) => {
                                    return (<>
                                        <option value={item?.vehiclecategory}>{item?.vehiclecategory}</option>
                                    </>)
                                })}
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Vehicle Model :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentVehicleModel(e.target.value)}>
                                <option>Open this select menu</option>
                                {getVehicleModelData?.filter((ele) => ele?.vehiclecategory == RentVehicleCatrgoey)?.map((item) => {
                                    return (<>
                                        <option value={item?.vehicleModel}>{item?.vehicleModel}</option>
                                    </>)
                                })}
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Vehicle Type :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentVehicleType(e.target.value)}>
                                <option>Open this select menu</option>
                                <option value="AC">AC</option>
                                <option value="NON-AC">NON-AC</option>
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Fule Type :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentFuelType(e.target.value)}>
                                <option>Open this select menu</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Electric">Electric</option>
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Base Km :</label>
                            <input type="text" placeholder='Km ' className="vi_0" onChange={(e) => setRentPerKmPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Base Hr :</label>
                            <input type="text" placeholder='Hour' className="vi_0" onChange={(e) => setRentPerHrPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Base Km Price :</label>
                            <input type="text" placeholder='base km price' className="vi_0" onChange={(e) => setRentBaseKmPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Per Minute Price :</label>
                            <input type="text" placeholder='per minute' className="vi_0" onChange={(e) => setRentPerMPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Package Price :</label>
                            <input type="text" placeholder='Package Price' className="vi_0" onChange={(e) => setRentPackagePrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>First Extra Km :</label>
                            <input type="text" placeholder='Km' className="vi_0" onChange={(e) => setRDriverAllowance(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Per Extra Km Price :</label>
                            <input type="text" placeholder='price' className="vi_0" onChange={(e) => setRNightDriveAllowance(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>CGST :</label>
                            <input type="text" placeholder='CGST ' className="vi_0" onChange={(e) => setRentportCGST(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>SGST :</label>
                            <input type="text" placeholder='SGST' className="vi_0" onChange={(e) => setRentportSGST(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>IGST :</label>
                            <input type="text" placeholder='IGST' className="vi_0" onChange={(e) => setRentportIGST(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Waiting Charges :</label>
                            <input type="text" placeholder='Waiting Charges' className="vi_0" onChange={(e) => setRentWaitingCharge(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Cancellation :</label>
                            <input type="text" placeholder='Cancelltaion' className="vi_0" onChange={(e) => setCancellation(e.target.value)} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddRental}>
                        Add Package
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal size='lg' className='amenties-modal' show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>City :</label>
                            <input type="text" placeholder='city' className="vi_0" onChange={(e) => setRentCity(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Package Name :</label>
                            <input type="text" placeholder='Package' className="vi_0" onChange={(e) => setRentPackage(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Vehicle Category :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentVehicleCatrgoey(e.target.value)}>
                                <option>Open this select menu</option>
                                {getVehicleModelData?.map((item) => {
                                    return (<>
                                        <option value={item?.vehiclecategory}>{item?.vehiclecategory}</option>
                                    </>)
                                })}
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Vehicle Model :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentVehicleModel(e.target.value)}>
                                <option>Open this select menu</option>
                                {getVehicleModelData?.filter((ele) => ele?.vehiclecategory == RentVehicleCatrgoey)?.map((item) => {
                                    return (<>
                                        <option value={item?.vehicleModel}>{item?.vehicleModel}</option>
                                    </>)
                                })}
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Vehicle Type :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentVehicleType(e.target.value)}>
                                <option>Open this select menu</option>
                                <option value="AC">AC</option>
                                <option value="NON-AC">NON-AC</option>
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Fule Type :</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setRentFuelType(e.target.value)}>
                                <option>Open this select menu</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Electric">Electric</option>
                            </Form.Select>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Base Km :</label>
                            <input type="text" placeholder='Km ' className="vi_0" onChange={(e) => setRentPerKmPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Base Hr :</label>
                            <input type="text" placeholder='Hour' className="vi_0" onChange={(e) => setRentPerHrPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Base Km Price :</label>
                            <input type="text" placeholder='base km price' className="vi_0" onChange={(e) => setRentBaseKmPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Per Minute Price :</label>
                            <input type="text" placeholder='base km price' className="vi_0" onChange={(e) => setRentPerMPrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Package Price :</label>
                            <input type="text" placeholder='Package Price' className="vi_0" onChange={(e) => setRentPackagePrice(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>First Extra Km :</label>
                            <input type="text" placeholder='Km' className="vi_0" onChange={(e) => setRDriverAllowance(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Per Extra Km Price :</label>
                            <input type="text" placeholder='price' className="vi_0" onChange={(e) => setRNightDriveAllowance(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>CGST :</label>
                            <input type="text" placeholder='CGST' className="vi_0" onChange={(e) => setRentportCGST(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>SGST :</label>
                            <input type="text" placeholder='SGST' className="vi_0" onChange={(e) => setRentportSGST(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>IGST :</label>
                            <input type="text" placeholder='IGST' className="vi_0" onChange={(e) => setRentportIGST(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Waiting Charges :</label>
                            <input type="text" placeholder='Waiting Charges' className="vi_0" onChange={(e) => setRentWaitingCharge(e.target.value)} />
                        </Col>
                        <Col lg={6} className="mb-2">
                            <label className='fw-bold'>Cancellation :</label>
                            <input type="text" placeholder='Cancelltaion' className="vi_0" onChange={(e) => setCancellation(e.target.value)} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditRental}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button onClick={DeleteRental} variant="danger"><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default AdminVehicleRental
