import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export const Register = () => {
  const navigation = useNavigate();

  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Cpassword2, setCpassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var f_Name = /^[a-zA-Z][a-zA-Z]/;
  const passRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const RegisterCustomer = async () => {
    if (!Fname) {
      return alert("Please Enter Your First Name");
    }
    if (!Fname.match(f_Name)) {
      return alert("Please Enter Your Valid Name");
    }

    if (!Lname) {
      return alert("Please Enter Your Last Name");
    }
    if (!Lname.match(f_Name)) {
      return alert("Please Enter Your Valid Last Name");
    }
    if (!Mobile) {
      return alert("Please Enter Your Number");
    }
    if (Mobile.length !== 10) {
      return alert("Number Should be 10 digit");
    }
    if (!Mobile.match(mobilevalid)) {
      return alert("Please Enter valid Number");
    }

    if (!Email) {
      return alert("Please Enter Your Email");
    }
    if (!Email.match(validRegex)) {
      return alert("Please Enter Your valid Email");
    }
    if (!passRegex.test(Password)) {
      return alert(
        "Please Enter a Valid Password.\nPassword must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character."
      );
    }
    if (Password !== Cpassword2) {
      return alert("password & Confirm password does not match");
    }
    try {
      const config = {
        url: "/customer/registercustomer",
        baseURL: "https://hayyyak.com/api",
        method: "POST",
        Headers: { "content-type": "application-json" },
        data: {
          Fname: Fname,
          Lname: Lname,
          Mobile: Mobile,
          Email: Email,
          Password: Password,
        },
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          alert(res.data.success);
          window.location.assign("/login");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
      // console.log(error);
    }
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: 'url("./img/header-bg-img-3.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          padding: "68px",
        }}
      >
        <Container>
          <div className="login-bg">
            <div className="login mb-4">
              <h4>
                Create an <span className="text-danger">Account</span>
              </h4>
              <p className="text-secondary">Enter Your Details</p>
            </div>

            <div className="mb-4">
              <InputGroup className="mb-4">
                <Form.Control
                  className="login-input"
                  placeholder="First Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setFname(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  className="login-input"
                  placeholder="Last Name"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setLname(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  type="number"
                  className="login-input"
                  placeholder="Mobile"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  type="email"
                  className="login-input"
                  placeholder="Email"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>

              <div className="login-eye mb-4">
                <Form.Control
                  className="login-input"
                  style={{ borderRadius: "5px 0px 0px 5px" }}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div onClick={togglePasswordVisibility} className="login-1-eye">
                  {showPassword ? (
                    <>
                      {" "}
                      <AiFillEye />
                    </>
                  ) : (
                    <>
                      <AiFillEyeInvisible />
                    </>
                  )}
                </div>
              </div>

              <div className="login-eye mb-4">
                <Form.Control
                  className="login-input"
                  style={{ borderRadius: "5px 0px 0px 5px" }}
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setCpassword(e.target.value)}
                />
                <div onClick={togglePasswordVisibility} className="login-1-eye">
                  {showPassword ? (
                    <>
                      {" "}
                      <AiFillEye />
                    </>
                  ) : (
                    <>
                      <AiFillEyeInvisible />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <Button
                className="header-search"
                onClick={RegisterCustomer}
                style={{ width: "100%" }}
              >
                Register
              </Button>
            </div>

            <div className="text-center mb-4">
              <p>
                {" "}
                Already member ? |{" "}
                <Link
                  to="/login"
                  style={{
                    color: "#d81d4a",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Login
                </Link>
              </p>
            </div>

            {/* <div className='login mb-4'>
                            <h4>Sign <span className='text-danger'>Up</span> using</h4>
                        </div>

                        <ul className='login-social'>
                            <li>
                                <a href="#" className='fb'><FaFacebookF className='me-1 fs-6' />Facebook</a>
                            </li>
                            <li>
                                <a href="#" className='twit'><BsTwitter className='me-1 fs-6' />Twitter</a>
                            </li>
                            <li>
                                <a href="#" className='gogle'><FaGooglePlusG className='me-1 fs-5' />Google</a>
                            </li>
                        </ul> */}
          </div>
        </Container>
      </div>
    </div>
  );
};
