import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function AdminVehicleDailyRide() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [RideCity, setRideCity] = useState("");
  const [RideVehicleCatrgoey, setRideVehicleCatrgoey] = useState("");
  const [RideVehicleModel, setRideVehicleModel] = useState("");
  const [RideVehiclType, setRideVehiclType] = useState("");
  const [RideFuelType, setRideFuelType] = useState("");
  const [RidePerKmPrice, setRidePerKmPrice] = useState("");
  const [RidePackagePrice, setRidePackagePrice] = useState("");
  const [RideDriverAllowance, setRideDriverAllowance] = useState("");
  const [RideNightDriveAllowance, setRideNightDriveAllowance] = useState("");
  const [RideportCGST, setRideportCGST] = useState("");
  const [RideportSGST, setRideportSGST] = useState("");
  const [RideportIGST, setRideportIGST] = useState("");
  const [RideWaitingCharge, setRideWaitingCharge] = useState("");
  const [Cancellation, setCancellation] = useState("");

  // Add
  const AddDailyRide = async () => {
    if (!RideCity) {
      return notify1("Please Enter City Name");
    }
    if (!RideVehicleCatrgoey) {
      return notify1("Please Select Vehicle Category");
    }
    if (!RideVehicleModel) {
      return notify1("Please Select Vehicle Model");
    }
    if (!RideVehiclType) {
      return notify1("Please Select Vehicle Type");
    }
    if (!RideFuelType) {
      return notify1("Please Select Fuel Type");
    }
    if (!RidePerKmPrice) {
      return notify1("Please Enter Per Km Price");
    }
    if (!RidePackagePrice) {
      return notify1("Please Enter Package Price");
    }
    if (!RideDriverAllowance) {
      return notify1("Please Enter Driver Allowance");
    }
    if (!RideNightDriveAllowance) {
      return notify1("Please Enter Night Driver Allowance");
    }
    if (!RideportCGST) {
      return notify1("Please Enter CGST");
    }
    if (!RideportSGST) {
      return notify1("Please Enter SGST");
    }
    if (!RideportIGST) {
      return notify1("Please Enter IGST");
    }
    if (!RideWaitingCharge) {
      return notify1("Please Enter Waiting Charges");
    }
    if (!Cancellation) {
      return notify1("Please Enter Cancellation Charges");
    }
    try {
      const config = {
        url: "/admin/taxi/addDailyRide",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          RideCity: RideCity,
          RideVehicleCatrgoey: RideVehicleCatrgoey,
          RideVehicleModel: RideVehicleModel,
          RideVehiclType: RideVehiclType,
          RideFuelType: RideFuelType,
          RidePerKmPrice: RidePerKmPrice,
          RidePackagePrice: RidePackagePrice,
          RideDriverAllowance: RideDriverAllowance,
          RideNightDriveAllowance: RideNightDriveAllowance,
          RideportCGST: RideportCGST,
          RideportSGST: RideportSGST,
          RideportIGST: RideportIGST,
          RideWaitingCharge: RideWaitingCharge,
          Cancellation: Cancellation,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose();
        GetDailyRide();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Get
  const [DailyRidePackageData, setDailyRidePackageData] = useState([]);
  const GetDailyRide = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getDailyRide"
      );
      if (res.status === 200) {
        setDailyRidePackageData(res.data.success);
        setnochangedata(res.data.success);
        setPagination(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get vehicle model
  const [getVehicleModelData, setgetVehicleModelData] = useState([]);
  const GetVehicleModel = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getvehiclemodel"
      );
      if (res.status === 200) {
        setgetVehicleModelData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetDailyRide();
    GetVehicleModel();
  }, []);

  // Edit
  const [EditDailyRideData, setEditDailyRideData] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setEditDailyRideData(id);
  };
  const EditDailyRide = async () => {
    try {
      const config = {
        url: "/admin/taxi/editDailyRide/" + EditDailyRideData,
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          RideCity: RideCity,
          RideVehicleCatrgoey: RideVehicleCatrgoey,
          RideVehicleModel: RideVehicleModel,
          RideVehiclType: RideVehiclType,
          RideFuelType: RideFuelType,
          RidePerKmPrice: RidePerKmPrice,
          RidePackagePrice: RidePackagePrice,
          RideDriverAllowance: RideDriverAllowance,
          RideNightDriveAllowance: RideNightDriveAllowance,
          RideportCGST: RideportCGST,
          RideportSGST: RideportSGST,
          RideportIGST: RideportIGST,
          RideWaitingCharge: RideWaitingCharge,
          Cancellation: Cancellation,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose1();
        GetDailyRide();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Delete
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    setDeleteDailyRideData(id);
  };
  const [DeleteDailyRideData, setDeleteDailyRideData] = useState("");
  const DeleteDailyRide = async () => {
    try {
      const config = {
        url: "admin/taxi/deleteDailyRide/" + DeleteDailyRideData,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose2();
        GetDailyRide();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return notify1("Please select from date");
    if (!tod) return notify1("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setPagination(abc);
    setDailyRidePackageData(abc);
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");

  //Pagination
  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(pagination?.length / usersPerPage);
  const changePage = (selected) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Daily Ride :</p>
          <button className="pink-btn" onClick={handleShow}>
            Add Package
          </button>
        </div>

        <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
          <div className="col-lg-2">
            <label>From :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => setfromd(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <label>To :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => settod(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <button className="pink-btn" onClick={searchDate}>
              Submit
            </button>
          </div>

          <div
            className="input-group col-lg-3"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
          <div className="col-lg-1 export-btn">
            <ReactHTMLTableToExcel
              id="export-to-excel"
              table="Export-table"
              filename="dailyride"
              sheet="dailyride"
              buttonText="Export"
            />
          </div>
        </div>

        <div>
          <Table
            responsive
            bordered
            className="admin-table-head"
            id="Export-table"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>City</th>
                <th>Vehicle Category</th>
                <th>Vehicle Model</th>
                <th>Vehicle Type</th>
                <th>Fuel Type</th>
                <th>Per Km Price</th>
                <th>Package Price</th>
                <th>Driver Allowances</th>
                <th>
                  Night Driver Allowances <br /> (10:00 pm - 06:00 am)
                </th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>Waiting Charges</th>
                <th>Cancellation Charges</th>
                <th>Ride Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {DailyRidePackageData?.slice(
                pagesVisited,
                pagesVisited + usersPerPage
              )?.map((item, i) => {
                if (
                  SearchItem === "" ||
                  Object.values(item).some((value) =>
                    String(value)
                      .toLowerCase()
                      .includes(SearchItem.toLowerCase())
                  )
                )
                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.RideCity}</td>
                        <td>{item?.RideVehicleCatrgoey}</td>
                        <td>{item?.RideVehicleModel}</td>
                        <td>{item?.RideVehiclType}</td>
                        <td>{item?.RideFuelType}</td>
                        <td>₹{item?.RidePerKmPrice}</td>
                        <td>₹{item?.RidePackagePrice}</td>
                        <td>₹{item?.RideDriverAllowance}</td>
                        <td>₹{item?.RideNightDriveAllowance}</td>
                        <td>{item?.RideportCGST}%</td>
                        <td>{item?.RideportSGST}%</td>
                        <td>{item?.RideportIGST}%</td>
                        <td>₹{item?.RideWaitingCharge}</td>
                        <td>₹{item?.Cancellation}</td>
                        <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                        <td>
                          <div
                            className="d-flex gap-4 fs-4"
                            style={{ cursor: "pointer" }}
                          >
                            <BiSolidEdit
                              className="text-primary"
                              onClick={() => handleShow1(item?._id)}
                            />
                            <MdDelete
                              className="text-danger"
                              onClick={() => handleShow2(item?._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  );
              })}
            </tbody>
          </Table>
        </div>
        <div className="my-3">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              color="primary"
              onChange={(event, value) => {
                changePage(value - 1);
              }}
            />
          </Stack>
        </div>
      </Container>

      {/* Add Modal */}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="amenties-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">City :</label>
              <input
                type="text"
                placeholder="city"
                className="vi_0"
                onChange={(e) => setRideCity(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Vehicle Category :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideVehicleCatrgoey(e.target.value)}
              >
                <option>Open this select menu</option>
                {getVehicleModelData?.map((item) => {
                  return (
                    <>
                      <option value={item?.vehiclecategory}>
                        {item?.vehiclecategory}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Vehicle Model :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideVehicleModel(e.target.value)}
              >
                <option>Open this select menu</option>
                {getVehicleModelData
                  ?.filter((ele) => ele?.vehiclecategory == RideVehicleCatrgoey)
                  ?.map((item) => {
                    return (
                      <>
                        <option value={item?.vehicleModel}>
                          {item?.vehicleModel}
                        </option>
                      </>
                    );
                  })}
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Vehicle Type :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideVehiclType(e.target.value)}
              >
                <option>Open this select menu</option>
                <option value="AC">AC</option>
                <option value="NON-AC">NON-AC</option>
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Fule Type :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideFuelType(e.target.value)}
              >
                <option>Open this select menu</option>
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="Electric">Electric</option>
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Per Km Price :</label>
              <input
                type="text"
                placeholder="Km Price"
                className="vi_0"
                onChange={(e) => setRidePerKmPrice(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Package Price :</label>
              <input
                type="text"
                placeholder="Package Price"
                className="vi_0"
                onChange={(e) => setRidePackagePrice(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Driver Allowances :</label>
              <input
                type="text"
                placeholder="Driver allowance"
                className="vi_0"
                onChange={(e) => setRideDriverAllowance(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Night Driver Allowances :</label>
              <input
                type="text"
                placeholder="Night Driver allowance"
                className="vi_0"
                onChange={(e) => setRideNightDriveAllowance(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">CGST :</label>
              <input
                type="text"
                placeholder="CGST"
                className="vi_0"
                onChange={(e) => setRideportCGST(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">SGST :</label>
              <input
                type="text"
                placeholder="SGST"
                className="vi_0"
                onChange={(e) => setRideportSGST(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">IGST :</label>
              <input
                type="text"
                placeholder="IGST"
                className="vi_0"
                onChange={(e) => setRideportIGST(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Waiting Charges :</label>
              <input
                type="text"
                placeholder="Waiting Charges"
                className="vi_0"
                onChange={(e) => setRideWaitingCharge(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Cancellation :</label>
              <input
                type="text"
                placeholder="Cancellation"
                className="vi_0"
                onChange={(e) => setCancellation(e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddDailyRide}>
            Add Package
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal*/}
      <Modal
        size="lg"
        className="amenties-modal"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">City :</label>
              <input
                type="text"
                placeholder="city"
                className="vi_0"
                onChange={(e) => setRideCity(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Vehicle Category :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideVehicleCatrgoey(e.target.value)}
              >
                <option>Open this select menu</option>
                {getVehicleModelData?.map((item) => {
                  return (
                    <>
                      <option value={item?.vehiclecategory}>
                        {item?.vehiclecategory}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Vehicle Model :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideVehicleModel(e.target.value)}
              >
                <option>Open this select menu</option>
                {getVehicleModelData
                  ?.filter((ele) => ele?.vehiclecategory == RideVehicleCatrgoey)
                  ?.map((item) => {
                    return (
                      <>
                        <option value={item?.vehicleModel}>
                          {item?.vehicleModel}
                        </option>
                      </>
                    );
                  })}
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Vehicle Type :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideVehiclType(e.target.value)}
              >
                <option>Open this select menu</option>
                <option value="AC">AC</option>
                <option value="NON-AC">NON-AC</option>
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Fule Type :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setRideFuelType(e.target.value)}
              >
                <option>Open this select menu</option>
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="Electric">Electric</option>
              </Form.Select>
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Per Km Price :</label>
              <input
                type="text"
                placeholder="Km Price"
                className="vi_0"
                onChange={(e) => setRidePerKmPrice(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Package Price :</label>
              <input
                type="text"
                placeholder="Package Price"
                className="vi_0"
                onChange={(e) => setRidePackagePrice(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Driver Allowances :</label>
              <input
                type="text"
                placeholder="Driver allowance"
                className="vi_0"
                onChange={(e) => setRideDriverAllowance(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Night Driver Allowances :</label>
              <input
                type="text"
                placeholder="Night Driver allowance"
                className="vi_0"
                onChange={(e) => setRideNightDriveAllowance(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">CGST :</label>
              <input
                type="text"
                placeholder="CGST"
                className="vi_0"
                onChange={(e) => setRideportCGST(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">SGST :</label>
              <input
                type="text"
                placeholder="SGST"
                className="vi_0"
                onChange={(e) => setRideportSGST(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">IGST :</label>
              <input
                type="text"
                placeholder="IGST"
                className="vi_0"
                onChange={(e) => setRideportIGST(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Waiting Charges :</label>
              <input
                type="text"
                placeholder="Waiting Charges"
                className="vi_0"
                onChange={(e) => setRideWaitingCharge(e.target.value)}
              />
            </Col>
            <Col lg={6} className="mb-2">
              <label className="fw-bold">Cancellation :</label>
              <input
                type="text"
                placeholder="Cancellation"
                className="vi_0"
                onChange={(e) => setCancellation(e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditDailyRide}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Cancel
          </Button>
          <Button onClick={DeleteDailyRide} variant="danger">
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AdminVehicleDailyRide;
