import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export const Contact = () => {
  const [Name, setName] = useState("");
  const [Number, setNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  var f_Name = /^[a-zA-Z][a-zA-Z]/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const Contact = async () => {
    if (!Name) {
      return alert("Please Enter Your Name");
    }
    if (!Name.match(f_Name)) {
      return alert("Please Enter Valid Name");
    }
    if (!Number) {
      return alert("Please Enter Your Number");
    }
    if (Number.length !== 10) {
      return alert("Number Should be 10 digit");
    }
    if (!Number.match(mobilevalid)) {
      return alert("Enter valid Mobile number");
    }

    if (!Email) {
      return alert("Please Enter Your Email");
    }
    if (!Email.match(validRegex)) {
      return alert("Please enter a valid email address");
    }
    if (!Message) {
      return alert("Please Enter Your Enquiry");
    }
    try {
      const config = {
        url: "/customer/contactcustomer",
        baseURL: "https://hayyyak.com/api",
        method: "POST",
        Headers: { "content-type": "application-json" },
        data: {
          Name: Name,
          Number: Number,
          Email: Email,
          Message: Message,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          window.location.reload();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      <div className="mb-4 contact-div-mb">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(./img/trip-package-bg-2.jpg)",
              backgroundPosition: "initial",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "350px",
              width: "100%",
            }}
            className="contact-top-activity"
          >
            <div className="activity-text">
              <div className="bread">
                <h2 className="text-uppercase fw-bold">Contact</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Contact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container className="mt-3">
        <div className="text-center mb-4 contact-div-mb">
          <h2 className="home-all-headings">
            <span>Contact Us</span>{" "}
          </h2>
          <p className="text-secondary">
            World's leading tour and travels Booking website,Over 30,000
            packages worldwide. Book travel packages and enjoy your holidays
            with distinctive experience
          </p>
        </div>

        <div className="row mb-4 contact-div-mb">
          <div className="col-lg-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d418403.3628324903!2d77.51081804555747!3d13.019617978649224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1695620280302!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-lg-6">
            <div className="row mb-4 contact-travel-div justify-content-evenly">
              <div className="col-lg-6 mb-2">
                <h3 style={{ color: "rgb(242, 104, 56)", fontWeight: "600" }}>
                  The <span style={{ color: "#000" }}> Travel</span>
                </h3>
                <p
                  style={{
                    fontSize: "14.5px",
                    lineHeight: "24px",
                    color: "rgb(0 0 0 / 62%)",
                  }}
                >
                  We Provide Outsourced Software Development Services To Over 50{" "}
                  <br /> Clients From 21 Countries.
                </p>
              </div>

              <div className="col-lg-6 mb-2">
                <h4 style={{ fontWeight: "600", color: "rgb(0 0 0 / 53%)" }}>
                  Website
                </h4>
                <p
                  style={{
                    fontSize: "14.5px",
                    lineHeight: "24px",
                    color: "rgb(0 0 0 / 62%)",
                  }}
                >
                  website: www.parnets.com{" "}
                </p>
                <p
                  style={{
                    fontSize: "14.5px",
                    lineHeight: "24px",
                    color: "rgb(0 0 0 / 62%)",
                  }}
                >
                  Email: Parnets@gmail.com{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Container>
          <div className="row mb-4 contact-travel-div">
            <div
              className="col-lg-6"
              style={{
                backgroundColor: "rgb(216 29 74)",
                padding: "20px",
                color: "#fff",
                borderRadius: "20px",
              }}
            >
              <h4 className="mb-4" style={{ letterSpacing: "2px" }}>
                OUR INFORMATION
              </h4>

              <div className="row mb-4 justify-content-evenly">
                <div className="col-lg-5 mb-3">
                  <h4 style={{ fontWeight: "600" }}>Address</h4>
                  <p
                    style={{
                      fontSize: "14.5px",
                      lineHeight: "24px",
                      color: "rgb(255 254 254 / 88%)",
                    }}
                  >
                    Parnets Software Singapoor <br /> Layout Banglore 580021{" "}
                  </p>
                </div>

                <div className="col-lg-5">
                  <h4 style={{ fontWeight: "600" }}>Contact Info:</h4>
                  <p
                    style={{
                      fontSize: "14.5px",
                      lineHeight: "24px",
                      color: "rgb(255 254 254 / 88%)",
                    }}
                  >
                    Phone: +91 987654321{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "14.5px",
                      lineHeight: "24px",
                      color: "rgb(255 254 254 / 88%)",
                    }}
                  >
                    Mobile: +91 987654321{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "14.5px",
                      lineHeight: "24px",
                      color: "rgb(255 254 254 / 88%)",
                    }}
                  >
                    Email: Parnets@gmail.com{" "}
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="col-lg-6 contact-form"
              style={{ padding: "20px" }}

            >
              <h4 className="mb-4" style={{ letterSpacing: "2px" }}>
                Contact Us
              </h4>

              <div class="mb-3  ">
                <input
                  style={{ border: "1px solid #ff4b76" }}
                  type="text"
                  className="form-control mb-3  "
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  style={{ border: "1px solid #ff4b76" }}
                  type="number"
                  className="form-control "
                  placeholder="Number"
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
              <div>
                <input
                  style={{ border: "1px solid #ff4b76" }}
                  type="email"
                  className="form-control mb-4"
                  placeholder="Email ID"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                  style={{ border: "1px solid #ff4b76" }}
                  class="form-control mb-4"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="pink-btn "
                  style={{ width: "150px" }}
                  onClick={Contact}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};
