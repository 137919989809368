import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Form, InputGroup, Table } from 'react-bootstrap'
import { CiLocationOn } from 'react-icons/ci'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";


export const ActivityReview = () => {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const location = useLocation()
    const { item, adultCount, childrenCount, Date, Time, adultAmount, childrenAmount } = location.state
    console.log("itemmmm", item);
    let ActualAdultAmount = (item?.act?.Price * adultCount)
    let ActualChildAmount = (item?.act?.Children * childrenCount)

    let TotalDiscount = (item?.act?.AdultDiscount + item?.act?.ChildrenDiscount)
    let TotalAmount = (adultAmount + childrenAmount)
    let TotalTraveller = (adultCount + childrenCount)
    let TotalDiscountPrice = (
        item?.act?.Price * adultCount +
        item?.act?.Children * childrenCount -
        (item?.act?.Adulttotal * adultCount +
            item?.act?.Childrentotal * childrenCount)
    ).toFixed(2)

    const Customer = JSON.parse(sessionStorage.getItem("customer"));


    const [TravelerEmalil, setTravelerEmalil] = useState()
    const [TravelerNumber, setTravelerNumber] = useState()

    const ActivityBooking = async () => {
        if (!Customer) {
            return notify1("Please Login")
        }
        if (TravelersData.length <= 0) {
            return notify1("Please Enter All Travelers Details")
        }
        if (TravelersData.length !== TotalTraveller) {
            return notify1("Please Enter valid Travelers Details")
        }
        if (!TravelerEmalil) {
            return notify1("Please Enter Email ID")
        }
        if (!TravelerNumber) {
            return notify1("Please Enter Phonenumber ID")
        }
        try {
            const config = {
                url: "customer/activity/activitybooking",
                baseURL: "https://hayyyak.com/api/",
                Headers: { "content-type": "application/json" },
                method: "post",
                data: {
                    userId: Customer?._id,
                    ActivityId: item?.id,
                    activitylocation: item?.act?._id,
                    checkindate: Date,
                    checkinTime: Time,
                    adultprice: item?.act?.Adulttotal * adultCount,
                    adultquantity: adultCount,
                    childrenprice: item?.act?.Childrentotal * childrenCount,
                    childrenquantity: childrenCount,
                    AllTotalamount: TotalAmount,
                    city: item?.act?.City,
                    country: item?.act?.Country,
                    state: item?.act?.State,
                    location: item?.act?.Location,
                    Title: item?.title,
                    adultdiscount: item?.act?.AdultDiscount,
                    childrendiscount: item?.act?.ChildrenDiscount,
                    TravelerEmalil: TravelerEmalil,
                    TravelerNumber: TravelerNumber,
                    TravelersData: TravelersData
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    window.location.assign("/activity");
                }
            });
        } catch (error) {
            console.log(error);
            notify2(error.response.data.error);
        }
    };

    const [TravelersData, setTravelersData] = useState([]);

    const handleInputChange = (index, field, value) => {
        setTravelersData((prevData) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], [field]: value };
            return newData;
        })
    }

    const travelerInputs = [];
    for (let i = 1; i <= TotalTraveller; i++) {
        travelerInputs.push(
            <div key={i}>
                <label className='fw-bold'>{`Traveller ${i}:`}</label>
                <div className='mb-2 row'>
                    <div className='col-lg-5'>
                        <label htmlFor={`firstName${i}`}>First Name</label>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='login-input'
                                type='text'
                                aria-label={`First Name ${i}`}
                                aria-describedby="basic-addon1"
                                onChange={(e) => handleInputChange(i - 1, 'TravellerFirstName', e.target.value)}
                            />
                        </InputGroup>
                    </div>

                    <div className="col-lg-5">
                        <label htmlFor={`lastName${i}`}>Last Name</label>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='login-input'
                                type='text'
                                aria-label={`Last Name ${i}`}
                                aria-describedby="basic-addon1"
                                onChange={(e) => handleInputChange(i - 1, 'TravellerLastName', e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={30000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <div className="row my-4 justify-content-between align-items-center">
                    <h3 className=' col-lg-4'>Review Your <span className='text-danger'>Booking</span></h3>
                    {!Customer ? (<p style={{ color: '#008cff', fontSize: "14px" }} className='col-lg-6'>Please<a href="/login" className='text-danger text-decoration-none'>"Login"</a> before entering the traveller details </p>
                    ) : (null)}
                </div>
                <div className="row mb-3">
                    <div className="col-lg-8 " >
                        <div className="hotel-bok mb-3" style={{ height: '238px' }}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <img
                                        src={`https://hayyyak.com/ActivityCategory/${item?.img}`}
                                        alt="" style={{ width: '200px', height: '200px', imageRendering: 'pixelated' }} 
                                        className='ActivityReview-image'
                                        />
                                </div>
                                <div className="col-lg-7">
                                    <p className='fw-bold fs-4 activity-h2'>{item?.title}</p>
                                    <p className='text-secondary mb-2'><CiLocationOn className="text-danger me-2" />{item?.act?.Location}</p>
                                    <p className='fw-semibold fs-6'><span className='text-secondary fs-6 fw-light'>City : </span>{item?.act?.City}</p>
                                    <hr />
                                    <div className="row gap-3">
                                        <div className="col-lg-5">
                                            <p className='text-secondary'>Activity Date</p>
                                            <p className='fw-bold'>{Date}</p>
                                        </div>
                                        <div className="col-lg-5">
                                            <p className='text-secondary'>Time</p>
                                            <p className='fw-bold'>{Time}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <h2 className='mb-4'>Traveller <span className='text-danger'>Details</span></h2>

                            <div>
                                {travelerInputs}
                            </div>

                            <div className='mb-2 row'>
                                <label>Contact Details</label>
                                <div className='col-lg-5'>
                                    <InputGroup className='mb-3'>
                                        <Form.Control
                                            className='login-input'
                                            type='email'
                                            placeholder="Enter Your Email"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setTravelerEmalil(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>

                                <div className="col-lg-5">
                                    <InputGroup className='mb-3' >
                                        <Form.Control
                                            className='login-input'
                                            type='text'
                                            placeholder="Enter Mobile Number"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setTravelerNumber(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            {/* <div>
                                <button className='header-search' style={{ width: '25%', height: '46px', marginLeft: '140px' }}>Pay Now</button>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div style={{
                            border: '1px solid rgb(221, 221, 221)',
                            borderRadius: '12px',
                            padding: '24px',
                            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px',
                        }}>
                            <p className='fw-semibold fs-5 mb-2'>Price Breakup</p>

                            <Table>
                                <tbody>
                                    <tr>
                                        <td className='fw-semibold'>Adults x {adultCount} </td>
                                        <td className='fw-medium text-secondary'>₹{adultAmount} <s>(₹{ActualAdultAmount})</s></td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Children x {childrenCount}</td>
                                        <td className='fw-medium text-secondary'>₹{childrenAmount} <s>(₹{ActualChildAmount})</s></td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Discount%</td>
                                        <td className='fw-medium text-secondary'>-₹{TotalDiscountPrice} <span style={{ fontSize: '12px' }}>({TotalDiscount}%)</span></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="vasghd_0 p-1">
                                <p className='fw-bold fs-5'>Total Amount </p>
                                <p className='fw-medium text-danger fs-5'>₹{TotalAmount}</p>
                            </div>
                            <div>
                                <button onClick={ActivityBooking} className="header-search" style={{ width: '100%', height: '44px' }}>Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}


