import React, { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete, MdOutlineCancel } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function AdminVehicleDriver() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [DriverImg, setDriverImg] = useState("");
  const [DriverName, setDriverName] = useState("");
  const [DriverMobile, setDriverMobile] = useState("");
  const [DriverGender, setDriverGender] = useState("");
  const [DriverDOB, setDriverDOB] = useState("");
  const [DLiecenceNumber, setDLiecenceNumber] = useState("");
  const [DAAdhaarNumber, setDAAdhaarNumber] = useState("");
  const [DLiecenceImg, setDLiecenceImg] = useState("");
  const [DAAdhaarBack, setDAAdhaarBack] = useState("");
  const [DAAdhaarFront, setDAAdhaarFront] = useState("");
  const [DPoliceVerifyImg, setDPoliceVerifyImg] = useState("");
  const [DVehicleType, setDVehicleType] = useState("");
  const [DVehicleInsuranceDoc, setDVehicleInsuranceDoc] = useState("");
  const [DVehiclePermitImg, setDVehiclePermitImg] = useState("");
  const [DVehicleRCImg, setDVehicleRCImg] = useState("");
  const [DVehicleRegNumber, setDVehicleRegNumber] = useState("");
  const [DVehicleImg, setDVehicleImg] = useState("");
  const [DriverType, setDriverType] = useState("");
  const [DVehicleColor, setDVehicleColor] = useState("");
  const [DVehicleModel, setDVehicleModel] = useState("");
  const [DVehicleCategory, setDVehicleCategory] = useState("");
  const [DriverBankName, setDriverBankName] = useState("");
  const [DriverBranch, setDriverBranch] = useState("");
  const [DriverBankAcc, setDriverBankAcc] = useState("");
  const [DriverIFSC, setDriverIFSC] = useState("");
  const [DBankHolder, setDBankHolder] = useState("");
  const [DPancardImg, setDPancardImg] = useState("");
  const [selectdriverType, setselectdriverType] = useState("");

  // Add Driver
  const mobileRegex = /^\d{10}$/;
  const AddDriver = async () => {
    if (!DriverType) {
      return notify1("Please select Driver Type");
    }
    if (!DriverName) {
      return notify1("Please enter Driver Name");
    }
    if (DriverType === "Driver With Vehicle") {
      if (!DVehicleColor) {
        return notify1("Please enter vehicle color ");
      }
    }

    if (!mobileRegex.test(DriverMobile)) {
      return notify1("Please enter a valid mobile number");
    }
    if (!DriverGender) {
      return notify1("Please select Driver Gender");
    }
    if (!DriverDOB) {
      return notify1("Please select Driver DOB");
    }
    if (!DLiecenceNumber) {
      return notify1("Please write Driver Liecence Number");
    }
    if (!DAAdhaarNumber) {
      return notify1("Please write Driver Aadhar Number");
    }
    if (DriverType === "Driver With Vehicle") {
      if (!DVehicleType) {
        return notify1("Please select Vehicle Type");
      }
      if (!DVehicleCategory) {
        return notify1("Please select Vehicle Category");
      }
      if (!DVehicleModel) {
        return notify1("Please select Vehicle Model");
      }
      if (!DVehicleRegNumber) {
        return notify1("Please write Vehicle Reg Number");
      }
    }

    if (!DriverBankName) {
      return notify1("Please write Driver Bank Name");
    }
    if (!DriverBranch) {
      return notify1("Please write Driver Branch Name");
    }
    if (!DriverBankAcc) {
      return notify1("Please write Driver Account Number");
    }
    if (!DriverIFSC) {
      return notify1("Please write Driver bank IFSC Code");
    }
    if (!DBankHolder) {
      return notify1("Please write Bank Holder Name");
    }

    if (!DPancardImg) {
      return notify1("Please upload pancard");
    }
    if (!DriverImg) {
      return notify1("Please select driver photo");
    }
    if (DAAdhaarFront.name === DAAdhaarBack.name) {
      return notify1("Aadhar Front Image and Back Image should not be same");
    }
    if (!DPoliceVerifyImg) {
      return notify1("Please select Driver Police Verification..Document");
    }
    if (!DLiecenceImg) {
      return notify1("Please select Driver Licence Image");
    }
    if (DriverType === "Driver With Vehicle") {
      if (!DVehiclePermitImg) {
        return notify1("Please select Vehicle Permit");
      }
      if (!DVehicleInsuranceDoc) {
        return notify1("Please Upload Vehicle Insurance Doc");
      }
      if (!DVehicleRCImg) {
        return notify1("Please Upload Vehicle RC Book");
      }
      if (!DVehicleImg) {
        return notify1("Please Upload Vehicle Image");
      }
    }

    try {
      const config = {
        url: "/admin/taxi/addDriver",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          DriverImg: DriverImg,
          DriverName: DriverName,
          DriverMobile: DriverMobile,
          DriverGender: DriverGender,
          DriverDOB: DriverDOB,
          DLiecenceNumber: DLiecenceNumber,
          DAAdhaarNumber: DAAdhaarNumber,
          DLiecenceImg: DLiecenceImg,
          DAAdhaarBack: DAAdhaarBack,
          DAAdhaarFront: DAAdhaarFront,
          DPoliceVerifyImg: DPoliceVerifyImg,
          DVehicleType: DVehicleType,
          DVehicleInsuranceDoc: DVehicleInsuranceDoc,
          DVehiclePermitImg: DVehiclePermitImg,
          DVehicleRCImg: DVehicleRCImg,
          DVehicleRegNumber: DVehicleRegNumber,
          DVehicleImg: DVehicleImg,
          DriverType: DriverType,
          DVehicleColor: DVehicleColor,
          DVehicleModel: DVehicleModel,
          DVehicleCategory: DVehicleCategory,
          DriverBankName: DriverBankName,
          DriverBranch: DriverBranch,
          DriverBankAcc: DriverBankAcc,
          DriverIFSC: DriverIFSC,
          DBankHolder: DBankHolder,
          DPancardImg: DPancardImg,
        },
      };

      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose();
        GetDriver();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Get Driver
  const [GetDriverData, setGetDriverData] = useState([]);
  const [VehicleModelData, setVehicleModelData] = useState({});
  const GetDriver = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getDriver"
      );
      if (res.status === 200) {
        setGetDriverData(res.data.success);
        setnochangedata(res.data.success);
        setPagination(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };


  // get vehicle model
  const [getVehicleModelData, setgetVehicleModelData] = useState([]);
  const GetVehicleModel = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getvehiclemodel"
      );
      if (res.status === 200) {
        setgetVehicleModelData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetDriver();
    GetVehicleModel();
  }, []);

  // Edit Driver
  const [EditDriverData, setEditDriverData] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setEditDriverData(id);
  };
  const EditDriver = async () => {
    try {
      const config = {
        url: "/admin/taxi/editDriver/" + EditDriverData,
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          DriverImg: DriverImg,
          DriverName: DriverName,
          DriverMobile: DriverMobile,
          DriverGender: DriverGender,
          DriverDOB: DriverDOB,
          DLiecenceNumber: DLiecenceNumber,
          DAAdhaarNumber: DAAdhaarNumber,
          DLiecenceImg: DLiecenceImg,
          DAAdhaarBack: DAAdhaarBack,
          DAAdhaarFront: DAAdhaarFront,
          DPoliceVerifyImg: DPoliceVerifyImg,
          DVehicleType: DVehicleType,
          DVehicleInsuranceDoc: DVehicleInsuranceDoc,
          DVehiclePermitImg: DVehiclePermitImg,
          DVehicleRCImg: DVehicleRCImg,
          DVehicleRegNumber: DVehicleRegNumber,
          DVehicleImg: DVehicleImg,
          DriverType: DriverType,
          DVehicleColor: DVehicleColor,
          DVehicleModel: DVehicleModel,
          DVehicleCategory: DVehicleCategory,
          DriverBankName: DriverBankName,
          DriverBranch: DriverBranch,
          DriverBankAcc: DriverBankAcc,
          DriverIFSC: DriverIFSC,
          DBankHolder: DBankHolder,
          DPancardImg: DPancardImg,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose1();
        GetDriver();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Delete Driver
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    setDeleteDriverData(id);
  };
  const [DeleteDriverData, setDeleteDriverData] = useState("");
  const DeleteDriver = async () => {
    try {
      const config = {
        url: "admin/taxi/deleteDriver/" + DeleteDriverData,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose2();
        GetDriver();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return notify1("Please select from date");
    if (!tod) return notify1("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setPagination(abc);
    setGetDriverData(abc);
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");

  //Pagination
  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(pagination?.length / usersPerPage);
  const changePage = (selected) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-start pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Drivers :</p>
          <div className="d-flex gap-2 align-items-end">
            <div>
              <label className="fw-bold">Driver Type :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setselectdriverType(e.target.value)}
              >
                <option value="Not-Selected">Select</option>
                <option value="Only Driver">Only Driver</option>
                <option value="Driver With Vehicle">Driver With Vehicle</option>
              </Form.Select>
            </div>
            <button className="pink-btn" onClick={handleShow}>
              Add Driver
            </button>
          </div>
        </div>

        <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
          <div className="col-lg-2">
            <label>From :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => setfromd(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <label>To :</label>
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              onChange={(e) => settod(e.target.value)}
            />
          </div>

          <div className="col-lg-2">
            <button className="pink-btn" onClick={searchDate}>
              Submit
            </button>
          </div>

          <div
            className="input-group col-lg-3"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
              value={SearchItem}
            />
            <MdOutlineCancel
            onClick={()=>setSearchItem("")}
              style={{cursor:"pointer", color: "red", fontSize: "22px", marginTop: "5px" }}
            />
          </div>

          <div className="col-lg-1 export-btn">
            <ReactHTMLTableToExcel
              id="export-to-excel"
              table="Export-table"
              filename="Driverdetails"
              sheet="Driverdetails"
              buttonText="Export"
            />
          </div>
          {/* <div className="col-lg-1 ">
                      <button className='import-btn'>Import</button>
                    </div> */}
        </div>

        <div>
          <Table
            responsive
            bordered
            className="admin-table-head"
            id="Export-table"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Driver Type</th>
                <th>Driver Id</th>
                <th>Driver Image</th>
                <th>Driver Name</th>
                <th>Mobile Number</th>
                <th>Gender</th>
                <th>Date of Birth</th>
                <th>Licence No</th>
                <th>Aadhar No</th>
                <th>Licence Image</th>
                <th>Aadhar Front Image</th>
                <th>Aadhar Back Image</th>
                <th>Driver Added Date</th>
                <th>Last Updated</th>
                <th>Vehicle & Bank Information</th>
                {/* <th>Status</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {GetDriverData?.filter((ele) =>
                selectdriverType ? ele?.DriverType == selectdriverType : ele
              )
                ?.slice(pagesVisited, pagesVisited + usersPerPage)
                ?.map((item, i) => {
                  if (
                    SearchItem === "" ||
                    Object.values(item).some((value) =>
                      String(value)
                        .toLowerCase()
                        .includes(SearchItem.toLowerCase())
                    )
                  )
                    return (
                      <>
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item?.DriverType}</td>
                          <td>{item?._id}</td>
                          <td>
                            <a
                              href={`https://hayyyak.com/TaxiDriver/${item?.DriverImg}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`https://hayyyak.com/TaxiDriver/${item?.DriverImg}`}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  imageRendering: "pixelated",
                                }}
                              />
                            </a>
                          </td>
                          <td>{item?.DriverName}</td>
                          <td>{item?.DriverMobile}</td>
                          <td>{item?.DriverGender}</td>
                          <td>{item?.DriverDOB}</td>
                          <td>{item?.DLiecenceNumber}</td>
                          <td>{item?.DAAdhaarNumber}</td>
                          <td>
                            <a
                              href={`https://hayyyak.com/TaxiDriver/${item?.DLiecenceImg}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`https://hayyyak.com/TaxiDriver/${item?.DLiecenceImg}`}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  imageRendering: "pixelated",
                                }}
                              />
                            </a>
                          </td>
                          <td>
                            <a
                              href={`https://hayyyak.com/TaxiDriver/${item?.DAAdhaarFront}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`https://hayyyak.com/TaxiDriver/${item?.DAAdhaarFront}`}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  imageRendering: "pixelated",
                                }}
                              />
                            </a>
                          </td>
                          <td>
                            <a
                              href={`https://hayyyak.com/TaxiDriver/${item?.DAAdhaarBack}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`https://hayyyak.com/TaxiDriver/${item?.DAAdhaarBack}`}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  imageRendering: "pixelated",
                                }}
                              />
                            </a>
                          </td>
                          <td>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {moment(item?.updatedAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            <span
                              className="fs-3 text-danger"
                              style={{ cursor: "pointer" }}
                            >
                              <AiFillEye
                                onClick={() => {
                                  handleShow3(item);
                                  setVehicleModelData(item);
                                }}
                              />
                            </span>
                          </td>
                          {/* <td>
                                            <div style={{ cursor: 'pointer' }}>
                                                <span className='unblock-user'>Approved</span>
                                                <span className='block-user' style={{ backgroundColor: 'rgb(219, 167, 9)' }}>Pending</span>
                                            </div>
                                        </td> */}
                          <td>
                            <div
                              className="d-flex gap-4 fs-4"
                              style={{ cursor: "pointer" }}
                            >
                              <BiSolidEdit
                                className="text-primary"
                                onClick={() => handleShow1(item?._id)}
                              />
                              <MdDelete
                                className="text-danger"
                                onClick={() => handleShow2(item?._id)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                })}
            </tbody>
          </Table>
        </div>
        <div className="my-3">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              color="primary"
              onChange={(event, value) => {
                changePage(value - 1);
              }}
            />
          </Stack>
        </div>
      </Container>

      {/* Add Modal */}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="amenties-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-6 mb-3">
            <label className="fw-bold">Driver Type :</label>
            <Form.Select
              aria-label="Default select example"
              className="vi_0"
              onChange={(e) => setDriverType(e.target.value)}
            >
              <option value="Not-Selected">Select</option>
              <option value="Only Driver">Only Driver</option>
              <option value="Driver With Vehicle">Driver With Vehicle</option>
            </Form.Select>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Driver Name :</label>
              <input
                type="text"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDriverName(e.target.value)}
              />
            </div>
            {DriverType === "Driver With Vehicle" && (
              <div className="col-lg-6">
                <label className="fw-bold">Vehicle Color :</label>
                <input
                  type="text"
                  placeholder="color"
                  className="vi_0"
                  onChange={(e) => setDVehicleColor(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Mobile Number :</label>
              <input
                type="text"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setDriverMobile(e.target.value)}
                maxLength={10}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Gender :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setDriverGender(e.target.value)}
              >
                <option value="Not-Selected">Select</option>
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
              </Form.Select>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Date of Birth :</label>
              <input
                type="date"
                className="vi_0"
                onChange={(e) => setDriverDOB(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Licence Number:</label>
              <input
                type="text"
                placeholder="Licence Number"
                className="vi_0"
                onChange={(e) => setDLiecenceNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Aadhar Number:</label>
              <input
                type="text"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setDAAdhaarNumber(e.target.value)}
                maxLength={12}
              />
            </div>
            {DriverType === "Driver With Vehicle" && (
              <div className="col-lg-6">
                <label className="fw-bold">Vehicle Type :</label>
                <Form.Select
                  aria-label="Default select example"
                  className="vi_0"
                  onChange={(e) => setDVehicleType(e.target.value)}
                >
                  <option value="Not-Selected">Select</option>
                  <option value="AC">AC</option>
                  <option value="NON-AC">NON-AC</option>
                </Form.Select>
              </div>
            )}
          </div>
          {DriverType === "Driver With Vehicle" && (
            <div className="row mb-3">
              <div className="col-lg-6">
                <label className="fw-bold">Vehicle Category :</label>
                <Form.Select
                  aria-label="Default select example"
                  className="vi_0"
                  onChange={(e) => setDVehicleCategory(e.target.value)}
                >
                  <option value="Not-Selected">Select</option>
                  {getVehicleModelData?.map((item) => {
                    return (
                      <>
                        <option value={item?.vehiclecategory}>
                          {item?.vehiclecategory}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </div>
              <div className="col-lg-6">
                <label className="fw-bold">Vehicle Model:</label>
                <Form.Select
                  aria-label="Default select example"
                  className="vi_0"
                  onChange={(e) => setDVehicleModel(e.target.value)}
                >
                  <option value="Not-Selected">Select</option>
                  {getVehicleModelData
                    ?.filter((ele) => ele.vehiclecategory === DVehicleCategory)
                    ?.map((item) => {
                      return (
                        <>
                          <option value={item?.vehicleModel}>
                            {item?.vehicleModel}
                          </option>
                        </>
                      );
                    })}
                </Form.Select>
              </div>
            </div>
          )}

          <div className="row mb-3">
            {" "}
            {DriverType === "Driver With Vehicle" && (
              <div className="col-lg-6">
                <label className="fw-bold">Vehicle Reg Number:</label>
                <input
                  type="text"
                  placeholder="Vehicle Reg Number"
                  className="vi_0"
                  onChange={(e) => setDVehicleRegNumber(e.target.value)}
                />
              </div>
            )}
            <div className="col-lg-6">
              <label className="fw-bold">Bank Name:</label>
              <input
                type="text"
                placeholder="Bank Name"
                className="vi_0"
                onChange={(e) => setDriverBankName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">Branch Name:</label>
              <input
                type="text"
                placeholder="Branch Name"
                className="vi_0"
                onChange={(e) => setDriverBranch(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Bank Acount Number :</label>
              <input
                type="text"
                placeholder="Bank Acount Number"
                className="vi_0"
                onChange={(e) => setDriverBankAcc(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">IFSC Code:</label>
              <input
                type="text"
                placeholder="IFSC Code"
                className="vi_0"
                onChange={(e) => setDriverIFSC(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Bank Holder Name :</label>
              <input
                type="text"
                placeholder="Bank Holder Name"
                className="vi_0"
                onChange={(e) => setDBankHolder(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">Pancard Image :</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDPancardImg(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold" htmlFor="upload">
                Driver Image :
              </label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDriverImg(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Aadhar Front Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDAAdhaarFront(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Aadhar Back Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDAAdhaarBack(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Police Verify Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDPoliceVerifyImg(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Licence Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDLiecenceImg(e.target.files[0])}
              />
            </div>
          </div>
          {DriverType === "Driver With Vehicle" && (
            <>
              <div className="row mb-3">
                <div className="col-lg-6">
                  <label className="fw-bold">Vehicle Permit:</label>
                  <input
                    type="file"
                    id="upload"
                    accept="image/*"
                    placeholder="Name"
                    className="vi_0"
                    onChange={(e) => setDVehiclePermitImg(e.target.files[0])}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="fw-bold">Vehicle Insurance Doc:</label>
                  <input
                    type="file"
                    id="upload"
                    accept="image/*"
                    placeholder="Name"
                    className="vi_0"
                    onChange={(e) => setDVehicleInsuranceDoc(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-6">
                  <label className="fw-bold">Vehicle Rc Image:</label>
                  <input
                    type="file"
                    id="upload"
                    accept="image/*"
                    placeholder="Name"
                    className="vi_0"
                    onChange={(e) => setDVehicleRCImg(e.target.files[0])}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="fw-bold">Vehicle Image:</label>
                  <input
                    type="file"
                    id="upload"
                    accept="image/*"
                    placeholder="Name"
                    className="vi_0"
                    onChange={(e) => setDVehicleImg(e.target.files[0])}
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddDriver}>
            Add Driver
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal*/}
      <Modal
        size="lg"
        show={show1}
        onHide={handleClose1}
        className="amenties-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-6 mb-3">
            <label className="fw-bold">Driver Type :</label>
            <Form.Select
              aria-label="Default select example"
              className="vi_0"
              onChange={(e) => setDriverType(e.target.value)}
            >
              <option value="Not-Selected">Select</option>
              <option value="Only Driver">Only Driver</option>
              <option value="Driver With Vehicle">Driver With Vehicle</option>
            </Form.Select>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Driver Name :</label>
              <input
                type="text"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDriverName(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Color :</label>
              <input
                type="text"
                placeholder="color"
                className="vi_0"
                onChange={(e) => setDVehicleColor(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Mobile Number :</label>
              <input
                type="text"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setDriverMobile(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Gender :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setDriverGender(e.target.value)}
              >
                <option value="Not-Selected">Select</option>
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
              </Form.Select>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Date of Birth :</label>
              <input
                type="date"
                className="vi_0"
                onChange={(e) => setDriverDOB(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Licence Number:</label>
              <input
                type="text"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setDLiecenceNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Aadhar Number:</label>
              <input
                type="text"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setDAAdhaarNumber(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Type :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setDVehicleType(e.target.value)}
              >
                <option value="Not-Selected">Select</option>
                <option value="AC">AC</option>
                <option value="NON-AC">NON-AC</option>
              </Form.Select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Category :</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setDVehicleCategory(e.target.value)}
              >
                <option value="Not-Selected">Select</option>
                {getVehicleModelData?.map((item) => {
                  return (
                    <>
                      <option value={item?.vehiclecategory}>
                        {item?.vehiclecategory}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Model:</label>
              <Form.Select
                aria-label="Default select example"
                className="vi_0"
                onChange={(e) => setDVehicleModel(e.target.value)}
              >
                <option value="Not-Selected">Select</option>
                {getVehicleModelData
                  ?.filter((ele) => ele.vehiclecategory == DVehicleCategory)
                  ?.map((item) => {
                    return (
                      <>
                        <option value={item?.vehicleModel}>
                          {item?.vehicleModel}
                        </option>
                      </>
                    );
                  })}
              </Form.Select>
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Reg Number:</label>
              <input
                type="text"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setDVehicleRegNumber(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold" htmlFor="upload">
                Bank Name:
              </label>
              <input
                type="text"
                placeholder="Bank Name"
                className="vi_0"
                onChange={(e) => setDriverBankName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">Branch Name:</label>
              <input
                type="text"
                placeholder="Branch Name"
                className="vi_0"
                onChange={(e) => setDriverBranch(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold" htmlFor="upload">
                Bank Acount Number :
              </label>
              <input
                type="text"
                placeholder="Bank Acount Number"
                className="vi_0"
                onChange={(e) => setDriverBankAcc(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">IFSC Code:</label>
              <input
                type="text"
                placeholder="IFSC Code"
                className="vi_0"
                onChange={(e) => setDriverIFSC(e.target.value)}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold" htmlFor="upload">
                Bank Holder Name :
              </label>
              <input
                type="text"
                placeholder="Bank Holder Name"
                className="vi_0"
                onChange={(e) => setDBankHolder(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            {" "}
            <div className="col-lg-6">
              <label className="fw-bold">Pancard Image :</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDPancardImg(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold" htmlFor="upload">
                Driver Image :
              </label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDriverImg(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Aadhar Front Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDAAdhaarFront(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Aadhar Back Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDAAdhaarBack(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="fw-bold">Police Verify Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDPoliceVerifyImg(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Licence Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDLiecenceImg(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Permit:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDVehiclePermitImg(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Insurance Doc:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDVehicleInsuranceDoc(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Rc Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDVehicleRCImg(e.target.files[0])}
              />
            </div>
            <div className="col-lg-6">
              <label className="fw-bold">Vehicle Image:</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                placeholder="Name"
                className="vi_0"
                onChange={(e) => setDVehicleImg(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditDriver}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Vehicle Info Model */}
      <Modal size="lg" show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          {/* <Modal.Title className='text-danger'>Vehicle Information</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="mb-3">
            <p className="fs-4 mb-3">
              Vehicle <span className="text-danger">Information :</span>
            </p>
            <tbody>
              <tr>
                <td className="fw-bold">Vehicle Color :</td>
                <td>{VehicleModelData?.DVehicleColor}</td>
                <td className="fw-bold">Vehicle Type :</td>
                <td>{VehicleModelData?.DVehicleType}</td>
              </tr>
              <tr>
                <td className="fw-bold">Vehicle Category :</td>
                <td>{VehicleModelData?.DVehicleCategory}</td>
                <td className="fw-bold">Vehicle Model :</td>
                <td>{VehicleModelData?.DVehicleModel}</td>
              </tr>
              <tr>
                <td className="fw-bold">Vehicle Reg Number :</td>
                <td>{VehicleModelData?.DVehicleRegNumber}</td>
                <td className="fw-bold">Police Verify Image :</td>
                <td>
                  <a
                    href={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DPoliceVerifyImg}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DPoliceVerifyImg}`}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        imageRendering: "pixelated",
                      }}
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Vehicle Permit :</td>
                <td>
                  <a
                    href={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehiclePermitImg}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehiclePermitImg}`}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        imageRendering: "pixelated",
                      }}
                    />
                  </a>
                </td>
                <td className="fw-bold">Vehicle Insurance Doc :</td>
                <td>
                  <a
                    href={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehicleInsuranceDoc}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehicleInsuranceDoc}`}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        imageRendering: "pixelated",
                      }}
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Vehicle RC Image :</td>
                <td>
                  <a
                    href={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehicleRCImg}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehicleRCImg}`}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        imageRendering: "pixelated",
                      }}
                    />
                  </a>
                </td>
                <td className="fw-bold">Vehicle Image :</td>
                <td>
                  <a
                    href={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehicleImg}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DVehicleImg}`}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        imageRendering: "pixelated",
                      }}
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered>
            <p className="fs-4 mb-3">
              Bank <span className="text-danger">Details :</span>
            </p>
            <tbody>
              <tr>
                <td className="fw-bold">Bank Name :</td>
                <td>{VehicleModelData?.DriverBankName}</td>
                <td className="fw-bold">Branch :</td>
                <td>{VehicleModelData?.DriverBranch}</td>
              </tr>
              <tr>
                <td className="fw-bold">Bank Acount Number :</td>
                <td>{VehicleModelData?.DriverBankAcc}</td>
                <td className="fw-bold">IFSC Code :</td>
                <td>{VehicleModelData?.DriverIFSC}</td>
              </tr>
              <tr>
                <td className="fw-bold">Bank Holder Name :</td>
                <td>{VehicleModelData?.DBankHolder}</td>
                <td className="fw-bold">PanCard Image</td>
                <td>
                  <a
                    href={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DPancardImg}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`https://hayyyak.com/TaxiDriver/${VehicleModelData?.DPancardImg}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        imageRendering: "pixelated",
                      }}
                      alt=""
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Cancel
          </Button>
          <Button onClick={DeleteDriver} variant="danger">
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AdminVehicleDriver;
