import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { AiOutlineRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import parse from "html-react-parser"

export const Blog = () => {

    // get blog
    const [blogdata, setblogdata] = useState([])
    const GetBlog = async () => {
        axios
            .get("https://hayyyak.com/api/admin/getblog")
            .then(function (response) {
                setblogdata(response.data.success)
            }).catch(function (error) {
                console.log(error)
            })
    }
    console.log("blogdata", blogdata);


    useEffect(() => {
        GetBlog()
    }, [])


    return (
        <div>
            <div className='mb-1'>
                <div className='activity-bg'>
                    <div
                        style={{
                            backgroundImage: 'url(././img/blog.jpg)',
                            backgroundPosition: 'top',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'fixed',
                            height: "350px",
                            width: "100%",
                        }}
                        className="blog-top-activity"
                    >
                        <div className="activity-text">
                            <div className='bread'>
                                <h2 className='text-uppercase fw-bold'>Blog</h2>
                                <div className='d-flex align-items-center gap-2'>
                                    <Link to='/' className='breadcrumb-active'><span>Home </span></Link>
                                    <span><AiOutlineRight /></span>
                                    <span className='' style={{color:"#D81D4A", fontWeight:"bold"}} >Blog</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Container
                style={{
                    boxShadow: "0px 0px 10px 0px #00000045",
                    padding: "25px"
                }}
                className='blog-pad'
            >
                {blogdata?.map((item, i) => {
                    if (i % 2 === 0) {
                        return (
                            <div className="row blog mb-5">
                                <div className="col-lg-6 " >
                                    <img src={`https://hayyyak.com/Blog/${item?.BlogImage}`} alt="" />
                                </div>
                                <div className="col-lg-6">
                                    <h4>{item?.BlogTitle}</h4>
                                    <p className='mb-2'><span className='fw-bold text-secondary'>Date</span> : {item?.BlogDate}, <span className='fw-bold text-secondary'>City</span> : {item?.BlogCity}</p>
                                    <span>{parse(`<div>${item?.BlogDescription}</div>`)}</span>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="row blog mb-5">
                                <div className="col-lg-6">
                                    <h4>{item?.BlogTitle}</h4>
                                    <p className='mb-2'><span className='fw-bold text-secondary'>Date</span> : {item?.BlogDate}, <span className='fw-bold text-secondary'>City</span> : {item?.BlogCity}</p>
                                    <span>{parse(`<div>${item?.BlogDescription}</div>`)}</span>
                                </div>
                                <div className="col-lg-6 " >
                                    <img src={`https://hayyyak.com/Blog/${item?.BlogImage}`} alt="" />
                                </div>
                            </div>
                        )
                    }
                })}
            </Container>
        </div>
    )
}
