import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";

function AdminNotification() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    GetAdminNotification();
  }, []);

  // Add Vechicle Category
  const [Header, setHeader] = useState("");
  const [Text, setText] = useState("");
  const AddNotification = async () => {
    if (!Header) {
      return notify1("Please Select Vehicle Category");
    }
    if (!Text) {
      return notify1("Please Enter Vehicle Model");
    }
    try {
      const config = {
        url: "/AddNotification",
        baseURL: "https://hayyyak.com/api/admin",
        method: "post",
        header: { "content-type": "application/json" },
        data: {
          Heading: Header,
          Text: Text,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          handleClose();
          GetAdminNotification();
        }
      });
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // EDIT Vehicle Category
  const [editdata, seteditdata] = useState("");
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    seteditdata(id);
  };
  const EditNotification = async () => {
    try {
      const config = {
        url: "/EditNotification/" + editdata,
        baseURL: "https://hayyyak.com/api/admin",
        method: "put",
        headers: { "content-type": "application/json" },
        data: {
          Heading: Header,
          Text: Text,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          handleClose1();
          GetAdminNotification();
        }
      });
    } catch (error) {
      console.log(error);
      notify2(error.response.data.error);
    }
  };

  // get Notification
  const [getNotification, setgetNotification] = useState([]);
  const GetAdminNotification = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/getNotification"
      );
      if (res.status === 200) {
        setgetNotification(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete
  const [Data, setData] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    setData(id);
  };

  const DeleteNotification = async () => {
    try {
      const config = {
        url: "/DeleteNotification/" + Data,
        baseURL: "https://hayyyak.com/api/admin",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const response = await axios(config);
      if (response.status === 200) {
        notify(response.data.success);
        GetAdminNotification();
        handleClose2();
      }
    } catch (error) {
      notify2(error.response ? error.response.data.error : "An error occurred");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2 mb-3">
          <p className="text-danger fs-4 fw-semibold">Notification :</p>
          <button className="pink-btn" onClick={handleShow}>
            Add Notification
          </button>
        </div>

        <div>
          <Table responsive bordered className="sdfsd-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Header</th>
                <th>Text</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {getNotification?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.Heading}</td>
                      <td>{item?.Text}</td>
                      <td>
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit
                            className="text-primary"
                            onClick={() => handleShow1(item?._id)}
                          />
                          <MdDelete
                            className="text-danger"
                            onClick={() => handleShow2(item?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* Add Activity Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Add Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" mt-2">
            <label className="fw-bold">Enter Header :</label>
            <input
              type="text"
              placeholder="Header"
              className="vi_0"
              onChange={(e) => setHeader(e.target.value)}
            />
          </div>
          <div className=" mt-2">
            <label className="fw-bold">Enter Text :</label>
            <input
              type="text"
              placeholder="Text"
              className="vi_0"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddNotification}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Activity */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Edit Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" mt-2">
            <label className="fw-bold">Enter Header :</label>
            <input
              type="text"
              placeholder="Header"
              className="vi_0"
              onChange={(e) => setHeader(e.target.value)}
            />
          </div>
          <div className=" mt-2">
            <label className="fw-bold">Enter Text :</label>
            <input
              type="text"
              placeholder="Text"
              className="vi_0"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditNotification}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteNotification}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AdminNotification;
