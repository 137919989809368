import { Autocomplete, Box, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { CiLocationOn, CiSearch } from "react-icons/ci";
import parse from "html-react-parser";
import { FaHotel } from "react-icons/fa6";

export const TripPackage = () => {
  const location = useLocation();
  const { item, City } = location?.state ? location?.state : "";
  console.log("item", item);
  console.log("City", City);

  // get Tour-Packages Category
  const [getTourCat, setgetTourCat] = useState([]);
  const getTourcategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/tourpackage/gettourcategory")
      .then(function (response) {
        setgetTourCat(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //get tour packages
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [newdata, setnewdata] = useState([]);
  const [Tourpackages, setTourpackages] = useState([]);



  const getTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/gettrippackages"
      );
      if (res.status === 200) {
        setTourpackages(res.data.getpackages);
        setnewdata(res.data.getpackages);
        setFilteredPackages(res.data.getpackages);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTourPackages();
    getTourcategory();
  }, []);
  console.log("Tourpackages", Tourpackages);

  // Category
  const [category, setcategory] = useState("");
  useEffect(() => {
    if (category) {
      const catfilter = Tourpackages?.filter(
        (ele) => ele.tripcategory === category
      );
      setFilteredPackages(catfilter);
      setnewdata(catfilter);
    } else {
      setFilteredPackages(Tourpackages);
      setnewdata(Tourpackages);
    }
  }, [Tourpackages, category]);

  // Search
  const [SearchItem, setSearchItem] = useState("");

  //City
  const [selectnight, setselectnight] = useState("");
  useEffect(() => {
    if (selectnight) {
      const nightfilter = Tourpackages?.filter(
        (ele) => ele?.tripnight === selectnight
      );
      setFilteredPackages(nightfilter);
      setnewdata(nightfilter);
    }
  }, [Tourpackages, selectnight]);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 3;
  const pagesVisited = pageNumber * usersPerPage;
  const changePage = (selected) => {
    setPageNumber(selected);
  };
  const SearchFilterTourPack = newdata?.filter(
    (ele) =>
      SearchItem === "" ||
      ele?.tripname?.toLowerCase()?.includes(SearchItem?.toLowerCase())
  );
  const filteredTourPackages = item
    ? SearchFilterTourPack?.filter(
        (ele) =>
          (ele?.venderaddstatus === "Approved" ||
            ele?.AddedbyAdmin === "AddedByAdmin") &&
          ele.tripcategory === item &&
          ele.city === City
      )
    : SearchFilterTourPack?.filter(
        (ele) =>
          ele?.venderaddstatus === "Approved" ||
          ele?.AddedbyAdmin === "AddedByAdmin"
      );

     
      

  const pageCount = Math.ceil(filteredTourPackages?.length / usersPerPage);
  const paginationTourPackages = filteredTourPackages?.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );


  return (
    <div>
      <div className="mb-4">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(././img/trip-package-bg.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              height: "350px",
              width: "100%",
            }}
            className="tripPackages-top"
          >
            <div className="activity-text">
              <div className="bread" id="tripPackages-bread">
                <h2 className="text-uppercase fw-bold">Tour Packages</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Tour Packages</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="row align-items-center mb-4 tourpackages">
          <div className="col-lg-3 fw-bold fs-6">
            <p>
              <span className="text-danger">Popular</span> Tourpackages Near
              You:
            </p>
          </div>
          <div className="col-lg-3">
            <label className="fw-bold">Tour packages :</label>
            <div class="input-group mb-3">
              <label class="input-group-text" for="inputGroupSelect01">
                <CiLocationOn />
              </label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setcategory(e.target.value)}
              >
                <option value="">Select Package</option>
                {Array.from(
                  new Set(
                    Tourpackages?.filter(
                      (ele) =>
                        ele?.venderaddstatus === "Approved" ||
                        ele?.AddedbyAdmin === "AddedByAdmin"
                    ).map((item) => item?.tripcategory)
                  )
                ).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-lg-3">
            <label className="fw-bold">Search :</label>
            <div class="input-group mb-3">
              <label class="input-group-text" for="inputGroupSelect01">
                <CiSearch />
              </label>
              <Form.Control
                onChange={(e) => setSearchItem(e.target.value)}
                type="text"
                placeholder="search here..."
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-lg-3 mb-2">
            <h4
              style={{
                background: "rgb(216 29 74)",
                color: "#fff",
                padding: "10px",
                marginBottom: "0px",
              }}
            >
              Suggesting Packages
            </h4>
            <div style={{ border: "1px solid #00000033" }}>
              <div
                className="scroll-cat "
                style={{ overflow: "auto", height: "300px" }}
              >
                <ul style={{ listStyle: "none", padding: "5px" }}>
                  {Array.from(
                    new Set(
                      Tourpackages?.filter(
                        (ele) =>
                          ele?.venderaddstatus === "Approved" ||
                          ele?.AddedbyAdmin === "AddedByAdmin"
                      ).map((item) => item?.tripcategory)
                    )
                  ).map((category) => {
                    const categoryItems = Tourpackages.filter(
                      (item) =>
                        item?.tripcategory === category &&
                        (item?.venderaddstatus === "Approved" ||
                          item?.AddedbyAdmin === "AddedByAdmin")
                    );
                    return (
                      <React.Fragment key={category}>
                        <li
                          onClick={() => {
                            setcategory(category);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a className="top-brand">
                            <div>
                              <h5>{category}</h5>
                            </div>
                            <p className="rate">{categoryItems.length}</p>
                          </a>
                        </li>
                        <hr />
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>

              <div >
                <h4
                  style={{
                    background: "rgb(216 29 74)",
                    color: "#fff",
                    padding: "10px",
                    marginBottom: "0px",
                  }}
                >
                  Days / Nights
                </h4>
                <div
                  className="scroll-cat"
                  style={{ overflow: "auto", height: "300px" }}
                >
                  <div className="p-2">
                    <div className="mt-3 mb-3">
                      {Tourpackages?.filter(
                        (ele) =>
                          ele?.venderaddstatus === "Approved" ||
                          ele?.AddedbyAdmin === "AddedByAdmin"
                      )?.map((item) => (
                        <React.Fragment key={item.id}>
                          <p
                            onClick={() => {
                              const nightfilter = Tourpackages.filter(
                                (ele) =>
                                  ele?.tripduration === item.tripduration &&
                                  ele?.tripnight === item.tripnight
                              );
                              setFilteredPackages(nightfilter);
                              setnewdata(nightfilter);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item?.tripduration}D/{item?.tripnight}N
                          </p>
                          <hr />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            {paginationTourPackages?.map((item) => {
              // if (
              //   SearchItem === "" ||
              //   item?.tripname
              //     ?.toLowerCase()
              //     ?.includes(SearchItem?.toLowerCase())
              // )
              return (
                <div className="ousf_0">
                  <div className="row align-items-start ">
                    <div className="col-lg-3 ">
                      <img
                        src={`https://hayyyak.com/Packages/${item?.GalleryImage[0]?.image}`}
                        alt=""
                        className="trip-img"
                      />
                      <span
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          padding: "5px",
                          borderRadius: "5px",
                          fontWeight: " 700",
                        }}
                      >
                        {item?.tripduration}D/{item?.tripnight}N
                      </span>
                    </div>

                    <div className="col-lg-6 trip">
                      <h4>{item?.tripname}</h4>
                      <p>
                        {parse(
                          `<div>${item?.tripdescription?.slice(
                            0,
                            250
                          )}...</div>`
                        )}
                      </p>
                    </div>

                    <div className="col-lg-3 trip-amount ">
                      <div>
                        <p>Price Starting</p>
                        <span>
                          ₹ {item?.offerprice} /{" "}
                          <span style={{ color: "grey", fontSize: "14px" }}>
                            <s>₹ {item?.tripcost}</s>
                          </span>{" "}
                        </span>
                        <br />
                        <Link
                          to="/trippackagedetails"
                          state={{ item: item }}
                          className="text-decoration-none"
                        >
                          <button
                            className="header-search"
                            style={{ width: "auto", height: "45px" }}
                          >
                            View Details
                          </button>
                        </Link>
                      </div>
                      <div className="trip-offer">{item?.tripdiscount}%off</div>
                    </div>
                  </div>

                  <hr />

                  <div>
                    <p className="fs-5 text-secondary fw-bold">
                      Detail and Includes
                    </p>
                    <ul className="trip-i">
                      {item?.signtseeing === "Yes" ? (
                        <>
                          <li>
                            <img src="./img/trip-icon-1.png" alt="" />
                            <p>Sightseeing</p>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {item?.transport === "Yes" ? (
                        <>
                          <li>
                            <img src="./img/trip-icon-3.png" alt="" />
                            <p>Transport</p>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {item?.hotels === "Yes" ? (
                        <>
                          <li>
                            {/* <img src="./img/trip-icon-4.png" alt="" /> */}
                            <FaHotel />
                            <p>Hotel</p>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              );
            })}
            <div className="my-3">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  onChange={(event, value) => {
                    changePage(value - 1);
                  }}
                  color="primary"
                />
              </Stack>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
