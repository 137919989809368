import React, { useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { AiFillEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'


function VendorStaysReview() {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    return (
        <div>
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Reviews :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Review</button>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn">Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-describedby="basic-addon1" />
                    </div>
                </div>

                <div >
                    <Table responsive bordered className='sdfsd-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Hotel Name</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Country</th>
                                <th style={{ width: '400px' }}>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Taj Hotel</td>
                                <td>
                                    <img src="./img/hotel-farm-1.webp" alt="" style={{ width: '100px', height: '100px', imageRendering: 'pixelated' }} />
                                </td>
                                <td>Ganesh Kalal</td>
                                <td>India</td>
                                <td>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Exercitationem voluptas delectus nostrum. Esse voluptates quia exercitationem</td>
                                <td>
                                    <div className='d-flex gap-2 align-items-center' style={{ cursor: 'pointer' }} >
                                        <BiSolidEdit className='text-primary fs-4' onClick={handleShow1} />
                                        <MdDelete onClick={handleShow2} className='text-danger fs-4' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose} className='vendorli'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <label className='fw-bold'>Select Hotel :</label>
                        <Form.Select aria-label="Default select example" className="vi_0" >
                            <option>select hotel</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <label className='fw-bold'>Customer Name :</label>
                        <input type="text" placeholder='name' className="vi_0" />
                    </div>
                    <div className="mb-2">
                        <label className='fw-bold'>Country :</label>
                        <input type="text" placeholder='country' className="vi_0" />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor} />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold' htmlFor='upload'> Image</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            multiple
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Add Room
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1} className='vendorli'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <label className='fw-bold'>Select Hotel :</label>
                        <Form.Select aria-label="Default select example" className="vi_0" >
                            <option>select hotel</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <label className='fw-bold'>Customer Name :</label>
                        <input type="text" placeholder='name' className="vi_0" />
                    </div>
                    <div className="mb-2">
                        <label className='fw-bold'>Country :</label>
                        <input type="text" placeholder='country' className="vi_0" />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold'>Description :</label>
                        <CKEditor editor={ClassicEditor} />
                    </div>

                    <div className="mb-2">
                        <label className='fw-bold' htmlFor='upload'> Image</label>
                        <input
                            id='upload'
                            type="file"
                            accept='image/*'
                            placeholder="Name"
                            className="vi_0"
                            multiple
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose1}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Delete Model */}
            <Modal show={show2} onHide={handleClose2} className='anties_mdl'>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">Amenities</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleClose2}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default VendorStaysReview

