import React, { useEffect, useState } from "react";
import { Button, Nav, NavDropdown, Navbar, Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { CiLogin } from "react-icons/ci";
import { FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { IoClose } from "react-icons/io5";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
export const NavbarScrol = () => {
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const [ShowNotification, setShowNotification] = useState(false);
  const handleNotificationClick = () => {
    setShowNotification(true);
  };

  // get Notification
  const [Notification, setgetNotification] = useState([]);
  const GetAdminNotification = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/getNotification"
      );
      if (res.status === 200) {
        setgetNotification(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAdminNotification();
  }, []);

  return (
    <>
      <div className="nic">
        <Navbar sticky="top" expand="lg" className="bg-body-tertiary ">
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src="./img/logo.jpeg"
                alt=""
                style={{ width: "auto", height: "62px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0 navigation "
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/hotel">Stays</Nav.Link>
                <Nav.Link href="/taxi">Taxi</Nav.Link>
                <Nav.Link href="/activity">Activities</Nav.Link>
                <Nav.Link href="/trippackage">Tour Packages</Nav.Link>
                <Nav.Link href="/community">Community</Nav.Link>
                <Nav.Link href="/restaurant_cafe">Restaurant and Cafe</Nav.Link>
                <Nav.Link href="/things_todo">Things To Do</Nav.Link>
                <Nav.Link
                  className="mobile-res-community-header"
                  onClick={handleNotificationClick}
                >
                  <Badge
                    color="secondary"
                    badgeContent={Notification?.length}
                    max={9}
                  >
                    <NotificationsIcon />
                  </Badge>
                </Nav.Link>
                <Nav.Link
                  className=" notification-icon-navbar"
                  onClick={handleNotificationClick}
                >
                  <Badge
                    color="secondary"
                    badgeContent={Notification?.length}
                    max={9}
                  >
                    <NotificationsIcon />
                  </Badge>
                </Nav.Link>
              </Nav>

              <div className="d-flex  gap-2  p-1 ">
                {Customer ? (
                  <>
                    <a href="/profile">
                      {Customer?.profileImage ? (
                        <div className="position-relative">
                          <img
                            className="login-img"
                            src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                          />
                        </div>
                      ) : (
                        <div className="">
                          {" "}
                          <img className="login-img" src="./img/profile.jpg" />
                        </div>
                      )}
                    </a>
                    <div className="position-relative">
                      <div className="d-flex align-items-center">
                        <p className="small-text  ">Active </p>
                        <span className="active-dot"></span>
                      </div>

                      <p className="advison-name">
                        {Customer?.Fname} {Customer?.Lname}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <Link to="/login">
                      <Button className="top-navbar-btn sign-in-btn">
                        <CiLogin className="me-1" />
                        Sign In
                      </Button>
                    </Link>
                    {/* <Link to='/register'>
                                    <Button className='top-navbar-btn sign-in-btn'>Sign Up</Button>
                                </Link> */}
                  </>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {ShowNotification && (
        <div className="nav-notification">
          <div>
            <div
              className="text-end "
              onClick={() => {
                setShowNotification(false);
              }}
            >
              <IoClose style={{ border: "1px solid", borderRadius: "4px" }} />
            </div>
            <h4>Notifications</h4>
            <hr />
          </div>
          {Notification?.map((item) => {
            return (
              <div
                className="mt-1"
                style={{
                  backgroundColor: "wheat",
                  borderRadius: "5px",
                  padding: "4px",
                }}
              >
                <b>{item?.Heading}</b>
                <p>{item?.Text}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
