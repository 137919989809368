import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VTaxiRegister() {
    const navigation = useNavigate();
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.error(data);
    const notify2 = (data) => toast.warn(data);

    const [VTaxiFname, setVTaxiFname] = useState("");
    const [VTaxiLname, setVTaxiLname] = useState("");
    const [VTaxiMobile, setVTaxiMobile] = useState();
    const [VTaxiEmail, setVTaxiEmail] = useState("");
    const [VTaxiPassword, setVTaxiPassword] = useState("");
    const [VTaxiCPassword, setVTaxiCPassword] = useState("");
    const [PasswordShow, setPasswordShow] = useState(false);
    const [confirmpasswordshow, setconfirmpasswordshow] = useState(false);

    const VendorTaxiRegister = async () => {
        if (!VTaxiFname && !VTaxiLname && !VTaxiMobile && !VTaxiEmail && !VTaxiPassword && !VTaxiCPassword) {
            notify2("Please fill all the fields");
        } else if (VTaxiPassword !== VTaxiCPassword) {
            notify2("Password & Confirm Password should be same");
        } else {
            try {
                const config = {
                    url: "/vendortaxiregister",
                    method: "post",
                    baseURL: "https://hayyyak.com/api/vendor",
                    data: {
                        Fname: VTaxiFname,
                        Lname: VTaxiLname,
                        Mobile: VTaxiMobile,
                        Email: VTaxiEmail,
                        Password: VTaxiPassword,
                        Cpassword: VTaxiCPassword,
                    },
                };
                await axios(config).then(function (res) {
                    console.log("res.status", res.status);
                    if ((res.status = 200)) {
                        notify("Registered successfully");
                        window.location.assign("/V_Taxi_Login");
                    } else {
                        notify1(res.data.error);
                    }
                });
            } catch (error) {
                console.log("error", error);
                notify1(error.response.data.error);
            }
        }
    };

    return (
        <div>
            <div
                style={{
                    backgroundImage: 'url("./img/header-bg-img-3.jpg")',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "100%",
                    width: "100%",
                    padding: "68px",
                }}
            >
                <ToastContainer
                    position="top-center"
                    autoClose={30000}
                    closeOnClick
                    pauseOnHover
                    draggable
                />
                <Container>
                    <div className="login-bg">
                        <div className="login mb-4">
                            <h4>
                                Create an <span className="text-danger">Account</span>
                            </h4>
                            <p className="text-secondary"><span className="text-danger">Vendor Taxi </span> Enter Your Details</p>
                        </div>

                        <div className="mb-4">
                            <InputGroup className="mb-4">
                                <Form.Control
                                    className="login-input"
                                    placeholder="First Name"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setVTaxiFname(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup className="mb-4">
                                <Form.Control
                                    className="login-input"
                                    placeholder="Last Name"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setVTaxiLname(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup className="mb-4">
                                <Form.Control
                                    type="number"
                                    className="login-input"
                                    placeholder="Mobile"
                                    aria-describedby="basic-addon1"
                                    maxLength={10}
                                    onChange={(e) => setVTaxiMobile(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup className="mb-4">
                                <Form.Control
                                    type="email"
                                    className="login-input"
                                    placeholder="Email"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setVTaxiEmail(e.target.value)}
                                />
                            </InputGroup>

                            <div>
                                <InputGroup className="mb-4">
                                    <Form.Control
                                        type={PasswordShow ? "text" : "password"}
                                        className="login-input"
                                        placeholder="Password"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setVTaxiPassword(e.target.value)}
                                    />
                                    {PasswordShow ? (
                                        <button
                                            onClick={() => setPasswordShow(!PasswordShow)}
                                            className="passbtn"
                                        >
                                            <i class="fas fa-eye-slash"></i>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => setPasswordShow(!PasswordShow)}
                                            className="passbtn"
                                        >
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    )}
                                </InputGroup>
                            </div>

                            <div>
                                <InputGroup className="mb-4">
                                    <Form.Control
                                        type={confirmpasswordshow ? "text" : "password"}
                                        className="login-input"
                                        placeholder="Confirm Password"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setVTaxiCPassword(e.target.value)}
                                    />
                                    {confirmpasswordshow ? (
                                        <button
                                            onClick={() =>
                                                setconfirmpasswordshow(!confirmpasswordshow)
                                            }
                                            className="passbtn"
                                        >
                                            <i class="fas fa-eye-slash"></i>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() =>
                                                setconfirmpasswordshow(!confirmpasswordshow)
                                            }
                                            className="passbtn"
                                        >
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    )}
                                </InputGroup>
                            </div>
                        </div>

                        <div className="mb-4">
                            <Button
                                className="header-search"
                                style={{ width: "100%" }}
                                onClick={VendorTaxiRegister}
                            >
                                Register
                            </Button>
                        </div>

                        <div className="text-center mb-4">
                            <p>
                                Already member ? |
                                <a
                                    href="/V_Taxi_Login"
                                    style={{
                                        color: "#d81d4a",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                    }}
                                >
                                    Login
                                </a>
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default VTaxiRegister;
