import React from 'react'
import { Container } from 'react-bootstrap'
import { BsFillBusFrontFill } from 'react-icons/bs'
import { FaHotel } from 'react-icons/fa'
import HikingIcon from '@mui/icons-material/Hiking';
function V_Taxi_Dashboard() {
    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-2 text-light p-3  "
                    style={{
                        backgroundColor: "rgb(171 37 136)",
                        width: "250px",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold">TAXI</h6>
                    <BsFillBusFrontFill className="me-5 float-start fs-2 mt-2" />
                    <span className="fs-2 fw-bold ms-5 ">231</span>
                    <p>18% Higher Then Last Month</p>
                </div>

                <div
                    className="col-lg-2 text-light p-3"
                    style={{
                        backgroundColor: "rgb(49 139 217)",
                        width: "261px",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold text-light">HOTELS</h6>
                    <FaHotel className="me-5 float-start fs-2 mt-2" />
                    <span className="fs-2 fw-bold ms-5 "> 42</span>
                    <p>37% Higher Then Last Month</p>
                </div>

                <div
                    className="col-lg-2 text-light p-3"
                    style={{
                        backgroundColor: "rgb(171 42 21)",
                        width: "250px",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold"> ACTIVITY</h6>
                    <HikingIcon className="me-5 float-start fs-2 mt-2" />
                    <span className="fs-2 fw-bold ms-5 "> 28</span>
                    <p>10% Higher Then Last Month</p>
                </div>
            </div>

        </Container>
    )
}

export default V_Taxi_Dashboard