import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';

export const VendorTourProfile = () => {

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const TourVendor = JSON.parse(sessionStorage.getItem("TourId"))

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const deleteClose = () => setShow2(false)
    const deleteShow = () => setShow2(true)

    const [Fname, setFname] = useState("");
    const [Lname, setLname] = useState("");
    const [Mobile, setMobile] = useState();
    const [Email, setEmail] = useState("");
    // Edit Vendor
    const [editData, seteditData] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (item) => { setShow(true); seteditData(item) }
    const EditTourVendor = async () => {
        try {
            const config = {
                url: `/vendor/edittourvendor/${editData}`,
                baseURL: "https://hayyyak.com/api",
                method: "put",
                headers: { "Content-Type": "application/json" },
                data: {
                    Fname: Fname,
                    Lname: Lname,
                    Mobile: Mobile,
                    Email: Email
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                sessionStorage.setItem("TourId", JSON.stringify(res.data.user))
                notify(res.data.success)
                handleClose()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <h1 class="display-6 text-center mb-3">Profile <span className='text-danger'>Details</span></h1>

                <div className='mb-5'>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td className='fw-bold '>First <span className="text-danger">Name :</span> </td>
                                <td>{TourVendor?.Fname} </td>
                                <td className='fw-bold '>Last <span className="text-danger">Name :</span> </td>
                                <td>{TourVendor?.Lname} </td>
                            </tr>
                            <tr>
                                <td className='fw-bold '>Mobile <span className="text-danger">Number :</span> </td>
                                <td>{TourVendor?.Mobile} </td>
                                <td className='fw-bold '>Email : </td>
                                <td>{TourVendor?.Email} </td>
                            </tr>
                        </tbody>
                    </Table>
                    <button className='pink-btn' onClick={() => handleShow(TourVendor?._id)}>Edit Profile</button>
                </div>


                {/* <Table bordered className='sdfsd-table-head'>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Property Name</th>
                            <th>GST Number</th>
                            <th>GST Documnet</th>
                            <th>Adhaar Card</th>
                            <th>Pan Card</th>
                            <th>Property Document</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {StaysDetails?.map((item, i) => {
                            return (<>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item?.propartyname}</td>
                                    <td>{item?.gst}</td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.gstdocument}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.aadhar}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.pancard}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td><img src={`https://hayyyak.com/VendorStay/${item?.propartydoc}`} style={{ imageRendering: 'pixelated', width: "100px", height: "100px", cursor: 'pointer' }} /></td>
                                    <td>
                                        <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                            <MdDelete className='text-danger' onClick={() => deleteShow()} />
                                        </div>
                                    </td>
                                </tr>
                            </>)
                        })}
                    </tbody>
                </Table> */}

            </Container>

            {/* Edit Profile Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit <span className='text-danger'>Profile</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-4">
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>First Name :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setFname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Last Name:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setLname(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Mobile Number:</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setMobile(e.target.value)} />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <label className='fw-bold'>Email :</label>
                            <input type="text" className='form-control login-input' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    {/* <div className="row mb-4">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Adhar card :</label>
                            <input type="file" multiple className='form-control login-input' />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Pan card :</label>
                            <input type="file" multiple className='form-control login-input' />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>GST Number:</label>
                            <input type="text" className='form-control login-input' />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>GST Document :</label>
                            <input type="file" multiple className='form-control login-input' />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <label className='fw-bold'>Property Document :</label>
                            <input type="file" className='form-control login-input' />
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditTourVendor} >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={deleteClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={deleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger"><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}
