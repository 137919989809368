import React, { useState } from "react";
import { Breadcrumb, Button, Form, InputGroup } from "react-bootstrap";
import { AiOutlineHome, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { FaHotel } from "react-icons/fa";
import NavigationIcon from "@mui/icons-material/Navigation";
import axios from "axios";
import { useEffect } from "react";
import parse from "html-react-parser";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export const Hotel = () => {
  const location = useLocation();
  const { stayslocation } = location?.state ? location?.state : "";

  console.log("stayslocationsss", stayslocation);
  const [Hotels, setHotels] = useState(true);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleButtonClick = (item) => {
    const newCategory =
      item?.Category === selectedCategory ? "" : item?.Category;
    setSelectedCategory(newCategory);
  };

  // Get Category
  const [StayCategory, setStayCategory] = useState([]);
  const getStayCategory = async () => {
    axios
      .get("https://hayyyak.com/api/admin/stays/getstaycategory")
      .then(function (res) {
        console.log(res.data);
        setStayCategory(res.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [StaysDetails2, setStaysDetails2] = useState([]);
  const [StaysDetails, setStaysDetails] = useState([]);
  const [StaysDetails1, setStaysDetails1] = useState([]);
  const getStays = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/stays/staysrooms"
      );
      if (res.data && res.status === 200) {
        setStaysDetails(res.data.allstays);
        setStaysDetails1(res.data.allstays);
        setStaysDetails2(res.data.allstays);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("StaysDetails", StaysDetails);
  const [AllCity, setAllCity] = useState([]);
  const staysrooms = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/stays/staysrooms"
      );
      if (res.status === 200) {
        setAllCity(res.data.allstays);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Category
  const [cityName, setCityname] = useState("");
  useEffect(() => {
    staysrooms();
    if (stayslocation) {
      setCityname(stayslocation?.selecetedCity);
    }
  }, [stayslocation]);

  const [category, setCategory] = useState("");
  useEffect(() => {
    if (category && StaysDetails1) {
      const filteredDetails = StaysDetails1?.filter((detail) =>
        detail.StaysCategory?.some((ele) => ele.Category === category)
      );
      setStaysDetails(filteredDetails);
    }
  }, [StaysDetails1, category]);

  useEffect(() => {
    getStayCategory();
    getStays();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const [Adult, setAdult] = useState(0);
  const increase = () => {
    setAdult(Adult + 1);
  };
  const decrease = () => {
    if (Adult > 0) {
      setAdult(Adult - 1);
    }
  };

  const [Children, setChildren] = useState(0);
  const Childreninc = () => {
    setChildren(Children + 1);
  };
  const Childrendec = () => {
    if (Children > 0) {
      setChildren(Children - 1);
    }
  };

  const [Infants, setInfants] = useState(0);
  const incinfants = () => {
    setInfants(Infants + 1);
  };
  const decinfants = () => {
    if (Infants > 0) {
      setInfants(Infants - 1);
    }
  };

  //Stays Search Content

  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDateChange = (selectedDates) => {
    setDate([selectedDates.selection]);
  };
  const startdate = date[0]?.startDate;
  const endDate = date[0]?.endDate;

  // console.log("stayslocation=====>",StaysDetails2?.filter((city) =>
  // city.city===stayslocation?.selecetedCity))

  const [filterstays, setfilterstays] = useState([]);
  // console.log("setfilterstays",filterstays);

  const filter = async () => {
    try {
      if (stayslocation) {
      }
    } catch (error) {}
  };

  const handleSliderChange = (value) => {
    const filtered = StaysDetails2.filter(
      (item) => item.startingprice <= value
    );
    setStaysDetails(filtered);
  };

  // Apply search filter
  const [SearchItem, setSearchItem] = useState("");
  const filteredStays = StaysDetails.filter(
    (item) =>
      SearchItem === "" ||
      item?.propartyname.toLowerCase().includes(SearchItem.toLowerCase())
  );

  //Pagination
  //  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 3;
  const pagesVisited = pageNumber * usersPerPage;
  const changePage = (selected) => {
    setPageNumber(selected);
  };

  const AproovedStays = filteredStays?.filter(
    (ele) =>
      ele?.isBlock === true &&
      ele?.Status === "Aprooved" &&
      (cityName ? ele?.city === cityName : true)
  );
  const pageCount = Math?.ceil(AproovedStays?.length / usersPerPage);
  const paginationStays = AproovedStays?.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: 'url("./img/hotel-bg.jpg")',
            height: "350px",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="hotel-top-bgimg"
        >
          <div className="gnednf">
            <div style={{ position: "relative" }}>
              <div className="hotel-cat" id="hotel-stays-top-icon">
                <div
                  onClick={() => {
                    setHotels(true);
                  }}
                  className={Hotels ? "mainbuttonn" : " mainbutton"}
                >
                  <span>
                    <FaHotel />
                  </span>
                  <p>Stays</p>
                </div>
              </div>
            </div>

            <div
              className="form-cn"
              style={{
                width: "76%",
                background: "rgb(255 255 255 / 84%);",
                padding: "15px",
                borderRadius: " 10px",
                position: "absolute",
                top: "275px",
                left: "12%",
                display: "table",
                boxShadow: "rgba(29, 29, 33, 0.09) 0px 2px 12px -3px",
                transition: " all 0.5s ease-in-out 0s",
              }}
              id="hotel-stay-destination"
            >
              <div className="hotel-arrow-icon-1">
                <NavigationIcon style={{ fontSize: "30px" }} />
              </div>

              <div className="boxes">
                <div>
                  <div class="form-field field-destination">
                    <label for="destination">
                      <span>Destination:</span> Country, City, Airport, Area,
                      Landmark
                    </label>
                    <select
                      id="destination"
                      className="field-input"
                      onChange={(e) => setCityname(e.target.value)}
                    >
                      <option>{cityName}</option>
                      {AllCity?.filter(
                        (ele) =>
                          ele?.isBlock === true && ele.Status === "Aprooved"
                      )?.map((city) => {
                        return <option value={city?.city}>{city?.city}</option>;
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-field field-date">
                  <label for="destination">
                    <span>Check In:</span>
                  </label>

                  <input
                    type="text"
                    value={
                      stayslocation?.checkIndate
                        ? `${format(stayslocation.checkIndate, "MM/dd/yyyy")}`
                        : format(new Date(startdate), "MM/dd/yyyy")
                    }
                    onClick={() => setOpenDate(!openDate)}
                    className="field-input"
                    id="check-in"
                  />
                </div>

                <div className="form-field field-date">
                  <label for="destination">
                    <span>Check Out:</span>
                  </label>
                  <input
                    type="text"
                    value={
                      stayslocation?.checkIndate
                        ? `${format(stayslocation.checkOutdate, "MM/dd/yyyy")}`
                        : format(new Date(startdate), "MM/dd/yyyy")
                    }
                    className="field-input"
                    id="check-in"
                  />
                </div>

                {/* <div className="form-field field-select">
                  <div className="select">
                  <span>Guest</span>
                  <div
                      onClick={handleClick}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <span style={{ fontSize: "10px" }}>
                        Adult {Adult}, Child {Children}, <br /> infants {Infants}

                      </span>
                      <span className="float-end">
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </div>
                </div> */}

                {/* <div>
                  <button onClick={() => filter()} className="header-search">
                    Search
                  </button>
                </div> */}
              </div>
              {openDate && (
                <div className="ghshasdg_00">
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    className="date"
                    minDate={new Date()}
                  />
                </div>
              )}
            </div>
            {isOpen && (
              <>
                <div
                  className="p-2"
                  style={{
                    background: "#fff",
                    width: "300px",
                    position: "absolute",
                    right: "16%",
                    bottom: "-24%",
                    zIndex: "9",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <b>Adults</b>
                      <p style={{ fontSize: "12px" }}>Age 13+</p>
                    </div>
                    <div className="d-flex gap-2">
                      <RemoveIcon
                        onClick={() => decrease()}
                        className="increase-icon"
                      />
                      <span>{Adult}</span>
                      <span>
                        {" "}
                        <AddIcon
                          onClick={() => increase()}
                          className="increase-icon"
                        />
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <b>Children</b>
                      <p style={{ fontSize: "12px" }}>Age 2-12</p>
                    </div>
                    <div className="d-flex gap-2">
                      <RemoveIcon
                        onClick={() => Childrendec()}
                        className="increase-icon"
                      />
                      <span>{Children}</span>
                      <span>
                        <AddIcon
                          onClick={() => Childreninc()}
                          className="increase-icon"
                        />
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <b>Infants</b>
                      <p style={{ fontSize: "12px" }}>Under 2</p>
                    </div>
                    <div className="d-flex gap-2">
                      <RemoveIcon
                        onClick={() => decinfants()}
                        className="increase-icon"
                      />
                      <span>{Infants}</span>
                      <span>
                        {" "}
                        <AddIcon
                          onClick={() => incinfants()}
                          className="increase-icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div>
          <div>
            <div className="main_hotel mb-5 mt-2 container">
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-4"
                    style={{ paddingRight: "unset", paddingLeft: "unset" }}
                  >
                    <div className="sidebar">
                      <div
                        className="container scroll-cat1"
                        style={{
                          maxHeight: "300px",
                          overflow: "auto",
                          width: "fit-content",
                          overflowY: "scroll",
                        }}
                      >
                        <div className=" d-flex flex-column gap-4 mb-4 mt-4">
                          {StayCategory?.filter(
                            (ele) => ele.CategoryStatus === true
                          )?.map((item) => {
                            const isSelected =
                              item.Category === selectedCategory;
                            return (
                              <Button
                                className="hotel-filter-btn"
                                onClick={() => {
                                  handleButtonClick(item);
                                  setCategory(item?.Category);
                                }}
                                style={{
                                  border: isSelected
                                    ? "3px solid #d81d4a"
                                    : "1px solid #da1b50",
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  width: "270px",
                                }}
                              >
                                <div className="hotel-filter">
                                  <img
                                    src={`https://hayyyak.com/StayCategory/${item?.Icon}`}
                                    alt=""
                                  />
                                  <span>{item?.Category}</span>
                                  <AiOutlineRight />
                                </div>
                              </Button>
                            );
                          })}
                        </div>
                      </div>
                      <br />
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              borderBottom: "1px dotted black",
                              paddingBottom: "5px",
                            }}
                          >
                            <h4> Price (per night)</h4>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mb-5 ps-3 pe-3 pt-2 ">
                          <Box sx={{ width: 300 }}>
                            {/* <Slider
                                    size="small"
                                    defaultValue={70}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                /> */}
                            <Slider
                              min={0}
                              max={100000}
                              step={1}
                              defaultValue={0}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              onChange={(e) =>
                                handleSliderChange(e.target.value)
                              }
                            />
                          </Box>
                          <div
                            className="d-flex justify-content-between me-4 "
                            style={{ marginTop: "-10px" }}
                          >
                            <span>₹ 100</span>
                            <span>₹ 100000</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-8 hotel_content"
                    style={{ paddingRight: "unset", paddingLeft: "unset" }}
                  >
                    <div>
                      <section>
                        <ul
                          class=" container breadcrumbs bc3 pt-3 "
                          role="menubar"
                          aria-label="breadcrumbs"
                        >
                          <li>
                            <a href="/">Home</a> <span>&raquo;</span>
                          </li>
                          <li>
                            {" "}
                            <a href="/hotel">Hotel</a>
                          </li>
                        </ul>
                      </section>
                      <section>
                        <div
                          className="container"
                          style={{
                            paddingTop: "0.8rem",
                            paddingBottom: "0.5rem",
                          }}
                        >
                          <div className="row justify-content-end">
                            <div className="col-md-4">
                              <InputGroup className="mb-3">
                                <Form.Control
                                  onChange={(e) =>
                                    setSearchItem(e.target.value)
                                  }
                                  placeholder="Search..."
                                  aria-describedby="basic-addon1"
                                  className="vi_0"
                                />
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                      </section>

                      {paginationStays?.map((item) => {
                        return (
                          <>
                            <div className="row p-2 justify-content-evenly align-items-center">
                              <div className=" col-lg-5">
                                <img
                                  src={`https://hayyyak.com/VendorStay/${item?.propartfullimage}`}
                                  alt=""
                                  className="urus_0"
                                />
                              </div>
                              <div className="hotel-name col-lg-7">
                                <Link
                                  to="/hoteldetails"
                                  state={{
                                    item: item,
                                    stayslocation: stayslocation,
                                  }}
                                >
                                  <span className="hotel-head">
                                    {item?.propartyname}
                                  </span>
                                </Link>
                                <div className="hotel-star-address">
                                  <div>
                                    <p
                                      style={{
                                        textAlign: "justify",
                                      }}
                                    >
                                      {parse(
                                        `<div>${item?.description?.slice(
                                          0,
                                          150
                                        )}....</div>`
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex gap-3 align-items-center mb-2">
                                  <div>
                                    <p>Starting Price :</p>
                                  </div>
                                  <div>
                                    <p className="text-secondary">
                                      <span className="text-danger fs-4 fw-bold">
                                        ₹{item?.startingprice}
                                      </span>{" "}
                                      /night{" "}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      display: "flex",
                                      padding: "0px",
                                    }}
                                  >
                                    {item?.Amenities?.slice(0, 8)?.map(
                                      (ele) => {
                                        return (
                                          <li>
                                            <img
                                              src={`https://hayyyak.com/StayAmenities/${ele?.Icon}`}
                                              alt=""
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                marginLeft: "10px",
                                              }}
                                            />
                                          </li>
                                        );
                                      }
                                    )}
                                    ...
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              onChange={(event, value) => {
                changePage(value - 1);
              }}
              color="primary"
            />
          </Stack>
        </div>
      </div>
    </>
  );
};
