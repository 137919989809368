import React, { useEffect } from "react";
import { Accordion, Container, Image, Table } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IoIosArrowDown } from "react-icons/io";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import parse from "html-react-parser"
import { ToastContainer, toast } from "react-toastify";
import { add } from 'date-fns';
import moment from "moment";

export const TripPackageDetails = () => {
  const notify1 = (data) => toast.warn(data);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const location = useLocation();
  const { item } = location.state;
  console.log("itemmmmm", item);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [Adult, setAdult] = useState(1);
  const adultinc = () => {
    setAdult(Adult + 1);
  };
  const adultdec = () => {
    if (Adult > 1) {
      setAdult(Adult - 1);
    }
  };

  const [Children, setChildren] = useState(0);
  const childreninc = () => {
    setChildren(Children + 1);
  };
  const childrendec = () => {
    if (Children > 0) {
      setChildren(Children - 1);
    }
  };

  const [Pets, setPets] = useState(0);
  const Petsinc = () => {
    setPets(Pets + 1);
  };
  const Petsdec = () => {
    if (Pets > 0) {
      setPets(Pets - 1);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Book Tour Package
  const [Checkindate, setCheckindate] = useState(new Date());
  const [pickupdate, setpickupdate] = useState(moment().format("DD/MM/YYYY"));
  const [CheckOutDate, setCheckOutDate] = useState();
  // const PackageBooking = async () => {
  //   try {
  //     const config = {
  //       url: "admin/booktourpackage",
  //       method: "post",
  //       baseURL: "https://hayyyak.com/api/",
  //       headers: { "Content-Type": "application/json" },
  //       data: {
  //         userId: Customer?._id,
  //         packageId: item?._id,
  //         checkindate: pickupdate,
  //         adultprice: item?.offerprice * Adult,
  //         adultquantity: Adult,
  //         childrenprice: item?.childrenoffer * Children,
  //         childrenquantity: Children,
  //         petsprice: item?.petoffer * Pets,
  //         petsquantity: Pets,
  //       },
  //     };
  //     await axios(config).then((res) => {
  //       if (res.status === 200) {
  //         notify1(res.data.success);
  //         window.location.assign("/");
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     notify1(error.response.data.error);
  //   }
  // };

  // popular packages
  const [getopularpackages, setgetPopularpackages] = useState([]);
  const getPopularpackages = () => {
    axios
      .get("https://hayyyak.com/api/admin/getpopularpackages")
      .then(function (response) {
        setgetPopularpackages(response.data.getallpopulatpackages);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getPopularpackages();
  }, []);
  console.log("getopularpackages", getopularpackages);

  const afsd = [5, 6, 78, 8]
  const asa = afsd?.sort((a, b) => b - a)
  const xyz = getopularpackages?.sort((a, b) => {
    console.log("b.booktourpackage.length", b.booktourpackage.length, a.booktourpackage.length);
    return (b.booktourpackage.length - a.booktourpackage.length)
  })

  // Gellery images
  const images =
    item?.GalleryImage?.map((ele) => ({
      original: `https://hayyyak.com/Packages/${ele?.image}`,
      thumbnail: `https://hayyyak.com/Packages/${ele?.image}`,
    })) || [];

  const navigate = useNavigate()
  const GoToReviewPage = () => {
    if (!Checkindate) {
      return notify1("please select Check In Date");
    }
    if (!Adult) {
      return notify1("please please Select Atleast 1 Adult");
    }
    const dataPass = {
      item: item,
      Checkindates: pickupdate,
      CheckOutDates: moment(CheckOutDate).format("DD/MM/YYYY"),
      Adults: Adult,
      Childrens: Children,
      Petss: Pets,
    }
    navigate("/trippackage_review", { state: dataPass })
  }


  // Check in & check out days calculation
  useEffect(() => {
    const tripDuration = parseInt(item?.tripduration);
    if (!isNaN(tripDuration) && Checkindate) {
      const calculatedCheckOutDate = add(new Date(Checkindate), { days: (tripDuration - 1) });
      setCheckOutDate(calculatedCheckOutDate);
    }
  }, [Checkindate, item?.tripduration]);
  const handleCheckInDateChange = (date) => {
    setCheckindate(date);
    setpickupdate(date?.format("DD/MM/YYYY"))
  };

  return (
    <div>
      <div className="mb-4 trippackages-details-mb">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(././img/trip-package-bg-2.jpg)",
              backgroundPosition: "initial",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              height: "350px",
              width: "100%",
            }}
            className="tripPackages-top"
          >
            <div className="activity-text">
              <div className="bread" id="tripPackages-bread">
                <h2 className="text-uppercase fw-bold">Tour Packages</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Tour Packages</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container>
        <div className="row mb-4">
          <div className="col-lg-8">
            <div className="mb-5 trippackages-details-mb">
              <h3 className="fs-2 fw-semibold mb-3 text-danger trippackages-details-mb">{item?.tripname}</h3>

              <div className="d-flex gap-2 align-items-center mb-3 trippackages-details-mb">
                <img src="./img/umbrella.png" alt="" className="umbrella" />
                <p className="fs-4 ">Description</p>
              </div>
              <p className="text-secondary">{item?.tripdescription}</p>
              <br />
            </div>

            <div className="mb-5 trippackages-details-mb">
              <ImageGallery items={images}  />
            </div>

            <div>
              <div className="d-flex gap-2 align-items-center mb-3">
                <img src="./img/umbrella.png" alt="" className="umbrella" />
                <p className="fs-4 ">Itnerary</p>
              </div>
              <Table bordered>
                <tbody>
                  {item?.itinerary?.map((item, i) => (
                    <tr>
                      <td className="fw-bold text-uppercase" width="10%">{item?.day} <br /> <span style={{ fontSize: '12px' }}>{moment(add(new Date(Checkindate), { days: i })).format("DD/MM/YYYY")}</span></td>
                      <td>{parse(`<div>${item?.todaysplan}</div>`)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="col-lg-4">
            <div
              style={{
                border: "1px solid rgb(221, 221, 221)",
                borderRadius: "12px",
                padding: "24px",
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
                marginBottom: "20px",
              }}
            >
              <div className="out_0">
                <p>
                  Starting From{" "}
                  <s className="text-secondary">INR {item?.tripcost} / per Adult</s>
                </p>
                <h6>
                  ₹{item?.offerprice * Adult} <span>/ per Adult</span>
                </h6>
                <h6>
                  ₹{item?.childrenoffer * Children} <span>/ per Child</span>
                </h6>
                <h6>
                  ₹{item?.petoffer * Pets} <span>/ per Infant</span>
                </h6>
              </div>

              <div>
                <div className="d-flex mb-2 gap-1">
                  <div className="warner">
                    <label>Check In</label> <br />
                    <DatePicker
                      minDate={new Date()}
                      value={moment(Checkindate).format("DD/MM/YYYY")}
                      onChange={(date) => handleCheckInDateChange(date)}
                      placeholder="Add Date"
                      format="DD/MM/YYYY"
                      style={{ border: "none", padding: "3px", width: "145px" }}
                    />
                  </div>
                  <div className="warner">
                    <label>Check Out</label> <br />
                    <DatePicker
                      value={CheckOutDate}
                      format="DD/MM/YYYY"
                      style={{ border: "none", padding: "3px", width: "145px", cursor: 'no-drop' }}
                      disabled
                    />
                  </div>
                </div>

                <div className="warner-out">
                  <p>Guest</p>
                  <div onClick={handleClick}>
                    <span>{Adult} Adult,</span>
                    <span>{Children} Children,</span>
                    <span>{Pets} Infant</span>
                    <span className="float-end">
                      <IoIosArrowDown />
                    </span>
                  </div>
                </div>

                {isOpen && (
                  <>
                    <div className="p-2">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Adults</b>
                          <p style={{ fontSize: "12px" }}>Age 13+</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => adultdec()}
                            className="increase-icon"
                          />
                          <span>{Adult}</span>
                          <span>
                            {" "}
                            <AddIcon
                              onClick={() => adultinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Children</b>
                          <p style={{ fontSize: "12px" }}>Age 2-12</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => childrendec()}
                            className="increase-icon"
                          />
                          <span>{Children}</span>
                          <span>
                            {" "}
                            <AddIcon
                              onClick={() => childreninc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Infants</b>
                          <p style={{ fontSize: "12px" }}>Age Under 2</p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => Petsdec()}
                            className="increase-icon"
                          />
                          <span>{Pets}</span>
                          <span>
                            {" "}
                            <AddIcon
                              onClick={() => Petsinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div>

                      {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <b>Pets</b>
                          <p style={{ fontSize: "12px" }}></p>
                        </div>
                        <div className="d-flex gap-2">
                          <RemoveIcon
                            onClick={() => Petsdec()}
                            className="increase-icon"
                          />
                          <span>{Pets}</span>
                          <span>
                            {" "}
                            <AddIcon
                              onClick={() => Petsinc()}
                              className="increase-icon"
                            />
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}

                <div>
                  <button
                    className="header-search"
                    style={{ width: "100%", height: "44px" }}
                    onClick={GoToReviewPage}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>

            <h4
              style={{
                background: "rgb(216 29 74)",
                color: "#fff",
                padding: "10px",
                borderRadius: "10px 10px 0px 0px",
                marginBottom: "0px",
              }}
            >
              Popular Packages
            </h4>
            <div
              style={{
                border: "1px solid #00000033",
                borderRadius: "0px 0px 10px 10px ",
                padding: "10px",
              }}
            >
              {getopularpackages?.sort((a, b) => b?.booktourpackage?.length - a?.booktourpackage?.length).slice(0, 2)?.map((item) => {
                return (
                  <div className="munna mb-3">
                    <img src={`https://hayyyak.com/Packages/${item?.GalleryImage?.[0]?.image}`} alt="" />
                    <br />
                    <span>{item?.tripname} {item?.tripduration}Days / {item?.tripnight}Nights</span>
                    <p>
                      {item?.tripdescription.slice(0, 100)}
                    </p>
                    <Link to="/trippackagedetails" state={{ item: item }}>
                      <button className="pink-btn fw-lighter">
                        View this Package
                      </button>
                    </Link>

                  </div>
                )
              })}
              {/* <div className="munna mb-3">
                <img src="./img/destination-img-1.jpg" alt="" />
                <br />
                <span>Dubai 7Days / 6Nights</span>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text
                </p>
                <button className="pink-btn fw-lighter">
                  View this Package
                </button>
              </div>

              <div className="munna mb-3">
                <img src="./img/destination-img-3.jpg" alt="" />
                <br />
                <span>India 7Days / 6Nights</span>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text
                </p>
                <button className="pink-btn fw-lighter">
                  View this Package
                </button>
              </div> */}

              <a href="/trippackage" className="text-danger fw-bold text-decoration-none">
                View More
              </a>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-6 mb-2">
            <div >
              <Accordion defaultActiveKey={null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                      Suggestions
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>{parse(`<div>${item?.Suggestion}</div>`)}</div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div >
              <Accordion defaultActiveKey={null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                      Terms and Conditions
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>{parse(`<div>${item?.termsandcondition}</div>`)}</div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-6 mb-2">
            <div >
              <Accordion defaultActiveKey={null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                      Policy
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>{parse(`<div>${item?.policy}</div>`)}</div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div >
              <Accordion defaultActiveKey={null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                      FAQ
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {item?.faq?.map((item) => (
                      <Accordion defaultActiveKey={null} className="mb-2">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <p className="over-taxi" style={{ fontSize: "17px", fontFamily: 'math' }}>
                              {parse(`<div>${item?.question}</div>`)}
                            </p>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>{parse(`<div>${item?.answer}</div>`)}</div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
