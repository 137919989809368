import {
  faCircle,
  faFaceSmile,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { CommunityHeader } from "./CommunityHeader";
import { BiPhoneCall, BiSolidDashboard } from "react-icons/bi";
import { BsCameraVideo } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GrSend } from "react-icons/gr";
import axios from "axios";
import io from "socket.io-client";
import ScrollToBottom from "react-scroll-to-bottom";
import { CiMenuKebab } from "react-icons/ci";
import { IoChatbubbleSharp } from "react-icons/io5";
import { IoChatbubbles } from "react-icons/io5";
import { MdAutoDelete } from "react-icons/md";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import moment from "moment";
import ChatModel from "./MobileRes/ChatModel";
import { Modal } from "react-bootstrap";
import "../../Styles/CommunityRes.css";

const socket = io.connect("https://hayyyak.com"); // Connect to your server

export const Chat = () => {
  // const ROOT_CSS = css({
  //     height: 600,
  //     width: 400
  // });
  const [ChatPopUp, setChatPopUp] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenWidth < 992) {
      handleClose();
      handleClose1();
    }
  }, [screenWidth]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // function screenwidthmainfunction(params) {

  // useEffect(() => {

  //   screenwidthmainfunction();

  // }, [screenWidth]);

  // useEffect(() => {
  //   const handleResize = () => setScreenWidth(window.innerWidth);

  //   window.addEventListener('resize', handleResize);

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const [Data, setData] = useState({});
  const [text, settext] = useState("");
  const [chats, setChats] = useState([]);

  // Get friends recivied req and send req
  const [friends, setfriends] = useState([]);
  const getfriendrequest = () => {
    axios
      .get(
        "https://hayyyak.com/api/customer/getFririndlistByuserId/" +
          Customer._id
      )
      .then(function (response) {
        setfriends(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSend = async () => {
    try {
      const newChat = {
        createdAt: new Date(),
        user: {
          _id: Customer?._id,
          name: Customer?.Fname,
          avthar: Customer?.profileImage,
        },
        receiver: {
          _id:
            Data?.senderId._id === Customer?._id
              ? Data?.receiverId._id
              : Data?.senderId._id,
          name:
            Data?.senderId._id === Customer?._id
              ? Data?.receiverId.Fname
              : Data?.senderId.Fname,
          avthar:
            Data?.senderId._id === Customer?._id
              ? Data?.receiverId.profileImage
              : Data?.senderId.profileImage,
        },
        text,
        type: "text",
      };

      // Emit the chat message
      socket.emit("chat message", newChat);

      // Update local state to include the new message immediately
      // setChats(prevChats => [...prevChats, newChat]);

      // Clear the text input
      settext("");

      socket.emit("receive message", Data.senderId, Data.receiverId);
    } catch (error) {
      console.error("Error sending chat message:", error);
    }
  };

  console.log("DataDataDataData", Data);
  // const addchat = async () => {
  //     try {
  //         const config = {
  //             url: '/addchat',
  //             method: 'post',
  //             baseURL: 'https://hayyyak.com/api/customer',
  //             headers: { "Content-Type": "application/json" },
  //             data: {
  //                 // text: messages[0]?.text,
  //                 createdAt: new Date(),
  //                 user: {
  //                     _id: Customer?._id,
  //                     name: Customer?.Fname,
  //                     avthar: Customer?.profileImage,
  //                 },
  //                 receiver: {
  //                     _id:
  //                         Data?.senderId?._id === Customer._id
  //                             ? Data?.receiverId?._id
  //                             : Data?.senderId?._id,
  //                     name:
  //                         Data?.senderId?._id === Customer._id
  //                             ? Data?.receiverId.Fname
  //                             : Data?.senderId.Fname,
  //                     avthar:
  //                         Data?.senderId?._id === Customer._id
  //                             ? Data?.receiverId?.profileImage
  //                             : Data?.senderId?.profileImage,
  //                 },
  //                 text: text,
  //                 type: "text"
  //             },
  //         };
  //         await axios(config).then(function (res) {
  //             if (res.status === 200) {
  //                 getchat();
  //                 settext('');
  //             }
  //         });
  //     } catch (error) {
  //         console.log(error);
  //         alert('Unable to send');
  //     }
  // };

  // const [GetData, setGetData] = useState([]);

  const getchat = () => {
    try {
      axios
        .get(
          `https://hayyyak.com/api/customer/getchat/` +
            `${Data?.senderId?._id}` +
            `/` +
            `${Data?.receiverId?._id}`
        )
        .then(function (response) {
          setChats(response.data.chat);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewMessages = async (item) => {
    try {
      // Update the status of messages to 'seen' locally
      const updatedChats = chats.map((chat) => {
        if (chat.user._id !== Customer?._id && chat.status === "unseen") {
          return { ...chat, status: "seen" };
        }
        return chat;
      });
      setChats(updatedChats);

      // Make an HTTP PUT request to update the message status
      await axios.put(
        `https://hayyyak.com/api/customer/chatsseen/${item?.receiverId?._id}/${item?.senderId?._id}`,
        {
          status: "seen",
        }
      );

      // Emit an event to inform the server to update the status (if needed)
      socket.emit(
        "viewed messages",
        item?.senderId?._id,
        item?.receiverId?._id
      );
    } catch (error) {
      console.error("Error updating message status:", error);
    }
  };
  useEffect(() => {
    if (Data.senderId && Data.receiverId) {
      getchat();
    }
  }, [Data]);

  useEffect(() => {
    // Listen for incoming chat messages
    socket.on("receive message client", (message) => {
      console.log("Received message:", message);
      // setChats([message])
      setChats((prevchats) => [...prevchats, ...message]);
    });

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error.message);
    });

    return () => {
      // Clean up event listeners when the component unmounts
      socket.off("receive message client");
      socket.off("connect_error");
    };
  }, [socket]);
  useEffect(() => {
    getfriendrequest();
  }, []);

  const [ViewIcon, setViewIcon] = useState(true);
  const [ChatGroup, setChatGroup] = useState(true);
  const [GroundForm, setGroundForm] = useState(true);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = useState([]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    // Map the selected friend objects to the desired structure
    const selectedNames = value.map((frd) => ({
      FName: frd?.Fname,
      LName: frd?.Lname,
      Profile: frd?.profileImage,
      userID: frd?._id,
    }));

    setPersonName(selectedNames);
  };

  console.log("personName", personName);
  const [GroupName, setGroupName] = useState("");
  const [ProfileImg, setProfileImg] = useState("");
  const CreateGroup = async () => {
    try {
      const config = {
        url: "/Addgroup",
        baseURL: "https://hayyyak.com/api/customer",
        method: "post",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Groupname: GroupName,
          Groupmembers: personName,
          Groupadmin: Customer?.Fname,
          Groupadminid: Customer?._id,
          Groupimage: ProfileImg,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.msg);
        getfriendrequest();
        setChatGroup(false);
        setGroundForm(true);
        getgrououp();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const [Group, setGroup] = useState([]);
  const getgrououp = async () => {
    try {
      const response = await axios.get(
        `https://hayyyak.com/api/customer/groupdatabyId/${Customer?._id}`
      );
      setGroup(response.data.getdata);
    } catch (error) {
      console.log(error);
    }
  };

  const [GroupById, setGroupById] = useState([]);
  const getgrououpById = async () => {
    try {
      const response = await axios.get(
        `https://hayyyak.com/api/customer/getaddgroup/${Customer?._id}`
      );
      setGroupById(response.data.getdata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (GroupById.length > 0) {
      // Check if GroupById has data
      setGroup(GroupById);
    }
  }, [GroupById]);

  useEffect(() => {
    getgrououp();
    getgrououpById();
  }, []);

  console.log("Group", Group);
  console.log("Data", Data);

  const [GroupData, setGroupData] = useState({});
  console.log("GroupData", GroupData);

  const groupDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        const config = {
          url: "/Deleteaddgroup/" + id,
          baseURL: "https://hayyyak.com/api/customer",
          method: "delete",
          headers: { "content-type": "application/json" },
        };
        let res = await axios(config);
        if (res.status === 200) {
          notify(res.data.success);
          // getfriendrequest();
          // setChatGroup(false);
          // setGroundForm(true);
          getgrououp();
        }
      } catch (error) {
        notify2(error.response.data.error);
      }
    } else {
      notify1("Sorry,Try again..");
    }
  };

  //Group Message...
  const [Grouptext, setGrouptext] = useState("");
  const currentDate = new Date();
  const groupsendmessage = async (id) => {
    try {
      const config = {
        url: "/Addgroupchat",
        method: "put",
        baseURL: "https://hayyyak.com/api/customer",
        headers: { "content-type": "application/json" },
        data: {
          groupId: id,
          message: Grouptext,
          name: Customer?.Fname,
          profile: Customer?.profileImage,
          createdAt: currentDate,
          type: "text",
          id: Customer?._id,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // notify(res.data.success);
        setGrouptext("");
        setGroupData(res.data.getdata);
        // getgrououp()
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");
  const [SearchItem1, setSearchItem1] = useState("");

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="row mb-5">
          <div className="col-lg-1 ">
            <div className="facebook-left">
              <ul>
                <li>
                  <Link
                    to="/community"
                    className="text-decoration-none text-light"
                  >
                    {" "}
                    <BiSolidDashboard />{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/coummunityprofile"
                    className="text-decoration-none text-light"
                  >
                    <AiOutlineUsergroupAdd />
                  </Link>
                </li>
                <li>
                  <Link to="/chat" className="text-decoration-none text-light">
                    <Stack spacing={2} direction="row">
                      <Badge badgeContent={0} color="secondary">
                        <IoChatbubbleEllipsesOutline className="text-light fs-3" />
                      </Badge>
                    </Stack>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-lg-3"
            style={{
              overflow: "hidden",
              overflowY: "scroll",
              height: "auto",
              maxHeight: "620px",
            }}
          >
            <Form
              className="d-flex text-center justify-content-center"
              style={{
                backgroundColor: "#d81d4a",
                color: "white",
                borderRadius: "6px",
              }}
            >
              <h2>Welcome to Chat</h2>
            </Form>
            <hr />
            <Form className="d-flex justify-content-evenly mt-2 form-chat-button-controll">
              <Button
                className="pink-btn"
                style={{ width: "45%" }}
                onClick={() => {
                  setChatGroup(true);
                }}
              >
                <IoChatbubbleSharp /> Chat
              </Button>
              <Button
                className="pink-btn "
                onClick={() => {
                  setChatGroup(false);
                }}
                style={{ width: "45%" }}
              >
                <IoChatbubbles /> Group
              </Button>
            </Form>

            {ChatGroup === true ? (
              <>
                <Form className="d-flex mt-2">
                  <Form.Control
                    type="search"
                    placeholder="Search friend..."
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => setSearchItem1(e.target.value)}
                  />
                </Form>
                {screenWidth > 992 ? (
                  <ul
                    style={{ listStyle: "none", paddingLeft: "0px" }}
                    className="chat-box"
                  >
                    {friends?.map((item) => {
                      if (
                        SearchItem1 === "" ||
                        Object.values(item).some((value) =>
                          String(value)
                            .toLowerCase()
                            .includes(SearchItem1.toLowerCase())
                        )
                      )
                        return (
                          <>
                            {item?.senderId?._id !== Customer._id ? (
                              <>
                                <li
                                  onClick={() => {
                                    setData(item);
                                    handleViewMessages(item);
                                    // handleShow();
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        margin: "8px",
                                      }}
                                      src={
                                        item?.senderId?.profileImage
                                          ? `https://hayyyak.com/Customer/${item?.senderId?.profileImage}`
                                          : "./img/profile.jpg"
                                      }
                                      alt=""
                                    />
                                    <div>
                                      <span className="chat fw-semibold activity-h2">
                                        {item?.senderId?.Fname}{" "}
                                        {item?.senderId?.Lname}{" "}
                                      </span>{" "}
                                      <br />
                                      {/* <span className="chat-online">
                  <span className="text-danger fw-bold fs-6">
                    2
                  </span>{" "}
                  New massages
                </span> */}
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : (
                              <>
                                <li
                                  onClick={() => {
                                    setData(item);
                                    handleViewMessages(item);
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        margin: "8px",
                                      }}
                                      src={
                                        item?.receiverId?.profileImage
                                          ? `https://hayyyak.com/Customer/${item?.receiverId?.profileImage}`
                                          : "./img/profile.jpg"
                                      }
                                      alt=""
                                    />
                                    <div>
                                      <span className="chat fw-semibold activity-h2">
                                        {item?.receiverId?.Fname}{" "}
                                        {item?.receiverId?.Lname}{" "}
                                      </span>{" "}
                                      <br />
                                      {/* <span className="chat-online">
                  <span className="text-danger fw-bold fs-6">
                    2
                  </span>{" "}
                  New massages
                </span> */}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </>
                        );
                    })}
                  </ul>
                ) : (
                  <ul
                    style={{ listStyle: "none", paddingLeft: "0px" }}
                    className="chat-box"
                  >
                    {friends?.map((item) => {
                      if (
                        SearchItem1 === "" ||
                        Object.values(item).some((value) =>
                          String(value)
                            .toLowerCase()
                            .includes(SearchItem1.toLowerCase())
                        )
                      )
                        return (
                          <>
                            {item?.senderId?._id !== Customer._id ? (
                              <>
                                <li
                                  onClick={() => {
                                    setData(item);
                                    handleViewMessages(item);
                                    handleShow();
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        margin: "8px",
                                      }}
                                      src={
                                        item?.senderId?.profileImage
                                          ? `https://hayyyak.com/Customer/${item?.senderId?.profileImage}`
                                          : "./img/profile.jpg"
                                      }
                                      alt=""
                                    />
                                    <div>
                                      <span className="chat fw-semibold activity-h2">
                                        {item?.senderId?.Fname}{" "}
                                        {item?.senderId?.Lname}{" "}
                                      </span>{" "}
                                      <br />
                                      {/* <span className="chat-online">
                                <span className="text-danger fw-bold fs-6">
                                  2
                                </span>{" "}
                                New massages
                              </span> */}
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : (
                              <>
                                <li
                                  onClick={() => {
                                    setData(item);
                                    handleViewMessages(item);
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        margin: "8px",
                                      }}
                                      src={
                                        item?.receiverId?.profileImage
                                          ? `https://hayyyak.com/Customer/${item?.receiverId?.profileImage}`
                                          : "./img/profile.jpg"
                                      }
                                      alt=""
                                    />
                                    <div>
                                      <span className="chat fw-semibold activity-h2">
                                        {item?.receiverId?.Fname}{" "}
                                        {item?.receiverId?.Lname}{" "}
                                      </span>{" "}
                                      <br />
                                      {/* <span className="chat-online">
                                <span className="text-danger fw-bold fs-6">
                                  2
                                </span>{" "}
                                New massages
                              </span> */}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </>
                        );
                    })}
                  </ul>
                )}
              </>
            ) : (
              <>
                <Form className="d-flex mt-2">
                  <Form.Control
                    type="search"
                    placeholder="Search Group Name..!"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                  <CiMenuKebab
                    onClick={() => setViewIcon((prevState) => !prevState)}
                    style={{
                      fontSize: "37px",
                      cursor: "pointer",
                    }}
                  />
                </Form>
                {!ViewIcon && (
                  <div style={{ position: "absolute", left: "281px" }}>
                    <Button
                      onClick={() => {
                        setViewIcon(true);
                        setGroundForm((prevState) => !prevState);
                      }}
                      variant="secondary"
                    >
                      {" "}
                      Create Group +
                    </Button>
                  </div>
                )}
                {GroundForm === false && (
                  <div
                    className="mt-3"
                    style={{
                      padding: "5px",
                      border: "1px solid #d1c8be",
                      borderRadius: "5px",
                    }}
                  >
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <div className="d-flex justify-content-between">
                          <Form.Label>Group Name</Form.Label>
                          <Button
                            onClick={() => setGroundForm(true)}
                            variant="danger"
                          >
                            X
                          </Button>
                        </div>

                        <Form.Control
                          onChange={(e) => setGroupName(e.target.value)}
                          className="vi_0"
                          type="text"
                          placeholder="Write group name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Profile Img</Form.Label>
                        <Form.Control
                          onChange={(e) => setProfileImg(e.target.files[0])}
                          className="vi_0"
                          type="file"
                        />
                      </Form.Group>

                      <label className="fw-bold">Friends :</label>
                      <FormControl sx={{ m: 1, width: 275, height: 43 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Friends
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleSelectChange}
                          input={<OutlinedInput label="Friends" />}
                          renderValue={(selected) =>
                            selected.map((frd) => frd?.FName).join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {friends?.map((frd) => (
                            <MenuItem key={frd._id} value={frd.receiverId}>
                              <Checkbox
                                checked={personName.some(
                                  (selected) =>
                                    selected?._id !== frd?.receiverId?._id
                                )}
                              />
                              <ListItemText primary={frd?.receiverId?.Fname} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div className="d-flex justify-content-center mt-3">
                        <Button onClick={() => CreateGroup()}>Create</Button>
                      </div>
                    </Form>
                  </div>
                )}
                {screenWidth > 992 ? (
                  <ul
                    style={{ listStyle: "none", paddingLeft: "0px" }}
                    className="chat-box"
                  >
                    {Group?.map((item) => {
                      if (
                        SearchItem === "" ||
                        Object.values(item).some((value) =>
                          String(value)
                            .toLowerCase()
                            .includes(SearchItem.toLowerCase())
                        )
                      )
                        return (
                          <>
                            <li
                              onClick={() => {
                                setGroupData(item);
                                // handleShow1()
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    margin: "8px",
                                  }}
                                  src={
                                    item?.Profile
                                      ? `https://hayyyak.com/Customer/${item?.Profile}`
                                      : "./img/profile.jpg"
                                  }
                                  alt=""
                                />
                                <div>
                                  <span className="chat fw-semibold activity-h2">
                                    {item?.Groupname}
                                  </span>{" "}
                                  <br />
                                  {/* <span className="chat-online">
                              <span className="text-danger fw-bold fs-6">
                                2
                              </span>{" "}
                              New massages
                            </span> */}
                                </div>
                              </div>
                            </li>
                          </>
                        );
                    })}
                  </ul>
                ) : (
                  <ul
                    style={{ listStyle: "none", paddingLeft: "0px" }}
                    className="chat-box"
                  >
                    {Group?.map((item) => {
                      if (
                        SearchItem === "" ||
                        Object.values(item).some((value) =>
                          String(value)
                            .toLowerCase()
                            .includes(SearchItem.toLowerCase())
                        )
                      )
                        return (
                          <>
                            <li
                              onClick={() => {
                                setGroupData(item);
                                handleShow1();
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    margin: "8px",
                                  }}
                                  src={
                                    item?.Profile
                                      ? `https://hayyyak.com/Customer/${item?.Profile}`
                                      : "./img/profile.jpg"
                                  }
                                  alt=""
                                />
                                <div>
                                  <span className="chat fw-semibold activity-h2">
                                    {item?.Groupname}
                                  </span>{" "}
                                  <br />
                                  {/* <span className="chat-online">
                                <span className="text-danger fw-bold fs-6">
                                  2
                                </span>{" "}
                                New massages
                              </span> */}
                                </div>
                              </div>
                            </li>
                          </>
                        );
                    })}
                  </ul>
                )}
              </>
            )}
          </div>

          {ChatGroup === true ? (
            <>
              {Object.keys(Data).length === 0 ? (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center m-auto flex-column"
                    style={{ width: "fit-content" }}
                  >
                    <img
                      src="./img/Logo-3-video.gif"
                      alt=""
                      className="animate-logo"
                    />
                    <p
                      className="activity-h2"
                      style={{
                        width: "fit-content",
                        textAlign: "center",
                        fontSize: "35px",
                      }}
                    >
                      Comunicate with Your <br /> Friends and Family
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {/* <-------- Individule Chat -------->  */}

                  <div className="col-lg-8 mobile-res-community-header">
                    {Data?.senderId?._id !== Customer._id ? (
                      <>
                        <div className="d-flex align-items-center">
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              margin: "8px",
                            }}
                            src={
                              Data?.senderId?.profileImage
                                ? `https://hayyyak.com/Customer/${Data?.senderId?.profileImage}`
                                : "./img/profile.jpg"
                            }
                            alt=""
                          />
                          <div>
                            <span className="chat fw-bold activity-h2">
                              {Data?.senderId?.Fname} {Data?.senderId?.Lname}
                            </span>{" "}
                            <br />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center">
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              margin: "8px",
                            }}
                            src={
                              Data?.receiverId?.profileImage
                                ? `https://hayyyak.com/Customer/${Data?.receiverId?.profileImage}`
                                : "./img/profile.jpg"
                            }
                            alt=""
                          />
                          <div>
                            <span className="chat fw-bold activity-h2">
                              {Data?.receiverId?.Fname}{" "}
                              {Data?.receiverId?.Lname}
                            </span>{" "}
                            <br />
                          </div>
                        </div>
                      </>
                    )}
                    <hr />
                    <div
                      className="scroll-cat"
                      style={{
                        overflow: "hidden",
                        overflowY: "scroll",
                        height: "400px",
                      }}
                    >
                      <div className="row justify-content-between">
                        <ScrollToBottom>
                          {[
                            ...new Map(
                              chats.map((item) => [item["text"], item])
                            ).values(),
                          ].map((item) => (
                            <>
                              {item?.user?._id === Customer?._id ? (
                                <>
                                  <div className="col-7 mb-3 ms-auto">
                                    <p
                                      style={{
                                        marginLeft: "400px",
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.user?.name}
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "rgb(255 195 209)",
                                        fontSize: "14px",
                                        padding: "26px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {item?.text}
                                    </p>
                                    <p
                                      className="text-end"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {item?.status}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {item?.user?._id !== Customer?._id ? (
                                <>
                                  <div className="col-7 mb-3">
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.user?.name}
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        fontSize: "14px",
                                        padding: "26px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {item?.text}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </ScrollToBottom>
                      </div>

                      {/* <div className="col-7">
                                <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>Robert</p>
                                <p style={{ backgroundColor: '#e8e8e8', fontSize: '14px', padding: '26px', borderRadius: '10px' }} >How are you feel today? What's about vacation?.</p>
                            </div>

                            <div className='row justify-content-end'>
                                <div className="col-7  mb-4">
                                    <p style={{ marginLeft: '400px', marginBottom: '0px', fontWeight: 'bold' }}>Maria</p>
                                    <p style={{ backgroundColor: 'rgb(255 195 209)', fontSize: '14px', padding: '26px', borderRadius: '10px' }} >I am good, We think for next weekend.</p>
                                </div>
                            </div> */}
                    </div>
                    <div style={{ position: "relative" }} className="mb-3">
                      <input
                        value={text}
                        onChange={(e) => settext(e.target.value)}
                        type="text"
                        placeholder="Enter text here"
                        style={{
                          width: "100%",
                          height: "60px",
                          padding: "10px",
                        }}
                      />
                      <span
                        onClick={() => {
                          handleSend();
                        }}
                        style={{
                          position: "absolute",
                          right: "21px",
                          bottom: "10px",
                          fontSize: "27px",
                          cursor: "pointer",
                        }}
                      >
                        <GrSend />
                      </span>
                    </div>
                    {/* <button className='fw-bold fs-4  mt-3 me-4 ' style={{ float: 'right', border: 'none', borderRadius: '50%', backgroundColor: 'orange', color: 'white', padding: '8px' }}> <FontAwesomeIcon icon={faPaperclip} /></button>
                        <button className='fw-bold fs-4  mt-3 me-2' style={{ float: 'right', border: 'none', borderRadius: '50%', backgroundColor: 'orange', color: 'white', padding: '8px' }}> <FontAwesomeIcon icon={faFaceSmile} /></button> */}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {Object.keys(GroupData).length === 0 ? (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center m-auto flex-column"
                    style={{ width: "fit-content" }}
                  >
                    <img
                      src="./img/Logo-3-video.gif"
                      alt=""
                      className="animate-logo"
                    />
                    <p
                      className="activity-h2"
                      style={{
                        width: "fit-content",
                        textAlign: "center",
                        fontSize: "35px",
                      }}
                    >
                      Comunicate with Your <br /> Friends and Family
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {/* <--------- Group Chat-------> */}

                  <div className="col-lg-8 mobile-res-community-header">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            margin: "8px",
                          }}
                          src={
                            GroupData?.Groupimage
                              ? `https://hayyyak.com/Customer/${GroupData?.Groupimage}`
                              : "./img/profile.jpg"
                          }
                          alt=""
                        />
                        <div>
                          <span
                            style={{ fontSize: "22px" }}
                            className="fw-bold activity-h2"
                          >
                            {GroupData?.Groupname}
                          </span>{" "}
                          <br />
                          {GroupData?.Groupmembers?.map((item) => {
                            return (
                              <span className="chat fw-bold ">
                                {item?.FName}
                                {item?.LName} ,
                              </span>
                            );
                          })}
                          <br />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "27px",
                          color: "red",
                          margin: "19px",
                          cursor: "pointer",
                        }}
                      >
                        <MdAutoDelete
                          onClick={() => groupDelete(GroupData?._id)}
                        />
                      </div>
                    </div>

                    <hr />
                    <div
                      className="scroll-cat"
                      style={{
                        overflow: "hidden",
                        overflowY: "scroll",
                        height: "400px",
                      }}
                    >
                      <div className="row justify-content-between">
                        {GroupData?.chat?.map((item) => {
                          return (
                            <>
                              {item?.id !== Customer?._id ? (
                                <>
                                  <div className="col-7 mb-3">
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        fontSize: "14px",
                                        padding: "26px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {item?.message}
                                    </p>
                                    <p
                                      className="text-start"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {moment(item.createdAt).format(
                                        "Do MMMM YY, hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {item?.id === Customer?._id ? (
                                <>
                                  <div className="col-7 mb-3 ms-auto">
                                    <p
                                      style={{
                                        marginLeft: "400px",
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "rgb(255 195 209)",
                                        fontSize: "14px",
                                        padding: "26px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {item?.message}
                                    </p>
                                    <p
                                      className="text-end"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {moment(item.createdAt).format(
                                        "Do MMMM YY, hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </div>

                      {/* <div className="col-7">
                                <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>Robert</p>
                                <p style={{ backgroundColor: '#e8e8e8', fontSize: '14px', padding: '26px', borderRadius: '10px' }} >How are you feel today? What's about vacation?.</p>
                            </div>

                            <div className='row justify-content-end'>
                                <div className="col-7  mb-4">
                                    <p style={{ marginLeft: '400px', marginBottom: '0px', fontWeight: 'bold' }}>Maria</p>
                                    <p style={{ backgroundColor: 'rgb(255 195 209)', fontSize: '14px', padding: '26px', borderRadius: '10px' }} >I am good, We think for next weekend.</p>
                                </div>
                            </div> */}
                    </div>
                    <div style={{ position: "relative" }} className="mb-3">
                      <input
                        value={Grouptext}
                        onChange={(e) => setGrouptext(e.target.value)}
                        type="text"
                        placeholder="Enter text here"
                        style={{
                          width: "100%",
                          height: "60px",
                          padding: "10px",
                        }}
                      />
                      {Grouptext ? (
                        <span
                          onClick={() => {
                            groupsendmessage(GroupData?._id);
                          }}
                          style={{
                            position: "absolute",
                            right: "21px",
                            bottom: "10px",
                            fontSize: "27px",
                            cursor: "pointer",
                          }}
                        >
                          <GrSend />
                        </span>
                      ) : (
                        <span
                          // onClick={() => {
                          //   groupsendmessage(GroupData?._id);
                          // }}
                          style={{
                            position: "absolute",
                            right: "21px",
                            bottom: "10px",
                            fontSize: "27px",
                            cursor: "pointer",
                          }}
                        >
                          <GrSend />
                        </span>
                      )}
                    </div>
                    {/* <button className='fw-bold fs-4  mt-3 me-4 ' style={{ float: 'right', border: 'none', borderRadius: '50%', backgroundColor: 'orange', color: 'white', padding: '8px' }}> <FontAwesomeIcon icon={faPaperclip} /></button>
                        <button className='fw-bold fs-4  mt-3 me-2' style={{ float: 'right', border: 'none', borderRadius: '50%', backgroundColor: 'orange', color: 'white', padding: '8px' }}> <FontAwesomeIcon icon={faFaceSmile} /></button> */}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Data?.senderId?._id !== Customer._id ? (
                <>
                  <div className="d-flex align-items-center">
                    <span className="border-profile-chat">
                      <img
                        height={50}
                        width={50}
                        alt=""
                        src={
                          Data?.senderId?.profileImage
                            ? `https://hayyyak.com/Customer/${Data?.senderId?.profileImage}`
                            : "./img/profile.jpg"
                        }
                      />
                    </span>

                    <div>
                      <span className="chat fw-semibold activity-h2">
                        {Data?.senderId?.Fname} {Data?.senderId?.Lname}
                      </span>{" "}
                      <br />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    <span className="border-profile-chat">
                      <img
                        height={50}
                        width={50}
                        alt=""
                        src={
                          Data?.receiverId?.profileImage
                            ? `https://hayyyak.com/Customer/${Data?.receiverId?.profileImage}`
                            : "./img/profile.jpg"
                        }
                      />
                    </span>

                    <div>
                      <span className="chat fw-semibold activity-h2">
                        {Data?.receiverId?.Fname} {Data?.receiverId?.Lname}
                      </span>{" "}
                      <br />
                    </div>
                  </div>
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="overflow-auto" style={{ height: "250px" }}>
              {/* <div className="row-md-6 text-start p-2">hai</div>
              <div className="row-md-6  p-2 text-end">hello</div> */}
              {[
                ...new Map(chats.map((item) => [item["text"], item])).values(),
              ].map((item) => (
                <>
                  {item?.user?._id === Customer?._id ? (
                    <>
                      <div className="col-7 mb-3 ms-auto">
                        <p
                          style={{
                            // marginLeft: "400px",
                            marginBottom: "0px",
                            fontWeight: "bold",
                            textAlign:"end", 
                            marginRight:"10px"
                          }}
                        >
                          {/* {item?.user?.name} */}
                        </p>
                        <p
                          style={{
                            backgroundColor: "rgb(255 195 209)",
                            fontSize: "13px",
                            padding: "10px",
                            borderRadius: "10px",
                            marginRight:"10px"
                          }}
                        >
                          
                          {item?.text}
                        </p>
                        <p className="text-end" style={{ fontSize: "12px", marginRight:"10px" }}>
                          {item?.status}
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {item?.user?._id !== Customer?._id ? (
                    <>
                      <div className="col-7 mb-3">
                        <p
                          style={{
                            marginBottom: "0px",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.user?.name}
                        </p>
                        <p
                          style={{
                            backgroundColor: "#e8e8e8",
                            fontSize: "13px",
                            padding: "10px",
                            borderRadius: "10px",
                             marginRight:"10px"
                          }}
                        >
                          {item?.text}
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Understood</Button> */}
            <div
              style={{ position: "relative", width: "100% " }}
              className="row-md-12"
            >
              <input
                value={text}
                onChange={(e) => settext(e.target.value)}
                type="text"
                placeholder="Enter text here"
                style={{ width: "100%", height: "60px", padding: "10px" }}
              />
              <span
                onClick={() => {
                  handleSend();
                }}
                style={{
                  position: "absolute",
                  right: "21px",
                  bottom: "10px",
                  fontSize: "27px",
                  cursor: "pointer",
                }}
              >
                <GrSend />
              </span>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="" style={{overflowX:"scroll"}}>
                <span className="border-profile-chat">
                  <img
                    className="border-profile-chat-img"
                    src={
                      GroupData?.Groupimage
                        ? `https://hayyyak.com/Customer/${GroupData?.Groupimage}`
                        : "./img/profile.jpg"
                    }
                    height={50}
                    width={50}
                    alt=""
                  />
                </span>
                <span className="chat fw-semibold activity-h2">
                  {" "}
                  {GroupData?.Groupname}
                </span>
                <br />
                {GroupData?.Groupmembers?.map((item) => {
                  return (
                    <span className="chat fw-bold ">
                      {item?.FName}
                      {item?.LName} ,
                    </span>
                  );
                })}
                 <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "27px",
                          color: "red",
                          margin: "19px",
                          cursor: "pointer",
                        }}
                        className=" chat-delete-icon"
                      >
                        <MdAutoDelete
                          onClick={() => groupDelete(GroupData?._id)}
                        />
                      </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="overflow-auto" style={{ height: "250px" }}>
              {/* <div className="row-md-6 text-start p-2">hai</div>
              <div className="row-md-6  p-2 text-end">hello</div> */}
            
            {GroupData?.chat?.map((item) => {
                          return (
                            <>
                              {item?.id !== Customer?._id ? (
                                <>
                                  <div className="col-7 mb-3">
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {/* {item?.name} */}
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        fontSize: "14px",
                                        padding: "10px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {item?.message}
                                    </p>
                                    <p
                                      className="text-start"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {moment(item.createdAt).format(
                                        "Do MMMM YY, hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {item?.id === Customer?._id ? (
                                <>
                                  <div className="col-7 mb-3 ms-auto">
                                    <p
                                      style={{
                                        // marginLeft: "400px",
                                        marginBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "rgb(255 195 209)",
                                        fontSize: "13px",
                                        padding: "10px",
                                        borderRadius: "10px",
                                         marginRight:"10px"
                                      }}
                                    >
                                      {item?.message}
                                    </p>
                                    <p
                                      className="text-end"
                                      style={{ fontSize: "12px", marginRight:"10px" }}
                                    >
                                      {moment(item.createdAt).format(
                                        "Do MMMM YY, hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary">Understood</Button> */}

            <div style={{ position: "relative", width: "100%" }} className="">
              <input
                value={Grouptext}
                onChange={(e) => setGrouptext(e.target.value)}
                type="text"
                placeholder="Enter text here"
                style={{ width: "100%", height: "60px", padding: "10px" }}
              />
              {Grouptext ? (
                <span
                  onClick={() => {
                    groupsendmessage(GroupData?._id);
                  }}
                  style={{
                    position: "absolute",
                    right: "21px",
                    bottom: "10px",
                    fontSize: "27px",
                    cursor: "pointer",
                  }}
                >
                  <GrSend />
                </span>
              ) : (
                <span
                  // onClick={() => {
                  //   groupsendmessage(GroupData?._id);
                  // }}
                  style={{
                    position: "absolute",
                    right: "21px",
                    bottom: "10px",
                    fontSize: "27px",
                    cursor: "pointer",
                  }}
                >
                  <GrSend />
                </span>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
