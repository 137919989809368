import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { BiLogOutCircle, BiUserCircle } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'

function VendorHotelHeader() {
    const StayVendor = JSON?.parse(sessionStorage.getItem("Stays"))

    const staysVendorLogout = async () => {
        try {
            sessionStorage.removeItem('Stays');
            window.location.assign("/Vlogin")
        } catch (error) {

        }
    }

    // Event listener for popstate
    // window.addEventListener('Stays', (event) => {
    //     if (event.back && event.back.page === 'Vlogin') {
    //         sessionStorage.removeItem('Stays');
    //     }
    //   });

    return (
        <>
            <div
                className="header"
                style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
                <Navbar expand="lg" style={{ marginLeft: "4rem" }}>
                    <Container fluid>
                        <Navbar.Brand href="/vendorstaysprofile" className='d-flex gap-1 align-items-center'>
                            {/* <BiUserCircle style={{ fontSize: "35px", color: '#ebc44c' }} /> */}
                            {StayVendor?.VendorImage ? (<>
                                <img src={`https://hayyyak.com/VendorStay/${StayVendor?.VendorImage}`} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                            </>) : (<>
                                <img src="./img/user.png" alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                            </>)}

                            <div
                                style={{
                                    fontSize: "17px",
                                    fontWeight: "600",
                                    color: "white",
                                }}
                            >
                                {StayVendor?.Fname} {StayVendor?.Lname}
                            </div>

                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="ms-auto my-2 my-lg-0 "
                                // style={{ maxHeight: "100px" }}
                                navbarScroll
                            >
                                {/* <div className='d-flex justify-content-end align-items-center me-2 '>
                                    <div className="input-group search-width" style={{ width: '250px' }}>
                                        <span className="input-group-text" id="basic-addon1"><BsSearch /></span>
                                        <input type="text" className="form-control " placeholder="Search" aria-describedby="basic-addon1" />
                                    </div>
                                </div> */}

                                <Nav.Link
                                    onClick={() => staysVendorLogout()}
                                    style={{ color: '#fff', border: '2px solid #fff', borderRadius: '10px' }}>
                                    <span>LOGOUT</span>
                                    <BiLogOutCircle
                                        style={{ fontSize: "25px", color: "#ebc44c" }}
                                    />
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default VendorHotelHeader