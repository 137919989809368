import React, { useEffect, useState } from "react";
import { Accordion, FloatingLabel, Form, Modal, Table } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import { BiTimeFive } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import DatePicker from "react-multi-date-picker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CiLocationOn } from "react-icons/ci";
import { HotelRooms } from "./HotelRooms";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { FaMapMarkedAlt } from "react-icons/fa";
import parse from "html-react-parser"
import { SlDirections } from "react-icons/sl";
import { Rating } from "react-simple-star-rating";

export const HotelDetails = () => {
    const [show1, setShow1] = useState(false);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);

    const [show2, setShow2] = useState(false);
    const handleShow2 = () => setShow2(true);
    const handleClose2 = () => setShow2(false);


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [Adult, setAdult] = useState(0);
    const increase = () => {
        setAdult(Adult + 1);
    };
    const decrease = () => {
        if (Adult > 0) {
            setAdult(Adult - 1);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    // data passing through item
    const location = useLocation()
    const { item, stayslocation } = location.state;
    console.log("itemmmm", item);
    console.log("stayslocation", stayslocation);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} swipeable={true} >
                {item?.gallery?.map((item) => {
                    return (
                        <div class="item">
                            <div class="box">
                                <img
                                    src={`https://hayyyak.com/VendorStay/${item?.image}`}
                                    alt="Jungle Lodges and Resorts"
                                    style={{ width: "100%", height: "300px" }}
                                />
                            </div>
                        </div>
                    )
                })}
            </Carousel>

            <div className="inner-content-listing-desc hotelDetails-content" style={{ padding: "30px" }}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-8">
                            <h1 className="hotel-head">{item?.propartyname}</h1>
                            <div className="listing-wro row align-items-start">
                                <span class="posted_by fs-5 col-lg-5">
                                    Starts From <span className="text-danger fs-4 fw-bold">₹{item?.startingprice}</span>/night
                                </span>
                                <div className="col-lg-7">
                                    <span style={{ fontSize: '12px' }}><CiLocationOn className="text-danger fs-6" /> {item?.propartyaddress}</span>
                                    <br />
                                    <span style={{ fontSize: '12px' }}><FaMapMarkedAlt className="text-danger fs-6" /> Map: <a href={item?.location} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-dark">Direction <SlDirections /></a></span>
                                </div>
                            </div>
                            <div className="mb-4 HotelDetails-mb">
                                <p className="d-flex align-items-center gap-2">
                                    <span className="fs-6 fw-bold">Check In : </span>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span>
                                            <BiTimeFive className="text-danger" />
                                        </span>
                                        <span>
                                            {item?.checkin}
                                        </span>
                                    </div>
                                </p>
                                <p className="d-flex align-items-center gap-2">
                                    <span className="fs-6 fw-bold">Check Out : </span>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span>
                                            <BiTimeFive className="text-danger" />
                                        </span>
                                        <span>
                                            {item?.checkout}
                                        </span>
                                    </div>
                                </p>
                            </div>



                            <h4 className=" ">OVERVIEW</h4>
                            <p className="mb-3 HotelDetails-mb" style={{ textAlign: "justify", }}>
                                <span style={{ color: "grey", }}>
                                    {" "}
                                    {parse(`<div>${item?.description}</div>`)}
                                </span>
                                &nbsp;
                            </p>
                        </div>

                        <div className="col-lg-4">
                            <h5 className=" ">AMENITIES</h5>
                            <div className="row">
                                {item?.Amenities?.slice(0, 5)?.map((item) => {
                                    return (
                                        <>
                                            <div className="col-lg-6 ">
                                                <div className="d-flex gap-3 align-items-center mb-3">
                                                    <img src={`https://hayyyak.com/StayAmenities/${item?.Icon}`} alt="" width="20" height="20" />
                                                    <span className=" fs-6">
                                                        {item?.AmenitiName}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <p onClick={handleShow1} className="fw-bold text-danger mb-4 HotelDetails-mb bhsagd_1" >See All Amenities</p>
                        </div>

                        <div>
                            <div>
                                <h4>Rooms</h4>
                            </div>
                            <HotelRooms />
                        </div>

                        <div className="mb-3 HotelDetails-mb">
                            <Accordion defaultActiveKey={null} style={{ width: '800px' }} className="HotelDetails-Accordion">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {" "}
                                        <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                                            Suggestions
                                        </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div >
                                            {parse(`<div>${item?.suggesition}</div>`)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        <div className="mb-3 HotelDetails-mb">
                            <Accordion defaultActiveKey={null} style={{ width: '800px' }} className="HotelDetails-Accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        {" "}
                                        <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                                            Terms And Conditions
                                        </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {parse(`<div>${item?.termsandcondition}</div>`)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        <div className="mb-4 HotelDetails-mb">
                            <Accordion defaultActiveKey={null} style={{ width: '800px' }} className="HotelDetails-Accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        {" "}
                                        <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                                            Near By Location
                                        </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {parse(`<div>${item?.Nearbyplaces}</div>`)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        {/* {item?.Vendor?.length === 0 ? (null) : (
                            <div className="mb-5 HotelDetails-mb">
                                <h4 className="mb-3 ">Hosted by <span className="text-danger">{item?.Vendor?.[0]?.Fname} {item?.Vendor?.[0]?.Lname}</span></h4>
                                {item?.Vendor?.[0]?.VendorImage ? (<>
                                    <div className="row gap-2">
                                        <img className="col-lg-4" src={`https://hayyyak.com/VendorStay/${item?.Vendor?.[0]?.VendorImage}`} alt="" style={{ width: '100px', height: '70px', borderRadius: '50%' }} />
                                        <p className="col-lg-7">{parse(`<div>${item?.Vendor?.[0]?.Description}</div>`)}</p>
                                    </div>
                                </>) : (null)}
                            </div>
                        )} */}

                        <div className="mb-3 HotelDetails-mb">
                            <Accordion defaultActiveKey={null} style={{ width: '800px' }} className="HotelDetails-Accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        {" "}
                                        <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                                            House Rules
                                        </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {parse(`<div>${item?.Houserules}</div>`)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="mb-3 HotelDetails-mb">
                            <Accordion defaultActiveKey={null} style={{ width: '800px' }} className="HotelDetails-Accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        {" "}
                                        <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                                            Things To Do?
                                        </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {parse(`<div>${item?.ThingToDo}</div>`)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="mb-3 HotelDetails-mb">
                            <Accordion defaultActiveKey={null} style={{ width: "800px" }} className="HotelDetails-Accordion">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <h4 className="over-taxi" style={{ fontSize: "17px" }}>
                                            FAQ
                                        </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {item?.faq?.map((item) => (
                                            <Accordion defaultActiveKey={null} className="mb-2">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <p className="over-taxi" style={{ fontSize: "17px", fontFamily: 'math' }}>
                                                            {parse(`<div>${item?.question}</div>`)}
                                                        </p>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div>{parse(`<div>${item?.answer}</div>`)}</div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>


                    <h4 className="mb-3 HotelDetails-mb">REVIEWS</h4>
                    <div className="row gap-3">
                        {item?.StarRatings?.slice(0, 4)?.map((item) => (<>
                            <div className="col-lg-5 mb-3">
                                <div className="d-flex gap-2 align-items-center">
                                    {item?.UserImage ? (<>
                                        <img src={`https://hayyyak.com/Customer/${item?.UserImage}`} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                    </>) : (<>
                                        <img src="./img/profile.jpg" alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                    </>)}
                                    <div style={{ fontSize: '14x' }}>
                                        <p className="fw-bold ">{item?.Fname} <span className="text-danger">{item?.Lname}</span></p>
                                        <Rating
                                            className="StarSize"
                                            readonly={true}
                                            initialValue={item?.Rating} />
                                    </div>
                                </div>
                                <p style={{ fontSize: '14x' }} className="text-secondary">{item?.Review}</p>
                            </div>
                        </>))}


                        {/* <div className="col-lg-5 mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <img src="./img/review-3.JPEG" alt="" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <div>
                                    <p className="fw-bold">Yellapa</p>
                                    <p className="text-secondary">Dubai</p>
                                </div>
                            </div>
                            <p className="text-secondary">I stayed at Berty’s place for 6 nights and it truly felt like a home away from from. The stay was clean, organised and comfortable. It had all the promised amenities</p>
                        </div>
                        <div className="col-lg-5 mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <img src="./img/login-img.JPG" alt="" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <div>
                                    <p className="fw-bold">Durgavva</p>
                                    <p className="text-secondary">Hubli</p>
                                </div>
                            </div>
                            <p className="text-secondary">I stayed at Berty’s place for 6 nights and it truly felt like a home away from from. The stay was clean, organised and comfortable. It had all the promised amenities</p>
                        </div>
                        <div className="col-lg-5 mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <img src="./img/fb-bg-6.JPG" alt="" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <div>
                                    <p className="fw-bold">Pooja</p>
                                    <p className="text-secondary">Italy</p>
                                </div>
                            </div>
                            <p className="text-secondary">I stayed at Berty’s place for 6 nights and it truly felt like a home away from from. The stay was clean, organised and comfortable. It had all the promised amenities</p>
                        </div> */}
                    </div>
                    <p onClick={handleShow2} className="fw-bold text-danger mb-4 bhsagd_1" >See all Reviews</p>
                </div>
            </div >


            {/* Amenties Modal */}
            < Modal show={show1} onHide={handleClose1} className="amenties-modal">
                <Modal.Header closeButton>
                    <Modal.Title>What <span className="text-danger">{item?.propartyname}</span>  offers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {item?.Amenities?.map((item, i) => (
                            <div className="d-flex gap-3 align-items-center mb-3">
                                <img src={`https://hayyyak.com/StayAmenities/${item?.Icon}`} alt="" width="25" height="25" />
                                <span className=" fs-5">
                                    {item?.AmenitiName}
                                </span>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal >

            {/* Reviews Modal */}
            < Modal show={show2} onHide={handleClose2} className="amenties-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{item?.StarRatings?.length} Reviews</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {item?.StarRatings?.map((item) => (<>
                        <div className="mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                {item?.UserImage ? (<>
                                    <img src={`https://hayyyak.com/Customer/${item?.UserImage}`} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                </>) : (<>
                                    <img src="./img/profile.jpg" alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                </>)}
                                <div style={{ fontSize: '14x' }}>
                                    <p className="fw-bold ">{item?.Fname} <span className="text-danger">{item?.Lname}</span></p>
                                    <Rating
                                        className="StarSize"
                                        readonly={true}
                                        initialValue={item?.Rating} />
                                </div>
                            </div>
                            <p style={{ fontSize: '14x' }} className="text-secondary">{item?.Review}</p>
                        </div>
                        <hr />
                    </>))}
                </Modal.Body>
            </Modal >


        </>
    );
}


