import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
// import Carousel from 'react-grid-carousel'
import parse from "html-react-parser"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Testimonial = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    // get testimonial
    const [GetTesti, setGetTesti] = useState([]);
    const getTestimonial = async () => {
        try {
            const response = await axios.get(
                "https://hayyyak.com/api/admin/home/gettestimonial"
            );
            console.log(response.data);
            setGetTesti(response.data.success);
        } catch (error) {
            console.log(error);
        }
    };
    console.log("GetTesti", GetTesti);

    useEffect(() => {
        getTestimonial();
    }, []);

    return (
        <div className='mainnn'>
            <Container>
                <div className="text-center ">
                    <h2 className="home-all-headings">
                        Testi<span>Monial</span>
                    </h2>
                    <p className="text-secondary">Our Customer Reviews</p>
                </div>

                <div style={{ marginBottom: "100px" }} id="testimonial-mb" className="testi ">
                    {/* <Carousel cols={2} rows={1}  gap={10} loop className="testi-carousel ">
                        {GetTesti?.map((item) => {
                            return (
                                <Carousel.Item>
                                    <div className="d-flex align-items-center gap-4 testi-carousel-div ">
                                        <div className='testi-carousel-innerdiv1'>
                                            <img
                                                src={`https://hayyyak.com/Testimonial/${item?.TestiImage}`}
                                                alt=""
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        </div>
                                        <div className='testi-carousel-innerdiv2'>
                                            <p className='fw-bold fs-5 text-danger'>{item?.Title}</p>
                                            <p className='text-secondary'>{parse(`<div>${item?.Description?.slice(0, 200)}</div`)}</p>
                                            <span className='fw-bold'>{item?.Location}</span>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        })}
                    </Carousel> */}
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={"mobile"}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["mobile"]}
                        //   deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >

                        {GetTesti?.map((item) => {
                            return (

                                <div
                                    className="Testmonial-main-div"
                                // className="d-flex align-items-center gap-4 testi-carousel-div "
                                >
                                    <div
                                        className="Testmonial-main-div-inner-left "
                                    // className='testi-carousel-innerdiv1'
                                    >
                                        <img
                                            src={`https://hayyyak.com/Testimonial/${item?.TestiImage}`}
                                            alt=""
                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                        />
                                    </div>
                                    <div
                                        className="Testmonial-main-div-inner-right"

                                    // className='testi-carousel-innerdiv2'
                                    >
                                        <p className='fw-bold fs-5 text-danger text-center'>{item?.Title}</p>
                                        <p className='text-secondary text-center'>{parse(`<div>${item?.Description?.slice(0, 250)}</div`)}</p>
                                        <span className='fw-bold text-end'>{item?.Location}</span>
                                    </div>
                                </div>

                            )
                        })}
                    </Carousel>

                </div>
            </Container>
        </div>
    )
};
