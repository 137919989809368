import React from "react";
import { useState } from "react";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import { BsFillTrainFrontFill, BsSearch } from "react-icons/bs";
import { AiFillEye, AiFillEyeInvisible, AiOutlineRight } from "react-icons/ai";
import { FaHotel } from "react-icons/fa";
import { BiEdit, BiLogOutCircle, BiUserPin } from "react-icons/bi";
import HikingIcon from "@mui/icons-material/Hiking";
import axios from "axios";
import { useEffect } from "react";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import parse from "html-react-parser";
import moment from "moment";
import { Rating } from "react-simple-star-rating";

export const Profile = () => {
  const [Taxi, setTaxi] = useState(true);
  const [Hotel, setHotel] = useState(false);
  const [Event, setEvent] = useState(false);
  const [Profile, setProfile] = useState(false);
  const [Tour, setTour] = useState(false);

  const [acc, setAcc] = useState(true);
  const [acc1, setAcc1] = useState("");
  const [acc2, setAcc2] = useState("");
  const [acc3, setAcc3] = useState("");
  const [acc4, setAcc4] = useState("");

  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [show3, setShow3] = useState();
  const [show4, setShow4] = useState();
  const [show5, setShow5] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [showPassword, setshowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setshowPassword(!showPassword);
  };
  const [showPassword1, setshowPassword1] = useState(false);
  const togglePasswordVisibility1 = () => {
    setshowPassword1(!showPassword1);
  };

  const [TaxiView, setTaxiView] = useState("");
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  console.log("TaxiView", TaxiView);
  // const handleClose5 = () => setShow5(false);
  // const handleShow5 = () => setShow5(true);

  const [View, setView] = useState({});
  console.log("View", View);
  const Customer = JSON.parse(sessionStorage.getItem("customer"));
  const Customerlogout = () => {
    sessionStorage.removeItem("customer");
    window.location.assign("/");
  };

  console.log("Customer", Customer);

  const [updateprofile, setupdateprofile] = useState(true);

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Cpassword, setCpassword] = useState("");
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [address, setaddress] = useState("");
  const [Mobile, setMobile] = useState("");

  // Update Profile
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var f_Name = /^[a-zA-Z][a-zA-Z]/;

  const updatedUser = async () => {
    if (Fname) {
      if (!Fname.match(f_Name)) {
        return alert("Please Enter Your Valid First Name");
      }
    }
    if (Lname) {
      if (!Lname.match(f_Name)) {
        return alert("Please Enter Your Valid Last Name");
      }
    }
    if (Password) {
      if (Password < 4) {
        return alert("Your password should have atleast four characters");
      }
    }
    if (Password) {
      if (Password !== Cpassword) {
        return alert("Password and Confirm Password does not match");
      }
    }

    if (Mobile) {
      if (Mobile.length !== 10) {
        return alert("Mobile Number Should be 10 Digit");
      }
      if (!Mobile.match(mobilevalid)) {
        return alert("Please Enter Valid Mobile Number ");
      }
    }
    if (Email) {
      if (!Email.match(validRegex)) {
        return alert("Please Enter a Valid Email Address.");
      }
    }

    try {
      const config = {
        url: "/customer/updateuser",
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: Customer?._id,
          Email: Email,
          Password: Password,
          Fname: Fname,
          Lname: Lname,
          address: address,
          Mobile: Mobile,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem("customer", JSON.stringify(res.data.userdata));
          window.location.reload();
        }
      });
    } catch (error) {}
  };

  // Prfoile update
  const [images, setImages] = useState([]);
  const formdata = new FormData();

  const ImageGallery = async (img) => {
    formdata.append("profileImage", img);
    formdata.append("userid", Customer?._id);
    try {
      const config = {
        url: "/customer/profileimg",
        baseURL: "https://hayyyak.com/api",
        method: "put",
        headers: { "Content-Type": "multipart/formdata" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          alert(res.data.msg);
          sessionStorage.setItem("customer", JSON.stringify(res.data.success));
          window.location.reload();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // Get Tour Packages
  const [GetTourpackages, setGetTourpackages] = useState([]);
  const gettourpackagesbyid = async () => {
    try {
      let res = await axios.get(
        "https://hayyyak.com/api/admin/getbookedtourpackagesbyid/" +
          Customer?._id
      );
      if (res.status === 200) {
        setGetTourpackages(res.data.bookedtourpackagesids);
      }
    } catch (error) {
      console.log(error);
      setGetTourpackages([]);
    }
  };

  const createPDF = async () => {
    const input = document.getElementById("pdf");
    const options = { scrollY: -window.scrollY };
    const canvas = await html2canvas(input, options);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("TourBookingdetails.pdf");
  };

  // Get Booked Activity
  const [GetactivityView, setGetactivityView] = useState({});
  const [ActivityHistory, setActivityHistory] = useState([]);
  const GetActivityHistory = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/activity/getactivityhistory"
      );
      if (res.status === 200) {
        setActivityHistory(res.data.ActivityHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get Booked Stays
  const [ViewStay, setViewStay] = useState({});
  const [getStayBooking, setgetStayBooking] = useState([]);
  const BookStayDetails = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/stays/bookstaydetails"
      );
      if (res.status === 200) {
        setgetStayBooking(res.data.bookedStays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("ViewStay,ViewStay", ViewStay);

  // Star Ratings
  const [rating, setrating] = useState(0);

  const handleRating = (rate) => {
    setrating(rate);
  };

  // Rating API
  const [Review, setReview] = useState("");

  const Rate = async (id) => {
    if (!rating) {
      return alert("Please Give Star Ratings");
    }
    if (!Review) {
      return alert("Please Enter Review");
    }
    try {
      const config = {
        url: "/api/vendor/stays/Ratings/" + id,
        baseURL: "https://hayyyak.com/",
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          UserID: Customer?._id,
          Rating: rating,
          Review: Review,
          UserImage: Customer?.profileImage,
          Fname: Customer?.Fname,
          Lname: Customer?.Lname,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose3();
      }
    } catch (error) {
      alert(error.res.data.error);
    }
  };

  // Get Taxi Bookings
  const [AllTaxiBooking, setAllTaxiBooking] = useState([]);
  const GetAllBooking = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/taxi/getAllBooking"
      );
      if (res.status === 200) {
        setAllTaxiBooking(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("AllTaxiBooking", AllTaxiBooking);

  useEffect(() => {
    gettourpackagesbyid();
    GetActivityHistory();
    BookStayDetails();
    GetAllBooking();
  }, []);

  console.log("getStayBooking", getStayBooking);
  console.log("GetActivityHistory", ActivityHistory);
  console.log("GetTourpackages", GetTourpackages);
  return (
    <div>
      <Container fluid>
        <div className="row mt-3 gap-4">
          <div className="col-lg-3  ">
            <div className="fb-profile_1">
              {Customer?.profileImage ? (
                <>
                  <img
                    src={`https://hayyyak.com/Customer/${Customer?.profileImage}`}
                    style={{
                      width: "100%",
                      height: "170px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                      imageRendering: "pixelated",
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src="/img/profile.jpg"
                    style={{
                      width: "100%",
                      height: "155px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                </>
              )}

              <p style={{ position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                    color: "#000",
                    fontSize: "30px",
                  }}
                >
                  <BiEdit />
                </span>
                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={(e) => ImageGallery(e.target.files[0])}
                />
              </p>
            </div>
            <p className="profile-name">
              {Customer?.Fname}
              <span className="text-danger"> {Customer?.Lname}</span>
            </p>

            <div className=" d-flex flex-column gap-4 mb-4 mt-4">
              <Button
                className="booking-btn d-flex align-items-center justify-content-between "
                onClick={() => {
                  setTaxi(true);
                  setHotel(false);
                  setEvent(false);
                  setProfile(false);
                  setAcc(true);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(false);
                }}
                style={{
                  border: acc ? "3px solid #d81d4a" : "",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                <BsFillTaxiFrontFill /> Taxi <AiOutlineRight />
              </Button>

              <Button
                className="booking-btn d-flex align-items-center justify-content-between"
                onClick={() => {
                  setTaxi(false);
                  setHotel(true);
                  setEvent(false);
                  setProfile(false);
                  setAcc(false);
                  setAcc1(true);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(false);
                }}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: acc1 ? "3px solid #d81d4a" : "",
                }}
              >
                <FaHotel /> Stays <AiOutlineRight />
              </Button>

              <Button
                className="booking-btn d-flex align-items-center justify-content-between"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(true);
                  setProfile(false);
                  setAcc(false);
                  setAcc1(false);
                  setAcc2(true);
                  setAcc3(false);
                  setAcc4(false);
                }}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: acc2 ? "3px solid #d81d4a" : "",
                }}
              >
                <HikingIcon /> Activities <AiOutlineRight />
              </Button>

              <Button
                className="booking-btn d-flex align-items-center justify-content-between"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(false);
                  setProfile(false);
                  setTour(true);
                  setAcc(false);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(false);
                  setAcc4(true);
                }}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: acc4 ? "3px solid #d81d4a" : "",
                }}
              >
                <AddHomeWorkIcon className="fs-5" /> Tour Package{" "}
                <AiOutlineRight />
              </Button>

              <Button
                className="booking-btn d-flex align-items-center justify-content-between"
                onClick={() => {
                  setTaxi(false);
                  setHotel(false);
                  setEvent(false);
                  setProfile(true);
                  setAcc(false);
                  setAcc1(false);
                  setAcc2(false);
                  setAcc3(true);
                  setAcc4(false);
                }}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: acc3 ? "3px solid #d81d4a" : "",
                }}
              >
                <BiUserPin className="fs-5" /> Profile <AiOutlineRight />
              </Button>

              <Button
                onClick={() => Customerlogout()}
                className="booking-btn d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#fff", color: "#000" }}
              >
                <BiLogOutCircle className="fs-5 text-danger" /> Log Out{" "}
                <AiOutlineRight />
              </Button>
            </div>
          </div>

          <div className="col-lg-8">
            {Taxi ? (
              <>
                <div className="wad_0">
                  <h4>Taxi Booking</h4>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-secondary">No</th>
                        <th className="text-secondary">Trip Type</th>
                        <th className="text-secondary">Pick Up Place</th>
                        <th className="text-secondary">Pick Up Date</th>
                        <th className="text-secondary">Price</th>
                        <th className="text-secondary">View More</th>
                      </tr>
                    </thead>

                    <tbody>
                      {AllTaxiBooking?.filter(
                        (ele) => ele?.userId?._id === Customer?._id
                      )?.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item?.TripType}</td>
                          <td>{item?.picUpPlace}</td>
                          <td>{item?.picUpDate}</td>
                          <td>₹{item?.BookedPrice}</td>
                          <td>
                            <div
                              className="pay-more"
                              onClick={() => {
                                handleShow4();
                                setTaxiView(item);
                              }}
                            >
                              View More
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                {Hotel ? (
                  <>
                    <div className="wad_0">
                      <h4>Stays Bookings</h4>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-secondary">No</th>
                            <th className="text-secondary">Stay Name</th>
                            <th className="text-secondary">Booking Date</th>
                            <th className="text-secondary">Total Guest</th>
                            <th className="text-secondary">Price</th>
                            <th className="text-secondary">Payment</th>
                            <th className="text-secondary">More</th>
                            <th className="text-secondary">Give Review</th>
                          </tr>
                        </thead>

                        <tbody>
                          {getStayBooking
                            ?.filter((ele) => ele.userId?._id === Customer?._id)
                            ?.map((item, i) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++i}</td>
                                    <td>{item?.stayId?.propartyname}</td>
                                    <td>
                                      {moment(item?.roomId?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      Adult:{" "}
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "18px",
                                        }}
                                      >
                                        {item?.adultquantity}
                                      </span>{" "}
                                      ,<br />
                                      Children:{" "}
                                      <span style={{ color: "green" }}>
                                        {item?.childrenquantity}
                                      </span>{" "}
                                    </td>
                                    <td>₹{item?.AllTotalamount}</td>
                                    <td>
                                      <div className="pay-done">Done</div>
                                    </td>
                                    <td>
                                      <div
                                        className="pay-more"
                                        onClick={() => {
                                          handleShow();
                                          setViewStay(item);
                                        }}
                                      >
                                        View More
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        style={{
                                          backgroundColor: "#ffb100",
                                          color: "#000",
                                          border: "none",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShow3();
                                          setViewStay(item);
                                        }}
                                      >
                                        Review
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <>
                    {Event ? (
                      <>
                        <div className="wad_0">
                          <h4>Activity Bookings</h4>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th className="text-secondary">No</th>
                                <th className="text-secondary">
                                  Activity Name
                                </th>
                                <th className="text-secondary">Booking Date</th>
                                <th className="text-secondary">Total Price</th>
                                <th className="text-secondary">Payment</th>
                                <th className="text-secondary">More</th>
                                {/* <th className="text-secondary">Give Review</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {ActivityHistory?.filter(
                                (ele) => ele.userId?._id === Customer?._id
                              )?.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>
                                      <div className="sdhjf_12">
                                        {parse(`<div>${item?.Title}</div>`)}
                                      </div>
                                    </td>
                                    <td>{item?.checkindate}</td>
                                    <td>₹{item?.AllTotalamount}</td>
                                    <td>
                                      <div className="pay-done">Done</div>
                                    </td>
                                    <td>
                                      <div
                                        className="pay-more"
                                        onClick={() => {
                                          handleShow1();
                                          setGetactivityView(item);
                                        }}
                                      >
                                        View More
                                      </div>
                                    </td>
                                    {/* <td>
                                      <button
                                        style={{
                                          backgroundColor: "#ffb100",
                                          color: "#000",
                                          border: "none",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShow4();
                                          setGetactivityView(item);
                                        }}
                                      >
                                        Review
                                      </button>
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <>
                        {Profile ? (
                          <>
                            <div className="wad_0">
                              {updateprofile == true ? (
                                <>
                                  <h4>My Profile</h4>
                                  <Table responsive>
                                    <tbody>
                                      <tr>
                                        <td className="fw-bold">Name</td>
                                        <td>:</td>
                                        <td>
                                          {Customer?.Fname} {Customer?.Lname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">Email</td>
                                        <td>:</td>
                                        <td>{Customer?.Email}</td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">Phone</td>
                                        <td>:</td>
                                        <td>{Customer?.Mobile}</td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">Address</td>
                                        <td>:</td>
                                        <td>{Customer?.address}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <div>
                                    <button
                                      onClick={() => {
                                        setupdateprofile(false);
                                      }}
                                      className="pink-btn"
                                    >
                                      Edit Profile
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h4>Update Profile</h4>
                                  <div>
                                    <div className="row">
                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            First Name
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label={Customer?.Fname}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            onChange={(e) =>
                                              setFname(e.target.value)
                                            }
                                          />
                                        </FloatingLabel>
                                      </div>

                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Last Name
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label={Customer?.Lname}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            onChange={(e) =>
                                              setLname(e.target.value)
                                            }
                                          />
                                        </FloatingLabel>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="mb-2 col-lg-6 ">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Password
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label="Password"
                                          className="login-eye"
                                        >
                                          <Form.Control
                                            style={{
                                              borderRadius: "5px 0px 0px 5px",
                                            }}
                                            className="login-input"
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            placeholder="Password"
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                          />
                                          <div
                                            onClick={togglePasswordVisibility}
                                            className="login-1-eye"
                                            style={{ padding: "13px" }}
                                          >
                                            {showPassword ? (
                                              <>
                                                {" "}
                                                <AiFillEye />
                                              </>
                                            ) : (
                                              <>
                                                <AiFillEyeInvisible />
                                              </>
                                            )}
                                          </div>
                                        </FloatingLabel>
                                      </div>

                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Confirm{" "}
                                          <span className="text-danger">
                                            Password
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label="Confirm Password"
                                          className="login-eye"
                                        >
                                          <Form.Control
                                            style={{
                                              borderRadius: "5px 0px 0px 5px",
                                            }}
                                            className="login-input"
                                            type={
                                              showPassword1
                                                ? "text"
                                                : "password"
                                            }
                                            placeholder="Password"
                                            onChange={(e) =>
                                              setCpassword(e.target.value)
                                            }
                                          />
                                          <div
                                            onClick={togglePasswordVisibility1}
                                            className="login-1-eye"
                                            style={{ padding: "13px" }}
                                          >
                                            {showPassword1 ? (
                                              <>
                                                {" "}
                                                <AiFillEye />
                                              </>
                                            ) : (
                                              <>
                                                <AiFillEyeInvisible />
                                              </>
                                            )}
                                          </div>
                                        </FloatingLabel>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Number
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label={Customer?.Mobile}
                                        >
                                          <Form.Control
                                            type="number"
                                            placeholder="Number"
                                            onChange={(e) =>
                                              setMobile(e.target.value)
                                            }
                                          />
                                        </FloatingLabel>
                                      </div>

                                      <div className="mb-2 col-lg-6">
                                        <label className="fw-bold mb-1">
                                          Enter{" "}
                                          <span className="text-danger">
                                            Email
                                          </span>{" "}
                                          :
                                        </label>
                                        <FloatingLabel
                                          controlId="floatingPassword"
                                          label={Customer?.Email}
                                        >
                                          <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                          />
                                        </FloatingLabel>
                                      </div>
                                    </div>

                                    <div className="mb-2 col-lg-6">
                                      <label className="fw-bold mb-1">
                                        Enter{" "}
                                        <span className="text-danger">
                                          Address
                                        </span>{" "}
                                        :
                                      </label>
                                      <FloatingLabel
                                        controlId="floatingPassword"
                                        label={Customer?.address}
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="address"
                                          onChange={(e) =>
                                            setaddress(e.target.value)
                                          }
                                        />
                                      </FloatingLabel>
                                    </div>
                                    <div className="d-flex gap-2">
                                      <Button
                                        variant="danger"
                                        onClick={updatedUser}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        variant="warning"
                                        onClick={() => {
                                          setupdateprofile(true);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {Tour ? (
                              <>
                                <div className="wad_0">
                                  <h4>Tour Package Booking</h4>
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th className="text-secondary">No</th>
                                        <th className="text-secondary">
                                          Package
                                        </th>
                                        <th className="text-secondary">
                                          Duration
                                        </th>
                                        <th className="text-secondary">
                                          Start Date
                                        </th>
                                        <th className="text-secondary">
                                          Price
                                        </th>
                                        <th className="text-secondary">
                                          Payment
                                        </th>
                                        <th className="text-secondary">More</th>
                                        {/* <th className="text-secondary">
                                          Give Review
                                        </th> */}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {GetTourpackages?.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>{item?.packageId?.tripname}</td>
                                            <td>
                                              {item?.packageId?.tripduration}{" "}
                                              D/N
                                            </td>
                                            <td>{item?.checkindate}</td>
                                            <td>
                                              ₹
                                              {item?.adultprice +
                                                item?.childrenprice +
                                                item?.petsprice}
                                            </td>
                                            <td>
                                              <div className="pay-done">
                                                Done
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                className="pay-more"
                                                onClick={() => {
                                                  handleShow2();
                                                  setView(item);
                                                }}
                                              >
                                                View More
                                              </div>
                                            </td>
                                            {/* <td>
                                              <button
                                                style={{
                                                  backgroundColor: "#ffb100",
                                                  color: "#000",
                                                  border: "none",
                                                  fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                  handleShow5();
                                                  setView(item);
                                                }}
                                              >
                                                Review
                                              </button>
                                            </td> */}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Container>

      {/* Stay Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Stays <span className="text-danger"> Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Room Name : </td>
                <td>{ViewStay?.roomId?.roomname}</td>
              </tr>
              <tr>
                <td className="text-danger">Check In Date : </td>
                <td>{ViewStay?.checkindate}</td>
              </tr>
              <tr>
                <td className="text-danger">Check Out Date : </td>
                <td>{ViewStay?.checkoutdate}</td>
              </tr>
              <tr>
                <td className="text-danger">Stay Location : </td>
                <td>{ViewStay?.stayId?.propartyaddress}</td>
              </tr>
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>
                  (Adults : {ViewStay?.adultquantity}, Children :{" "}
                  {ViewStay?.childrenquantity})
                </td>
              </tr>
              <tr>
                <td className="text-danger">Payment : </td>
                <td>
                  <div className="pay-done">Paid</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="success" onClick={handleClose}>
            Save
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* Activity Modal */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            Activity <span className="text-danger"> Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Activity Name : </td>
                <td className="sdhjf_12">
                  {parse(`<div>${GetactivityView?.Title}</div>`)}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Activity Location : </td>
                <td>
                  {GetactivityView?.location} <br />
                  <span>
                    {" "}
                    <span className="fw-bold">Country</span> :{" "}
                    {GetactivityView?.country}{" "}
                  </span>
                  <span>
                    <span className="fw-bold">State</span> :{" "}
                    {GetactivityView?.state}{" "}
                  </span>
                  <span>
                    <span className="fw-bold">City</span> :{" "}
                    {GetactivityView?.city}{" "}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="text-danger">Booking Date : </td>
                <td>{GetactivityView?.checkindate}</td>
              </tr>
              <tr>
                <td className="text-danger">Total Price : </td>
                <td>₹{GetactivityView?.AllTotalamount}</td>
              </tr>
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>
                  Adults : {GetactivityView?.adultquantity}, Children :{" "}
                  {GetactivityView?.childrenquantity}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Payment : </td>
                <td>
                  <div className="pay-done">Paid</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Tour Package Modal */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            Tour Package <span className="text-danger"> Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="pdf">
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Package Name : </td>
                <td>{View?.packageId?.tripname}</td>
              </tr>
              <tr>
                <td className="text-danger">Duration : </td>
                <td>{View?.packageId?.tripduration} D/N</td>
              </tr>
              <tr>
                <td className="text-danger">Price : </td>
                <td>
                  ₹{View?.adultprice + View?.childrenprice + View?.petsprice}
                </td>
              </tr>
              <tr>
                <td className="text-danger">Start Date : </td>
                <td>{View?.checkindate}</td>
              </tr>
              {/* <tr>
                                <td className='text-danger'>End Date : </td>
                                <td>10/21/2023</td>
                            </tr> */}
              <tr>
                <td className="text-danger">Total Guest : </td>
                <td>
                  {View?.adultquantity +
                    View?.childrenquantity +
                    View?.petsquantity}
                  (Adults : {View?.adultquantity}, Children :
                  {View?.childrenquantity} , Pets : {View?.petsquantity})
                </td>
              </tr>
              {/* <tr>
                <td className="text-danger">Places Covered : </td>
                <td>
                  {View?.packageId?.TourSchedule?.map((item) => {
                    return <>{item?.placename} ,</>;
                  })}
                </td>
              </tr> */}
              <tr>
                <td className="text-danger">Payment : </td>
                <td>
                  <div className="pay-done">Paid</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="success" onClick={createPDF}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //////////////// */}

      {/* Stay Review Model */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-danger"> Review</span>
          </Modal.Title>
        </Modal.Header>
        {ViewStay?.stayId?.StarRatings?.some(
          (rating) => rating.UserID === Customer?._id
        ) ? (
          <>
            <Modal.Body>
              <div className="text-center">
                <h4>You have already given a rating</h4>
                <h5>
                  "Thank you for your{" "}
                  <span className="text-danger">Review</span>"
                </h5>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleClose3}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <div>
                <p className="fw-bold fs-4 text-danger mb-2 text-uppercase">
                  {ViewStay?.stayId?.propartyname}
                </p>
                <label>
                  Star <span className="text-danger">Rate :</span>
                </label>
                <div className="mb-3">
                  <Rating onClick={handleRating} />
                </div>

                <label>
                  Enter <span className="text-danger">Review :</span>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Review....."
                  onChange={(e) => setReview(e.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleClose3}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => Rate(ViewStay?.stayId?._id)}
              >
                Submit
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      {/* Activity Review Model */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>
            Booking <span className="text-danger"> Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="text-danger">Pick Up Place : </td>
                <td style={{ width: "300px" }}>{TaxiView?.picUpPlace}</td>
              </tr>
              <tr>
                <td className="text-danger">Drop Place : </td>
                <td>{TaxiView?.dropPlace}</td>
              </tr>
              <tr>
                <td className="text-danger">Pick up Date : </td>
                <td>{TaxiView?.picUpDate}</td>
              </tr>
              <tr>
                <td className="text-danger">Pick Up Time : </td>
                <td>{TaxiView?.picUpTime}</td>
              </tr>
              <tr>
                <td className="text-danger">Oneway or Round Trip : </td>
                <td>{TaxiView?.OneRound}</td>
              </tr>
              {TaxiView?.AirportPicDrop && (
                <tr>
                  <td className="text-danger">Airport PickUp / Drop : </td>
                  <td>{TaxiView?.AirportPicDrop}</td>
                </tr>
              )}
              {TaxiView?.ReturnpicUpDate && (
                <tr>
                  <td className="text-danger">Return Pick Up Date : </td>
                  <td>{TaxiView?.ReturnpicUpDate}</td>
                </tr>
              )}
              {TaxiView?.ReturnpicUpTime && (
                <tr>
                  <td className="text-danger">Return Pick Up Time : </td>
                  <td>{TaxiView?.ReturnpicUpTime}</td>
                </tr>
              )}
              {TaxiView?.RentPackage && (
                <tr>
                  <td className="text-danger">Rental Package : </td>
                  <td>{TaxiView?.RentPackage}</td>
                </tr>
              )}
              {TaxiView?.SelectedCity && (
                <tr>
                  <td className="text-danger">Selected City : </td>
                  <td>{TaxiView?.SelectedCity}</td>
                </tr>
              )}
              {TaxiView?.WaitingCharge && (
                <tr>
                  <td className="text-danger">Waiting Charges : </td>
                  <td>{TaxiView?.WaitingCharge}</td>
                </tr>
              )}
              {TaxiView?.TollCharge && (
                <tr>
                  <td className="text-danger">Toll Charges : </td>
                  <td>{TaxiView?.TollCharge}</td>
                </tr>
              )}
              {TaxiView?.ParkingCharge && (
                <tr>
                  <td className="text-danger">Parking Charges : </td>
                  <td>{TaxiView?.ParkingCharge}</td>
                </tr>
              )}
              <tr>
                <td className="text-danger">Payment Method : </td>
                <td>{TaxiView?.PaymentMethod}</td>
              </tr>
              <tr>
                <td className="text-danger">Booking Price : </td>
                <td>₹{TaxiView?.BookedPrice}</td>
              </tr>
              <tr>
                <td className="text-danger">Payment : </td>
                <td>
                  <div className="pay-done">Paid</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Tour Review Model */}
      {/* <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>
            Give <span className="text-danger"> Review</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fw-bold fs-4 text-danger mb-2 text-uppercase">
            {View?.packageId?.tripname}
          </p>
          <label>
            Star <span className="text-danger">Rate :</span>
          </label>
          <div className="mb-3">
            <Rating onClick={handleRating} />
          </div>

          <label>
            Enter <span className="text-danger">Review :</span>
          </label>
          <textarea className="form-control" placeholder="Review....." />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="danger">Sumbit</Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};
