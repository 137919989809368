import React from 'react'
import { Container } from 'react-bootstrap'
import { AiOutlineIdcard, AiOutlineRight } from 'react-icons/ai'
import { BsBatteryCharging, BsCashCoin, BsEmojiSunglasses, BsFillCameraFill } from 'react-icons/bs'
import { FaBicycle, FaFlagCheckered, FaHotel, FaMapMarkedAlt } from 'react-icons/fa'
import { GiArchiveResearch, GiElectric } from 'react-icons/gi'
import { GrAnnounce } from 'react-icons/gr'
import { Link } from 'react-router-dom'

export const PrivacyPolicy = () => {
    return (
        <div>
            <div className='mb-5 privacy-div-mb'>
                <div className='activity-bg'>
                    <div
                        style={{
                            backgroundImage: 'url(././img/blog.jpg)',
                            backgroundPosition: 'top',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'fixed',
                            height: "350px",
                            width: "100%",
                        }}
                        className="Privacy-top-activity"
                    >
                        <div className="activity-text">
                            <div className='bread' id="bread-privacy">
                                <h2 className='text-uppercase fw-bold'>Privacy & Policy</h2>
                                <div className='d-flex align-items-center gap-2'>
                                    <Link to='/' className='breadcrumb-active'><span>Home </span></Link>
                                    <span><AiOutlineRight /></span>
                                    <span className='' style={{color:"#D81D4A", fontWeight:"bold"}}>Privacy & Policy</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Container>
                <div className="row mb-4 policy-div-mb">
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><AiOutlineIdcard /></span>
                            <h4>Bring Copies Of Your Passport</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><FaFlagCheckered /></span>
                            <h4>Register With Your Embassy</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><BsCashCoin /></span>
                            <h4>Always Have Local Cash</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                </div>


                <div className="row mb-4 policy-div-mb">
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><FaMapMarkedAlt /></span>
                            <h4>Get Guidebooks</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><GiArchiveResearch /></span>
                            <h4>Research Events</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><BsFillCameraFill /></span>
                            <h4>Bring Your Camera</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-4 policy-div-mb">
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><BsBatteryCharging /></span>
                            <h4>Power Bank</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><FaBicycle /></span>
                            <h4>Bicycle</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><FaHotel /></span>
                            <h4>Book Your Hotel</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                </div>


                <div className="row mb-4 policy-div-mb">
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><BsEmojiSunglasses /></span>
                            <h4>Research Events</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><GiElectric /></span>
                            <h4>Research Events</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='policy'>
                            <span><GrAnnounce /></span>
                            <h4>Research Events</h4>
                            <p>This privacy policy describes how your personal information is collected, used and shared when you visit or make a purchase from our website</p>
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}
