import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
import { AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Pagination, Stack } from "@mui/material";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export const VendorBookedStays = () => {
  const StayVendor = JSON.parse(sessionStorage.getItem("Stays"));
  // console.log("StayVendor", StayVendor);

  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  // get Booked Stays
  const [bookingDetails, setbookingDetails] = useState([]);
  const BookStayDetails = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/stays/bookstaydetails"
      );
      if (res.status === 200) {
        setbookingDetails(res.data.bookedStays);
        setnochangedata(res.data.bookedStays);
        // setPagination(res.data.bookedStays)
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("bookingDetails", bookingDetails);

  // Delete
  const [show, setShow] = useState(false);
  const [Data, setdata] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setdata(item);
  };

  const DeleteBookedStay = async () => {
    try {
      const config = {
        url: "customer/stays/daletebookedstays/" + Data?._id,
        baseURL: "https://hayyyak.com/api/",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          handleClose();
          BookStayDetails();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    BookStayDetails();
  }, []);

  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setbookingDetails(abc);
  };

  // Search
  const [SearchItem, setSearchItem] = useState("");
  // Apply search filter
  const filteredStays = bookingDetails.filter(
    (item) =>
      SearchItem === "" ||
      Object.values(item.userId).some((value) =>
        String(value).toLowerCase().includes(SearchItem.toLowerCase())
      ) ||
      (item?.stayId?.propartyname &&
        item?.stayId?.propartyname
          .toLowerCase()
          .includes(SearchItem.toLowerCase()))
  );

  // Paginate the filtered dataset
  // const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const changePage = (selected) => {
    setPageNumber(selected);
  };

  const filterStaysForVendor = filteredStays?.filter(
    (ele) => ele?.roomId?.vendorId === StayVendor?._id
  );

  const pageCount = Math.ceil(filterStaysForVendor.length / usersPerPage);
  const paginatedActivities = filterStaysForVendor.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );

console.log("paginatedActivities==> Stay",paginatedActivities);


  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [BookingDetails, setBookingDetails] = useState("");
  const [PaidAmount, setPaidAmount] = useState();
  useEffect(() => {
    setPaidAmount(BookingDetails?.AllTotalamount || "");
  }, [BookingDetails]);

  const CustomerPaidAmount = async () => {
    if (!PaidAmount) {
      return notify1("Please Enter Commission %");
    } else if (!/^\d+(\.\d+)?$/.test(PaidAmount)) {
      return notify1("Commission should be a valid number");
    }
    let commissionRate = StayVendor?.commission / 100;
    let adminCommission = PaidAmount * commissionRate;
    let paidAmountAfterCommission = PaidAmount - adminCommission;
    try {
      const config = {
        url: "customer/stays/commissionamount",
        method: "put",
        baseURL: "https://hayyyak.com/api/",
        headers: { "content-type": "application/json" },
        data: {
          bookingId: BookingDetails?._id,
          PaidAmount: PaidAmount,
          AdminCommission: paidAmountAfterCommission,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        handleClose1();
        BookStayDetails();
        setPaidAmount();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  return (
    <>
      <div>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
            <p className="text-danger fs-4 fw-semibold">Stays Booked List :</p>
            {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
          </div>

          <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
            <div className="col-lg-2">
              <label>From :</label>
              <Form.Control
                type="date"
                aria-describedby="basic-addon1"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>

            <div className="col-lg-2">
              <label>To :</label>
              <Form.Control
                type="date"
                aria-describedby="basic-addon1"
                onChange={(e) => settod(e.target.value)}
              />
            </div>

            <div className="col-lg-2">
              <button className="pink-btn" onClick={searchDate}>
                Submit
              </button>
            </div>

            <div
              className="input-group col-lg-3"
              style={{ width: "auto", height: "35px", marginTop: "20px" }}
            >
              <span class="input-group-text" id="basic-addon1">
                <BsSearch />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                aria-describedby="basic-addon1"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
            <div className="col-lg-1 export-btn">
              <ReactHTMLTableToExcel
                id="export-to-excel"
                table="Export-table"
                filename="bookedStays"
                sheet="bookedStays"
                buttonText="Export"
              />
            </div>
          </div>
          <div>
            <Table
              responsive
              bordered
              className="admin-table-head"
              id="Export-table"
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>User Number</th>
                  <th>Stay Name</th>
                  <th width="400px">Stay Location</th>
                  <th>Room Name</th>
                  <th>Check In date</th>
                  <th>Check Out date</th>
                  <th>Booking Date</th>
                  <th>Total Person</th>
                  <th>Actual Price</th>
                  <th>Discount(%)</th>
                  <th>Discount(%) Price</th>
                  <th>GST(%) Price</th>
                  <th>Total Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedActivities?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        {item?.userId?.Fname} {item?.userId?.Lname}
                      </td>
                      <td>{item?.userId?.Email}</td>
                      <td>{item?.userId?.Mobile}</td>
                      <td>{item?.stayId?.propartyname}</td>
                      <td>{item?.stayId?.propartyaddress}</td>
                      <td>{item?.roomId?.roomname}</td>
                      <td>{item?.checkindate}</td>
                      <td>{item?.checkoutdate}</td>
                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {item?.adultquantity}
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          {item?.childrenquantity}
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          {item?.infantsquantity}
                        </span>{" "}
                      </td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {" "}
                          ₹{item?.roomId?.adultprice * item?.adultquantity}
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          ₹{item?.roomId?.children * item?.childrenquantity}
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          ₹{item?.roomId?.infants * item?.infantsquantity}
                        </span>{" "}
                        ,<br />
                      </td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {" "}
                          {item?.roomId?.adultdiscount}%
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          {item?.roomId?.childrendiscount}%
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          {" "}
                          {item?.roomId?.infantsdiscount}%
                        </span>{" "}
                      </td>
                      <td>
                        Adult:{" "}
                        <span style={{ color: "green", fontSize: "18px" }}>
                          {" "}
                          ₹ {item?.adulttotalprice}
                        </span>{" "}
                        ,<br />
                        Children:{" "}
                        <span style={{ color: "green" }}>
                          ₹ {item?.childrentotalprice}
                        </span>{" "}
                        ,<br />
                        Infants:{" "}
                        <span style={{ color: "green" }}>
                          ₹ {item?.infantstotalprice}
                        </span>{" "}
                      </td>
                      <td style={{ color: "green", fontSize: "18px" }}>
                        {item?.roomId?.gst}%
                      </td>
                      <td style={{ color: "green", textAlign:"justify", fontSize: "18px" }}>
                        <b>Total = </b> ₹{item?.AllTotalamount} <br />
                        <b>Customer Paid = </b> ₹{item?.PaidAmount}
                        <br />
                        <b>Vendor Commission = </b> ₹{item?.AdminCommission?.toFixed(2)}
                        <br />
                        <b>Admin Commission = </b> ₹
                        {(Number(item?.PaidAmount?.toFixed(2)) - Number(item?.AdminCommission?.toFixed(2))).toFixed(2)}
                        <br />
                        {item?.PaidAmount > 0 ? (
                         ""
                        ):(
                            <Button
                            onClick={() => {
                              handleShow1();
                              setBookingDetails(item);
                            }}
                          >
                            Pending
                          </Button>
                        )}
                        
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 fs-4 align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          <MdDelete
                            className="text-danger"
                            onClick={() => handleShow(item)}
                          />
                          {/* <Link to=""><AiFillEye /> </Link> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="my-3">
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                onChange={(event, value) => {
                  changePage(value - 1);
                }}
                color="primary"
              />
            </Stack>
          </div>
        </Container>

        {/* Delete Modal */}

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
                src="./img/delete-btn.png"
                alt=""
              />
              <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
              <p>This data will be removed permanently</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={DeleteBookedStay}>
              <FontAwesomeIcon icon={faCancel} className=" me-2" />
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment Process</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <b>Admin Commission = </b> {StayVendor?.commission} %<br />
              <b>Total Amount = </b> ₹{BookingDetails?.AllTotalamount}
            </div>
            <Form className="mt-3">
              <label>Set Commission :</label>
              <Form.Control
                type="text"
                className="vi_0"
                placeholder="total amount"
                onChange={(e) => setPaidAmount(e.target.value)}
                value={PaidAmount}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={CustomerPaidAmount}>
              Pay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
