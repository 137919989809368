import React, { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Container, FloatingLabel, InputGroup } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CiLocationOn } from "react-icons/ci";
import { TiArrowRightOutline, TiTickOutline } from "react-icons/ti";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import moment from "moment";

export const Taxi = () => {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [daily, setDaily] = useState(true);
  const [outStation, setoutStation] = useState(false);
  const [airport, setairport] = useState(false);
  const [local, setlocal] = useState(false);

  const [one, setOne] = useState(true);
  const [round, setRound] = useState(false);
  const [TripType, setTripType] = useState("Dailyride");

  const Destination = [
    { label: "Singapura Layout" },
    { label: "Majestic" },
    { label: "Yeshwanthpura" },
    { label: "Banglore Airport" },
    { label: "Jalhalli" },
    { label: "Whitefield" },
  ];

  const Destination2 = [
    { label: "Bengaluru" },
    { label: "Mysore" },
    { label: "Hubli" },
    { label: "Chennai" },
    { label: "Chikkaballapur" },
    { label: "Mangalore" },
  ];

  const when = [{ label: "Now" }, { label: "Schedule for later" }];

  const Airport = [{ label: "Airport Pickup" }, { label: "Airport Drop" }];

  const [OneRound, setOneRound] = useState("Oneway");
  const handleChange = (event) => {
    setOneRound(event.target.value);
  };

  const [isLoading, setisLoading] = useState(false);
  const [ShowTaxi, setShowTaxi] = useState(false);

  const navigate = useNavigate();
  const [picUpPlace, setpicUpPlace] = useState("");
  const [dropPlace, setdropPlace] = useState("");
  const [picUpDate, setpicUpDate] = useState("");
  const [picUpTime, setpicUpTime] = useState("");
  const [ReturnpicUpDate, setReturnpicUpDate] = useState("");
  const [ReturnpicUpTime, setReturnpicUpTime] = useState("");
  const [Whenn, setWhen] = useState("");
  const [SelectedCity, setSelectedCity] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [AirportPicDrop, setAirportPicDrop] = useState("");

  // Get Vehicle Category Data
  const [getVehicleCat, setgetVehicleCat] = useState([]);
  const getVehiclecategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/taxi/getvechiclecategory")
      .then(function (response) {
        setgetVehicleCat(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Get Daily Ride Package
  const [DailyRidePackageData, setDailyRidePackageData] = useState([]);
  const GetDailyRide = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getDailyRide"
      );
      if (res.status === 200) {
        setDailyRidePackageData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get Out Station
  const [OutStationDetails, setOutStationDetails] = useState([]);
  const GetOutStation = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getoutstation"
      );
      if (res.status === 200) {
        setOutStationDetails(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("OutStationDetails", OutStationDetails);

  // Get Airport
  const [AirportPackageData, setAirportPackageData] = useState([]);
  const GetAirport = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getAirport"
      );
      if (res.status === 200) {
        setAirportPackageData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get Rental
  const [RentalPackageData, setRentalPackageData] = useState([]);
  const GetRental = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/taxi/getRental"
      );
      if (res.status === 200) {
        setRentalPackageData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehiclecategory();
    GetDailyRide();
    GetOutStation();
    GetAirport();
    GetRental();
  }, []);

  // Navigate to Next page
  const GoToTaxiBook = (
    item,
    image,
    price,
    Category,
    DriverAllowance,
    CGST,
    IGST,
    SGST,
    WaitingCharge,
    NightDriverAllowance,
    TollCharge,
    ParkingCharge
  ) => {
    const dataPass = {
      // Daily Ride
      TripType: TripType,
      image: image,
      item: item,
      Category: Category,
      price: price,
      picUpPlace: picUpPlace,
      dropPlace: dropPlace,
      picUpDate: picUpDate,
      picUpTime: picUpTime,
      When: Whenn,
      DriverAllowance: DriverAllowance,
      CGST: CGST,
      IGST: IGST,
      SGST: SGST,
      WaitingCharge: WaitingCharge,
      NightDriverAllowance: NightDriverAllowance,
      TollCharge: TollCharge,
      ParkingCharge: ParkingCharge,

      // Rent
      SelectedCity: SelectedCity,
      selectedPackage: selectedPackage,

      // airport
      AirportPicDrop: AirportPicDrop,

      // Outstation
      ReturnpicUpDate: ReturnpicUpDate,
      ReturnpicUpTime: ReturnpicUpTime,
      OneRound: OneRound,
    };
    navigate("/taxibook", { state: dataPass });
  };

  // Daily Ride Search
  const DailyRideSearch = () => {
    if (!picUpPlace) {
      notify1("Please Select Pick Up Location");
      return false;
    }
    if (!dropPlace) {
      notify1("Please Select Drop Location");
      return false;
    }
    if (!Whenn) {
      notify1("Please Select When You Want to Travel");
      return false;
    }
    if (Whenn === "Schedule for later" && !picUpDate) {
      notify1("Please Select Pick Up Date");
      return Whenn === "Schedule for later" ? false : true;
    }
    if (Whenn === "Schedule for later" && !picUpTime) {
      notify1("Please Select Pick Up Time");
      return Whenn === "Schedule for later" ? false : true;
    }
    return true;
  };

  // Rental Search
  const RentalSearch = () => {
    if (!SelectedCity) {
      notify1("Please Select Location");
      return false;
    }
    if (!selectedPackage) {
      notify1("Please Select Package");
      return false;
    }
    if (!Whenn) {
      notify1("Please Select When You Want Rental");
      return false;
    }
    if (Whenn === "Schedule for later" && !picUpDate) {
      notify1("Please Select Pick Up Date");
      return Whenn === "Schedule for later" ? false : true;
    }
    if (Whenn === "Schedule for later" && !picUpTime) {
      notify1("Please Select Pick Up Time");
      return Whenn === "Schedule for later" ? false : true;
    }
    return true;
  };

  // Airport Search
  const AirportSearch = () => {
    if (!AirportPicDrop) {
      notify1("Please Select Airport PickUp Or Drop");
      return false;
    }
    if (!SelectedCity) {
      notify1("Please Select City");
      return false;
    }
    if (AirportPicDrop === "Airport Pickup" && !picUpPlace) {
      notify1("Please Select Pick Up Airport");
      return AirportPicDrop === "Airport Pickup" ? false : true;
    }
    if (AirportPicDrop === "Airport Drop" && !picUpPlace) {
      notify1("Please Enter Pick Up Location");
      return AirportPicDrop === "Airport Drop" ? false : true;
    }
    if (AirportPicDrop === "Airport Pickup" && !dropPlace) {
      notify1("Please Enter Drop Location");
      return AirportPicDrop === "Airport Pickup" ? false : true;
    }
    if (AirportPicDrop === "Airport Drop" && !dropPlace) {
      notify1("Please Select Drop Airport");
      return AirportPicDrop === "Airport Drop" ? false : true;
    }
    if (!picUpDate) {
      notify1("Please Select Pick Up Date");
      return false;
    }
    if (!picUpTime) {
      notify1("Please Select Pick Up Time");
      return false;
    }
    return true;
  };

  // OutStation
  const OutStationSearch = () => {
    if (!picUpPlace) {
      notify1("Please Select Pick Up Location");
      return false;
    }
    if (!dropPlace) {
      notify1("Please Select Drop Location");
      return false;
    }
    if (!picUpDate) {
      notify1("Please Select Pick Up Date");
      return false;
    }
    if (!picUpTime) {
      notify1("Please Select Pick Up Time");
      return false;
    }
    if (OneRound === "Roundtrip" && !ReturnpicUpDate) {
      notify1("Please Select Return Date");
      return OneRound === "Roundtrip" ? false : true;
    }
    if (OneRound === "Roundtrip" && !ReturnpicUpTime) {
      notify1("Please Select Return Time");
      return OneRound === "Roundtrip" ? false : true;
    }
    return true;
  };

  // Rental select Packages
  const uniqueRentCities = [
    ...new Set(RentalPackageData?.map((item) => item.RentCity)),
  ];
  const filteredPackages = RentalPackageData?.filter(
    (item) => item?.RentCity === SelectedCity
  ).map((ele) => ele.RentPackage);

  // Airport Select
  const filterAirport = [
    ...new Set(
      AirportPackageData?.filter((ele) => ele?.AirCity === SelectedCity)?.map(
        (item) => item?.AirName
      )
    ),
  ];

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <div className="activity-bg" id="taxi-bg">
        <div
          style={{
            backgroundImage: "url(././img/taxi-bg.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            height: "350px",
            width: "100%",
          }}
          className="taxi-top-activity"

        ></div>
      </div>
      <section className="jitotrg">
        <div className="banner-1">
          <div className="banner_hero">
            <div className="container">
              <div className="geh">
                <div className="local-00">
                  <div className="ver_her0">
                    <ul className="ul_wrap">
                      <li
                        className={daily ? "tab-active" : "null"}
                        onClick={() => {
                          setShowTaxi(false);
                          setDaily(true);
                          setoutStation(false);
                          setairport(false);
                          setlocal(false);
                          setWhen("");
                          setTripType("Dailyride");
                        }}
                        id="ul_wrap-Dailyride"
                      >
                        Daily Rides
                      </li>
                      <li
                        className={outStation ? "tab-active" : "null"}
                        onClick={() => {
                          setShowTaxi(false);
                          setDaily(false);
                          setoutStation(true);
                          setairport(false);
                          setlocal(false);
                          setTripType("Out Station");
                        }}
                      >
                        Outstation
                      </li>
                      <li
                        className={airport ? "tab-active" : "null"}
                        onClick={() => {
                          setShowTaxi(false);
                          setDaily(false);
                          setoutStation(false);
                          setairport(true);
                          setlocal(false);
                          setTripType("Airport");
                        }}
                      >
                        Airport
                      </li>
                      <li
                        className={local ? "tab-active" : "null"}
                        onClick={() => {
                          setShowTaxi(false);
                          setDaily(false);
                          setoutStation(false);
                          setairport(false);
                          setlocal(true);
                          setWhen("");
                          setTripType("Rental");
                        }}
                      >
                        Rental
                      </li>
                    </ul>
                  </div>

                  <div className="localtab tab">
                    {daily ? (
                      <>
                        <div>
                          <div className="row mb-3">
                            <div className="col-lg-4">
                              <label className="text-danger fw-bold">
                                Pick Up Location
                              </label>
                              <Autocomplete
                                onChange={(event, newValue) =>
                                  setpicUpPlace(newValue ? newValue.label : "")
                                }
                                disablePortal
                                id="combo-box-demo"
                                options={Destination}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Pick Up Point"
                                  />
                                )}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label className="text-danger fw-bold">
                                Drop Off Destination
                              </label>
                              <Autocomplete
                                onChange={(event, newValue) =>
                                  setdropPlace(newValue ? newValue.label : "")
                                }
                                disablePortal
                                id="combo-box-demo"
                                options={Destination}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search for locality or landmark"
                                  />
                                )}
                              />
                            </div>

                            <div className="col-lg-4">
                              <label className="text-danger fw-bold">
                                When
                              </label>
                              <Autocomplete
                                onChange={(event, newValue) =>
                                  setWhen(newValue ? newValue.label : "")
                                }
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={(option) => option.label}
                                options={when}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} label="when" />
                                )}
                              />
                            </div>
                          </div>

                          {Whenn === "Schedule for later" ? (
                            <>
                              <div className="row mb-3 justify-content-center">
                                <div className="col-lg-3">
                                  <div className="smith">
                                    <label>Pick Up</label> <br />
                                    <DatePicker
                                      minDate={new Date()}
                                      onChange={(date) =>
                                        setpicUpDate(date.format("DD/MM/YYYY"))
                                      }
                                      placeholder="Choose Date"
                                      style={{
                                        border: "none",
                                        padding: "3px",
                                        width: "145px",
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-3">
                                  <div className="smith">
                                    <label>Pick Up Time</label> <br />
                                    <input
                                      onChange={(e) =>
                                        setpicUpTime(e.target.value)
                                      }
                                      type="time"
                                      style={{
                                        border: "none",
                                        padding: "3px",
                                        width: "145px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div className="d-flex justify-content-center">
                            <button
                              className="header-search h-100"
                              onClick={() => {
                                if (DailyRideSearch()) {
                                  setShowTaxi(false);
                                  setisLoading(true);

                                  setTimeout(() => {
                                    setisLoading(false);
                                    setShowTaxi(true);
                                  }, 3000);
                                }
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {outStation ? (
                          <>
                            <div>
                              <div className="d-flex gap-5 justify-content-center mb-3">
                                <div>
                                  <Radio
                                    checked={OneRound === "Oneway"}
                                    onChange={handleChange}
                                    value="Oneway"
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                    onClick={() => {
                                      setOne(true);
                                      setRound(false);
                                      setOneRound("Oneway");
                                    }}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault1"
                                  >
                                    One Way
                                  </label>
                                </div>

                                <div>
                                  <Radio
                                    checked={OneRound === "Roundtrip"}
                                    onChange={handleChange}
                                    value="Roundtrip"
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "B" }}
                                    onClick={() => {
                                      setOne(false);
                                      setRound(true);
                                      setOneRound("Roundtrip");
                                    }}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault2"
                                  >
                                    Round Trip
                                  </label>
                                </div>
                              </div>

                              <div className="row mb-3">
                                <div className="col-lg-6">
                                  <label className="text-danger fw-bold">
                                    Pick Up Location
                                  </label>
                                  <Autocomplete
                                    onChange={(event, newValue) =>
                                      setpicUpPlace(
                                        newValue ? newValue.label : ""
                                      )
                                    }
                                    disablePortal
                                    id="combo-box-demo"
                                    options={Destination2}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Pick Up Point"
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label className="text-danger fw-bold">
                                    Drop Location
                                  </label>
                                  <Autocomplete
                                    onChange={(event, newValue) =>
                                      setdropPlace(
                                        newValue ? newValue.label : ""
                                      )
                                    }
                                    disablePortal
                                    id="combo-box-demo"
                                    options={Destination2}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Enter a city, hotel or address "
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              {one ? (
                                <>
                                  <div className="row mb-3 justify-content-center">
                                    <div className="col-lg-3">
                                      <div className="smith">
                                        <label>Pick Up</label> <br />
                                        <DatePicker
                                          onChange={(date) =>
                                            setpicUpDate(
                                              date.format("DD/MM/YYYY")
                                            )
                                          }
                                          placeholder="Choose Date"
                                          style={{
                                            border: "none",
                                            padding: "3px",
                                            width: "145px",
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div className="smith">
                                        <label>Pick Up Time</label> <br />
                                        <input
                                          onChange={(e) =>
                                            setpicUpTime(e.target.value)
                                          }
                                          type="time"
                                          style={{
                                            border: "none",
                                            padding: "3px",
                                            width: "145px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {round ? (
                                    <>
                                      <div className="row mb-3 justify-content-center">
                                        <div className="col-lg-3">
                                          <div className="smith">
                                            <label>Pick Up</label> <br />
                                            <DatePicker
                                              onChange={(date) =>
                                                setpicUpDate(
                                                  date.format("DD/MM/YYYY")
                                                )
                                              }
                                              placeholder="Choose Date"
                                              style={{
                                                border: "none",
                                                padding: "3px",
                                                width: "145px",
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-3">
                                          <div className="smith">
                                            <label>Pick Up Time</label> <br />
                                            <input
                                              onChange={(e) =>
                                                setpicUpTime(e.target.value)
                                              }
                                              type="time"
                                              style={{
                                                border: "none",
                                                padding: "3px",
                                                width: "145px",
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-3">
                                          <div className="smith">
                                            <label>Return Date</label> <br />
                                            <DatePicker
                                              onChange={(date) =>
                                                setReturnpicUpDate(
                                                  date.format("DD/MM/YYYY")
                                                )
                                              }
                                              placeholder="Choose Date"
                                              style={{
                                                border: "none",
                                                padding: "3px",
                                                width: "145px",
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-3">
                                          <div className="smith">
                                            <label>Return Time</label> <br />
                                            <input
                                              onChange={(e) =>
                                                setReturnpicUpTime(
                                                  e.target.value
                                                )
                                              }
                                              type="time"
                                              style={{
                                                border: "none",
                                                padding: "3px",
                                                width: "145px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}

                              <div className="d-flex justify-content-center">
                                <button
                                  className="header-search h-100"
                                  onClick={() => {
                                    if (OutStationSearch()) {
                                      setShowTaxi(false);
                                      setisLoading(true);
                                      setTimeout(() => {
                                        setisLoading(false);
                                        setShowTaxi(true);
                                      }, 3000);
                                    }
                                  }}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {airport ? (
                              <>
                                <div>
                                  <div className="row mb-3">
                                    <div className="col-lg-3">
                                      <label className="text-danger fw-bold">
                                        Airport Pickup/Drop
                                      </label>
                                      <Autocomplete
                                        onChange={(event, newValue) =>
                                          setAirportPicDrop(
                                            newValue ? newValue.label : ""
                                          )
                                        }
                                        disablePortal
                                        id="combo-box-demo"
                                        options={Airport}
                                        // sx={{ width: 300 }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select Pickup/Drop"
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="col-lg-3">
                                      <label className="text-danger fw-bold">
                                        Select City
                                      </label>
                                      <Autocomplete
                                        onChange={(event, newValue) => {
                                          setSelectedCity(newValue);
                                        }}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={[
                                          ...new Set(
                                            AirportPackageData?.map(
                                              (item) => item?.AirCity
                                            )
                                          ),
                                        ]}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select City"
                                          />
                                        )}
                                      />
                                    </div>

                                    {AirportPicDrop === "Airport Pickup" ? (
                                      <>
                                        <div className="col-lg-3">
                                          <label className="text-danger fw-bold">
                                            Select Airport
                                          </label>
                                          <Autocomplete
                                            onChange={(event, newValue) =>
                                              setpicUpPlace(newValue)
                                            }
                                            disablePortal
                                            id="combo-box-demo"
                                            options={filterAirport}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Select Airport"
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-3">
                                          <label className="text-danger fw-bold">
                                            Enter Drop Location
                                          </label>
                                          <InputGroup size="lg">
                                            <Form.Control
                                              onChange={(e) =>
                                                setdropPlace(e.target.value)
                                              }
                                              placeholder="Enter Drop Location"
                                              aria-label="Large"
                                              aria-describedby="inputGroup-sizing-sm"
                                            />
                                          </InputGroup>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-3">
                                          <label className="text-danger fw-bold">
                                            Enter Pickup Location
                                          </label>
                                          <InputGroup size="lg">
                                            <Form.Control
                                              onChange={(e) =>
                                                setpicUpPlace(e.target.value)
                                              }
                                              placeholder="Enter Pickup Location"
                                              aria-label="Large"
                                              aria-describedby="inputGroup-sizing-sm"
                                            />
                                          </InputGroup>
                                        </div>
                                        <div className="col-lg-3">
                                          <label className="text-danger fw-bold">
                                            Select Airport
                                          </label>
                                          <Autocomplete
                                            onChange={(event, newValue) =>
                                              setdropPlace(newValue)
                                            }
                                            disablePortal
                                            id="combo-box-demo"
                                            options={filterAirport}
                                            // sx={{ width: 300 }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Select Airport"
                                              />
                                            )}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  <div className="row mb-3 justify-content-center">
                                    <div className="col-lg-3">
                                      <div className="smith">
                                        <label>Pick Up Date</label> <br />
                                        <DatePicker
                                          minDate={new Date()}
                                          onChange={(date) =>
                                            setpicUpDate(
                                              date.format("DD/MM/YYYY")
                                            )
                                          }
                                          placeholder="Choose Date"
                                          style={{
                                            border: "none",
                                            padding: "3px",
                                            width: "145px",
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div className="smith">
                                        <label>Pick Up Time</label> <br />
                                        <input
                                          onChange={(e) =>
                                            setpicUpTime(e.target.value)
                                          }
                                          type="time"
                                          style={{
                                            border: "none",
                                            padding: "3px",
                                            width: "145px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="header-search h-100"
                                      onClick={() => {
                                        if (AirportSearch()) {
                                          setShowTaxi(false);
                                          setisLoading(true);
                                          setTimeout(() => {
                                            setisLoading(false);
                                            setShowTaxi(true);
                                          }, 3000);
                                        }
                                      }}
                                    >
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {local ? (
                                  <>
                                    <div>
                                      <div className="row mb-3">
                                        <div className="col-lg-4">
                                          <label className="text-danger fw-bold">
                                            Location
                                          </label>
                                          <Autocomplete
                                            onChange={(event, newValue) => {
                                              setSelectedCity(newValue);
                                            }}
                                            disablePortal
                                            id="combo-box-demo"
                                            options={uniqueRentCities}
                                            // sx={{ width: 300 }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Location"
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <label className="text-danger fw-bold">
                                            Package
                                          </label>
                                          <Autocomplete
                                            onChange={(event, newValue) => {
                                              setSelectedPackage(newValue);
                                            }}
                                            disablePortal
                                            id="combo-box-demo"
                                            options={filteredPackages}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Select a Package"
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <label className="text-danger fw-bold">
                                            When
                                          </label>
                                          <Autocomplete
                                            onChange={(event, newValue) =>
                                              setWhen(
                                                newValue ? newValue.label : ""
                                              )
                                            }
                                            disablePortal
                                            id="combo-box-demo"
                                            options={when}
                                            // sx={{ width: 300 }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="when"
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      {Whenn === "Schedule for later" ? (
                                        <>
                                          <div className="row mb-3 justify-content-center">
                                            <div className="col-lg-3">
                                              <div className="smith">
                                                <label>Pick Up</label> <br />
                                                <DatePicker
                                                  minDate={new Date()}
                                                  onChange={(date) =>
                                                    setpicUpDate(
                                                      date.format("DD/MM/YYYY")
                                                    )
                                                  }
                                                  placeholder="Choose Date"
                                                  style={{
                                                    border: "none",
                                                    padding: "3px",
                                                    width: "145px",
                                                  }}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-lg-3">
                                              <div className="smith">
                                                <label>Pick Up Time</label>{" "}
                                                <br />
                                                <input
                                                  onChange={(e) =>
                                                    setpicUpTime(e.target.value)
                                                  }
                                                  type="time"
                                                  style={{
                                                    border: "none",
                                                    padding: "3px",
                                                    width: "145px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="d-flex justify-content-center">
                                        <button
                                          className="header-search h-100"
                                          onClick={() => {
                                            if (RentalSearch()) {
                                              setShowTaxi(false);
                                              setisLoading(true);

                                              setTimeout(() => {
                                                setisLoading(false);
                                                setShowTaxi(true);
                                              }, 3000);
                                            }
                                          }}
                                        >
                                          Search
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <Container className="margin-bottom">
        {isLoading === true ? (
          <div className="d-flex justify-content-center">
            <img
              src="./img/spinner.gif"
              style={{ width: "80px", height: "80px" }}
            />
          </div>
        ) : (
          <></>
        )}

        {ShowTaxi === true ? (
          <div className="row justify-content-center gap-2">
            <div className="col-lg-2">
              <h6>
                AVAILABLE{" "}
                <span className="text-danger">
                  RIDES <TiArrowRightOutline />
                </span>
              </h6>

              <div className="d-flex gap-2 align-items-center mb-2">
                <CiLocationOn className="fs-4" />
                <div>
                  <p>About 30 Km</p>
                  <p style={{ fontSize: "12px" }}>
                    <TiTickOutline /> No pre-payment required
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    <TiTickOutline /> Free Cancellation
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div
                className="ola-box scroll-cat"
                style={{ height: "auto", maxHeight: "400px", overflow: "auto" }}
              >
                {daily ? (
                  <>
                    {" "}
                    {DailyRidePackageData?.map((item) => {
                      if (
                        getVehicleCat?.some(
                          (ele) =>
                            ele?.vehiclecategory === item?.RideVehicleCatrgoey
                        )
                      )
                        return (
                          <>
                            <div
                              className="ola-link"
                              onClick={() =>
                                GoToTaxiBook(
                                  item,
                                  getVehicleCat?.find(
                                    (ele) =>
                                      ele?.vehiclecategory ===
                                      item?.RideVehicleCatrgoey
                                  )?.vehicleImage,
                                  30 * item?.RidePerKmPrice +
                                    item?.RideDriverAllowance +
                                    (30 * item?.RidePerKmPrice +
                                      item?.RideDriverAllowance) *
                                      ((item?.RideportCGST +
                                        item?.RideportIGST +
                                        item?.RideportSGST) /
                                        100),
                                  item?.RideVehicleCatrgoey,
                                  item?.RideDriverAllowance,
                                  item?.RideportCGST,
                                  item?.RideportIGST,
                                  item?.RideportSGST,
                                  item?.RideWaitingCharge,
                                  item?.RideNightDriveAllowance
                                )
                              }
                            >
                              <div className="ola">
                                <div>
                                  <img
                                    src={`https://hayyyak.com/VehicleCategory/${
                                      getVehicleCat?.find(
                                        (ele) =>
                                          ele?.vehiclecategory ===
                                          item?.RideVehicleCatrgoey
                                      )?.vehicleImage
                                    }`}
                                    alt=""
                                    style={{ width: "50px", height: "50px" }}
                                  />{" "}
                                  <br />
                                  <p className="text-center">4 min</p>
                                </div>
                                <div className="ola-content">
                                  <span>{item?.RideVehicleCatrgoey}</span>
                                  <p>Get an auto at your doorstep</p>
                                </div>
                                <div className="d-flex gap-2 align-items-center float-end">
                                  <span style={{ fontSize: "14px" }}>
                                    ₹
                                    {(
                                      30 * item?.RidePerKmPrice +
                                      item?.RideDriverAllowance +
                                      (30 * item?.RidePerKmPrice +
                                        item?.RideDriverAllowance) *
                                        ((item?.RideportCGST +
                                          item?.RideportIGST +
                                          item?.RideportSGST) /
                                          100)
                                    )?.toFixed(2)}
                                  </span>
                                  <span>
                                    <IoIosArrowForward />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        );
                    })}
                  </>
                ) : (
                  <></>
                )}
                {outStation ? (
                  <>
                    {" "}
                    {OutStationDetails?.filter(
                      (ele) => ele?.OutCity === picUpPlace
                    )?.map((item) => {
                      if (
                        getVehicleCat?.some(
                          (ele) =>
                            ele?.vehiclecategory === item?.OutVehicleCatrgoey
                        )
                      )
                        return (
                          <>
                            <div
                              className="ola-link"
                              onClick={() =>
                                GoToTaxiBook(
                                  item,
                                  getVehicleCat?.find(
                                    (ele) =>
                                      ele?.vehiclecategory ===
                                      item?.OutVehicleCatrgoey
                                  )?.vehicleImage,
                                  30 * item?.OutPerKmPrice +
                                    item?.ODriverAllowance +
                                    item?.OutTollCharge +
                                    (30 * item?.OutPerKmPrice +
                                      item?.ODriverAllowance +
                                      item?.OutTollCharge) *
                                      ((item?.OutportCGST +
                                        item?.OutportIGST +
                                        item?.OutportSGST) /
                                        100),
                                  item?.OutVehicleCatrgoey,
                                  item?.ODriverAllowance,
                                  item?.OutportCGST,
                                  item?.OutportIGST,
                                  item?.OutportSGST,
                                  item?.OutWaitingCharge,
                                  item?.ONightDriveAllowance,
                                  item?.OutTollCharge,
                                  item?.OutParkingCharge
                                )
                              }
                            >
                              <div className="ola">
                                <div>
                                  <img
                                    src={`https://hayyyak.com/VehicleCategory/${
                                      getVehicleCat?.find(
                                        (ele) =>
                                          ele?.vehiclecategory ===
                                          item?.OutVehicleCatrgoey
                                      )?.vehicleImage
                                    }`}
                                    alt=""
                                    style={{ width: "50px", height: "50px" }}
                                  />{" "}
                                  <br />
                                  <p className="text-center">4 min</p>
                                </div>
                                <div className="ola-content">
                                  <span>{item?.OutVehicleCatrgoey}</span>
                                  <p>Get an auto at your doorstep</p>
                                </div>
                                <div className="d-flex gap-2 align-items-center float-end">
                                  <span style={{ fontSize: "14px" }}>
                                    ₹
                                    {Number(
                                      30 * item?.OutPerKmPrice +
                                        item?.ODriverAllowance +
                                        item?.OutTollCharge +
                                        (30 * item?.OutPerKmPrice +
                                          item?.ODriverAllowance +
                                          item?.OutTollCharge) *
                                          ((item?.OutportCGST +
                                            item?.OutportIGST +
                                            item?.OutportSGST) /
                                            100)
                                    )?.toFixed(2)}
                                  </span>
                                  <span>
                                    <IoIosArrowForward />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        );
                    })}
                  </>
                ) : (
                  <></>
                )}

                {airport ? (
                  <>
                    {" "}
                    {AirportPackageData?.filter(
                      (ele) =>
                        ele?.AirCity === SelectedCity &&
                        (ele?.AirName === picUpPlace ||
                          ele?.AirName === dropPlace)
                    )?.map((item) => {
                      if (
                        getVehicleCat?.some(
                          (ele) =>
                            ele?.vehiclecategory === item?.AirVehicleCatrgoey
                        )
                      )
                        return (
                          <>
                            <div
                              className="ola-link"
                              onClick={() =>
                                GoToTaxiBook(
                                  item,
                                  getVehicleCat?.find(
                                    (ele) =>
                                      ele?.vehiclecategory ===
                                      item?.AirVehicleCatrgoey
                                  )?.vehicleImage,
                                  30 * item?.AirPerKmPrice +
                                    item?.ADriverAllowance +
                                    item?.AirTollCharge +
                                    (30 * item?.AirPerKmPrice +
                                      item?.ADriverAllowance +
                                      item?.AirTollCharge) *
                                      ((item?.AirportCGST +
                                        item?.AirportIGST +
                                        item?.AirportSGST) /
                                        100),
                                  item?.AirVehicleCatrgoey,
                                  item?.ADriverAllowance,
                                  item?.AirportCGST,
                                  item?.AirportIGST,
                                  item?.AirportSGST,
                                  item?.AirWaitingCharge,
                                  item?.ANightDriveAllowance,
                                  item?.AirTollCharge,
                                  item?.AirParkingCharge
                                )
                              }
                            >
                              <div className="ola">
                                <div>
                                  <img
                                    src={`https://hayyyak.com/VehicleCategory/${
                                      getVehicleCat?.find(
                                        (ele) =>
                                          ele?.vehiclecategory ===
                                          item?.AirVehicleCatrgoey
                                      )?.vehicleImage
                                    }`}
                                    alt=""
                                    style={{ width: "50px", height: "50px" }}
                                  />{" "}
                                  <br />
                                  <p className="text-center">4 min</p>
                                </div>
                                <div className="ola-content">
                                  <span>{item?.AirVehicleCatrgoey}</span>
                                  <p>Get an auto at your doorstep</p>
                                </div>
                                <div className="d-flex gap-2 align-items-center float-end">
                                  <span style={{ fontSize: "14px" }}>
                                    ₹
                                    {Number(
                                      30 * item?.AirPerKmPrice +
                                        item?.ADriverAllowance +
                                        item?.AirTollCharge +
                                        (30 * item?.AirPerKmPrice +
                                          item?.ADriverAllowance +
                                          item?.AirTollCharge) *
                                          ((item?.AirportCGST +
                                            item?.AirportIGST +
                                            item?.AirportSGST) /
                                            100)
                                    )?.toFixed(2)}
                                  </span>
                                  <span>
                                    <IoIosArrowForward />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        );
                    })}
                  </>
                ) : (
                  <></>
                )}
                {local ? (
                  <>
                    {RentalPackageData?.filter(
                      (ele) =>
                        ele?.RentCity === SelectedCity &&
                        ele?.RentPackage === selectedPackage
                    ).map((item) => {
                      if (
                        getVehicleCat?.some(
                          (ele) =>
                            ele?.vehiclecategory === item?.RentVehicleCatrgoey
                        )
                      )
                        return (
                          <>
                            <div
                              className="ola-link"
                              onClick={() =>
                                GoToTaxiBook(
                                  item,
                                  getVehicleCat?.find(
                                    (ele) =>
                                      ele?.vehiclecategory ===
                                      item?.RentVehicleCatrgoey
                                  )?.vehicleImage,
                                  item?.RentPackagePrice +
                                    (item?.RentportCGST +
                                      item?.RentportIGST +
                                      item?.RentportSGST) /
                                      100,
                                  item?.RentVehicleCatrgoey,
                                  0,
                                  item?.RentportCGST,
                                  item?.RentportIGST,
                                  item?.RentportSGST,
                                  item?.RentWaitingCharge,
                                  0
                                )
                              }
                            >
                              <div className="ola">
                                <div>
                                  <img
                                    src={`https://hayyyak.com/VehicleCategory/${
                                      getVehicleCat?.find(
                                        (ele) =>
                                          ele?.vehiclecategory ===
                                          item?.RentVehicleCatrgoey
                                      )?.vehicleImage
                                    }`}
                                    alt=""
                                    style={{ width: "50px", height: "50px" }}
                                  />{" "}
                                  <br />
                                  <p className="text-center">4 min</p>
                                </div>
                                <div className="ola-content">
                                  <span>{item?.RentVehicleCatrgoey}</span>
                                  <p>Get an Vehicle at your doorstep</p>
                                </div>
                                <div className="d-flex gap-2 align-items-center float-end">
                                  <span style={{ fontSize: "14px" }}>
                                    ₹
                                    {Number(
                                      item?.RentPackagePrice +
                                        (item?.RentportCGST +
                                          item?.RentportIGST +
                                          item?.RentportSGST) /
                                          100
                                    )?.toFixed(2)}
                                  </span>
                                  <span>
                                    <IoIosArrowForward />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};
