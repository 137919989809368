import React, { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

function AllActivityVendorList() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // // get vehicle model
  const [getsubscriptions, setgetsubscriptions] = useState([]);
  const GetVendorSubscriptions = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/VendorGetSubscription"
      );
      if (res.status === 200) {
        setgetsubscriptions(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const VendorSubscriptionss = getsubscriptions.filter(
    (ele) => ele.VendorType === "Activity Vendor"
  );

  // // get vehicle model
  const [gatVendorData, setgatVendorData] = useState([]);
  const GetVendorData = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/vendor/getallActivityVendor"
      );
      if (res.status === 200) {
        setgatVendorData(res.data.Vendor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Block and Unblock
  const BlockVendor = async (blockId) => {
    try {
      const res = await axios.put(
        `https://hayyyak.com/api/vendor/BlockActivityVendor/${blockId}`
      );
      if (res.status === 200) {
        notify(res.data.success);
        notify1(res.data.msg);
        GetVendorData();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  const today = moment().format("DD/MM/YYYY")?.split("/")?.reverse()?.join("");
  const [selectvendorId, setselectvendorId] = useState("");
  const [Commission, setCommission] = useState("");
  const SetVendorCommision = async () => {
    if (!Commission) {
      return notify1("Please Enter Commission %");
    } else if (!/^\d+(\.\d+)?$/.test(Commission)) {
      return notify1("Commission should be a valid number");
    }
    try {
      const config = {
        url: "/setvendorcommission",
        method: "put",
        baseURL: "https://hayyyak.com/api/vendor",
        headers: { "content-type": "application/json" },
        data: {
          vendorId: selectvendorId?._id,
          commission: Commission,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.success);
        setCommission("");
        handleClose();
        GetVendorData();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };



  const [bookedActivity, setbookedactivity] = useState([]);
  const GetBookedActivity = async () => {
      try {
          const res = await axios.get(
              "https://hayyyak.com/api/customer/activity/getbookedactivity"
          );
          if (res.status === 200) {
              setbookedactivity(res.data.bookedActivity);
          }
      } catch (error) {
          console.log(error);
      }
  };
  useEffect(() => {
    GetVendorData();
    GetVendorSubscriptions();
    GetBookedActivity();
  }, []);
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2 mb-3">
          <p className="text-danger fs-4 fw-semibold">Activity Vendor List :</p>
        </div>

        <div>
          <Table responsive bordered className="sdfsd-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Vendor Name</th>
                <th>Vendor Number</th>
                <th>Vendor Email</th>
                <th>Subscription</th>
                <th>Commission</th>
                <th>Commission Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {gatVendorData?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        {item?.Fname} {item?.Lname}
                      </td>
                      <td>{item?.Mobile}</td>
                      <td>{item?.Email}</td>
                      <td>
                        <div style={{width:"122px"}}>
                          {VendorSubscriptionss?.some((ele) => {
                            const enddate = ele?.EndDate?.split("/")
                              ?.reverse()
                              ?.join("");

                            return (
                              ele.VendorID === item?._id &&
                              parseInt(enddate) >= parseInt(today)
                            );
                          }) ? (
                            <span className="unblock-user">Subscribed</span>
                          ) : (
                            <span className="block-user">Not Subscribed</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <b>Commission : </b>
                        {item?.commission}%
                        <br />
                        <Button
                          onClick={() => {
                            handleShow();
                            setselectvendorId(item);
                          }}
                        >
                          Set Commission
                        </Button>
                      </td>
                      <td>
                        <br />
                        <Button
                          onClick={() => {
                            handleShow1();
                            setselectvendorId(item);
                          }}
                        >
                          View Details
                        </Button>
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            onClick={() => BlockVendor(item?._id)}
                            className={
                              item?.BlockVendor ? "block-user" : "unblock-user"
                            }
                          >
                            {item?.BlockVendor ? "Block" : "Unblock"}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Activity Verndor Set Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <label>Set Commission :</label>
            <Form.Control
              type="text"
              className="vi_0"
              maxLength={2}
              onChange={(e) => setCommission(e.target.value)}
              value={Commission}
              placeholder="Commission 0%"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={SetVendorCommision}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verndor Commission Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table responsive bordered className="sdfsd-table-head">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>User Name</th>
                  <th>Booked Date</th>
                  <th>Total Amount</th>
                  <th>Commission Amount</th>
                </tr>
              </thead>
              <tbody>
                {bookedActivity
                  ?.filter(
                    (ele) => ele?.ActivityId?.actiitiyid === selectvendorId?._id
                  )
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {item?.userId?.Fname} {item?.userId?.Lname}{" "}
                        </td>
                        <td>{moment(item?.createdAt).format("DD-MM-YYYY")} </td>
                        <td>
                          <b>Total Amount =</b> ₹{item?.AllTotalamount}
                          <br />
                          <b>Customer Paid =</b> ₹{item?.PaidAmount?.toFixed(2)}{" "}
                          ({selectvendorId?.commission} %)
                        </td>
                        <td>
                          {item?.PaidAmount > 0 ? (
                            <>
                              {(
                                Number(item?.PaidAmount?.toFixed(2)) -
                                Number(item?.AdminCommission?.toFixed(2))
                              ).toFixed(2)}
                              {/* ₹{item?.PaidAmount - item?.AdminCommission} */}
                            </>
                          ) : (
                            <>
                              <p>Pending..</p>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {/* Calculate the total sum of PaidAmount - AdminCommission */}
            <div>
              <b>Total Outcoming Amount:</b> ₹
              {
                bookedActivity
                  ?.filter(
                    (ele) => ele?.ActivityId?.actiitiyid === selectvendorId?._id
                  )
                  ?.reduce(
                    (acc, item) =>
                      acc +
                      ((item?.PaidAmount || 0) - (item?.AdminCommission || 0)),
                    0
                  )
                  ?.toFixed(2) // Ensure two decimal places
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AllActivityVendorList;
