import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { BiSolidContact } from "react-icons/bi";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import { FaHotel, FaUsers } from "react-icons/fa6";
import HikingIcon from "@mui/icons-material/Hiking";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
function Admindashboard() {
  const navigate = new useNavigate();
  const [bookedActivity, setbookedactivity] = useState([]);
  const GetBookedActivity = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/activity/getbookedactivity"
      );
      if (res.status === 200) {
        setbookedactivity(res.data.bookedActivity);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get Booked Stays
  const [bookingDetails, setbookingDetails] = useState([]);
  const BookStayDetails = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/stays/bookstaydetails"
      );
      if (res.status === 200) {
        setbookingDetails(res.data.bookedStays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [bookedTourpackages, setbookedTourpackages] = useState([]);
  const getbookedTourPackages = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/getbookedtourpackages"
      );
      if (res.status === 200) {
        setbookedTourpackages(res.data.bookedtourpackages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get taxi Bookings
  const [AllBookingData, setAllBookingData] = useState([]);
  const GetAllBooking = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/taxi/getAllBooking"
      );
      if (res.status === 200) {
        setAllBookingData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [resgisterUser, setRegisterUser] = useState([]);
  const GetRegisterUser = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/registeruser"
      );
      if (res.status === 200) {
        setRegisterUser(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [ContactList, setContactList] = useState([]);
  const getCustomerContastlist = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/customer/getcontactcustomer"
      );
      if (res.status == 200) {
        setContactList(res.data.getdata);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    GetBookedActivity();
    BookStayDetails();
    getbookedTourPackages();
    GetAllBooking();
    GetRegisterUser();
    getCustomerContastlist();
  }, []);

  return (
    <>
      <Container>
        <div className="row align-items-center justify-content-evenly mt-3">
          <div
            onClick={()=>navigate("/admin_vehicle_allbooking")}
            className="col-lg-5 text-light p-3 mb-3 "
            style={{
              backgroundColor: "rgb(171 37 136)",
              borderRadius: "10px",
              cursor:"pointer"
            }}
          >
            <h6 className="fs-4 fw-bold">Taxi</h6>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <BsFillTaxiFrontFill className=" fs-2 " />
              <span className="fs-2 fw-bold">{AllBookingData?.length}</span>
            </div>
            <p>{AllBookingData?.length} Booking of Taxi</p>
          </div>

          <div
           onClick={()=>navigate("/bookedstays")}
            className="col-lg-5 text-light p-3 mb-3"
            style={{
              backgroundColor: "rgb(131 49 217)",
              // width: "261px",
              borderRadius: "10px",
              cursor:"pointer"
            }}
          >
            <h6 className="fs-4 fw-bold text-light">Stays</h6>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <FaHotel className=" fs-2 " />
              <span className="fs-2 fw-bold  ">{bookingDetails?.length}</span>
            </div>
            <p>{bookingDetails?.length} Booking of Stays</p>
          </div>
          
          <div
           onClick={()=>navigate("/bookedactivity")}
            className="col-lg-5 text-light p-3 mb-3"
            style={{
              backgroundColor: "rgb(71 171 21)",
              borderRadius: "10px",
              cursor:"pointer"
            }}
          >
            <h6 className="fs-4 fw-bold"> Activity</h6>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <HikingIcon className="fs-2" />
              <span className="fs-2 fw-bold">{bookedActivity?.length}</span>
            </div>
            <p>{bookedActivity?.length} Booking of Activity</p>
          </div>

          <div
          onClick={()=>navigate("/bookedtourpackages")}
            className="col-lg-5 text-light p-3 mb-3"
            style={{
              backgroundColor: "rgb(171 155 21)",
              borderRadius: "10px",
            }}
          >
            <h6 className="fs-4 fw-bold"> Tour Package</h6>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <FaMapMarkedAlt className="fs-2" />
              <span className="fs-2 fw-bold ">
                {bookedTourpackages?.length}
              </span>
            </div>
            <p>{bookedTourpackages?.length} Bookings of Tour Package</p>
          </div>

          <div
          onClick={()=>navigate("/registereduser")}
            className="col-lg-5 text-light p-3 mb-3"
            style={{
              backgroundColor: "rgb(173 9 72)",
              borderRadius: "10px",
              cursor:"pointer"
            }}
          >
            <h6 className="fs-4 fw-bold">Users</h6>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <FaUsers className="fs-2" />
              <span className="fs-2 fw-bold ">{resgisterUser?.length}</span>
            </div>
            <p>{resgisterUser?.length} Number of Users</p>
          </div>

          <div
           onClick={()=>navigate("/admincontact")}
            className="col-lg-5 text-light p-3 mb-3"
            style={{
              backgroundColor: "rgb(173 80 9)",
              borderRadius: "10px",
            }}
          >
            <h6 className="fs-4 fw-bold">Enquiries</h6>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <BiSolidContact className="fs-2" />
              <span className="fs-2 fw-bold ">{ContactList?.length}</span>
            </div>
            <p>{ContactList?.length} Number of Users</p>
          </div>
        </div>
      </Container>

      <div style={{ position: "fixed", right: "5%", bottom: "13px" }}>
        <Link to={"/AdminChat"}>
          <IoMdChatboxes style={{
            fontSize: "65px",
            color: "#c93177",
            cursor: "pointer"
          }} />
        </Link>

      </div>
    </>
  );
}

export default Admindashboard;
