import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap/esm";
import Carousel from "react-grid-carousel";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export const TourPackages = () => {
  // Get activity
  const [GetActivity, setGetActivity] = useState([]);
  const GetActivityDetail = () => {
    axios
      .get("https://hayyyak.com/api/admin/activity/getactivitydetail")
      .then(function (response) {
        console.log(response.data);
        setGetActivity(response.data.getActivity);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("GetActivity", GetActivity);
  useEffect(() => {
    GetActivityDetail();
  }, []);

  return (
    <>
      <div style={{ marginBottom: "100px" }} classname='tourPackages-Main'>
        <Container>
          <div className="text-center mb-4" id='tourPackages-container'>
            <h2 className="home-all-headings">
              Activity <span>Packages</span>{" "}
            </h2>
            <p className="text-secondary">
              World's leading tour and travels Booking website,Over 30,000
              packages worldwide.
            </p>
          </div>

          <Carousel cols={3} rows={1} gap={30} loop >
            {GetActivity?.map((item) => (
              <Carousel.Item key={item.id}  >
                <div className="chacha">
                  <div
                    className="box-1"
                    style={{
                      backgroundImage: `url(https://hayyyak.com/ActivityCategory/${item?.activitycategories?.Image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "400px",
                      width: "100%",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="text-center text-light chacha-text">
                      <h2>
                        {parse(`<div>${item?.activitycategories?.Title}</div>`)}
                      </h2>
                      <h4>
                        <span>{item?.addlocation?.length} +</span> destinations
                      </h4>
                      <Link
                        state={{ item: item }}
                        to="/multipleactivity"
                        style={{ textDecoration: "none" }}
                      >
                        <button className="color-btn">Book Now</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </div>
    </>
  );
};
