import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { BsFillBusFrontFill } from 'react-icons/bs'
import { FaHotel } from 'react-icons/fa'
import HikingIcon from '@mui/icons-material/Hiking';
import axios from 'axios';
import moment from 'moment';
function VendorHotelDashboard() {

    const StayVendor = JSON.parse(sessionStorage.getItem("Stays"))

    // get Booked Stays
    const [bookingDetails, setbookingDetails] = useState([]);
    const BookStayDetails = async () => {
        try {
            const res = await axios.get("https://hayyyak.com/api/customer/stays/bookstaydetails");
            if (res.status === 200) {
                setbookingDetails(res.data.bookedStays);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        BookStayDetails()
    }, [])


    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-5 text-light p-3 mb-3"
                    style={{
                        backgroundColor: "rgb(131 49 217)",
                        // width: "261px",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold text-light">STAYS</h6>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <FaHotel className=" fs-2 " />
                        <span className="fs-2 fw-bold  ">{bookingDetails?.filter((ele) => ele?.roomId?.vendorId === StayVendor?._id)?.length}</span>
                    </div>
                    <p>{bookingDetails?.filter((ele) => ele?.roomId?.vendorId === StayVendor?._id)?.length} Booking of Stays</p>
                </div>
            </div>

            <p className="text-danger fw-bold fs-3 mb-2">Recent Booking List :</p>
            <div>
                <Table responsive bordered className="admin-table-head" id="Export-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Number</th>
                            <th>Stay Name</th>
                            <th width="400px">Stay Location</th>
                            <th>Room Name</th>
                            <th>Check In date</th>
                            <th>Check Out date</th>
                            <th>Booking Date</th>
                            <th>Total Person</th>
                            <th>Actual Price</th>
                            <th>Discount(%)</th>
                            <th>Discount(%) Price</th>
                            <th>GST(%) Price</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookingDetails?.filter((ele) => ele?.roomId?.vendorId === StayVendor?._id)?.slice(0, 5)?.map((item) => {
                            return (
                                <tr>
                                    <td>1</td>
                                    <td>{item?.userId?.Fname} {item?.userId?.Lname}</td>
                                    <td>{item?.userId?.Email}</td>
                                    <td>{item?.userId?.Mobile}</td>
                                    <td>{item?.stayId?.propartyname}</td>
                                    <td>{item?.stayId?.propartyaddress}</td>
                                    <td>{item?.roomId?.roomname}</td>
                                    <td>{item?.checkindate}</td>
                                    <td>{item?.checkoutdate}</td>
                                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {item?.adultquantity}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {item?.childrenquantity}

                                        </span>{" "}
                                        ,<br />
                                        Infants:{" "}
                                        <span style={{ color: "green" }}>
                                            {item?.infantsquantity}
                                        </span>{" "}
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            ₹{item?.roomId?.adultprice * item?.adultquantity}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            ₹{item?.roomId?.children * item?.childrenquantity}
                                        </span>{" "}
                                        ,<br />
                                        Infants:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            ₹{item?.roomId?.infants * item?.infantsquantity}
                                        </span>{" "}
                                        ,<br />
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            {item?.roomId?.adultdiscount}%
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            {item?.roomId?.childrendiscount}%
                                        </span>{" "}
                                        ,<br />
                                        Infants:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            {item?.roomId?.infantsdiscount}%
                                        </span>{" "}
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            ₹ {item?.adulttotalprice}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            ₹ {item?.childrentotalprice}
                                        </span>{" "}
                                        ,<br />
                                        Infants:{" "}
                                        <span style={{ color: "green" }}>
                                            ₹ {item?.infantstotalprice}
                                        </span>{" "}
                                    </td>
                                    <td style={{ color: "green", fontSize: "18px" }}>{item?.roomId?.gst}%</td>
                                    <td style={{ color: "green", fontSize: "18px" }}>₹{item?.AllTotalamount}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
            </div>

        </Container>
    )
}

export default VendorHotelDashboard