import React from 'react'
import { useEffect } from 'react'
import { Container, Form, InputGroup, Table } from 'react-bootstrap'
import { CiLocationOn } from 'react-icons/ci'
import { useLocation } from 'react-router-dom'
import parse from "html-react-parser"
import DatePicker from "react-multi-date-picker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IoIosArrowDown } from 'react-icons/io'
import { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { differenceInDays, format, parse as parseDateFns } from 'date-fns';
import moment from 'moment'

export const HotelReview = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    // data passing through item
    const location = useLocation()
    const { item, item2, stayslocation } = location.state;
    console.log("itemmmm", item);
    console.log("item2", item2);
    console.log("stayslocation", stayslocation);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [Adult, setAdult] = useState(1);
    const adultinc = () => {
        setAdult(Adult + 1);
    };
    const adultdec = () => {
        if (Adult > 1) {
            setAdult(Adult - 1);
        }
    };

    const [Children, setChildren] = useState(0);
    const childreninc = () => {
        setChildren(Children + 1);
    };
    const childrendec = () => {
        if (Children > 0) {
            setChildren(Children - 1);
        }
    };

    const [Infants, setInfants] = useState(0);
    const Infantsinc = () => {
        setInfants(Infants + 1);
    };
    const Infantsdec = () => {
        if (Infants > 0) {
            setInfants(Infants - 1);
        }
    };




    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    var today = new Date();
    var today2 = new Date();
    const [checkindate, setcheckindate] = useState(moment(today.setDate(today.getDate() + 1)).format("DD/MM/YYYY"))
    const [checkoutdate, setcheckoutdate] = useState(moment(today2.setDate(today2.getDate() + 2)).format("DD/MM/YYYY"))

    // Function to calculate the number of nights between check-in and check-out
    const calculateNights = () => {
        if (checkindate && checkoutdate) {
            const checkinDate = parseDateFns(checkindate, 'dd/MM/yyyy', new Date());
            const checkoutDate = parseDateFns(checkoutdate, 'dd/MM/yyyy', new Date());
            const nights = differenceInDays(checkoutDate, checkinDate);
            if (nights <= 0) {
                return 1
            } else return nights;
        }
        return 1; // Return 0 if either check-in or check-out date is not selected
    };
    const adulttotalprice = (item?.adultdiscountprice * Adult * calculateNights()).toFixed(2);
    const childrentotalprice = (item?.childrendiscountprice * Children * calculateNights()).toFixed(2);
    const infantstotalprice = (item?.infantsdiscountprice * Infants * calculateNights()).toFixed(2);

    const Customer = JSON.parse(sessionStorage.getItem("customer"));

    let wotgstamount = (item?.adultdiscountprice * Adult * calculateNights() +
        item?.childrendiscountprice * Children * calculateNights() +
        item?.infantsdiscountprice * Infants * calculateNights()).toFixed(2);

    let AllTotalamount = Number(wotgstamount) + Number(wotgstamount * item?.gst) / 100

    const BookingStay = async () => {
        try {
            const config = {
                url: "/customer/stays/staybooking",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "Content-Type": "application/json" },
                data: {
                    userId: Customer?._id,
                    stayId: item2?._id,
                    roomId: item?._id,
                    checkindate: checkindate,
                    checkoutdate: checkoutdate,
                    adulttotalprice: adulttotalprice,
                    adultquantity: Adult,
                    childrentotalprice: childrentotalprice,
                    childrenquantity: Children,
                    infantstotalprice: infantstotalprice,
                    infantsquantity: Infants,
                    AllTotalamount: AllTotalamount,
                }
            }
            const response = await axios(config)
            if (response.status === 200) {
                notify(response.data.success)
                window.location.assign("/hotel")
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    const OpenModel = () => {
        if (!Customer) {
            return notify1("Please Login")
        }
        if (!checkindate) {
            return notify1("Select Check in date")
        }
        if (!checkoutdate) {
            return notify1("Select Check out date")
        }
        if (!Adult) {
            return notify1("Please Select atleast one Adult")
        }
        handleShow()
    }


    useEffect(() => {
        if (stayslocation) {
            setAdult(stayslocation?.Adult)
            setChildren(stayslocation?.Children)
            setInfants(stayslocation?.Infants)
            setcheckindate(moment(stayslocation?.checkIndate).format("DD/MM/YYYY"));
            setcheckoutdate(moment(stayslocation?.checkOutdate).format("DD/MM/YYYY"))
        }
    }, [stayslocation])

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <h3 className='my-4'>Review Your <span className='text-danger'>Booking</span></h3>
                <div className="row mb-3">
                    <div className="col-lg-8">
                        <div className="hotel-bok mb-3" id="hotel-review-hotel-bok">
                            <div className="row">
                                <div className="col-lg-9">
                                    <p className='fw-bold fs-4 text-danger'>{item2?.propartyname}</p>
                                    <p className='text-secondary mb-2'><CiLocationOn className="text-danger" /> {item2?.propartyaddress}</p>
                                    <p className='fw-semibold fs-5 activity-h2'><span className='text-secondary fs-6 fw-light'>Room : </span>{item?.roomname}</p>
                                    <div className='dnasj_01'>
                                        {parse(`<div>${item?.amenities}</div>`)}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <img src={`https://hayyyak.com/VendorStay/${item?.roomimages?.[0]?.image}`} alt=""
                                     style={{ width: '140px', height: '100px', imageRendering: 'pixelated' }}
                                     className='hotel-review-image'
                                     />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="col-lg-4">
                        <div
                            style={{
                                border: "1px solid rgb(221, 221, 221)",
                                borderRadius: "12px",
                                padding: "24px",
                                boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
                                marginBottom: "20px",
                            }}
                            className='hotel-review-bok-details'
                        >
                            <div className="out_0">
                                <p>Starting From</p>
                                <h6>
                                    {/* {Adult > 0 ? (<> */}
                                    ₹{item?.adultdiscountprice * Adult * calculateNights()}{" "}
                                    <span>
                                        * {Adult} / <s>{item?.adultprice * Adult}</s> per Adult
                                    </span>

                                    ,

                                    <br />
                                    ₹{item?.childrendiscountprice * Children * calculateNights()}{" "}
                                    <span>
                                        * {Children} / <s>{item?.children * Children}</s> per children
                                    </span>
                                    ,
                                    <br />
                                    ₹{item?.infantsdiscountprice * Infants * calculateNights()}{" "}
                                    <span>
                                        * {Infants} / <s>{item?.infants * Infants}</s> per Infants
                                    </span>
                                    <hr />
                                </h6>
                                <div className="mb-2">
                                    <div className="bhjbsdf_0">
                                        <span>Total Amount</span>
                                        <s>
                                            ₹
                                            {item?.adultprice * Adult +
                                                item?.children * Children +
                                                item?.infants * Infants}
                                        </s>
                                    </div>
                                    <div className="bhjbsdf_0">
                                        <span>
                                            Discount Amount (%)
                                        </span>
                                        <p className="mb-0">
                                            {" "}
                                            ₹
                                            {(
                                                item?.adultprice * Adult +
                                                item?.children * Children +
                                                item?.infants * Infants -
                                                (item?.adultdiscountprice * Adult +
                                                    item?.childrendiscountprice * Children +
                                                    item?.infantsdiscountprice * Infants)
                                            ).toFixed(2)}

                                            <div style={{ fontSize: "12px" }}>
                                                ({item?.adultdiscount + item?.childrendiscount + item?.infantsdiscount}%off)
                                            </div>
                                        </p>
                                    </div>
                                    <div className="bhjbsdf_0">
                                        <span>GST</span>
                                        <p>
                                            {item?.gst} %
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="bhjbsdf_0">
                                        <span> Final Total</span>
                                        {Adult + Children + Infants}
                                        <p className="text-danger fw-bold">
                                            {" "}
                                            ₹
                                            {/* { (item?.adultdiscountprice * Adult +
                                                    item?.childrendiscountprice * Children).toFixed(2)} */}
                                            {AllTotalamount}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="d-flex mb-2">
                                    <div className="warner">
                                        <label>CheckIn Date</label> <br />
                                        <DatePicker
                                            minDate={new Date()}
                                            value={checkindate}
                                            onChange={(date) =>
                                                setcheckindate(date.format("DD/MM/YYYY"))
                                            }
                                            format='DD/MM/YYYY'
                                            placeholder="Add Date"
                                            style={{
                                                border: "none",
                                                padding: "3px",
                                                width: "145px",
                                            }}
                                        />
                                    </div>
                                    <div className="warner">
                                        <label>Check Out</label> <br />
                                        <DatePicker
                                            placeholder="Add Date"
                                            style={{ border: "none", padding: "3px", width: "145px" }}
                                            minDate={new Date()}
                                            value={checkoutdate}
                                            onChange={(date) =>
                                                setcheckoutdate(date.format("DD/MM/YYYY"))
                                            }
                                            format='DD/MM/YYYY'
                                        />
                                    </div>
                                </div>

                                <div className="warner-out">
                                    <p>Guest</p>
                                    <div onClick={handleClick}>
                                        <span>
                                            {" "}
                                            <span className="text-danger fw-bold">
                                                {Adult}{" "}
                                            </span>{" "}
                                            Adult,{" "}
                                        </span>
                                        <span>
                                            {" "}
                                            <span className="text-danger fw-bold">
                                                {Children}{" "}
                                            </span>{" "}
                                            Children,{" "}
                                        </span>
                                        <span>
                                            {" "}
                                            <span className="text-danger fw-bold">
                                                {Infants}{" "}
                                            </span>{" "}
                                            Infants,{" "}
                                        </span>
                                        {/* <span>{Pets} pet</span> */}
                                        <span className="float-end">
                                            <IoIosArrowDown />
                                        </span>
                                    </div>
                                </div>

                                {isOpen && (
                                    <>
                                        <div className="p-2">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <b>Adults</b>
                                                    <p style={{ fontSize: "12px" }}>Age 13+</p>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <RemoveIcon
                                                        onClick={() => adultdec()}
                                                        className="increase-icon"
                                                    />
                                                    <span>{Adult}</span>
                                                    <span>
                                                        {" "}
                                                        <AddIcon
                                                            onClick={() => adultinc()}
                                                            className="increase-icon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <b>Children</b>
                                                    <p style={{ fontSize: "12px" }}>Age 6-12</p>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <RemoveIcon
                                                        onClick={() => childrendec()}
                                                        className="increase-icon"
                                                    />
                                                    <span>{Children}</span>
                                                    <span>
                                                        {" "}
                                                        <AddIcon
                                                            onClick={() => childreninc()}
                                                            className="increase-icon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <b>Infants</b>
                                                    <p style={{ fontSize: "12px" }}>Age below </p>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <RemoveIcon
                                                        onClick={() => Infantsdec()}
                                                        className="increase-icon"
                                                    />
                                                    <span>{Infants}</span>
                                                    <span>
                                                        {" "}
                                                        <AddIcon
                                                            onClick={() => Infantsinc()}
                                                            className="increase-icon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div>
                                    <button
                                        className="header-search"
                                        style={{ width: "100%", height: "44px" }}
                                        onClick={OpenModel}
                                    >
                                        Confirm Booking
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Book model */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Booking <span className='text-danger'>Confirmation</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-between mb-2 text-center">
                        <div className="col-lg-4">
                            <p className='text-secondary'>Check In</p>
                            <p className='fw-bold'>{checkindate}</p>
                        </div>
                        <div className="col-lg-3">
                            <p className='prpt-nights'>{calculateNights()} Night(s)</p>
                        </div>
                        <div className="col-lg-4">
                            <p className='text-secondary'>Check Out</p>
                            <p className='fw-bold'>{checkoutdate}</p>
                        </div>
                    </div>

                    <div style={{
                        border: '1px solid rgb(221, 221, 221)',
                        borderRadius: '12px',
                        padding: '24px',
                        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px',
                    }}>
                        <p className='fw-semibold fs-5 mb-2'>Price Breakup</p>

                        <Table>
                            <tbody>
                                <tr>
                                    <td className='fw-semibold'>Adults x {Adult} </td>
                                    <td className='fw-medium text-secondary'>₹{adulttotalprice}</td>
                                </tr>
                                <tr>
                                    <td className='fw-semibold'>Children x {Children}</td>
                                    <td className='fw-medium text-secondary'>₹{childrentotalprice}</td>
                                </tr>
                                <tr>
                                    <td className='fw-semibold'>Infants x {Infants}</td>
                                    <td className='fw-medium text-secondary'>₹{infantstotalprice}</td>
                                </tr>
                                <tr>
                                    <td className='fw-semibold'>Discount%</td>
                                    <td className='fw-medium text-secondary'>
                                        -₹{
                                            (
                                                item?.adultprice * Adult * calculateNights() +
                                                item?.children * Children * calculateNights() +
                                                item?.infants * Infants * calculateNights() -
                                                (item?.adultdiscountprice * Adult * calculateNights() +
                                                    item?.childrendiscountprice * Children * calculateNights() +
                                                    item?.infantsdiscountprice * Infants * calculateNights())
                                            ).toFixed(2)
                                        }
                                        <span style={{ fontSize: '12px' }}>({item?.adultdiscount + item?.childrendiscount + item?.infantsdiscount}%)</span></td>
                                </tr>
                                <tr>
                                    <td className='fw-semibold'>GST%</td>
                                    <td className='fw-medium text-secondary'>{item?.gst}%</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="vasghd_0 p-1">
                            <p className='fw-bold fs-5'>Total Amount </p>
                            <p className='fw-medium text-danger fs-5'>₹{AllTotalamount}</p>
                        </div>
                        <div>
                            <button onClick={() => BookingStay()} className="header-search" style={{ width: '100%', height: '44px' }}>Pay Now</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
